// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import type { TBaseAction } from '../../types';
import { appApi } from '../../config/api.config';
import type { TSystemSetting } from '../../types/system_setting.types';
import { getSystemSettingsPageAction, receivedSystemSettingsPageAction, SystemSettingsActions } from '../actions/system_settings.actions';
import { genericErrorHandler } from '../../utils';
import { showNotificationAction } from '../actions/notificationActions';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import { API_PATHS } from '../../config/api.constants';

export function* page(): Generator<*, *, *> {
  const response = yield call(appApi.get, API_PATHS.V1.SYSTEM_SETTINGS);
  yield put(receivedSystemSettingsPageAction(response.data));
}

function base64EncodeValueAndReturnPayload(action) {
  const payload = action.payload;
  payload.value = btoa(action.payload.value);
  return payload;
}

export function* update(action: TBaseAction<TSystemSetting>): Generator<*, *, *> {
  try {
    const encodedPayload = base64EncodeValueAndReturnPayload(action);
    delete encodedPayload.description;
    yield call(appApi.put, `${API_PATHS.V1.SYSTEM_SETTINGS}/${action.payload.id}`, encodedPayload);
  } catch (err) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.SYSTEM_SETTINGS_FAIL));
  } finally {
    yield put(getSystemSettingsPageAction());
  }
}

export function* SystemSettingsSaga(): Generator<*, *, *> {
  yield takeLatest(SystemSettingsActions.page, genericErrorHandler(page));
  yield takeLatest(SystemSettingsActions.update, genericErrorHandler(update));
}
