// @flow
import { combineReducers } from 'redux';
import loadingReducer from '../loading.reducers';
import { ContainerVisitActions } from '../../actions';
import { pageReducer } from '../page.reducers';
import { lplListReducer } from './lplList.reducer';

const reducer = combineReducers({
  lplList: lplListReducer,
  loadingLplList: loadingReducer(ContainerVisitActions.lpl, ContainerVisitActions.receivedLPLList),
  visitPage: pageReducer(ContainerVisitActions.receivedVisitPage),
  loadingVisitPage: loadingReducer(ContainerVisitActions.visitPage, ContainerVisitActions.receivedVisitPage),
});

export default reducer;
