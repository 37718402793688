// @flow

import type { AggregatedWebSocketMessage, WebSocketMessage } from '../../types/websocket.types';

export type ActionSocketWithHandler = {
  payload: {
    handler: Function,
    message: WebSocketMessage | AggregatedWebSocketMessage,
  },
  type: string,
};

export const SocketActions = {
  refresh: 'SOCKET_ACTIONS.REFRESH',
};

export const refreshAction = (message: WebSocketMessage | AggregatedWebSocketMessage, handler: Function): ActionSocketWithHandler => ({
  type: SocketActions.refresh,
  payload: {
    message,
    handler,
  },
});
