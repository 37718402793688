// @flow
import { Button, Form, Input } from 'antd';
import 'braft-editor/dist/index.css';
import { FormattedMessage, injectIntl } from 'react-intl';
import BraftEditor from 'braft-editor';
import React, { useEffect } from 'react';
import { useForm } from 'antd/es/form/Form';
import type { TElearningPopover } from '../../../types';
import { FormStyles } from '../../../theme/form';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { browserHistory } from '../../../store';

export type TElearningFormProps = {
  data: TElearningPopover,
  form: any,
  intl: Object,
  onSubmit: (data: TElearningPopover) => void,
};

/**
 * Form to edit the elearning popover badges. The rich text is based on the BraftEditor.
 * It is also written in Chinese but it interplays well with AntD. Disadvantage is that it's content
 * has to be wrapped in an EditorState.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function EditForm(props: TElearningFormProps) {
  const [form] = useForm();
  const formCols = FormStyles.vertical;

  // Wrap the received body content as an editor state
  useEffect(() => {
    form.setFieldsValue({
      body: BraftEditor.createEditorState(props.data.body),
    });
  }, []);

  // General rule factory for required rules, the rich text has its own rule
  const requiredRules = () => [
    {
      message: props.intl.formatMessage(VALIDATION_MESSAGES.null),
      required: true,
    },
  ];

  // Handle the form submission
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        const body = values.body.toHTML();
        props.onSubmit({ ...values, body });
      })
      .catch((errorInfo) => console.error('Errors while submitting form', errorInfo));
  };

  return (
    <Form form={form} onFinish={handleSubmit} layout="vertical">
      <Form.Item {...formCols} name="key" initialValue={props.data.key} label={<FormattedMessage id="elearning.list.key" defaultMessage="Key" />}>
        <Input disabled />
      </Form.Item>
      <Form.Item {...formCols} name="title" initialValue={props.data.title} rules={requiredRules()} label={<FormattedMessage id="elearning.list.title" defaultMessage="Title" />}>
        <Input />
      </Form.Item>

      <Form.Item
        {...formCols}
        name="body"
        validateTrigger="onBlur"
        initialValue={props.data.body}
        rules={[
          {
            required: true,
            validator: async (_, value) => {
              if (value.isEmpty()) {
                await Promise.reject(new Error(props.intl.formatMessage(VALIDATION_MESSAGES.null)));
              } else {
                await Promise.resolve();
              }
            },
          },
        ]}
        label={<FormattedMessage id="elearning.list.body" defaultMessage="Body" />}
      >
        <BraftEditor className="elearning-editor" controls={['bold', 'italic', 'underline', 'link', 'list-ol', 'list-ul']} language="en" />
      </Form.Item>

      <Form.Item {...formCols}>
        <Button type="primary" htmlType="submit">
          <FormattedMessage id="general.submit" defaultMessage="Submit" />
        </Button>
        <Button type="link" onClick={() => browserHistory.goBack()}>
          <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
        </Button>
      </Form.Item>
    </Form>
  );
}

export const ElearningForm = injectIntl(EditForm);
