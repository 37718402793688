import React from 'react';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { Map } from '../map/Map';

export function BargeClerkDetailMap() {
  const visit = useSelector(selectBargeClerkVisit);

  return visit ? (
    //  At this point there should be a planned quay
    // $FlowFixMe
    <Map visit={visit} barge={visit.barge} quay={visit.quayPlanned || visit.quayRequested} />
  ) : null;
}
