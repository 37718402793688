// @flow
import type { TBaseAction, TBargeOperatorPageRequest, TBargeOperator, TBargeOperatorForm, TBargeOperatorEditForm, TPage } from '../../types';

export const BargeOperatorActions = {
  create: 'BARGE_OPERATOR_ACTIONS.CREATE',
  detail: 'BARGE_OPERATOR_ACTIONS.GET',
  edit: 'BARGE_OPERATOR_ACTIONS.EDIT',
  page: 'BARGE_OPERATOR_ACTIONS.PAGE',
  receivedDetail: 'BARGE_OPERATOR_ACTIONS.RECEIVED_DETAIL',
  receivedPage: 'BARGE_OPERATOR_ACTIONS.RECEIVED_PAGE',
  remove: 'BARGE_OPERATOR_ACTIONS.REMOVE',
};

export const createBargeOperatorAction = (payload: TBargeOperatorForm, callback: Function): TBaseAction<TBargeOperatorForm> => ({
  payload,
  callback,
  type: BargeOperatorActions.create,
});

export const editBargeOperatorAction = (payload: TBargeOperatorForm, callback: Function): TBaseAction<TBargeOperatorEditForm> => ({
  payload,
  callback,
  type: BargeOperatorActions.edit,
});

export const getBargeOperatorAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: BargeOperatorActions.detail,
});

export const getBargeOperatorPageAction = (payload: ?TBargeOperatorPageRequest): TBaseAction<?TBargeOperatorPageRequest> => ({
  payload,
  type: BargeOperatorActions.page,
});

export const removeBargeOperatorAction = (payload: TBargeOperator): TBaseAction<TBargeOperator> => ({
  payload,
  type: BargeOperatorActions.remove,
});

export const receivedBargeOperatorPageAction = (payload: TPage<TBargeOperator>): TBaseAction<TPage<TBargeOperator>> => ({
  payload,
  type: BargeOperatorActions.receivedPage,
});

export const receivedBargeOperatorAction = (payload: TBargeOperator): TBaseAction<TBargeOperator> => ({
  payload,
  type: BargeOperatorActions.receivedDetail,
});
