// @flow
import { AuthResponse } from 'msal';
import { takeLatest, call, put } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import { AuthenticationActions, isAuthenticatedAction, isAuthenticatingAction, setAuthenticationCachedAction, setAuthenticationTokenAction } from '../actions/authentication.actions';
import { AZURE_ID, azureProvider } from '../../config/authentication.config';
import store from '../index';
import { log } from '../../utils/log.util';

const saveTokenInStore = (newToken: string) => {
  const { user } = store.getState();
  appApi.defaults.headers.Authorization = `Bearer ${newToken}`;
  if (!user || !user.token || newToken !== user.token) {
    log.auth('acquireTokenSilent')('saveTokenInStore %s', newToken);
    store.dispatch(setAuthenticationTokenAction(newToken));
    store.dispatch(setAuthenticationCachedAction(true));
  }
};

const removeTokenFromStore = () => {
  log.auth('acquireTokenSilent')('removeTokenFromStore');
  store.dispatch(setAuthenticationTokenAction(null));
  store.dispatch(setAuthenticationCachedAction(false));
};

const acquireTokenSilent = async (account: any, forceRefresh: boolean = false): ?string => {
  try {
    log.auth('acquireTokenSilent')(`init ${forceRefresh ? ' - forced refresh' : ''} %O`, account);

    const { rawIdToken } = (await azureProvider.acquireTokenSilent({ account, scopes: [AZURE_ID], forceRefresh })).idToken;

    log.auth('acquireTokenSilent')(`done ${forceRefresh ? ' - forced refresh' : ''} %s`, rawIdToken);

    saveTokenInStore(rawIdToken);
  } catch (e) {
    log.auth('acquireTokenSilent')(`failed ${forceRefresh ? ' - forced refresh' : ''} %O`, e);
    if (!forceRefresh) {
      log.auth('acquireTokenSilent')('retry with forced refresh');
      await acquireTokenSilent(account, true);
    } else {
      removeTokenFromStore();
    }
  }
};

function setInterceptor(account: any) {
  log.auth('setInterceptor')('%O', account);
  // Register a callback to check if the token is still valid and refresh it with the refresh token silently.
  appApi.interceptors.request.use(async (config) => {
    log.auth('setInterceptor')('init %O', account);
    await acquireTokenSilent(account);
    log.auth('acquireTokenSilent')('interceptor - end');
    return config;
  });
}

export function* isAuthenticationCached(): Generator<*, *, *> {
  log.auth('isAuthenticationCached')('init');
  const account = azureProvider.getAccount();
  if (account) {
    yield call(acquireTokenSilent, account);
    log.auth('isAuthenticationCached')('set interceptor');
    // Register a callback to check if the token is still valid and refresh it with the refresh token silently.
    setInterceptor(account);
  } else {
    log.auth('isAuthenticationCached')('no account available');
    yield put(setAuthenticationCachedAction(false));
  }
  log.auth('isAuthenticationCached')('end');
}

export function* acquireToken(): Generator<*, *, *> {
  log.auth('acquireToken')('init');
  yield put(isAuthenticatingAction(true));

  try {
    const { account }: AuthResponse = yield call(() => azureProvider.loginPopup());
    // interceptor
    log.auth('acquireToken')('set interceptor');
    setInterceptor(account);

    yield put(isAuthenticatedAction(true));
  } catch (e) {
    log.auth('acquireToken')('error occurred %O', e);
    yield put(isAuthenticatedAction(false));
  }

  yield put(isAuthenticatingAction(false));
  log.auth('acquireToken')('end');
}

export function* AuthenticationSaga(): Generator<*, *, *> {
  yield takeLatest(AuthenticationActions.acquireToken, acquireToken);
  yield takeLatest(AuthenticationActions.isAuthenticationCached, isAuthenticationCached);
}
