import { defineMessages } from 'react-intl';

export const PLACEHOLDER_MESSAGES = defineMessages({
  email: {
    defaultMessage: 'john@doe.com',
    id: 'placeholder.email',
  },
  skype: {
    defaultMessage: 'skype',
    id: 'placeholder.skype',
  },
  mobile: {
    defaultMessage: '+32496979507',
    id: 'placeholder.mobile',
  },
  phone: {
    defaultMessage: '+3232219339',
    id: 'placeholder.phone',
  },
  remark: {
    defaultMessage: 'Some remark here...',
    id: 'placeholder.remark',
  },
  select_time: {
    defaultMessage: 'Select time',
    id: 'placeholder.from_time',
  },
  search: {
    defaultMessage: 'barge name, BTS id, …',
    id: 'placeholder.search',
  },
  generic_search: {
    defaultMessage: 'Search...',
    id: 'placeholder.search.generic',
  },
  now: {
    defaultMessage: 'Now',
    id: 'placeholder.now',
  },
});
