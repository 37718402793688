// @flow
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import { ContainerVisitActions, receivedLPLContainersAction, receivedVisitContainerPageAction } from '../actions';
import { genericErrorHandler } from '../../utils';
import type { TBaseAction, TContainerVisit, TPage, TPageRequest } from '../../types';
import { TContainerVisitPageAction } from '../../types';
import { formatSortUri, formatUri } from '../../utils/format.utils';
import type { TLPLGroup } from '../../types/lpl.types';
import { API_PATHS } from '../../config/api.constants';
import { createPath } from '../../utils/routing.utils';

export function* getPage(url: string, pageRequest: ?TPageRequest<null>): Generator<*, *, *> {
  const fullUrl = pageRequest ? formatUri(url, pageRequest) : url;

  return yield call(appApi.get, fullUrl);
}

export function* lplList(action: TBaseAction<TLPLGroup>): Generator<*, *, *> {
  const groupId = action.payload.id;
  const response = yield call(appApi.get, `${API_PATHS.V1.CONTAINER_VISIT_LPL_GROUP}/${groupId}`);

  yield put(receivedLPLContainersAction(response.data.content, groupId));
}

export function* lplSocketUpdate(): Generator<*, *, *> {
  const openLplsSelect = yield select((state) => state.containerVisits.lplList);
  const openLplGroupIds = openLplsSelect.map((lpl) => lpl.lplGroupId);
  const refreshCalls = openLplGroupIds.map((groupId) => call(appApi.get, `${API_PATHS.V1.CONTAINER_VISIT_LPL_GROUP}/${groupId}`));

  const responses = yield all(refreshCalls);
  const results = responses.map((response, index) => {
    const lplGroupId = openLplGroupIds[index];
    return put(receivedLPLContainersAction(response.data.content, lplGroupId));
  });

  yield all(results);
}

function* doPageCall(action: TBaseAction<TContainerVisitPageAction>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.CONTAINER_VISIT_VISIT, { id: action.payload.visitId });
  const savedPageInfo: TPage<TContainerVisit> = yield select((state) => state.containerVisits.visitPage.data);

  const urlSorted = savedPageInfo && savedPageInfo.sort && !action.payload.pageable ? `?${formatSortUri(savedPageInfo.sort)}` : '';

  return yield getPage(`${url}${urlSorted}`, action.payload.pageable);
}

export function* visit(action: TBaseAction<TContainerVisitPageAction>): Generator<*, *, *> {
  const response = yield call(doPageCall, action);
  yield put(receivedVisitContainerPageAction({ data: response.data }));
}

export function* ContainerVisitSaga(): Generator<*, *, *> {
  yield takeLatest(ContainerVisitActions.lpl, genericErrorHandler(lplList));
  yield takeLatest(ContainerVisitActions.lplSocket, genericErrorHandler(lplSocketUpdate));
  yield takeLatest(ContainerVisitActions.visitPage, genericErrorHandler(visit));
}
