// @flow

/**
 * Ant design counts from 1 and spring boot starts counting from 0. This helper fixes the problem
 * application wide.
 *
 * @param number
 * @returns {number}
 */
export const fixPaginationNumbers = (number: number) => number - 1;
