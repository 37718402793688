import { defineMessages } from 'react-intl';

export const VALIDATION_MESSAGES = defineMessages({
  max_characters: {
    defaultMessage: "Can't be longer than 255 characters",
    id: 'validation.rules.max_length',
  },
  phone_characters: {
    defaultMessage: "Can't be longer than 12 characters",
    id: 'validation.rules.phone_length',
  },
  null: {
    defaultMessage: 'Please enter a value',
    id: 'validation.rules.field_null',
  },
  blank: {
    defaultMessage: 'Should not be blank',
    id: 'validations.rules.field_blank',
  },
  email: {
    defaultMessage: 'Please enter an email address',
    id: 'validation.rules.format.email',
  },
  skype: {
    defaultMessage: 'Please enter a Skype address',
    id: 'validation.rules.required.skype',
  },
  phone: {
    defaultMessage: 'Please enter a valid number',
    id: 'validation.rules.required.phone',
  },
  type_of_contact_detail: {
    defaultMessage: 'Please select the type of contact detail',
    id: 'validation.rules.required.type_of_contact_detail',
  },
  start_before_end_time: {
    defaultMessage: 'The starting time should precede the ending time',
    id: 'validation.rules.required.start_before_end_time',
  },
  eni_character_limit: {
    defaultMessage: 'Must be 8 characters long',
    id: 'validation.rules.format.eni_character_limit',
  },
  higher_then_zero: {
    defaultMessage: 'Should be higher than 0',
    id: 'validation.rules.required.higher_then_zero',
  },
  number_of_centimeters: {
    defaultMessage: 'Should be higher then 0 and lower than 1 000 000',
    id: 'validation.rules.required.lower_then_one_mil',
  },
  min_number: {
    defaultMessage: 'Should be higher than {amount}',
    id: 'validation.rules.required.higher_than_amount',
  },
  max_number: {
    defaultMessage: 'Should be lower than {amount}',
    id: 'validation.rules.required.lower_than_amount',
  },
  not_unique: {
    defaultMessage: 'Not unique',
    id: 'validation.rules.required.not_unique',
  },
  invalid_geojson: {
    defaultMessage: 'Geojson invalid',
    id: 'validation.rules.required.geojson_invalid',
  },
  invalid_polygon: {
    defaultMessage: 'Only polygon geojson can be used. To create a new polygon geojson, please go to http://geojson.io, draw your polygon and copy everything inside the features array in this field.',
    id: 'validation.rules.required.invalid_polygon_geojson',
  },
  ops_time_within_current_shift_if_saldo: {
    defaultMessage: 'OPS time cannot be within the current shift when moving SALDO.',
    id: 'validations.rules.format.within_shift_if_saldo',
  },
});
