// @flow
import * as React from 'react';
import { Tooltip } from 'antd';
import { FullscreenOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';

export const fourArrowsTag = (
  <Tooltip title={<FormattedMessage id="special_containers.tooltip.message.oversize" defaultMessage="This visit contains containers with special sizes" />}>
    <FullscreenOutlined className="icon-oversize" />
  </Tooltip>
);
