// @flow
import * as React from 'react';
import { Alert, Button, Modal } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { SPACING } from '../../../constants/style.constants';
import { undoOperationsTimeAction } from '../../../store/actions';

type TProps = {
  undoOperationsTimeAction: typeof undoOperationsTimeAction,
  hideModal: Function,
  showOpsModal: Function,
  visible: boolean,
  visit: Object,
};

export function UndoOperationsTimeConfirmationModalComponent({ undoOperationsTimeAction, hideModal, showOpsModal, visible, visit }: TProps) {
  if (!visit) return null;

  const onClick = () => {
    undoOperationsTimeAction(visit);
    hideModal();
  };

  const onClose = () => {
    hideModal();
    showOpsModal();
  };

  return (
    <Modal title={<FormattedMessage id="molecules.modals.undo_ops_time.title" defaultMessage="Undo OPT time" />} centered open={visible} footer={null} onCancel={onClose} maskClosable width={700}>
      <Alert
        message={<FormattedMessage id="opt_time_modal.warning.gangs.title" defaultMessage="Watch out" />}
        description={<FormattedMessage id="molecules.modals.undo_ops_time.detail" defaultMessage="Undo OPS time" />}
        type="warning"
        style={{ marginBottom: SPACING.COLLAPSE }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <Button onClick={onClick} type="default" style={{ marginBottom: 0, marginRight: SPACING.COLLAPSE }} htmlType="submit">
          <FormattedMessage id="general.submit" defaultMessage="Submit" />
        </Button>
        <button className="visit-top-link" onClick={onClose} style={{ marginRight: SPACING.COLLAPSE }} type="button">
          <FormattedMessage id="general.cancel_change" defaultMessage="Cancel change" />
        </button>
      </div>
    </Modal>
  );
}

const mapStateToProps = (dispatch) => {
  return { undoOperationsTimeAction: (visit) => dispatch(undoOperationsTimeAction(visit)) };
};

export const UndoOperationsTimeConfirmationModal = connect<any, Object, _, _, _, _>(mapStateToProps, { undoOperationsTimeAction })(injectIntl(UndoOperationsTimeConfirmationModalComponent));
