// @flow
import * as React from 'react';
import { Table } from 'antd';
import moment from 'moment/moment';
import type { TDiagnostics } from '../../types/diagnostic.types';
import { humanizeTimeStamp } from '../../utils/dates.utils';

type TProps = {
  diagnostics: TDiagnostics,
};

export function DiagnosticsComponent(props: TProps) {
  function objToArray(obj) {
    const array = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const p in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, p)) {
        array.push(`${p}:\t${humanizeTimeStamp(moment(obj[p]))} (${obj[p]})`);
      }
    }
    return array;
  }

  function tsToString(ts) {
    return [`${humanizeTimeStamp(moment(ts))} (${ts})`];
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Name',
      dataIndex: 'name',
    },
    {
      title: 'Last Seen',
      dataIndex: 'timestamp',
      render: (tss: ?(string[])) => {
        if (!tss) return '';
        if (tss.length === 1) return `${tss[0]}`;
        return (
          <ul>
            {tss.map((ts) => {
              return <li key={ts}>{ts}</li>;
            })}
          </ul>
        );
      },
    },
  ];
  const data: { name: string, timestamp: string }[] = [
    {
      name: 'IFTSAI',
      timestamp: props.diagnostics.iftsaiProcessedAt && tsToString(props.diagnostics.iftsaiProcessedAt),
      key: 'IFTSAI',
    },
    {
      name: 'IFTSTA',
      timestamp: props.diagnostics.iftstaProcessedAt && tsToString(props.diagnostics.iftstaProcessedAt),
      key: 'IFTSTA',
    },
    {
      name: 'VisitTosRegistration',
      timestamp: objToArray(props.diagnostics.visitTosRegistrationProcessedAt),
      key: 'VisitTosRegistration',
    },
    {
      name: 'ContainerVisit',
      timestamp: objToArray(props.diagnostics.containerVisitProcessedAt),
      key: 'ContainerVisit',
    },
    {
      name: 'Error',
      timestamp: props.diagnostics.errorProcessedAt && tsToString(props.diagnostics.errorProcessedAt),
      key: 'Error',
    },
    {
      name: 'ContainerCustoms',
      timestamp: props.diagnostics.containerCustomsProcessedAt && tsToString(props.diagnostics.containerCustomsProcessedAt),
      key: 'ContainerCustoms',
    },
  ];
  return <Table columns={columns} dataSource={data} pagination={false} />;
}
