// @flow
import type { TBaseAction } from '../../types';
import type { TVisitLplRemark } from '../../types/remark.types';

export const LPLRemarkActions = {
  create: 'LPL_REMARK.CREATE',
  list: 'LPL_REMARKS.LIST',
  receivedList: 'LPL_REMARKS.RECEIVED_LIST',
};

export const getLPLRemarksAction = (payload: number): TBaseAction<string> => ({
  payload,
  type: LPLRemarkActions.list,
});

export const receivedLPLRemarksAction = (payload: TVisitLplRemark[]): TBaseAction<TVisitLplRemark[]> => ({
  payload,
  type: LPLRemarkActions.receivedList,
});

export const createLPLRemarkAction = (payload: TVisitLplRemark): TBaseAction<TVisitLplRemark> => ({
  payload,
  type: LPLRemarkActions.create,
});
