// @flow
import type { TBargePageRequest, TBaseAction, TAction, TBarge, TBargeEditForm, TBargeForm, TPage } from '../../types';
import type { TLabel } from '../../types/label.types';

export const BargeActions = {
  autocomplete: 'BARGE_ACTIONS.AUTO_COMPLETE',
  clearAutocompletions: 'BARGE_ACTIONS.CLEAR_AUTO_COMPLETE',
  create: 'BARGE_ACTIONS.CREATE',
  detail: 'BARGE_ACTIONS.GET',
  edit: 'BARGE_ACTIONS.EDIT',
  page: 'BARGE_ACTIONS.PAGE',
  receivedDetail: 'BARGE_ACTIONS.RECEIVED_DETAIL',
  receivedPage: 'BARGE_ACTIONS.RECEIVED_PAGE',
  receivedAutocompletions: 'BARGE_ACTIONS.RECEIVED_AUTOCOMPLETION',
  remove: 'BARGE_ACTIONS.REMOVE',
};

export const autocompleteBargeAction = (payload: string): TBaseAction<string> => ({
  payload,
  type: BargeActions.autocomplete,
});

export const clearBargeAutocompletions = (): TAction => ({
  type: BargeActions.clearAutocompletions,
});

export const createBargeAction = (payload: TBargeForm, callback?: Function): TBaseAction<TBargeForm> => ({
  payload,
  callback,
  type: BargeActions.create,
});

export const editBargeAction = (payload: TBargeForm, callback?: Function): TBaseAction<TBargeEditForm> => ({
  payload,
  callback,
  type: BargeActions.edit,
});

export const getBargeAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: BargeActions.detail,
});

export const getBargePageAction = (payload: ?TBargePageRequest): TBaseAction<?TBargePageRequest> => ({
  payload,
  type: BargeActions.page,
});

export const removeBargeAction = (payload: TBarge): TBaseAction<TBarge> => ({
  payload,
  type: BargeActions.remove,
});

export const receivedBargePageAction = (payload: TPage<TBarge>): TBaseAction<TPage<TBarge>> => ({
  payload,
  type: BargeActions.receivedPage,
});

export const receivedBargeAction = (payload: TBarge): TBaseAction<TBarge> => ({
  payload,
  type: BargeActions.receivedDetail,
});

export const receivedBargeAutocompletions = (payload: TLabel[]): TBaseAction<TLabel[]> => ({
  payload,
  type: BargeActions.receivedAutocompletions,
});
