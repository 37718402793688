// @flow
import * as React from 'react';
import { CrudTemplate } from '../CrudTemplate';
import { EditHeader } from '../../molecules/master_data/EditHeader';

type TProps = {
  children: React.Node,
  loading?: boolean,
  menuBlock?: string,
  menuItem?: string,
  title: string,
};

export function EditTemplate(props: TProps) {
  return (
    <CrudTemplate loading={props.loading} menuItem={props.menuItem} menuBlock={props.menuBlock}>
      <EditHeader title={props.title} />
      {props.children}
    </CrudTemplate>
  );
}

EditTemplate.defaultProps = {
  loading: false,
  menuBlock: '',
  menuItem: '',
};
