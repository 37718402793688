// @flow
/* eslint sort-keys: 0 */
import { ENV } from './environment';

export const BASE_URL = ENV.REACT_APP_BASE_URL || '';

export const SITE_PATHS = {
  HOME: '/',
  MASTER_DATA_HOME: '/master-data',
  SKIPPER_LIST: '/skippers',
  SKIPPER_CREATE: '/skipper/create',
  SKIPPER_DETAIL: '/skipper/detail/:id',
  SKIPPER_UPDATE: '/skipper/edit/:id',

  BARGE_LIST: '/barges',
  BARGE_CREATE: '/barge/create',
  BARGE_DETAIL: '/barge/detail/:id',
  BARGE_UPDATE: '/barge/edit/:id',

  CONFIGURATION: '/configuration',
  DIAGNOTICS: '/diagnostics',

  VISIT_LIST: '/visits',
  VISIT_DETAIL: '/visit/detail/:id',

  BARGE_OPERATOR_LIST: '/barge-operators',
  BARGE_OPERATOR_CREATE: '/barge-operator/create',
  BARGE_OPERATOR_DETAIL: '/barge-operator/detail/:id',
  BARGE_OPERATOR_UPDATE: '/barge-operator/edit/:id',

  ERROR_CODE_LIST: '/error-codes',
  ERROR_CODE_CREATE: '/error-code/create',
  ERROR_CODE_DETAIL: '/error-code/detail/:id',
  ERROR_CODE_UPDATE: '/error-code/edit/:id',

  E_LEARNING_DP_OVERVIEW_LIST: '/elearning/dp-overview',
  E_LEARNING_DP_DETAIL_LIST: '/elearning/dp-detail',
  E_LEARNING_LB_OVERVIEW_LIST: '/elearning/lb-overview',
  E_LEARNING_LB_DETAIL_LIST: '/elearning/lb-detail',
  E_LEARNING_EDIT: '/elearning/edit/:key',

  QUAY_LIST: '/quays',
  QUAY_DETAIL: '/quay/detail/:id',
  QUAY_CREATE: '/quay/create',
  QUAY_UPDATE: '/quay/edit/:id',

  LPL_LIST: '/lpl',
  LPL_DETAIL: '/lpl/detail/:id',

  BARGE_CLERK_LIST: '/barge-clerk',
  BARGE_CLERK_DETAIL: '/barge-clerk/detail/:id',

  NOT_FOUND: '/notFound',
  UNAUTHORIZED: '/forbidden',
  LOGIN: '/login',
};

export const API_PATHS = {
  V1: {
    BARGE: '/v1/barge',
    BARGE_OPERATOR: '/v1/barge-operator',
    BARGE_AUTOCOMPLETE: '/v1/barge/autocomplete',

    CONTAINER_VISIT_LPL_GROUP: '/v1/container-visit/lpl-group',
    CONTAINER_VISIT_VISIT: '/v1/container-visit/visit/:id/',

    ERRORS_INTERNAL: '/v1/error/visit/:id/INTERNAL',
    ERRORS_EXTERNAL: '/v1/error/visit/:id/EXTERNAL',

    ERROR_CODE: '/v1/error-code',
    ERROR_ARCHIVE: '/v1/error/archive',
    ERROR_UNARCHIVE: '/v1/error/unarchive',

    GANG: '/v1/gang',
    GANG_FOR_OM: '/v1/gang/for/om',
    GANG_ASSIGNMENTS: '/v1/gang/assignments',

    GANG_LB: '/v1/gang/lb',

    GANG_OM: '/v1/gang/om',

    GANG_REMARK_GANG: '/v1/gang-remark/gang',

    LOGOUT: '/v1/user/logout',

    LPL_GROUP: '/v1/lpl-group',
    LPL_GROUP_VISIT: '/v1/lpl-group/visit/:id',

    LPL_REQUEST: '/v1/lpl-request',
    LPL_REQUEST_TOGGLE_PROGRESS: '/v1/lpl-request/:id/toggle-progress',
    LPL_REQUEST_CANCEL: '/v1/lpl-request/:id/cancel',
    LPL_REQUEST_COMPLETE: '/v1/lpl-request/:id/complete',
    LPL_REQUEST_LPL_GROUP: '/v1/lpl-request/lpl-group',

    SAILING_SCHEDULE: '/v1/sailling-schedule/visit/:id',

    SHIFT: '/v1/shift',
    SHIFTS_FILTER: '/v1/shift/filter',
    SHIFT_PER_TAL_VIEW: '/v1/shift/tal-per-shift-view',

    SKIPPER: '/v1/skipper',

    DIAGNOSTICS: '/v1/diagnostics',

    SYSTEM_SETTINGS: '/v1/system-setting',
    SYSTEM_SETTINGS_PORT_GEOFENCE: '/v1/system-setting/port-geofence',

    VISIT: '/v1/visit',
    VISIT_DP_SEARCH_VIEW: '/v1/visit/dp-search-view',
    VISIT_LPL_SEARCH_VIEW: '/v1/visit/lpl-search-view',
    VISIT_LB_OVERVIEW_VIEW: '/v1/visit/lb-overview-view',
    VISIT_FILTER_STATUS_DATA_PROCESSOR: '/v1/visit/statuses',
    VISIT_FILTER_SHIFTS_DATA_PROCESSOR: '/v1/visit/shifts',
    VISIT_FILTER_QUAYS_REQUESTED_DATA_PROCESSOR: '/v1/visit/quays-requested',
    VISIT_FILTER_QUAYS_PLANNED_DATA_PROCESSOR: '/v1/visit/quays-planned',
    VISIT_FILTER_BTS_ID_DATA_PROCESSOR: '/v1/visit/bts-ids',
    VISIT_FILTER_BARGES_DATA_PROCESSOR: '/v1/visit/barges',
    VISIT_FILTER_BARGE_OPERATORS_DATA_PROCESSOR: '/v1/visit/barge-operators',
    VISIT_FILTER_STATUS_LPL: '/v1/visit/statuses',
    VISIT_FILTER_BTS_ID_LPL: '/v1/visit/bts-ids',
    VISIT_FILTER_QUAYS_REQUESTED_LPL: '/v1/visit/quays-requested',
    VISIT_FILTER_QUAYS_PLANNED_LPL: '/v1/visit/quays-planned',
    VISIT_FILTER_BARGES_DATA_LPL: '/v1/visit/barges',
    VISIT_FILTER_BARGE_OPERATORS_LPL: '/v1/visit/barge-operators',
    VISIT_BARGE_CLERKS_DOWNLOAD_EXCEL_NEXT_SHIFTS: '/v1/visit/barge-clerks/download-next-shifts',
    VISIT_FILTER_STATUS_BARGE_CLERK: '/v1/visit/statuses?type=barge_clerk',
    VISIT_FILTER_BTS_ID_BARGE_CLERK: '/v1/visit/bts-ids?type=barge_clerk',
    VISIT_FILTER_BARGES_BARGE_CLERK: '/v1/visit/barges?type=barge_clerk',
    VISIT_FILTER_SPECIAL_CONTAINERS_BARGE_CLERK: '/v1/visit/special-containers?type=barge_clerk',
    VISIT_SEND_ERRORS: '/v1/visit/:id/send-errors',
    VISIT_BOLLARDS: '/v1/visit/:id/bollards',
    VISIT_CONTACT_DETAILS: '/v1/visit/:id/contact_details',
    VISIT_PROCESSED: '/v1/visit/:id/processed/:value',
    VISIT_PRIORITY: '/v1/visit/:id/priority/:value',
    VISIT_AVAILABLE: '/v1/visit/:id/available/:value',
    VISIT_IDLE_TIME_REASON: '/v1/visit/:id/idle-time-reason',
    VISIT_ACTUAL_TIME_ARRIVAL: '/v1/visit/:id/actual-time-arrival',
    VISIT_OPERATIONS_TIME: '/v1/visit/:id/operations-time',
    VISIT_LB_CANCEL: '/v1/visit/:id/lb-cancel',
    VISIT_GANG: '/v1/visit/:id/gang',
    VISIT_BTS: '/v1/visit/:id/BTS/:direction/:size/:state',

    VISIT_REMARK: '/v1/visit-remark/visit/:id',
    VISIT_REMARK_LPL: '/v1/visit-remark/visit/:id/LPL',
    VISIT_REMARK_VISIT: '/v1/visit-remark/visit/:id/VISIT',

    QUAY: '/v1/quay',
    QUAY_DELETE: '/v1/quay/:id',
    QUAY_PSA: '/v1/quay/psa',
    QUAY_ALL: '/v1/quay/all',
  },
};

export const API_STATUS_CODE = {
  OK: 200,
  SUCCESS_NO_CONTENT: 204,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  CONFLICT: 409,
  UNPROCESSABLE_ENTITY: 422,
  INTERNAL_SERVER_ERROR: 500,
};
