// @flow
// The ID of the root element where the portal is hooked on
import { useCallback, useEffect, useRef } from 'react';

const PORTAL_ID = 'elearning';

/**
 * Hook to create an element under the portal.
 *
 * @returns HTMLElement
 */
export function usePortal(): HTMLElement | null {
  if (document) {
    // Get the root element of the portal
    const getRootElement = useCallback(() => document.getElementById(PORTAL_ID));

    // Create an element ref
    const elementRef = useRef(document.createElement('div'));

    // Append the element to the root
    useEffect(() => {
      const rootElement = getRootElement();
      if (!rootElement) {
        return () => null;
      }

      rootElement.appendChild(elementRef.current);

      // Provide a 'destructor' to remove the badge
      return () => elementRef.current.remove();
    }, []);

    return elementRef.current;
  }
  return null;
}
