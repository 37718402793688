// @flow
import debug from 'debug';
import { ENV } from '../config/environment';

export const LOG_INITIAL_FILTER = ENV.REACT_APP_DEBUG_CONFIG || '*';
const LOG_PREFIX = 'optibarge';

const logger = (...keys: string[]) => debug(`${LOG_PREFIX} ${keys.join(' ')}`);

export const log = {
  auth: (...keys: string[]) => logger('auth', ...keys),
  ws: (...keys: string[]) => logger('websocket', ...keys),
  app: (...keys: string[]) => logger('app', ...keys),
  api: (...keys: string[]) => logger('api', ...keys),
};
