// @flow
import * as React from 'react';
import { Button, Form, Modal, Row, Select } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { keys } from 'lodash';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { IDLE_TIME_REASON_MESSAGES } from '../../../config/messages/idleTimeReasons';
import { setIdleTimeReasonAction } from '../../../store/actions';
import { SPACING } from '../../../constants/style.constants';
import type { TVisit } from '../../../types';
import { VisitSummary } from './VisitSummary';
import { createPath } from '../../../utils/routing.utils';
import { API_PATHS } from '../../../config/api.constants';

type TProps = {
  form: any,
  hideModal: Function,
  intl: intlShape,
  showDialog: boolean,
  visit: TVisit,
};

function IdleTimeReasonModalComponent(props: TProps) {
  const [form] = useForm();

  const dispatch = useDispatch();
  if (!props.visit) return null;

  const onSubmit = () => {
    form.validateFields().then((values) => {
      const { reason } = values;
      const url = createPath(API_PATHS.V1.VISIT_IDLE_TIME_REASON, { id: props.visit.id });
      dispatch(setIdleTimeReasonAction(url, reason ? reason.toString() : null));
      props.hideModal();
    });
  };

  return (
    <Modal
      title={<FormattedMessage id="atoms.modals.idle_time_reason.title" defaultMessage="Select a reason" />}
      centered
      open={props.showDialog}
      onOk={props.hideModal}
      onCancel={props.hideModal}
      maskClosable
      footer={null}
      destroyOnClose
    >
      <Row type="flex" justify="start" style={{ marginBottom: SPACING.COLLAPSE }}>
        <VisitSummary visit={props.visit} />
      </Row>
      <Row>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item name="reason" initialValue={props.visit.idleTimeReason}>
            <Select
              allowClear
              style={{ width: 233 }}
              placeholder={<FormattedMessage id="detail-section.idle_time_reason.placeholder" defaultMessage="Select a reason" />}
              value={props.visit.idleTimeReason}
              size="large"
            >
              {keys(IDLE_TIME_REASON_MESSAGES).map((key) => (
                <Select.Option key={key} value={key}>
                  {props.intl.formatMessage(IDLE_TIME_REASON_MESSAGES[key])}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Form.Item style={{ marginBottom: 0, marginRight: SPACING.COLLAPSE }}>
              <Button type="default" htmlType="submit">
                <FormattedMessage id="general.submit_change" defaultMessage="Submit change" />
              </Button>
            </Form.Item>
            <button className="visit-top-link" onClick={props.hideModal} style={{ padding: 0 }} type="button">
              <FormattedMessage id="general.cancel_change" defaultMessage="Cancel change" />
            </button>
          </div>
        </Form>
      </Row>
    </Modal>
  );
}

export const IdleTimeReasonModal = injectIntl(IdleTimeReasonModalComponent);
