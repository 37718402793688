// @flow
import { Button, Col, Row } from 'antd';
import qs from 'qs';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FilterFilled, FilterOutlined } from '@ant-design/icons';
import { SITE_PATHS } from '../../../config/api.constants';
import { FILTER_TYPES } from '../../../constants/filters.constants';
import { SPACING } from '../../../constants/style.constants';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { browserHistory } from '../../../store';
import {
  getBargeLPLFilterAction,
  getBargeOperatorLPLFilterAction,
  getLPLBtsIdFilterAction,
  getLPLPageAction,
  getQuayPlannedLPLFilterAction,
  getQuayRequestedLPLFilterAction,
  getStatusLplPageAction,
} from '../../../store/actions/lpl.actions';
import { refreshAction } from '../../../store/actions/socket.actions';
import { updateLplVisitList } from '../../../store/handlers/visits.handler';
import type { TFilterObject, TPageWithFilter, TPaging, TSortingRequest, TVisit, TVisitDataProcessorOverview, TVisitFilter } from '../../../types';
import { createPath } from '../../../utils/routing.utils';
import { ProgressTracker } from '../../atoms/ProgressTracker';
import { renderOPTOrRTAOrETA, renderQuay, renderStatus } from '../../atoms/renderers';
import { Socket } from '../../atoms/Socket';
import { Filter } from '../../atoms/Tables/filters/Filter';
import { FilterRange } from '../../atoms/Tables/filters/FilterRange';
import { FilterWithSearch } from '../../atoms/Tables/filters/FilterWithSearch';
import { FilterWithSearchAndObjects } from '../../atoms/Tables/filters/FilterWithSearchAndObjects';
import { Table } from '../../atoms/Tables/Table';
import { formatAscDesc, pageToPageRequest } from '../../../utils/format.utils';

type TProps = {
  getBargeLPLFilterAction: typeof getBargeLPLFilterAction,
  getBargeOperatorLPLFilterAction: typeof getBargeOperatorLPLFilterAction,
  getLPLBtsIdFilterAction: typeof getLPLBtsIdFilterAction,
  getLPLPageAction: typeof getLPLPageAction,
  getQuayPlannedLPLFilterAction: typeof getQuayPlannedLPLFilterAction,
  getQuayRequestedLPLFilterAction: typeof getQuayRequestedLPLFilterAction,
  getStatusLplPageAction: typeof getStatusLplPageAction,
  loadingPage: boolean,
  page: TPageWithFilter<TVisit>,
  refreshAction: typeof refreshAction,
};

type TState = {
  isBargeOpen: boolean,
  isBargeOperatorOpen: boolean,
  isBtsIdOpen: boolean,
  isQuayPlannedOpen: boolean,
  isQuayRequestedOpen: boolean,
  isStatusFilterOpen: boolean,
};

export class LplListTable extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      isBargeOpen: false,
      isBargeOperatorOpen: false,
      isBtsIdOpen: false,
      isStatusFilterOpen: false,
      isQuayPlannedOpen: false,
      isQuayRequestedOpen: false,
    };
  }

  updateUrl = (urlObject?: { filter?: TVisitFilter | {}, paging: TPaging, sorting?: ?TSortingRequest }) => {
    browserHistory.push(`/lpl?${qs.stringify(urlObject)}`);
  };

  handleSearch = (selectedValues: string[] | TFilterObject[], filterProp: string) => {
    const { filter, data } = this.props.page;
    const { pageable, sort } = data;

    if (!filter) {
      throw Error('Filter values should be provided.');
    }

    const newFilter: TVisitFilter = { ...filter };
    newFilter[filterProp] = selectedValues;

    this.updateUrl({ filter: newFilter, paging: pageable, sort });
  };

  clearFilter = () => {
    this.updateUrl();
  };

  update = (pagination: any, filters: any, sorter: any) => {
    this.updateUrl({
      filter: this.props.page.filter,
      paging: { number: pagination.current - 1, size: this.props.page.data.size },
      sorting: { by: [sorter.columnKey], order: sorter.order },
    });
  };

  getSortOrder(key: string) {
    const { sort } = this.props.page.data;
    if (sort && sort.by && sort.by.indexOf(key) > -1) {
      return formatAscDesc(sort.order);
    }

    return null;
  }

  render() {
    if (!this.props.page.data) return null;

    const columns = [
      {
        align: 'left',
        dataIndex: 'btsId',
        key: 'btsId',
        title: <FormattedMessage id="organisms.visits.visit_list.bts_id_title" defaultMessage="BTS ID" />,
        filterDropdown: ({ confirm }) => (
          <FilterWithSearch
            filterType={FILTER_TYPES.LPL}
            filterProp="btsId"
            search={this.handleSearch}
            confirm={confirm}
            dataAction={this.props.getLPLBtsIdFilterAction}
            visible={this.state.isBtsIdOpen}
          />
        ),
        onFilterDropdownVisibleChange: (visible) => {
          this.setState({ isBtsIdOpen: visible });
        },
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.btsId.length) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.btsId.length ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.btsId.length ? 'filtered' : ''} />;
        },
        width: '10%',
      },
      {
        align: 'left',
        key: 'etaRtaOps',
        render: renderOPTOrRTAOrETA,
        sorter: true,
        sortOrder: this.getSortOrder('etaRtaOps'),
        title: <FormattedMessage id="organisms.visits.visit_list.rta_eta_title" defaultMessage="RTA / ETA" />,
        filterDropdown: () => <FilterRange filterType={FILTER_TYPES.LPL} filterProp="etaRtaOps" search={this.handleSearch} />,
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.etaRtaOps) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.etaRtaOps.from ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.etaRtaOps.from ? 'filtered' : ''} />;
        },
        width: '15%',
        defaultSortOrder: 'ascend',
      },
      {
        align: 'left',
        key: 'quayRequestedName',
        render: (visit: TVisitDataProcessorOverview) => renderQuay({ name: visit.quayRequestedName }),
        sorter: true,
        sortOrder: this.getSortOrder('quayRequestedName'),
        title: <FormattedMessage id="organisms.visits.visit_list.quay_requested_title" defaultMessage="Quay requested" />,
        filterDropdown: ({ confirm }) => (
          <FilterWithSearchAndObjects
            filterType={FILTER_TYPES.LPL}
            filterProp="quayRequested"
            search={this.handleSearch}
            confirm={confirm}
            visible={this.state.isQuayRequestedOpen}
            dataAction={this.props.getQuayRequestedLPLFilterAction}
          />
        ),
        onFilterDropdownVisibleChange: (visible) => {
          this.setState({ isQuayRequestedOpen: visible });
        },
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.quayRequested.length) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.quayRequested.length ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.quayRequested.length ? 'filtered' : ''} />;
        },
        width: '10%',
      },
      {
        align: 'left',
        key: 'quayPlannedName',
        render: (visit: TVisitDataProcessorOverview) => renderQuay({ name: visit.quayPlannedName }),
        sorter: true,
        sortOrder: this.getSortOrder('quayPlannedName'),
        title: <FormattedMessage id="organisms.visits.visit_list.quay_planned_title" defaultMessage="Quay planned" />,
        filterDropdown: ({ confirm }) => (
          <FilterWithSearchAndObjects
            filterType={FILTER_TYPES.LPL}
            filterProp="quayPlanned"
            search={this.handleSearch}
            confirm={confirm}
            visible={this.state.isQuayPlannedOpen}
            dataAction={this.props.getQuayPlannedLPLFilterAction}
          />
        ),
        onFilterDropdownVisibleChange: (visible) => {
          this.setState({ isQuayPlannedOpen: visible });
        },
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.quayPlanned.length) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.quayPlanned.length ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.quayPlanned.length ? 'filtered' : ''} />;
        },
        width: '10%',
      },
      {
        align: 'left',
        className: 'relative',
        dataIndex: 'bargeName',
        key: 'bargeName',
        sorter: true,
        sortOrder: this.getSortOrder('bargeName'),
        title: <FormattedMessage key="test" id="organisms.visits.visit_list.barge_title" defaultMessage="Barge" />,
        filterDropdown: ({ confirm }) => (
          <FilterWithSearchAndObjects
            filterType={FILTER_TYPES.LPL}
            filterProp="barge"
            search={this.handleSearch}
            confirm={confirm}
            visible={this.state.isBargeOpen}
            dataAction={this.props.getBargeLPLFilterAction}
          />
        ),
        onFilterDropdownVisibleChange: (visible) => {
          this.setState({ isBargeOpen: visible });
        },
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.barge.length) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.barge.length ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.barge.length ? 'filtered' : ''} />;
        },
        width: '10%',
      },
      {
        align: 'left',
        dataIndex: 'bargeOperatorName',
        key: 'bargeOperatorName',
        sorter: true,
        sortOrder: this.getSortOrder('bargeOperatorName'),
        title: <FormattedMessage id="organisms.visits.visit_list.barge_operator_title" defaultMessage="Operator" />,
        // eslint-disable-next-line react/no-unused-prop-types
        filterDropdown: ({ confirm }) => (
          <FilterWithSearchAndObjects
            filterType={FILTER_TYPES.LPL}
            filterProp="bargeOperator"
            search={this.handleSearch}
            confirm={confirm}
            visible={this.state.isBargeOperatorOpen}
            dataAction={this.props.getBargeOperatorLPLFilterAction}
          />
        ),
        onFilterDropdownVisibleChange: (visible) => {
          this.setState({ isBargeOperatorOpen: visible });
        },
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.bargeOperator.length) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.bargeOperator.length ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.bargeOperator.length ? 'filtered' : ''} />;
        },
        width: '10%',
      },
      {
        align: 'left',
        dataIndex: 'status',
        key: 'status',
        render: renderStatus,
        sorter: true,
        sortOrder: this.getSortOrder('status'),
        title: <FormattedMessage id="organisms.visits.visit_list.status_bts_title" defaultMessage="Status" />,
        // eslint-disable-next-line react/no-unused-prop-types
        filterDropdown: ({ confirm }) => (
          <Filter
            filterType={FILTER_TYPES.LPL}
            filterProp="status"
            search={this.handleSearch}
            parseEnum={renderStatus}
            confirm={confirm}
            visible={this.state.isStatusFilterOpen}
            dataAction={this.props.getStatusLplPageAction}
          />
        ),
        onFilterDropdownVisibleChange: (visible) => {
          this.setState({ isStatusFilterOpen: visible });
        },
        filterIcon: () => {
          if (this.props.page.filter && this.props.page.filter.status.length) {
            return <FilterFilled className={this.props.page.filter && this.props.page.filter.status.length ? 'filtered' : ''} />;
          }
          return <FilterOutlined className={this.props.page.filter && this.props.page.filter.status.length ? 'filtered' : ''} />;
        },
        width: '10%',
      },
      {
        align: 'left',
        key: 'progress',
        // eslint-disable-next-line no-unused-vars
        render: (text: string, record: TVisit) => <ProgressTracker fracture={record.lplDone} total={record.lplRequested} />,
        sorter: false,
        title: <FormattedMessage id="lpl_progress_bar.lpl_done_title" defaultMessage="lpl done" />,
        width: '10%',
      },
      {
        align: 'left',
        key: 'lplRequested',
        // eslint-disable-next-line no-unused-vars
        render: (text: string, record: TVisit) => <ProgressTracker fracture={record.lplRequested} total={record.lplToRequest + record.lplRequested} />,
        sorter: false,
        title: <FormattedMessage id="lpl_progress_bar.lpl_requested_total_title" defaultMessage="lpl requested / lpl total" />,
        width: '10%',
      },
    ];

    return (
      <Socket
        topics={[EVENT_TYPES.LPL_REQUESTS_STREAM]}
        onMessage={(message) => {
          this.props.refreshAction(
            message,
            updateLplVisitList({
              getLPLPageAction: this.props.getLPLPageAction,
              pageRequest: pageToPageRequest(this.props.page),
            }),
          );
        }}
        socketKey="LplListTable"
      >
        <Row className="overview-top">
          <Col span={2} style={{ marginBottom: SPACING.DP }}>
            <Button onClick={this.clearFilter}>
              <FormattedMessage id="organisms.visits.visit_list.clearFilter" defaultMessage="Clear filters" />
            </Button>
          </Col>
        </Row>
        <Table
          className="visit-overview"
          loading={this.props.loadingPage}
          page={this.props.page}
          columns={columns}
          rowKey="visitId"
          size="small"
          onRow={(row) => ({
            onClick: () => browserHistory.push(createPath(SITE_PATHS.LPL_DETAIL, { id: row.visitId })),
          })}
          onChange={this.update}
        />
      </Socket>
    );
  }
}
