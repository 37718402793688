// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { IntlShape, useIntl } from 'react-intl';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import type { TGlobalState } from '../../types/index';
import { BargeDelete } from '../atoms/notifications/BargeDeleteNotification';
import { BargeOperatorDelete } from '../atoms/notifications/BargeOperatorDeleteNotification';
import { ErrorCodeDelete } from '../atoms/notifications/ErrorCodeDeleteNotification';
import { GangMoreThenOneQuayNotification } from '../atoms/notifications/GangMoreThenOneQuayNotification';
import { QuayDelete } from '../atoms/notifications/QuayDeleteNotification';
import { ExternalErrorsSentFailNotification, ExternalErrorsSentSuccessNotification } from '../atoms/notifications/ExternalErrorsSentNotification';
import { resetNotificationAction } from '../../store/actions/notificationActions';
import { SkipperDelete } from '../atoms/notifications/SkipperDeleteNotification';
import { SystemSettingsFailNotification } from '../atoms/notifications/SystemSettingsNotification';
import {
  ErrorsArchiveFailNotification,
  ErrorsArchiveSuccessNotification,
  ErrorsUnarchiveFailNotification,
  ErrorsUnarchiveSuccessNotification,
} from '../atoms/notifications/ExternalErrorrArchiveNotification';

type TProps = {
  notification: string,
  resetNotificationAction: typeof resetNotificationAction,
};

// all notification component must be wrapped in a useEffectOnce hook in order to avoid
// a strict check error by react regarding nested component updates

function getNotification(notification: string, intl: IntlShape) {
  switch (notification) {
    case NOTIFICATION_TYPES.QUAY_DELETE:
      return <QuayDelete intl={intl} />;
    case NOTIFICATION_TYPES.BARGE_DELETE:
      return <BargeDelete intl={intl} />;
    case NOTIFICATION_TYPES.BARGE_OPERATOR_DELETE:
      return <BargeOperatorDelete intl={intl} />;
    case NOTIFICATION_TYPES.ERROR_CODE_DELETE:
      return <ErrorCodeDelete intl={intl} />;
    case NOTIFICATION_TYPES.SKIPPER_DELETE:
      return <SkipperDelete intl={intl} />;
    case NOTIFICATION_TYPES.EXTERNAL_ERROR_SENT_SUCCESS:
      return <ExternalErrorsSentSuccessNotification intl={intl} />;
    case NOTIFICATION_TYPES.EXTERNAL_ERROR_SENT_FAIL:
      return <ExternalErrorsSentFailNotification intl={intl} />;
    case NOTIFICATION_TYPES.EXTERNAL_ERROR_ARCHIVE_SUCCESS:
      return <ErrorsArchiveSuccessNotification intl={intl} />;
    case NOTIFICATION_TYPES.EXTERNAL_ERROR_UNARCHIVE_SUCCESS:
      return <ErrorsUnarchiveSuccessNotification intl={intl} />;
    case NOTIFICATION_TYPES.EXTERNAL_ERROR_ARCHIVE_FAIL:
      return <ErrorsArchiveFailNotification intl={intl} />;
    case NOTIFICATION_TYPES.EXTERNAL_ERROR_UNARCHIVE_FAIL:
      return <ErrorsUnarchiveFailNotification intl={intl} />;
    case NOTIFICATION_TYPES.INTERNAL_ERROR_ARCHIVE_SUCCESS:
      return <ErrorsArchiveSuccessNotification intl={intl} />;
    case NOTIFICATION_TYPES.INTERNAL_ERROR_UNARCHIVE_SUCCESS:
      return <ErrorsUnarchiveSuccessNotification intl={intl} />;
    case NOTIFICATION_TYPES.INTERNAL_ERROR_ARCHIVE_FAIL:
      return <ErrorsArchiveFailNotification intl={intl} />;
    case NOTIFICATION_TYPES.INTERNAL_ERROR_UNARCHIVE_FAIL:
      return <ErrorsUnarchiveFailNotification intl={intl} />;
    case NOTIFICATION_TYPES.SYSTEM_SETTINGS_FAIL:
      return <SystemSettingsFailNotification intl={intl} />;
    case NOTIFICATION_TYPES.SELECT_ONLY_ONE_QUAY:
      return <GangMoreThenOneQuayNotification intl={intl} />;
    default:
      return null;
  }
}

function NotificationManager(props: TProps) {
  const intl = useIntl();

  useEffect(() => {
    if (props.notification !== null) {
      props.resetNotificationAction();
    }
  }, [props.notification]);

  return getNotification(props.notification, intl);
}

const mapStateToProps = (state: TGlobalState) => ({
  notification: state.notification,
});

export default connect<any, Object, _, _, _, _>(mapStateToProps, { resetNotificationAction })(NotificationManager);
