// @flow
export const SPACING = {
  COLLAPSE: 24,
  CONTENT: 36,
  DP: 10,
};

export const COLORS = {
  GREEN: '#31AD4F',
  RED: '#EC5B55',
  BLUE: '#1B89CA',
};
