// @flow
import * as React from 'react';
import { Tag as T } from 'antd';
import { FormattedMessage } from 'react-intl';

type TProps = {
  children?: any,
  className?: string,
  // eslint-disable-next-line react/no-unused-prop-types
  color?: string,
  style?: Object,
};

export function PrioTag(props: TProps) {
  return (
    <T style={props.style} className={`tag-rounded ${props.className}`}>
      <FormattedMessage id="atoms.tags.prio" defaultMessage="PRIO" />
      {props.children}
    </T>
  );
}

export function RedTag(props: TProps) {
  return (
    <T style={props.style} color="red" className={`circle ${props.className}`}>
      {props.children}
    </T>
  );
}

export function Tag(props: TProps) {
  return (
    <T {...props} className={`tag-rounded ${props.className}`}>
      {props.children}
    </T>
  );
}

Tag.defaultProps = {
  children: null,
  style: {},
  className: '',
  color: '',
};

PrioTag.defaultProps = {
  children: null,
  style: {},
  className: '',
  color: '',
};

RedTag.defaultProps = {
  children: null,
  style: {},
  className: '',
  color: '',
};
