// @flow
import { isEqual } from 'lodash';
import React, { createRef } from 'react';
import { FeatureGroup, GeoJSON, Map as LeafletMap, TileLayer } from 'react-leaflet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPortGeofenceAction } from '../../../store/actions/barge_clerk.actions';
import type { TBarge, TGlobalState, TQuay, TVisit } from '../../../types';
import { textToGeoJson } from '../../../utils/geo.utils';
import { BargePosition } from './BargePosition';

type TProps = {
  barge: TBarge,
  getPortGeofenceAction: typeof getPortGeofenceAction,
  portGeofence: Object,
  quay: TQuay,
  visit: TVisit,
};

class MapComponent extends React.Component<TProps, {}> {
  componentDidMount() {
    this.props.getPortGeofenceAction();
  }

  componentDidUpdate(prevProps: TProps) {
    if (
      prevProps.barge.id !== this.props.barge.id ||
      !isEqual(prevProps.portGeofence, this.props.portGeofence) ||
      this.props.visit.id !== prevProps.visit.id ||
      !isEqual(prevProps.barge.position, this.props.barge.position)
    ) {
      this.zoomBounds();
    }

    if (prevProps.quay.id !== this.props.quay.id) {
      this.props.getPortGeofenceAction();
    }
  }

  refMap = createRef();

  refFeatureGroup = createRef();

  zoomBounds() {
    const leafletMap = this.refMap.current;
    const leafletFeatureGroup = this.refFeatureGroup.current;

    if (leafletMap && leafletFeatureGroup) {
      const fgLeafletElement = leafletFeatureGroup.leafletElement;
      const bounds = fgLeafletElement.getBounds();

      if (fgLeafletElement.getLayers().length > 1) {
        const mapLeafletElement = leafletMap.leafletElement;
        mapLeafletElement.fitBounds(bounds);
      }
    }
  }

  onFeatureGroupAdd = (e) => {
    const leafletMap = this.refMap.current;

    if (leafletMap) {
      const bounds = e.target.getBounds();
      if (e.target.getLayers().length > 1) {
        const mapLeafletElement = leafletMap.leafletElement;
        mapLeafletElement.fitBounds(bounds);
      }
    }
  };

  render() {
    const { quay, barge, portGeofence } = this.props;
    const { position } = barge;

    if (position) {
      const quayBoundaries = textToGeoJson(quay.geofence);
      const portBoundaries = textToGeoJson(portGeofence && portGeofence.value);

      // If the quay boundaries are present, the center of the leafletMap should't be the barge position.
      // Use a dummy location instead, to prevent position updates from rerendering the map without the desired bounds.
      const center = quayBoundaries ? [4.285848, 51.346275] : position.location;

      return (
        <LeafletMap className="map-panel" ref={this.refMap} center={center} zoom={14}>
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="© <a href='https://openstreetmap.org'>OpenStreetMap</a>" />

          <FeatureGroup ref={this.refFeatureGroup} onAdd={this.onFeatureGroupAdd}>
            <BargePosition direction={position.direction} position={position.location} speed={position.speed} lastUpdate={position.lastUpdate} />
            {quayBoundaries ? <GeoJSON key={quay.id} data={quayBoundaries} /> : null}
          </FeatureGroup>

          {portBoundaries ? <GeoJSON key={JSON.stringify(portBoundaries)} data={portBoundaries} color="brown" weight={1} opacity={0.5} fillOpacity={0} /> : null}
        </LeafletMap>
      );
    }

    return (
      <div className="no-data-panel">
        <LeafletMap className="map-panel" center={[51.260197, 4.402771]} zoom={11}>
          <TileLayer url="http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution="© <a href='https://openstreetmap.org'>OpenStreetMap</a>" />
        </LeafletMap>
        <div className="no-data-panel-text">
          <span className="no-data-label">
            <FormattedMessage id="atoms.map.no_data" defaultMessage="No data available" />
          </span>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  portGeofence: state.bargeClerks.portGeofence,
});

export const Map = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getPortGeofenceAction,
})(withRouter<any>(MapComponent));
