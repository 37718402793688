// @flow
import { DiagnosticsActions } from '../../actions/diagnostics.actions';
import { TDiagnostics } from '../../../types/diagnostic.types';

const reducer = (state: ?TDiagnostics = null, action: Object) => {
  switch (action.type) {
    case DiagnosticsActions.receivedPage:
      return action.payload;
    default:
      return state;
  }
};

export const diagnosticsReducer = reducer;
