// @flow
import { Switch } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SPACING } from '../../../constants/style.constants';

type TProps = {
  checked: boolean,
  loading?: boolean,
  text?: FormattedMessage,
  onToggle: (newState: boolean) => void,
};

export function DebouncedSwitch({ checked, loading = false, text, onToggle }: TProps) {
  const [localChecked, setLocalChecked] = React.useState(checked);

  React.useEffect(() => {
    if (checked !== localChecked) {
      setLocalChecked(checked);
    }
  }, [checked]);

  const onSwitchTrigger = React.useCallback(() => {
    const newLocalChecked = !localChecked;
    setLocalChecked(newLocalChecked);
    onToggle(newLocalChecked);
  }, [localChecked, onToggle, setLocalChecked, checked]);

  return (
    <div style={{ display: 'flex', alignItems: 'center', height: 32 }}>
      <Switch style={{ marginRight: SPACING.DP }} className="approved" checked={localChecked} onChange={onSwitchTrigger} loading={loading} />
      <strong className={localChecked ? 'text-green' : ''}>{text}</strong>
    </div>
  );
}
