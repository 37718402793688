export const LPL_STATUS = {
  NEW: 'NEW',
  IN_PROGRESS: 'IN_PROGRESS',
  CANCELLED: 'CANCELLED',
  COMPLETED: 'COMPLETED',
};

export const LPL_TYPE = {
  DV: 'DV',
  HC: 'HC',
  PW: 'PW',
  HCPW: 'HPCW',
  RF: 'RF',
  RFHC: 'RFHC',
  OT: 'OT',
  HT: 'HT',
  FR: 'FR',
  PF: 'PF',
  BC: 'BC',
  VC: 'VC',
  OS: 'OS',
  IMO0: 'IMO0',
  IMO1: 'IMO1',
  IMO2: 'IMO2',
  IMO5: 'IMO5',
  SBT: 'SBT',
  FG: 'FG',
  RT: 'RT',
  GT: 'GT',
  ST: 'ST',
  TN: 'TN',
};
