// @flow
import * as React from 'react';
import { CrudTemplate } from '../CrudTemplate';
import { DetailHeader } from '../../molecules/master_data/DetailHeader';

export type TDetailTemplateProps = {
  backRoute: string,
  children: any,
  deleteAction: Function,
  editRoute: string,
  entity: Object,
  headerTitle: string,
  id: any,
  loading: boolean,
  menuBlock?: string,
  menuItem?: string,
};

export function DetailTemplate(props: TDetailTemplateProps) {
  return (
    <CrudTemplate loading={props.loading} menuItem={props.menuItem} menuBlock={props.menuBlock}>
      <DetailHeader title={props.headerTitle} id={props.id} editRoute={props.editRoute} deleteAction={props.deleteAction} entity={props.entity} backRoute={props.backRoute} />
      {props.children}
    </CrudTemplate>
  );
}
DetailTemplate.defaultProps = {
  menuBlock: '',
  menuItem: '',
};
