// @flow
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { humanizeTimeStamp } from '../../utils/dates.utils';
import { CollapseHeader } from './CollapseHeader';
import { checkOrError } from './renderers';
import { Timestamp } from './Timestamp';
import type { TVisit } from '../../types';

type TProps = {
  numUnreadRemarks: number,
  timeStamp?: ?string,
  title: string | React.Node,
  visit: TVisit,
};

export function EmptiesYardCollapseHeader(props: TProps) {
  return (
    <CollapseHeader title={props.title} subTitle={`(${props.visit.lplRequested + props.visit.lplToRequest})`}>
      <div style={{ display: 'inline-block' }}>
        <FormattedMessage id="collapse.empties_yard_table.header.containers_requested" defaultMessage="Containers requested" /> {checkOrError(props.visit.lplToRequest)}
        {props.numUnreadRemarks > 0 ? (
          <span style={{ marginLeft: 10 }}>
            <FormattedMessage id="collapse.empties_yard_table.header.remarks" defaultMessage="Remarks" />: {props.numUnreadRemarks}
          </span>
        ) : null}
      </div>
      {props.timeStamp ? (
        <span className="collapse-error-table-header-timestamp">
          {Timestamp(<FormattedMessage id="collapse.error_table.header.timestamp" defaultMessage="last send {duration}" values={{ duration: humanizeTimeStamp(moment(props.timeStamp)) }} />)}
        </span>
      ) : null}
    </CollapseHeader>
  );
}

EmptiesYardCollapseHeader.defaultProps = {
  timeStamp: null,
};
