// @flow
import type { TVisitFilter } from '../../../types';
import { VisitActions, VisitFilterActions } from '../../actions';

const initialState: ?TVisitFilter = null;

const reducer = (state: ?TVisitFilter = initialState, action: Object) => {
  switch (action.type) {
    case VisitActions.receivedPage:
      return action.payload && action.payload.page.filter && action.payload.page.filter ? action.payload.page.filter : state;
    case VisitFilterActions.receivedVisitFilter:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const filtersReducer = reducer;
