// @flow
import * as React from 'react';
import { withRouter } from 'react-router-dom';

type TProps = {
  children: any,
  location: Location,
};

class ScrollToTop extends React.Component<TProps> {
  componentDidUpdate(prevProps: TProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter<any>(ScrollToTop);
