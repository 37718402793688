// @flow

import type { TBaseAction } from '../../../types';
import { AuthenticationActions } from '../../actions/authentication.actions';

const reducer = (state: string = null, action: TBaseAction<string>) => {
  switch (action.type) {
    case AuthenticationActions.setAuthenticationToken:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
