// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getVisitAction } from '../../../store/actions';
import { DPTemplate } from '../../templates/DPTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import { Refresh } from '../../atoms/Refresh';
import { BargeClerkDetail } from '../../organisms/barge_clerks_detail/Detail';

export function BargeClerkDetailPage() {
  const { id } = useParams();
  const dispatch = useDispatch();

  const onRefresh = React.useCallback(() => {
    if (id) {
      dispatch(getVisitAction(id));
    }
  }, [id, dispatch]);

  return (
    <DPTemplate menuItem={SITE_PATHS.BARGE_CLERK_LIST} menuBlock={SITE_PATHS.BARGE_CLERK_LIST}>
      {/* $FlowFixMe */}
      <Refresh rate={60000} refreshAction={onRefresh}>
        <BargeClerkDetail />
      </Refresh>
    </DPTemplate>
  );
}
