// @flow
import { combineReducers } from 'redux';
import { BargeOperatorActions } from '../../actions';
import loadingReducer from '../loading.reducers';
import { pageListReducer } from '../pageList.reducers';
import { detailReducer } from './detail.reducers';

const reducer = combineReducers({
  detail: detailReducer,
  list: pageListReducer(BargeOperatorActions.receivedPage),
  loadingDetail: loadingReducer(BargeOperatorActions.detail, BargeOperatorActions.receivedDetail),
  loadingPage: loadingReducer(BargeOperatorActions.page, BargeOperatorActions.receivedPage),
});

export default reducer;
