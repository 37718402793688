// @flow
import * as React from 'react';
import { Table } from 'antd';
import { EditableCell } from './editables/EditableCell';

type TProps = {
  columns: Object[],
  data: Object[],
  loading: boolean,
  rowKey: string,
  // eslint-disable-next-line react/no-unused-prop-types
  saveAction: Function,
};

export function EditableTable(props: TProps) {
  const components = {
    body: {
      cell: EditableCell,
    },
  };

  const mapColumns = (columns: Object[]) =>
    columns.map((column) => {
      const mappedColumn = { ...column };

      mappedColumn.onCell = (record) => ({
        editable: column.editable,
        property: column.dataIndex,
        type: column.type || record.type || typeof record[column.dataIndex],
        record,
        renderer: column.renderer,
        saveRecordFn: props.saveAction,
      });

      return mappedColumn;
    });

  return (
    <Table
      className="editable-table"
      size="small"
      bordered
      columns={mapColumns(props.columns)}
      dataSource={props.data}
      pagination={false}
      rowKey={props.rowKey}
      components={components}
      loading={props.loading}
    />
  );
}
