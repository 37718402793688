/* eslint-disable no-unused-vars */
// @flow
import * as React from 'react';
import { Table as AntTable } from 'antd';
import type { TPageRequest, TPageWithFilter, TSort } from '../../../types';
import { pageToPageRequest } from '../../../utils/format.utils';

export type TTableProps = {
  className: ?string,
  columns: Object[],
  getPageAction?: ?Function,
  loading?: boolean,
  onChange: ?Function,
  onFilter?: ?Function,
  onPage?: ?Function,
  onRow?: ?Function,
  onSort?: ?Function,
  page: TPageWithFilter<any, any>,
  pagination?: boolean,
  rowClassName?: ?Function,
  rowKey: string,
  scroll?: ?Object,
  size: ?string,
};

type TState = {
  firstRender: boolean,
};

export class Table extends React.Component<TTableProps, TState> {
  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    loading: false,
    onSort: null,
    onPage: null,
    onFilter: null,
    onRow: null,
    pagination: false,
    getPageAction: null,
    rowClassName: () => '',
  };

  componentDidMount(): void {
    if (this.props.getPageAction) {
      this.props.getPageAction();
    }
  }

  onChange = (pagination: any, filters: any, sorter: TSort) => {
    if (!this.props.getPageAction) return;

    let pageRequest: TPageRequest = pageToPageRequest(this.props.page);

    if (this.props.onPage) {
      pageRequest = this.props.onPage(pageRequest, pagination);
    } else {
      // $FlowFixMe
      pageRequest.paging.number = pagination.current - 1;
    }

    if (this.props.onSort) {
      pageRequest = this.props.onSort(pageRequest, sorter);
    } else {
      // $FlowFixMe
      pageRequest.sorting.by = sorter.sortBy;
      // $FlowFixMe
      pageRequest.sorting.order = sorter.sortDir;
    }

    if (this.props.onFilter) {
      pageRequest = this.props.onFilter(pageRequest, filters);
    }

    // $FlowFixMe
    this.props.getPageAction(pageRequest);
  };

  render() {
    const { page } = this.props;
    if (!page.data) return null;
    const pagination = {
      current: page.data.pageable.pageNumber + 1,
      defaultCurrent: page.data.pageable.pageNumber + 1,
      defaultPageSize: page.data.pageable.pageSize,
      showQuickJumper: true,
      total: page.data.totalElements,
      size: 'default',
    };

    const rowClassName = (record, index) => `${index % 2 ? 'even-row' : ''} ${this.props.rowClassName ? this.props.rowClassName(record) : ''}`;

    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 'auto' }} className="Table">
        <AntTable
          columns={this.props.columns}
          rowKey={this.props.rowKey}
          pagination={this.props.pagination ? pagination : false}
          dataSource={page.data.content}
          onRow={this.props.onRow}
          bordered
          loading={this.props.loading}
          size={this.props.size}
          className={this.props.className}
          rowClassName={rowClassName}
          onChange={this.props.onChange || this.onChange}
          showSorterTooltip={false}
        />
      </div>
    );
  }
}
