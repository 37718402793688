// @flow
import { notification } from 'antd';
import { defineMessages, intlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  bargeOperatorDeletedMessage: {
    id: 'bargeOperator.delete.confirmation',
    defaultMessage: 'Barge operator deleted',
  },
  bargeOperatorDeletedDescription: {
    id: 'bargeOperator.delete.confirmation.description',
    defaultMessage: 'Barge operator successfully deleted',
  },
});

export function BargeOperatorDelete({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.bargeOperatorDeletedMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.bargeOperatorDeletedDescription),
    });
  });
}
