// @flow
import type { TLPLRequest } from '../../../types/lpl.types';
import type { TBaseAction } from '../../../types';
import { LPLActions } from '../../actions/lpl.actions';

export const lplRequestReducer = (state: ?Object = null, action: TBaseAction<TLPLRequest[]>) => {
  switch (action.type) {
    case LPLActions.receivedRequests: {
      if (!action.payload.length) return state;

      const newState = { ...state };
      newState[action.payload[0].lplGroupId] = action.payload;

      return newState;
    }
    default:
      return state;
  }
};
