// @flow
import { Button, Checkbox, Row } from 'antd';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../../../types';
import { Loading } from '../../Loading';

type TProps = {
  confirm: Function,
  dataAction?: Function,
  filterProp: string,
  filterType: string,
  parseEnum?: Function,
  search: Function,
  selectedValues: string[],
  values: string[],
  visible?: boolean,
};

export function FilterComponent(props: TProps) {
  const [loading, setLoading] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [values, setValues] = useState(props.values);
  const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);

  useEffect(() => {
    if (props.visible && props.dataAction) {
      props.dataAction();
      setLoading(true);
    }
  }, [props.visible]);

  useEffect(() => {
    if (!props.visible || loading) {
      setValues(props.values);
      setLoading(false);
    }
  }, [props.values]);

  useEffect(() => {
    if (!updating) {
      setSelectedValues(props.selectedValues || []);
    }
  }, [props.selectedValues]);

  const search = (values) => {
    setUpdating(false);
    props.confirm();
    props.search(values || selectedValues, props.filterProp);
  };

  const reset = () => {
    setSelectedValues([]);
    search([]);
  };

  const emptyIsNa = (item: string) => item || 'N/A';

  const handleSelectChange = (e: any) => {
    let newValues = [...selectedValues];
    if (e.target.checked) {
      newValues.push(e.target.value);
    } else {
      newValues = newValues.filter((item) => item !== e.target.value);
    }

    setUpdating(true);
    setSelectedValues(newValues);
  };

  return (
    <div className="custom-filter-dropdown">
      {loading ? (
        <Loading />
      ) : (
        <div className="custom-filter-content">
          {values.map((item) => (
            <Row className="custom-filter-row" key={item}>
              <Checkbox value={item} checked={selectedValues.includes(item)} onChange={handleSelectChange}>
                {props.parseEnum ? emptyIsNa(props.parseEnum(item)) : item}
              </Checkbox>
            </Row>
          ))}
        </div>
      )}
      <div className="custom-filter-footer">
        <Row>
          <Button type="primary" onClick={() => search()}>
            <FormattedMessage id="filterDropdown.search" defaultMessage="Apply filter" />
          </Button>
        </Row>
        <Row id="reset" className="text-underline" style={{ cursor: 'pointer' }} onClick={reset}>
          <FormattedMessage id="filterDropdown.reset" defaultMessage="Reset" />
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state: TGlobalState, ownProps: TProps) => ({
  selectedValues: (state[ownProps.filterType].page && state[ownProps.filterType].page.filter && state[ownProps.filterType].page.filter[ownProps.filterProp]) || [],
  values: (state[ownProps.filterType].filters && state[ownProps.filterType].filters[ownProps.filterProp]) || [],
});
// $FlowFixMe
export const Filter = connect<any, Object, _, _, _, _>(mapStateToProps, undefined)(FilterComponent);
