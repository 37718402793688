// @flow
import type { TBaseAction } from '../../types';
import type { TGangLB, TGangOM, TGangRemark } from '../../types/gang.types';

export const GangRemarkActions = {
  clear: 'GANG_REMARK.CLEAR',
  get: 'GANG_REMARK.GET',
  post: 'GANG_REMARK.POST',
  received: 'GANG_REMARK.RECEIVED',
};

export const getGangRemarksAction = (payload: TGangOM | TGangLB): TBaseAction<TGangOM | TGangLB> => ({
  payload,
  type: GangRemarkActions.get,
});

export const postGangRemarkAction = (gang: TGangOM, remark: TGangRemark, callback?: Function): TBaseAction<{ callback?: Function, gang: TGangOM, remark: TGangRemark }> => ({
  payload: {
    gang,
    remark,
    callback,
  },
  type: GangRemarkActions.post,
});

export const receivedGangRemarksAction = (payload: TGangRemark[]): TBaseAction<TGangRemark[]> => ({
  payload,
  type: GangRemarkActions.received,
});

export const clearRemarksAction = (payload: TGangOM): TBaseAction<TGangOM> => ({
  payload,
  type: GangRemarkActions.clear,
});
