/* eslint-disable */
export const defaultNumber = (value: number | typeof undefined): number => value || 0;
export const calculatePercentage = (fraction: number, total: number): number => (fraction / total) * 100;
export const uuidv4 = () => ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) => (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16));

/**
 * Takes the 2 arrays and makes a list of array of all combinations.
 *
 * @param array1
 * @param array2
 * @returns {[]}
 */
export const combineArrays = (array1: [], array2: []) => {
  const combinations = [];

  array1.forEach((value) => {
    array2.forEach((value2) => {
      combinations.push([value, value2]);
    });
  });

  return combinations;
};

/**
 * Generates a random number between the min and max parameter.
 * The min and max parameter are included.
 *
 * @param min
 * @param max
 * @returns {number}
 */
export const randomIntFromInterval = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);
