// @flow
import type { TBaseAction, TPageRequest, TPage, TErrorCode, TErrorCodeForm } from '../../types';

export const ErrorCodeActions = {
  create: 'ERROR_CODE_ACTIONS.CREATE',
  detail: 'ERROR_CODE_ACTIONS.GET',
  edit: 'ERROR_CODE_ACTIONS.EDIT',
  page: 'ERROR_CODE_ACTIONS.PAGE',
  receivedDetail: 'ERROR_CODE_ACTIONS.RECEIVED_DETAIL',
  receivedPage: 'ERROR_CODE_ACTIONS.RECEIVED_PAGE',
  remove: 'ERROR_CODE_ACTIONS.REMOVE',
};

export const createErrorCodeAction = (payload: TErrorCodeForm, callback?: Function): TBaseAction<TErrorCodeForm> => ({
  payload,
  callback,
  type: ErrorCodeActions.create,
});

export const editErrorCodeAction = (payload: TErrorCodeForm, callback?: Function): TBaseAction<TErrorCodeForm> => ({
  payload,
  callback,
  type: ErrorCodeActions.edit,
});

export const getErrorCodeAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: ErrorCodeActions.detail,
});

export const getErrorCodePageAction = (payload: ?TPageRequest<null>): TBaseAction<?TPageRequest<null>> => ({
  payload,
  type: ErrorCodeActions.page,
});

export const removeErrorCodeAction = (payload: TErrorCode): TBaseAction<TErrorCode> => ({
  payload,
  type: ErrorCodeActions.remove,
});

export const receivedErrorCodePageAction = (payload: TPage<TErrorCode>): TBaseAction<TPage<TErrorCode>> => ({
  payload,
  type: ErrorCodeActions.receivedPage,
});

export const receivedErrorCodeAction = (payload: TErrorCode): TBaseAction<TErrorCode> => ({
  payload,
  type: ErrorCodeActions.receivedDetail,
});
