import { defineMessages } from 'react-intl';

export const IDLE_TIME_REASON_MESSAGES = defineMessages({
  START_STOP_TIMES: {
    defaultMessage: 'Start-en stoptijden',
    id: 'start_stop_times',
  },
  TRANSITIONS: {
    defaultMessage: 'Transitions',
    id: 'transitions',
  },
  TECHNICAL_PROBLEMS: {
    defaultMessage: 'Technical problems / crane breakdown',
    id: 'technical_problems',
  },
  WEATHER: {
    defaultMessage: 'Weather conditions',
    id: 'weather_conditions',
  },
  HOUSEKEEPING: {
    defaultMessage: 'Housekeeping',
    id: 'housekeeping',
  },
  LATE_ARRIVAL_BARGE: {
    defaultMessage: 'Late arrival barge',
    id: 'late_arrival_barge',
  },
  CEELBAAS: {
    defaultMessage: 'Deviating decision dock supervisor / FM',
    id: 'ceelbaas',
  },
  BREAKBULK: {
    defaultMessage: 'Breakbulk',
    id: 'breakbulk',
  },
  IT_PROBLEMS: {
    defaultMessage: 'IT problems',
    id: 'it_problems',
  },
  CLEAN_UP_PREVIOUS_SHIFT: {
    defaultMessage: 'Cleanup previous shift',
    id: 'clean_up_previous_shift',
  },
  PUPIL_CRANE_OPERATOR: {
    defaultMessage: 'Pupil crane operator',
    id: 'pupil_crane_operator',
  },
  VESSEL_DELAYED_DEPARTURE: {
    defaultMessage: 'Vessel delayed departure',
    id: 'vessel_delayed_departure',
  },
  VESSEL_EARLY_ARRIVAL: {
    defaultMessage: 'Vessel early arrival',
    id: 'vessel_early_arrival',
  },
  CONDITION_BARGE: {
    defaultMessage: 'Condition barge',
    id: 'condition_barge',
  },
  OPS_PLANNING_OM_IM: {
    defaultMessage: 'OPS planning at / LI',
    id: 'ops_planning_om_im',
  },
  LATE_INFO_BO: {
    defaultMessage: '(Late) info BO',
    id: 'late_info_bo',
  },
  CONSTRUCTION_TD: {
    defaultMessage: 'construction TD',
    id: 'construction_td',
  },
  EMPTIES_YARD_DISTANCE: {
    defaultMessage: 'Empties yard (distance)',
    id: 'empties_yard_distance',
  },
  DAMAGED_CONTAINERS: {
    defaultMessage: 'Damaged containers',
    id: 'damaged_containers',
  },
  DROP_VOLUME_GANG: {
    defaultMessage: 'Drop volume gang',
    id: 'drop_volume_gang',
  },
  DROP_VOLUME_IDLE_TIME_SHIFT: {
    defaultMessage: 'Drop volume idle time shift',
    id: 'drop_volume_idle_time_shift',
  },
  REPLAN_STACK_POSITIONS: {
    defaultMessage: 'Replan stack positions',
    id: 'replan_stack_positions',
  },
  STOWAGE_PLAN_INCORRECT: {
    defaultMessage: 'Stowage plan incorrect',
    id: 'stowage_plan_incorrect',
  },
  BARGE_DEPARTURE_INTEREST_BO: {
    defaultMessage: 'Barge departure interest BO',
    id: 'barge_departure_interest_bo',
  },
  BARGE_UNREACHABLE: {
    defaultMessage: 'Barge unreachable',
    id: 'barge_unreachable',
  },
  BARGE_UNCOMPLIANT_INSTRUCTIONS: {
    defaultMessage: 'Barge not complying to instructions',
    id: 'barge_uncompliant_instructions',
  },
  INSUFFICIENT_BERTH_SPACE: {
    defaultMessage: 'Insufficient berth space',
    id: 'insufficient_berth_space',
  },
  EFFICIENCY_BETTER_WORSE: {
    defaultMessage: 'Efficiency better / worse',
    id: 'efficiency_better_worse',
  },
});
