// @flow
import * as React from 'react';
import { CrudTemplate } from '../CrudTemplate';
import { ListHeader } from '../../molecules/master_data/ListHeader';

type TProps = {
  buttonText: ?string,
  children: React.Node,
  createRoute?: string,
  menuBlock?: string,
  menuItem?: string,
  title: string,
};

export function ListTemplate(props: TProps) {
  return (
    <CrudTemplate menuBlock={props.menuBlock} menuItem={props.menuItem}>
      <ListHeader buttonText={props.buttonText} createRoute={props.createRoute} title={props.title} />
      {props.children}
    </CrudTemplate>
  );
}

ListTemplate.defaultProps = {
  menuBlock: '',
  menuItem: '',
};
