// @flow
import { VisitActions } from '../../actions';

const reducer = (state: ?Object = null, action: Object) => {
  switch (action.type) {
    case VisitActions.receivedDetail:
      return action.payload;
    case VisitActions.reduceProcessed:
      return { ...state, processed: action.payload.processed, processedAt: action.payload.processedAt };
    case VisitActions.reducePriority:
      return { ...state, priority: action.payload.priority };
    default:
      return state;
  }
};

export const detailReducer = reducer;
