// @flow
import type { TAction, TBaseAction } from '../../types';

export const AuthenticationActions = {
  acquireToken: 'AUTHENTICATION.ACQUIRE_TOKEN',
  isAuthenticated: 'AUTHENTICATION.IS_AUTHENTICATED',
  isAuthenticationCached: 'AUTHENTICATION.IS_AUTHENTICATION_CACHED',
  setAuthenticationCached: 'AUTHENTICATION.SET_AUTHENTICATION_CACHED',
  setAuthenticationToken: 'AUTHENTICATION.SET_AUTHENTICATION_TOKEN',
  isAuthenticating: 'AUTHENTICATION.IS_AUTHENTICATING',
};

export const loginAction = (): TAction => ({
  type: AuthenticationActions.acquireToken,
});

export const isAuthenticatedAction = (payload: boolean): TBaseAction<boolean> => ({
  payload,
  type: AuthenticationActions.isAuthenticated,
});

export const isAuthenticationCachedAction = (): TAction => ({
  type: AuthenticationActions.isAuthenticationCached,
});

export const setAuthenticationCachedAction = (payload: boolean): TBaseAction<boolean> => ({
  payload,
  type: AuthenticationActions.setAuthenticationCached,
});

export const setAuthenticationTokenAction = (payload: string): TBaseAction<string> => ({
  payload,
  type: AuthenticationActions.setAuthenticationToken,
});

export const isAuthenticatingAction = (payload: boolean): TBaseAction<boolean> => ({
  payload,
  type: AuthenticationActions.isAuthenticating,
});
