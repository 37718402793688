// @flow
import { isEqual } from 'lodash';
import type { TVisit, TVisitFilter } from '../../types';
import type { TLPLGroup } from '../../types/lpl.types';
import type { AggregatedWebSocketMessage, WebSocketMessage } from '../../types/websocket.types';
import { combineArrays } from '../../utils/number.utils';
import { getDetailsAction, getLPLContainersSocketAction, getVisitPageAction } from '../actions';
import { getBargeClerkPageAction, getSaldoOverviewAction, getShiftQuaysOverviewAction, getTotalsOverviewAction } from '../actions/barge_clerk.actions';
import { getLPLGroupAction, getLPLPageAction, getLPLRequestsAction } from '../actions/lpl.actions';
import { getSailingScheduleAction } from '../actions/sailing_schedule.actions';
import { TPageRequest } from '../../types';

type DataProcessorVisitListProps = {
  getVisitPageAction: typeof getVisitPageAction,
  pageRequest: TPageRequest<TVisitFilter>,
};
export const updateVisitList = (props: DataProcessorVisitListProps) => () => {
  props.getVisitPageAction(props.pageRequest);
};

type DataProcessorVisitProps = {
  getDetailsAction: typeof getDetailsAction,
  visit: TVisit,
};
export const updateVisitDetail = (props: DataProcessorVisitProps) => () => {
  setTimeout(() => {
    props.getDetailsAction(props.visit.id);
  }, 100);
};

/**
 * Looks at the incoming message and extracts the shift quay combinations out of it.
 * If it matches with the shift quay combinations passed to this function, it will trigger the
 * callback.
 *
 * @param incomingMessage The websocket message
 * @param quayIds all quay ID's that need refreshing
 * @param shiftIds All shift ID's that need refreshing
 * @param callback The function that should be triggered
 */
export const processMessagePerShiftQuayCombo = (incomingMessage: Object, quayIds: number[], shiftIds: number[], callback: Function) => {
  const shiftQuayCombinations = combineArrays(quayIds, shiftIds);
  if (!incomingMessage) return;
  if (incomingMessage.force) {
    callback();
    return;
  }

  if (incomingMessage.subMessages) {
    const receivedMessage: AggregatedWebSocketMessage = incomingMessage;
    const { subMessages }: { subMessages: WebSocketMessage[] } = receivedMessage;

    const receivedShiftQuayCombinations: [number, number][] = subMessages.map((message) => [message.quayId, message.shiftId]);

    // For loop can here be used for optimized looping.
    // eslint-disable-next-line no-restricted-syntax
    for (const combination of shiftQuayCombinations) {
      const result = receivedShiftQuayCombinations.find((combo) => isEqual(combination, combo));

      if (result) {
        callback();
        return;
      }
    }
  } else {
    const receivedMessage: WebSocketMessage = incomingMessage;
    const receivedShiftQuayCombination: [number, number] = [receivedMessage.quayId, receivedMessage.shiftId];

    // For loop can here be used for optimized looping.
    // eslint-disable-next-line no-restricted-syntax
    for (const combination of shiftQuayCombinations) {
      const result = isEqual(combination, receivedShiftQuayCombination);

      if (result) {
        callback();
        return;
      }
    }
  }
};

type BargeClerkVisitsProps = {
  getBargeClerkPageAction: typeof getBargeClerkPageAction,
  quayIds: number[],
  shiftIds: number[],
  pageRequest: TPageRequest<TVisitFilter>,
};

export const updateBargeClerkVisitList = (props: BargeClerkVisitsProps) => (incomingMessage: Object) => {
  const callback = () => {
    props.getBargeClerkPageAction(props.pageRequest);
  };

  processMessagePerShiftQuayCombo(incomingMessage, props.quayIds, props.shiftIds, callback);
};

type TalCounterProps = {
  getShiftQuaysOverviewAction: typeof getShiftQuaysOverviewAction,
  getSaldoOverviewAction: typeof getSaldoOverviewAction,
  getTotalsOverviewAction: typeof getTotalsOverviewAction,
  quayIds: number[],
  shiftIds: number[],
  otherQuayIds: number[],
  otherShiftIds: number[],
};
export const updateTalCounter = (props: TalCounterProps) => (incomingMessage: Object) => {
  const callback = () => {
    props.getShiftQuaysOverviewAction([...props.shiftIds, ...props.otherShiftIds], [...props.quayIds, ...props.otherQuayIds]);
    props.getSaldoOverviewAction(props.shiftIds[0], props.quayIds);
    props.getTotalsOverviewAction(props.shiftIds[0], props.quayIds);
  };

  processMessagePerShiftQuayCombo(incomingMessage, props.quayIds, props.shiftIds, callback);
};

type TalOverviewProps = {
  getShiftQuaysOverviewAction: typeof getShiftQuaysOverviewAction,
  quayIds: number[],
  shiftIds: number[],
};
export const updateTalOverview = (props: TalOverviewProps) => (incomingMessage: Object) => {
  const callback = () => {
    props.getShiftQuaysOverviewAction(props.shiftIds, props.quayIds);
  };

  processMessagePerShiftQuayCombo(incomingMessage, props.quayIds, props.shiftIds, callback);
};

type VisitSailingScheduleProps = {
  getSailingScheduleAction: typeof getSailingScheduleAction,
  visit: TVisit,
};
export const updateVisitSailingSchedule = (props: VisitSailingScheduleProps) => () => {
  props.getSailingScheduleAction(props.visit);
};

type LplVisitListProps = {
  getLPLPageAction: typeof getLPLPageAction,
  pageRequest: TPageRequest<TVisitFilter>,
};
export const updateLplVisitList = (props: LplVisitListProps) => () => {
  props.getLPLPageAction(props.pageRequest);
};

type LplVisitDetailTableProps = {
  getLPLRequestsAction: typeof getLPLRequestsAction,
  group: TLPLGroup,
};
export const updateLplVisitDetailTable = (props: LplVisitDetailTableProps) => () => {
  props.getLPLRequestsAction(props.group);
};

type LplGroupsProps = {
  getLPLGroupAction: typeof getLPLGroupAction,
  visit: TVisit,
};
export const updateLplGroups = (props: LplGroupsProps) => () => {
  props.getLPLGroupAction(props.visit);
};

type LplGroupDetailProps = {
  getLPLContainersSocketAction: typeof getLPLContainersSocketAction,
  lplGroup: TLPLGroup,
};
export const updateLplGroupDetails = (props: LplGroupDetailProps) => () => {
  props.getLPLContainersSocketAction();
};
