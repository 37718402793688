// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { editBargeOperatorAction, getBargeOperatorAction } from '../../../store/actions';
import type { TGlobalState, TBargeOperator } from '../../../types';
import BargeOperatorForm from '../../organisms/barge_operators/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';

type TProps = {
  bargeOperator: TBargeOperator,
  editBargeOperatorAction: typeof editBargeOperatorAction,
  getBargeOperatorAction: typeof getBargeOperatorAction,
  intl: intlShape,
  loading: boolean,
  match: any,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.barge_operator.edit.header.title', defaultMessage: 'Edit {name}' },
});

class BargeOperatorEdit extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getBargeOperatorAction(id);
  }

  render() {
    return (
      <EditTemplate
        loading={this.props.loading}
        title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title, {
          name: `${(this.props.bargeOperator && this.props.bargeOperator.name) || ''} (${(this.props.bargeOperator && this.props.bargeOperator.abbreviation) || ''})`,
        })}
        menuBlock="barge-operators"
        menuItem="barge-operators-overview"
      >
        <BargeOperatorForm bargeOperator={this.props.bargeOperator} handleSubmit={this.props.editBargeOperatorAction} />
      </EditTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  bargeOperator: state.bargeOperators.detail,
  loading: state.bargeOperators.loadingDetail,
});

export const BargeOperatorEditPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { editBargeOperatorAction, getBargeOperatorAction })(BargeOperatorEdit));
