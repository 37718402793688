// @flow
const initialState: boolean = true;

const reducerFactory =
  (setActionType: string, unsetActionType: string) =>
  (state: boolean = initialState, action: Object) => {
    switch (action.type) {
      case setActionType:
        return true;
      case unsetActionType:
        return false;

      default:
        return state;
    }
  };

export default reducerFactory;
