// @flow
import * as React from 'react';
import { Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { boltTag } from '../bolt';
import { fourArrowsTag } from '../fourArrows';
import { snowFlakeTag } from '../snowflake';
import { TitleSubtitle } from '../../molecules/titlesubtitle';
import { SPACING } from '../../../constants/style.constants';

type TProps = {
  imdgTodo: number,
  imdgTotal: number,
  oogTodo: number,
  oogTotal: number,
  reefersTodo: number,
  reefersTotal: number,
};

export function SpecialContainersTable(props: TProps) {
  const data = [
    {
      icon: boltTag,
      iconName: 'IMDG',
      todo: props.imdgTodo,
      total: props.imdgTotal,
    },
    {
      icon: fourArrowsTag,
      iconName: 'OOG',
      todo: props.oogTodo,
      total: props.oogTotal,
    },
    {
      icon: snowFlakeTag,
      iconName: 'Reefers',
      todo: props.reefersTodo,
      total: props.reefersTotal,
    },
  ];

  const columns = [
    {
      dataIndex: 'icon',
      title: <FormattedMessage id="atoms.tables.special_containers.type" defaultMessage="Type" />,
      render: (text, record) => (
        <span>
          {record.icon} {record.iconName}
        </span>
      ),
    },
    {
      dataIndex: 'todo',
      title: <FormattedMessage id="atoms.tables.special_containers.todo" defaultMessage="To do" />,
    },
    {
      dataIndex: 'total',
      title: <FormattedMessage id="atoms.tables.special_containers.total" defaultMessage="Total" />,
    },
  ];

  return (
    <div>
      <TitleSubtitle
        style={{ marginBottom: SPACING.COLLAPSE / 2 }}
        subTitle={`(${props.imdgTotal + props.oogTotal + props.reefersTotal})`}
        title={
          <h3 className="collapse-header-title">
            <FormattedMessage id="organisms.visits.barge_clerks.detail.panel.special_containers" defaultMessage="Special containers" />
          </h3>
        }
      />
      <Table rowKey="iconName" loading={false} columns={columns} pagination={false} size="small" dataSource={data} bordered />
    </div>
  );
}
