// @flow
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import type { TGangLB, TGangOM } from '../../types/gang.types';
import type { TBaseAction } from '../../types';
import {
  GangActions,
  handleCreateGangLBActionResponse,
  handleUpdateGangLBActionResponse,
  receivedEditGangsAction,
  receivedGangAssignmentsAction,
  receivedGangsAction,
  receivedLastModifiedGangsAction,
  receivedPreviousNumberOfGangsAction,
} from '../actions/gang.actions';
import { genericErrorHandler } from '../../utils';
import { appApi } from '../../config/api.config';
import { API_PATHS } from '../../config/api.constants';

export function* createLB(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shift, quays } = action.payload;
  const gang: Object = { shiftId: Number(shift), quayId: Number(quays[0]), workload: '0' };
  const response = yield call(appApi.post, API_PATHS.V1.GANG_LB, gang);

  yield put(handleCreateGangLBActionResponse(response.data));
}

export function* createOM(action: TBaseAction<{ callback?: Function, gang: TGangOM }>): Generator<*, *, *> {
  const {
    payload: { gang, callback },
  } = action;

  let response;

  if (gang.id) {
    response = yield call(appApi.put, `${API_PATHS.V1.GANG_OM}/${gang.id}`, gang);
  } else {
    response = yield call(appApi.post, API_PATHS.V1.GANG_OM, gang);
  }

  if (callback) {
    callback(response.data);
  }
}

export function* updateLB(action: TBaseAction<{ callback?: Function, gang: TGangLB }>): Generator<*, *, *> {
  const {
    payload: { gang, callback },
  } = action;
  const response = yield call(appApi.put, `${API_PATHS.V1.GANG_LB}/${gang.id}`, action.payload.gang);

  yield put(handleUpdateGangLBActionResponse(response.data));

  if (callback) {
    callback();
  }
}

export function* updateOM(action: TBaseAction<{ callback?: Function, gang: TGangOM }>): Generator<*, *, *> {
  const {
    payload: { gang, callback },
  } = action;
  yield call(appApi.put, `${API_PATHS.V1.GANG_OM}/${gang.id}`, gang);
  if (callback) {
    callback();
  }
}

export function* deleteLB(action: TBaseAction<{ callback?: Function, gang: TGangLB }>): Generator<*, *, *> {
  const {
    payload: { gang, callback },
  } = action;
  yield call(appApi.delete, `${API_PATHS.V1.GANG_LB}/${gang.id}`, action.payload);
  if (callback) {
    callback();
  }
}

export function* deleteOM(action: TBaseAction<{ callback?: Function, gang: TGangOM }>): Generator<*, *, *> {
  const {
    payload: { gang, callback },
  } = action;
  yield call(appApi.delete, `${API_PATHS.V1.GANG_OM}/${gang.id}`, gang);
  if (callback) {
    callback();
  }
}

export function* getGangsOM(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shift, quays } = action.payload;

  const response = yield call(appApi.get, `${API_PATHS.V1.GANG_FOR_OM}${encodeURI(`?shift=${shift}&quays[]=${quays.join('&quays[]=')}`)}`);

  yield put(receivedEditGangsAction(response.data));
}

export function* getGangs(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shift, quays, edit } = action.payload;

  const response = yield call(appApi.get, `${API_PATHS.V1.GANG}${encodeURI(`?shift=${shift}&quays[]=${quays.join('&quays[]=')}`)}`);

  if (edit) {
    yield put(receivedEditGangsAction(response.data));
  } else {
    yield put(receivedLastModifiedGangsAction(response.headers['last-modified'] || null));
    yield put(receivedPreviousNumberOfGangsAction(response.headers['x-last-number-gangs'] || null));
    yield put(receivedGangsAction(response.data));
  }
}

export function* getGangAssignments(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shifts, quays, gangs } = action.payload;
  const gangString = gangs.length > 0 ? `&gangs[]=${gangs.join('&gangs[]=')}` : '';

  const response = yield call(appApi.get, `${API_PATHS.V1.GANG_ASSIGNMENTS}${encodeURI(`?shifts[]=${shifts.join('&shifts[]=')}&quays[]=${quays.join('&quays[]=')}${gangString}`)}`);
  const lbData = response.data.map((gang) => gang.lb);

  yield put(receivedGangAssignmentsAction(lbData));
}

export function* GangSaga(): Generator<*, *, *> {
  yield takeEvery(GangActions.createGangLB, genericErrorHandler(createLB));
  yield takeEvery(GangActions.createGangOM, genericErrorHandler(createOM));
  yield takeLatest(GangActions.updateGangLB, genericErrorHandler(updateLB));
  yield takeLatest(GangActions.updateGangOM, genericErrorHandler(updateOM));
  yield takeLatest(GangActions.deleteGangLB, genericErrorHandler(deleteLB));
  yield takeLatest(GangActions.deleteGangOM, genericErrorHandler(deleteOM));
  yield takeLatest(GangActions.getGangs, genericErrorHandler(getGangs));
  yield takeLatest(GangActions.getGangsEdit, genericErrorHandler(getGangsOM));
  yield takeLatest(GangActions.getGangAssignments, genericErrorHandler(getGangAssignments));
}
