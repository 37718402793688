// @flow
import type { TAction, TBaseAction, TExternalErrorPayload } from '../../types';
import type { TError } from '../../types/error.types';

export const ErrorActions = {
  receivedVisitExternalList: 'ERROR_ACTIONS.EXTERNAL.RECEIVED_VISIT_LIST',
  receivedVisitInternalList: 'ERROR_ACTIONS.INTERNAL.RECEIVED_VISIT_LIST',
  receivedUpdateVisitExternalList: 'ERROR_ACTIONS.EXTERNAL.RECEIVED_UPDATE_VISIT_LIST',
  receivedUpdateVisitInternalList: 'ERROR_ACTIONS.INTERNAL.RECEIVED_UPDATE_VISIT_LIST',
  sendExternalErrors: 'ERROR_ACTIONS.EXTERNAL.SEND',
  archiveExternalErrors: 'ERROR_ACTIONS.EXTERNAL.ARCHIVE',
  unarchiveExternalErrors: 'ERROR_ACTIONS.EXTERNAL.UNARCHIVE',
  archiveInternalErrors: 'ERROR_ACTIONS.INTERNAL.ARCHIVE',
  unarchiveInternalErrors: 'ERROR_ACTIONS.INTERNAL.UNARCHIVE',
  visitExternalList: 'ERROR_ACTIONS.EXTERNAL.VISIT_LIST',
  visitInternalList: 'ERROR_ACTIONS.INTERNAL.VISIT_LIST',
};

export const getVisitExternalErrorsAction = (payload: number): TAction => ({
  payload,
  type: ErrorActions.visitExternalList,
});

export const getVisitInternalErrorsAction = (payload: number): TAction => ({
  payload,
  type: ErrorActions.visitInternalList,
});

export const receivedVisitExternalErrorsActions = (externalErrors: TError[]): TBaseAction<TError[]> => ({
  payload: externalErrors,
  type: ErrorActions.receivedVisitExternalList,
});

export const receivedVisitInternalErrorsAction = (internalErrors: TError[]): TBaseAction<TError[]> => ({
  payload: internalErrors,
  type: ErrorActions.receivedVisitInternalList,
});
export const receivedUpdateVisitExternalErrorsAction = (externalErrors: TError[]): TBaseAction<TError[]> => ({
  payload: externalErrors,
  type: ErrorActions.receivedUpdateVisitExternalList,
});

export const receivedUpdateVisitInternalErrorsAction = (internalErrors: TError[]): TBaseAction<TError[]> => ({
  payload: internalErrors,
  type: ErrorActions.receivedUpdateVisitInternalList,
});

export const sendExternalErrorsAction = (payload: TExternalErrorPayload): TBaseAction<TExternalErrorPayload> => ({
  payload,
  type: ErrorActions.sendExternalErrors,
});

export const archiveExternalErrorsAction = (payload: number[]): TBaseAction<number[]> => ({
  payload,
  type: ErrorActions.archiveExternalErrors,
});

export const unarchiveExternalErrorsAction = (payload: number[]): TBaseAction<number[]> => ({
  payload,
  type: ErrorActions.unarchiveExternalErrors,
});

export const archiveInternalErrorsAction = (payload: number[]): TBaseAction<number[]> => ({
  payload,
  type: ErrorActions.archiveInternalErrors,
});

export const unarchiveInternalErrorsAction = (payload: number[]): TBaseAction<number[]> => ({
  payload,
  type: ErrorActions.unarchiveInternalErrors,
});
