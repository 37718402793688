// @flow
import { all, fork } from 'redux-saga/effects';
import { AuthenticationSaga } from './authentication.sagas';
import { BargeSaga } from './barge.sagas';
import { BargeClerkSaga } from './barge_clerk.sagas';
import { BargeOperatorSaga } from './barge_operator.sagas';
import { ErrorCodeSaga } from './error_code.sagas';
import { LPLRemarkSaga } from './lpl_remark.sagas';
import { SkipperSaga } from './skipper.sagas';
import { SocketSaga } from './socket.sagas';
import { UserSaga } from './user.sagas';
import { VisitSaga } from './visit.sagas';
import { QuaySaga } from './quay.sagas';
import { ContainerVisitSaga } from './container_visit.sagas';
import { ErrorSaga } from './error.sagas';
import { SystemSettingsSaga } from './system_settings.sagas';
import { VisitRemarkSaga } from './visit_remark.sagas';
import { LplSaga } from './lpl.sagas';
import { ShiftSaga } from './shift.sagas';
import { SailingScheduleSaga } from './sailing_schedule.sagas';
import { GangRemarkSaga } from './gang_remark.sagas';
import { GangSaga } from './gang.sagas';
import { ElearningSaga } from './elearning.saga';
import { DiagnosticsSaga } from './diagnostics.sagas';

export default function* rootSaga(): Generator<*, *, *> {
  yield all([
    fork(AuthenticationSaga),
    fork(BargeClerkSaga),
    fork(BargeOperatorSaga),
    fork(BargeSaga),
    fork(ContainerVisitSaga),
    fork(ElearningSaga),
    fork(ErrorCodeSaga),
    fork(ErrorSaga),
    fork(GangRemarkSaga),
    fork(GangSaga),
    fork(LPLRemarkSaga),
    fork(LplSaga),
    fork(QuaySaga),
    fork(SailingScheduleSaga),
    fork(ShiftSaga),
    fork(SkipperSaga),
    fork(SystemSettingsSaga),
    fork(UserSaga),
    fork(VisitRemarkSaga),
    fork(VisitSaga),
    fork(SocketSaga),
    fork(DiagnosticsSaga),
  ]);
}
