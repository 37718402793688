// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { editBargeAction, getBargeAction } from '../../../store/actions';
import type { TGlobalState, TBarge } from '../../../types';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import BargeForm from '../../organisms/barges/CreateForm';

type TProps = {
  barge: ?TBarge,
  editBargeAction: typeof editBargeAction,
  getBargeAction: typeof getBargeAction,
  intl: intlShape,
  loading: boolean,
  match: any,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.barge.edit.header.title', defaultMessage: 'Edit {name}' },
});

class BargeEdit extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getBargeAction(id);
  }

  render() {
    return (
      <EditTemplate
        loading={this.props.loading}
        title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title, { name: this.props.barge && this.props.barge.name })}
        menuBlock="barges"
        menuItem="barges-overview"
      >
        <BargeForm barge={this.props.barge} handleSubmit={this.props.editBargeAction} />
      </EditTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  barge: state.barges.detail,
  loading: state.barges.loadingDetail,
});

export const BargeEditPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { editBargeAction, getBargeAction })(BargeEdit));
