// @flow
/* skeleton
 * All partial reducers are combined here into 1 partial reducer
 */
import { combineReducers } from 'redux';
import { pageListReducer } from '../pageList.reducers';
import skipperDetail from './detail.reducers';
import loadingReducer from '../loading.reducers';
import { SkipperActions } from '../../actions';

const reducer = combineReducers({
  detail: skipperDetail,
  list: pageListReducer(SkipperActions.receivedPage),
  loadingPage: loadingReducer(SkipperActions.page, SkipperActions.receivedPage),
  loadingDetail: loadingReducer(SkipperActions.detail, SkipperActions.receivedDetail),
});

export default reducer;
