// @flow
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import { API_PATHS, SITE_PATHS } from '../../config/api.constants';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import type { TBaseAction, TGlobalState, TPageRequest, TQuay, TQuayForm, TVisitFilter } from '../../types';
import { genericErrorHandler } from '../../utils';
import { createPath } from '../../utils/routing.utils';
import { getQuayPageAction, QuayActions, receivedAllQuaysAction, receivedPsaQuaysAction, receivedQuayAction, receivedQuayPageAction } from '../actions';
import { showNotificationAction } from '../actions/notificationActions';
import { browserHistory } from '../index';
import { formatUri, pageToPageRequest } from '../../utils/format.utils';

function base64EncodeBodyAndReturnPayload(action) {
  const payload = action.payload;
  if (action.payload.geofence) {
    payload.geofence = btoa(action.payload.geofence);
  }
  return payload;
}

export function* create(action: TBaseAction<TQuayForm>): Generator<*, *, *> {
  try {
    yield call(appApi.post, API_PATHS.V1.QUAY, base64EncodeBodyAndReturnPayload(action));
    browserHistory.push(SITE_PATHS.QUAY_LIST);
  } catch (err) {
    const { response } = err;

    if (response && response.status === 422) {
      const errors = [];
      const { validationErrors } = response.data;

      if ('name' in validationErrors) {
        errors.push('name');
      }

      if (action.callback) {
        action.callback(errors);
      }
    }
  }
}

export function* list(): Generator<*, *, *> {
  const response = yield call(appApi.get, API_PATHS.V1.QUAY_PSA);
  yield put(receivedPsaQuaysAction(response.data));
}

export function* listAllQuays(): Generator<*, *, *> {
  const response = yield call(appApi.get, API_PATHS.V1.QUAY_ALL);
  yield put(receivedAllQuaysAction(response.data));
}

export function* get(id: number): Generator<*, *, *> {
  const response = yield call(appApi.get, `${API_PATHS.V1.QUAY}/${id}`);
  return response.data;
}

export function* detail(action: TBaseAction<number>): Generator<*, *, *> {
  const quay: TQuay = yield get(action.payload);
  yield put(receivedQuayAction(quay));
}

export function* edit(action: TBaseAction<TQuay>): Generator<*, *, *> {
  try {
    yield call(appApi.put, `${API_PATHS.V1.QUAY}/${action.payload.id}`, base64EncodeBodyAndReturnPayload(action));
    browserHistory.push(createPath(SITE_PATHS.QUAY_DETAIL, { id: action.payload.id }));
  } catch (err) {
    const { response } = err;
    if (response && response.status === 422) {
      const errors = [];
      const { validationErrors } = response.data;

      if ('name' in validationErrors) {
        errors.push('name');
      }

      if (action.callback) {
        action.callback(errors);
      }
    }
  }
}

function* doPageCall(action: TBaseAction<?TPageRequest<TVisitFilter>>): Generator<*, *, *> {
  const quayUri = API_PATHS.V1.QUAY;
  const uri = action.payload ? formatUri(quayUri, action.payload) : quayUri;

  return yield call(appApi.get, uri);
}

export function* page(action: TBaseAction<?TPageRequest<null>>): Generator<*, *, *> {
  const response = yield call(doPageCall, action);
  yield put(receivedQuayPageAction({ data: response.data }));
}

export function* remove(action: TBaseAction<TQuay>): Generator<*, *, *> {
  const { keys, ...quay } = action.payload;
  if (quay.deletable) {
    const response = yield call(appApi.delete, createPath(API_PATHS.V1.QUAY_DELETE, { id: quay.id }), quay);
    if (response.status === 204) {
      yield put(showNotificationAction(NOTIFICATION_TYPES.QUAY_DELETE));
    }

    const page = yield select((state: TGlobalState) => state.quays.page);
    yield put(getQuayPageAction(pageToPageRequest(page)));
  }
}

export function* QuaySaga(): Generator<*, *, *> {
  yield takeLatest(QuayActions.create, genericErrorHandler(create));
  yield takeLatest(QuayActions.detail, genericErrorHandler(detail));
  yield takeLatest(QuayActions.edit, genericErrorHandler(edit));
  yield takeLatest(QuayActions.page, genericErrorHandler(page));
  yield takeLatest(QuayActions.remove, genericErrorHandler(remove));
  yield takeLatest(QuayActions.listPsaQuays, genericErrorHandler(list));
  yield takeLatest(QuayActions.listAllQuays, genericErrorHandler(listAllQuays));
}
