// @flow
import moment from 'moment';
import type { TContactable, TContactDetail } from '../types';

export const cleanContactable = <T: TContactable>(o: T): T => {
  const contactDetailsFormat = o.contactDetails ? o.contactDetails.filter((obj) => obj != null) : [];
  const unavailabilityWindows = o.unavailabilityWindows
    ? o.unavailabilityWindows
        .filter((obj) => obj != null)
        .map((unavailabilityWindow) => {
          if (unavailabilityWindow.allDay) {
            return {
              ...unavailabilityWindow,
              fromTime: null,
              toTime: null,
            };
          }
          return {
            ...unavailabilityWindow,
            fromTime: moment(unavailabilityWindow.fromTime).local().format('H:mm z'),
            toTime: moment(unavailabilityWindow.toTime).local().format('H:mm z'),
          };
        })
    : [];

  const contactDetails = contactDetailsFormat.map((contact: TContactDetail) => {
    const remark = contact.remark === '' ? null : contact.remark;
    return { ...contact, remark };
  });

  return {
    ...o,
    contactDetails,
    unavailabilityWindows,
  };
};
