// @flow
import React from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { ElearningListTable } from '../../organisms/elearning/ElearningListTable';
import { LB_DETAIL } from '../../../config/elearning.keys';

export type TElearningListProps = {
  intl: intlShape,
  // keys: string[];
};

const TRANSLATIONS = {
  TITLE: { id: 'templates.crud_sider.elearning_title', defaultMessage: 'Elearning' },
  OVERVIEW: { id: 'templates.crud_sider.elearning_lb_detail', defaultMessage: 'DP Overview' },
  CREATE: { id: 'elearning.overview.create', defaultMessage: 'Edit help item' },
};

function List(props: TElearningListProps) {
  // Layout
  const title = `${props.intl.formatMessage(TRANSLATIONS.TITLE)} ${props.intl.formatMessage(TRANSLATIONS.OVERVIEW)}`;

  return (
    <ListTemplate title={title} buttonText={props.intl.formatMessage(TRANSLATIONS.CREATE)} menuBlock="elearning" menuItem="elearning-lb-detail">
      <ElearningListTable keys={LB_DETAIL} />
    </ListTemplate>
  );
}

export const ElearningLbDetailListPage = injectIntl(List);
