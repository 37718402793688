// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Checkbox, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../../../types';

type TProps = {
  confirm: Function,
  falseName: string | React.Node,
  filterProp: string,
  filterType: string,
  search: Function,
  selectedValues: boolean[],
  trueName: string | React.Node,
};

export const filterBoolean = (props: TProps) => {
  const [updating, setUpdating] = useState(false);
  const [selectedValues, setSelectedValues] = useState(props.selectedValues || []);

  const backendToInternal = (selectedValues): boolean[] => {
    switch (selectedValues.length) {
      case 0:
        return [false, false];
      case 1:
        return selectedValues[0] ? [true, false] : [false, true];
      case 2:
      default:
        return [true, true];
    }
  };

  const internalToBackend = (selectedValues) => {
    if (selectedValues[0] && selectedValues[1]) {
      return [true, false];
    }
    if (selectedValues[0] && !selectedValues[1]) {
      return [true];
    }
    if (!selectedValues[0] && selectedValues[1]) {
      return [false];
    }

    return [];
  };

  useEffect(() => {
    if (!updating) {
      setSelectedValues(backendToInternal(props.selectedValues));
    }
  }, [props.selectedValues]);

  const search = (values) => {
    setUpdating(false);
    props.confirm();
    props.search(internalToBackend(values || selectedValues), props.filterProp);
  };

  const reset = () => {
    setSelectedValues([]);
    search([]);
  };

  const onChange = (index: number) => (e: any) => {
    const newValues = [...selectedValues];
    newValues[index] = e.target.checked;

    setUpdating(true);
    setSelectedValues(newValues);
  };

  return (
    <div className="custom-filter-dropdown">
      <div className="custom-filter-content">
        <Row className="custom-filter-row">
          <Checkbox checked={selectedValues[0]} onChange={onChange(0)}>
            {props.trueName}
          </Checkbox>
        </Row>
        <Row className="custom-filter-row">
          <Checkbox checked={selectedValues[1]} onChange={onChange(1)}>
            {props.falseName}
          </Checkbox>
        </Row>
      </div>
      <div className="custom-filter-footer">
        <Row>
          <Button type="primary" onClick={() => search()}>
            <FormattedMessage id="filterDropdown.search" defaultMessage="Apply filter" />
          </Button>
        </Row>
        <Row className="text-underline" style={{ cursor: 'pointer' }} onClick={reset}>
          <FormattedMessage id="filterDropdown.reset" defaultMessage="Reset" />
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = (state: TGlobalState, ownProps: TProps) => ({
  selectedValues: (state[ownProps.filterType].page && state[ownProps.filterType].page.filter && state[ownProps.filterType].page.filter[ownProps.filterProp]) || [],
});

// $FlowFixMe
export const FilterBoolean = connect<any, Object, _, _, _, _>(mapStateToProps, undefined)(filterBoolean);
