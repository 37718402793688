// @flow
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import type { TBargeOperator, TBargeOperatorForm, TBargeOperatorPageRequest, TBaseAction, TGlobalState, TPage } from '../../types';
import { genericErrorHandler } from '../../utils';
import { browserHistory } from '../index';
import { appApi } from '../../config/api.config';
import { SITE_PATHS, API_PATHS, API_STATUS_CODE } from '../../config/api.constants';
import { createPath } from '../../utils/routing.utils';
import { showNotificationAction } from '../actions/notificationActions';
import { BargeOperator } from '../models/BargeOperator';
import { formatUri } from '../../utils/format.utils';
import { getBargeOperatorPageAction, receivedBargeOperatorPageAction, BargeOperatorActions, receivedBargeOperatorAction } from '../actions';

export function* create(action: TBaseAction<TBargeOperatorForm>): Generator<*, *, *> {
  const { payload, callback } = action;
  const bargeOperator = BargeOperator.toJson(payload);

  try {
    yield call(appApi.post, API_PATHS.V1.BARGE_OPERATOR, bargeOperator);
    browserHistory.push(SITE_PATHS.BARGE_OPERATOR_LIST);
  } catch (err) {
    const { response } = err;
    if (response && response.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      const errors = [];
      const { validationErrors } = response.data;

      if ('abbreviation' in validationErrors) {
        errors.push('abbreviation');
      }

      if ('reference' in validationErrors) {
        errors.push('reference');
      }

      if (callback) {
        callback(errors);
      }
    }
  }
}

export function* detail(action: TBaseAction<number>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.get, `${API_PATHS.V1.BARGE_OPERATOR}/${payload}`);

  const { data } = response;

  data.unavailabilityWindows = data.unavailabilityWindows.map((unavailabilityWindow) => ({
    ...unavailabilityWindow,
    fromTime: moment(unavailabilityWindow.fromTime, 'HH:mmZ').local().format('HH:mm'),
    toTime: moment(unavailabilityWindow.toTime, 'HH:mmZ').local().format('HH:mm'),
  }));

  yield put(receivedBargeOperatorAction(data));
}

export function* edit(action: TBaseAction<TBargeOperator>): Generator<*, *, *> {
  const { payload, callback } = action;
  const bargeOperator = BargeOperator.toJson(payload);

  try {
    const response = yield call(appApi.put, `${API_PATHS.V1.BARGE_OPERATOR}/${payload.id}`, bargeOperator);

    yield put(receivedBargeOperatorAction(response.data));
    browserHistory.push(createPath(SITE_PATHS.BARGE_OPERATOR_DETAIL, { id: payload.id }));
  } catch (err) {
    const { response } = err;
    if (response && response.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
      const errors = [];
      const { validationErrors } = response.data;

      if ('reference' in validationErrors) {
        errors.push('reference');
      }

      if (callback) {
        callback(errors);
      }
    }
  }
}

export function* page(action: TBaseAction<?TBargeOperatorPageRequest>): Generator<*, *, *> {
  const { payload } = action;
  const uri = payload ? formatUri(API_PATHS.V1.BARGE_OPERATOR, payload) : API_PATHS.V1.BARGE_OPERATOR;
  const response = yield call(appApi.get, uri);

  yield put(receivedBargeOperatorPageAction(response.data));
}

export function* remove(action: TBaseAction<TBargeOperator>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.delete, `${API_PATHS.V1.BARGE_OPERATOR}/${payload.id}`);
  if (response.status === API_STATUS_CODE.SUCCESS_NO_CONTENT) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.BARGE_OPERATOR_DELETE));
  }

  const pageData: TPage<TBargeOperator> = yield select((state: TGlobalState) => state.bargeOperators.list);
  yield put(getBargeOperatorPageAction({ filter: {}, paging: { size: pageData.size, number: pageData.number } }));
}

export function* BargeOperatorSaga(): Generator<*, *, *> {
  yield takeLatest(BargeOperatorActions.create, genericErrorHandler(create));
  yield takeLatest(BargeOperatorActions.detail, genericErrorHandler(detail));
  yield takeLatest(BargeOperatorActions.edit, genericErrorHandler(edit));
  yield takeLatest(BargeOperatorActions.page, genericErrorHandler(page));
  yield takeLatest(BargeOperatorActions.remove, genericErrorHandler(remove));
}
