// @flow
import * as React from 'react';
import { Col, Row, Table } from 'antd';
import { capitalize } from '../../../../utils/format.utils';

type TProps = {
  columns: Object[],
  dataSource: Object[],
  loading?: boolean,
  title: string,
};

export const renderLabel = (text: string) => (
  <span>
    <b>{capitalize(text.toLowerCase())}</b>
  </span>
);

export function MDDetailTable(props: TProps) {
  const indexedData = props.dataSource.map((val, index) => {
    const { ...indexedVal } = val;
    indexedVal.index = index;
    return indexedVal;
  });

  return (
    <Row>
      <Col span={16}>
        <Table
          showHeader={false}
          bordered={false}
          size="middle"
          loading={props.loading}
          columns={props.columns}
          dataSource={indexedData}
          rowKey="index"
          title={() => <h2>{props.title}</h2>}
          pagination={false}
          rowClassName={() => 'table-hoverless-row'}
          className="table-hoverless"
        />
      </Col>
    </Row>
  );
}

MDDetailTable.defaultProps = {
  loading: false,
};
