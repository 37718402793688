// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisitB2BLink, selectBargeClerkVisitErrorsSentAt, selectBargeClerkVisitId, selectSendErrorsLink } from '../../../store/selectors/barge_clerk.selectors';
import { selectExternalErrors, selectLoadingExternalErrors } from '../../../store/selectors/errors.selectors';
import { ExternalErrorsCollapse } from '../../molecules/collapses/ExternalErrors/ExternalErrors';

export function BargeClerkDetailExternalErrors() {
  const externalErrors = useSelector(selectExternalErrors);
  const loadingExternalErrors = useSelector(selectLoadingExternalErrors);
  const b2bLink: string = useSelector(selectBargeClerkVisitB2BLink);
  const visitId: string = useSelector(selectBargeClerkVisitId);
  const mailLink: string = useSelector(selectSendErrorsLink);
  const errorsSentAt = useSelector(selectBargeClerkVisitErrorsSentAt);

  return (
    <ExternalErrorsCollapse
      elearningContentKey="LB_DETAIL_EXT_ERRORS_BLOCK_HEADER"
      errors={externalErrors}
      mailLink={mailLink}
      timestamp={errorsSentAt}
      loading={loadingExternalErrors}
      b2bLink={b2bLink}
      visitId={visitId}
      disableArchive
    />
  );
}
