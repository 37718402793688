// @flow
import { combineReducers } from 'redux';
import { ShiftActions } from '../../actions/shift.actions';
import { listReducer } from './list.reducer';
import loadingReducer from '../loading.reducers';

const reducer = combineReducers({
  list: listReducer,
  loadingList: loadingReducer(ShiftActions.get, ShiftActions.received),
});

export default reducer;
