// @flow
import { Layout } from 'antd';
import * as React from 'react';
import { connect } from 'react-redux';
import { APPLICATION_VERSION } from '../../config/settings';
import { logoutAction } from '../../store/actions';
import type { TGlobalState } from '../../types';
import { TopMenu } from '../organisms/menus/TopMenu';

export type TAppTemplateProps = {
  backendVersion: string,
  children: any,
  className?: string,
  logout: Function,
  menuBlock?: string,
  menuItem?: string,
  username: string,
};

const { Content, Header } = Layout;

function Template(props: TAppTemplateProps) {
  return (
    <Layout className={`full-page ${props.className ? props.className : ''}`}>
      <Header>
        <TopMenu menuItem={props.menuItem} menuBlock={props.menuBlock} logout={props.logout} username={props.username} />
      </Header>
      <Content className="full-height">{props.children}</Content>
      <p className="text-center" style={{ color: '#b7b7b7' }}>
        <small>
          Frontend: <strong>{APPLICATION_VERSION}</strong> | Backend: <strong>{props.backendVersion}</strong>
        </small>
      </p>
    </Layout>
  );
}

Template.defaultProps = {
  menuBlock: '',
  menuItem: '',
  className: '',
};

const mapStateToProps = (state: TGlobalState) => ({
  username: (state.user && state.user.bootstrap.profile && state.user.bootstrap.profile.name) || 'username',
  backendVersion: (state.user && state.user.bootstrap.version) || null,
});

export const AppTemplate = connect<any, Object, _, _, _, _>(mapStateToProps, {
  logout: logoutAction,
})(Template);
