// @flow
import { combineReducers } from 'redux';
import { QuayActions } from '../../actions';
import loadingReducer from '../loading.reducers';
import { detailReducer } from './detail.reducers';
import { pageReducer } from '../page.reducers';
import { listReducer } from './list.reducer';

const reducer = combineReducers({
  detail: detailReducer,
  page: pageReducer(QuayActions.receivedPage),
  loadingDetail: loadingReducer(QuayActions.detail, QuayActions.receivedDetail),
  loadingPage: loadingReducer(QuayActions.page, QuayActions.receivedPage),
  list: listReducer,
  loadingList: loadingReducer(QuayActions.listAllQuays, QuayActions.receivedAllQuays),
});

export default reducer;
