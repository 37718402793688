// @flow
import type { TTALInfo, TVisit } from '../types';

export const extractTALInfo = (visit: TVisit, out: boolean): TTALInfo[] => {
  if (out) {
    return [
      {
        containerSize: 20,
        containerSizeLabel: '20ft.',
        direction: 'out',
        mismatchEmpty: visit.talMismatchOutTwentyFtEmpty,
        mismatchFull: visit.talMismatchOutTwentyFtFull,
        numberOfContainersEmpty: visit.btsNumberOfContainersOutTwentyFtEmpty,
        numberOfContainersEmptyTodo: visit.tosNumberOfContainersOutTwentyFtEmptyTodo,
        numberOfContainersFull: visit.btsNumberOfContainersOutTwentyFtFull,
        numberOfContainersFullTodo: visit.tosNumberOfContainersOutTwentyFtFullTodo,
        thresholdMismatchBtsTos: visit.thresholdMismatchBtsTos,
      },
      {
        containerSize: 40,
        containerSizeLabel: '40ft.',
        direction: 'out',
        mismatchEmpty: visit.talMismatchOutFortyFtEmpty,
        mismatchFull: visit.talMismatchOutFortyFtFull,
        numberOfContainersEmpty: visit.btsNumberOfContainersOutFortyFtEmpty,
        numberOfContainersEmptyTodo: visit.tosNumberOfContainersOutFortyFtEmptyTodo,
        numberOfContainersFull: visit.btsNumberOfContainersOutFortyFtFull,
        numberOfContainersFullTodo: visit.tosNumberOfContainersOutFortyFtFullTodo,
        thresholdMismatchBtsTos: visit.thresholdMismatchBtsTos,
      },
    ];
  }

  return [
    {
      containerSizeLabel: '20ft.',
      containerSize: 20,
      direction: 'in',
      mismatchEmpty: visit.talMismatchInTwentyFtEmpty,
      mismatchFull: visit.talMismatchInTwentyFtFull,
      numberOfContainersEmpty: visit.btsNumberOfContainersInTwentyFtEmpty,
      numberOfContainersEmptyTodo: visit.tosNumberOfContainersInTwentyFtEmptyTodo,
      numberOfContainersFull: visit.btsNumberOfContainersInTwentyFtFull,
      numberOfContainersFullTodo: visit.tosNumberOfContainersInTwentyFtFullTodo,
      thresholdMismatchBtsTos: visit.thresholdMismatchBtsTos,
    },
    {
      containerSizeLabel: '40ft.',
      containerSize: 40,
      direction: 'in',
      mismatchEmpty: visit.talMismatchInFortyFtEmpty,
      mismatchFull: visit.talMismatchInFortyFtFull,
      numberOfContainersEmpty: visit.btsNumberOfContainersInFortyFtEmpty,
      numberOfContainersEmptyTodo: visit.tosNumberOfContainersInFortyFtEmptyTodo,
      numberOfContainersFull: visit.btsNumberOfContainersInFortyFtFull,
      numberOfContainersFullTodo: visit.tosNumberOfContainersInFortyFtFullTodo,
      thresholdMismatchBtsTos: visit.thresholdMismatchBtsTos,
    },
  ];
};
