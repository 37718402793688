import { defineMessages } from 'react-intl';

export const CANCEL_REASON_MESSAGES = defineMessages({
  CANCELLATION_SKIPPER: {
    id: 'cancellation_skipper',
    defaultMessage: 'Annulatie Schipper',
  },
  CANCELLATION_BARGE_OPERATOR: {
    id: 'cancellation_barge_operator',
    defaultMessage: 'Annulatie Barge Operator',
  },
  CANCELLATION_BTS: {
    id: 'cancellation_bts',
    defaultMessage: 'Annulatie BTS',
  },
});
