// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SPACING } from '../../../constants/style.constants';
import { VISIT_STATUS } from '../../../constants/visit.constants';
import { availableVisitAction, priorityVisitAction } from '../../../store/actions';
import { selectBargeClerkVisitStatus } from '../../../store/selectors/barge_clerk.selectors';
import {
  selectVisitAvailable,
  selectVisitAvailableLink,
  selectVisitAvailableLoading,
  selectVisitPriority,
  selectVisitPriorityLink,
  selectVisitPriorityLoading,
} from '../../../store/selectors/visit.selectors';
import { CancelledSection } from '../../atoms/barge_clerks/CancelledSection';
import { DebouncedSwitch } from '../../atoms/barge_clerks/DebouncedSwitch';
import { IdleTimeReasonSection } from '../../atoms/barge_clerks/IdleTimeReasonSection';
import { Elearning } from '../../atoms/elearning';

type TProps = {
  onShowCancelModal: () => void,
};

export function BargeClerkSwitches({ onShowCancelModal }: TProps) {
  const status = useSelector(selectBargeClerkVisitStatus);
  const available = useSelector(selectVisitAvailable);
  const loadingAvailable = useSelector(selectVisitAvailableLoading);
  const priority = useSelector(selectVisitPriority);
  const loadingPriority = useSelector(selectVisitPriorityLoading);
  const dispatch = useDispatch();
  const priorityLink: string = useSelector(selectVisitPriorityLink);
  const availableLink: string = useSelector(selectVisitAvailableLink);

  const onTogglePriority = React.useCallback(
    (newPriority: boolean) => {
      if (priorityLink) {
        dispatch(priorityVisitAction({ previousPriority: priority, newPriority, link: priorityLink }));
      }
    },
    [priorityLink, priority, dispatch, priorityVisitAction],
  );
  const onToggleAvailable = React.useCallback(
    (newAvailable: boolean) => {
      if (availableLink) {
        dispatch(availableVisitAction({ previousAvailable: available, newAvailable, link: availableLink }));
      }
    },
    [availableLink, available, dispatch, availableVisitAction],
  );

  return status !== VISIT_STATUS.CANCELLED && status !== VISIT_STATUS.DECISION_CBPA ? (
    <>
      <IdleTimeReasonSection />
      <div className="idletime" style={{ marginLeft: SPACING.DP }}>
        <div style={{ display: 'flex', alignItems: 'center', height: 32 }}>
          <DebouncedSwitch
            loading={loadingPriority}
            checked={priority}
            text={<FormattedMessage id="detail-info-section.switch.priority" defaultMessage="Barge is Prio" />}
            onToggle={onTogglePriority}
          />
        </div>
      </div>
      <div className="idletime" style={{ marginLeft: SPACING.DP }}>
        <DebouncedSwitch
          loading={loadingAvailable}
          checked={available}
          text={<FormattedMessage id="detail-info-section.switch.available" defaultMessage="Barge is available" />}
          onToggle={onToggleAvailable}
        />
      </div>
      <div className="idletime" style={{ marginLeft: SPACING.DP * 2 }}>
        <div style={{ display: 'flex', alignItems: 'center', height: 32 }}>
          <Elearning contentKey="LB_DETAIL_DECISION_CBPA" />
          <button type="button" className="visit-top-link" onClick={onShowCancelModal}>
            <FormattedMessage id="detail_info_section.button.cancel" defaultMessage="Cancel visit" />
          </button>
        </div>
      </div>
    </>
  ) : (
    <CancelledSection />
  );
}
