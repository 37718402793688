// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Popover } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { keys } from 'lodash';
import moment from 'moment';
import type { TVisitMetaData } from '../../types';
import { VISIT_ACTIONS } from '../../constants/visit.constants';
import { humanizeTimeStamp } from '../../utils/dates.utils';
import { SPACING } from '../../constants/style.constants';

type TProps = {
  actions: TVisitMetaData[],
  intl: intlShape,
};

function ActionLogComponent(props: TProps) {
  const shouldDisplay = (action) => !!VISIT_ACTIONS[action.name] && (action.name === VISIT_ACTIONS.TAL_CUTOFF_REACHED.id ? moment().isAfter(moment(action.value)) : true);

  const content = (
    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: SPACING.DP / 2, paddingBottom: SPACING.DP / 2 }}>
      {keys(VISIT_ACTIONS).map((key) => {
        if (!props.actions) {
          return null;
        }

        const action = props.actions.find((a) => a.name === VISIT_ACTIONS[key].id);

        return (
          <div
            key={VISIT_ACTIONS[key].id}
            style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', flexWrap: 'nowrap', marginTop: SPACING.DP / 2, marginBottom: '2px' }}
          >
            <span style={{ display: 'inline-block', width: '14px' }}>{action && shouldDisplay(action) ? <CheckOutlined style={{ color: '#237036' }} /> : ''}</span>
            <div style={{ paddingLeft: SPACING.DP }}>
              <span className="text-black">{props.intl.formatMessage(VISIT_ACTIONS[key])}</span>
              {action && shouldDisplay(action) ? <span className="text-extra-small text-dust"> - {humanizeTimeStamp(action.value)}</span> : null}
            </div>
          </div>
        );
      })}
    </div>
  );

  const list = props.actions && props.actions.length ? props.actions : [];
  const listFiltered = list.filter((item) => shouldDisplay(item) && item.name !== 'PREV_BTS_TAL');
  const shortList = listFiltered.length > 3 ? listFiltered.slice(0, 3) : listFiltered;

  return (
    <Popover content={content} placement="left" title={<FormattedMessage id="action_log.popover.title" defaultMessage="Action log details" />}>
      <div className="actionlog-wrapper">
        {shortList.map((action) => {
          return (
            <span key={action.name} className={`label-mini ${action.name === VISIT_ACTIONS.TAL_CUTOFF_REACHED.id ? 'red' : ''}`}>
              {props.intl.formatMessage(VISIT_ACTIONS[action.name])}
            </span>
          );
        })}
      </div>
    </Popover>
  );
}

export const ActionLog = injectIntl(ActionLogComponent);
