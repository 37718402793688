// @flow
import * as React from 'react';
import moment, { Moment } from 'moment';
import $ from 'jquery';
import { connect } from 'react-redux';
import { NAVIGATION_CHART } from '../../../constants/navigation_chart.constants';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { updateVisitSailingSchedule } from '../../../store/handlers/visits.handler';
import { Refresh } from '../../atoms/Refresh';
import { Timeline } from '../../molecules/navigation_chart/Timeline';
import type { TGlobalState, TVisit } from '../../../types';
import { Visit } from '../../molecules/navigation_chart/Visit';
import { getSailingScheduleAction } from '../../../store/actions/sailing_schedule.actions';
import type { TSailingSchedule } from '../../../types/sailing_schedule.types';
import { Socket } from '../../atoms/Socket';

type TProps = {
  getSailingScheduleAction: typeof getSailingScheduleAction,
  sailingSchedule: TSailingSchedule,
  visit: TVisit,
};

type TState = {
  deepestLevel: number,
};

class NavigationChartComponent extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { deepestLevel: 1 };
  }

  componentDidMount(): void {
    this.props.getSailingScheduleAction(this.props.visit);
  }

  componentDidUpdate(prevProps: TProps) {
    if (prevProps.visit.id !== this.props.visit.id) {
      this.props.getSailingScheduleAction(this.props.visit);
    }
    if (prevProps.sailingSchedule !== this.props.sailingSchedule) {
      /*
        deepestLevel is only updated when sailingSchedule changes, so no infinite loop will occur.
       */
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ deepestLevel: 1 });
      this.getVisitTree();
    }
  }

  getVisitTree() {
    let structure = [];

    this.props.sailingSchedule.forEach((visit) => {
      structure = this.goDeeper(structure, visit, 1);
    });

    return structure;
  }

  goDeeper(visits: Object[], visit: Object, level: number) {
    const structure = visits;

    const root: ?Object = structure.find((foundVisit) => {
      const start = moment(visit.etaRtaOps);
      const foundStart = moment(foundVisit.etaRtaOps);
      const foundEnd = moment(foundVisit.etdRtdCtd);

      return start.isSameOrAfter(foundStart) && start.isSameOrBefore(foundEnd);
    });

    if (root && root.id !== visit.id) {
      root.overlappingVisits = this.goDeeper(root.overlappingVisits, visit, level + 1);
    } else {
      structure.push({ ...visit, overlappingVisits: [] });

      if (this.state.deepestLevel < level) {
        this.setState({ deepestLevel: level });
      }
    }

    return structure;
  }

  renderVisits(pivot: Moment, visits: Object[], level?: number): any {
    const currentLevel = level || 1;

    return visits.map((visit: Object) => {
      const result = visit.overlappingVisits && visit.overlappingVisits.length > 0 ? this.renderVisits(pivot, visit.overlappingVisits, currentLevel + 1) : null;

      return (
        <React.Fragment key={visit.id}>
          <Visit
            btsId={visit.btsId}
            endTime={moment(visit.etdRtdCtd)}
            percentageCompleted={(visit.numberOfContainersToDo / visit.btsNumberOfContainers) * 100}
            pivot={pivot}
            quay={(visit.quayPlanned && visit.quayPlanned.name) || (visit.quayRequested && visit.quayRequested.name) || ''}
            startTime={moment(visit.etaRtaOps)}
            tal={visit.btsNumberOfContainers}
            travelDuration={moment.duration(visit.travelTime)}
            todo={visit.btsNumberOfContainers - visit.numberOfContainersDone}
            psaVisit={visit.psaVisit}
            status={visit.status}
            level={currentLevel}
            isOwnVisit={this.props.visit.btsId === visit.btsId}
          />
          {result}
        </React.Fragment>
      );
    });
  }

  render() {
    if (!this.props.sailingSchedule) return null;

    const calculatedComponentHeight = NAVIGATION_CHART.HEIGHT + (this.state.deepestLevel - 1) * 70;

    const chartWrapperWidth = $('#navigation_chart_wrapper').width();
    NAVIGATION_CHART.HOUR_WIDTH = chartWrapperWidth / NAVIGATION_CHART.HOURS_RANGE || NAVIGATION_CHART.HOUR_WIDTH;

    const outerHeight = calculatedComponentHeight + NAVIGATION_CHART.PADDING * 2;
    const outerWidth = NAVIGATION_CHART.HOUR_WIDTH * NAVIGATION_CHART.HOURS_RANGE + NAVIGATION_CHART.PADDING * 2;

    const pivot = moment(this.props.visit.etaRtaOps);
    const impactedVisitStreams = this.props.sailingSchedule.map((visit) => EVENT_TYPES.SPECIFIC_VISIT_STREAM.replace(':id', visit.id));

    return (
      <Socket
        topics={impactedVisitStreams}
        onMessage={updateVisitSailingSchedule({ getSailingScheduleAction: this.props.getSailingScheduleAction, visit: this.props.visit })}
        socketKey={`NavigationChart.${this.props.visit.id}`}
      >
        <Refresh rate={10000} refreshAction={() => this.props.getSailingScheduleAction(this.props.visit)}>
          <svg viewBox={`0 0 ${outerWidth} ${outerHeight}`} style={{ width: '100%' }}>
            <g x={NAVIGATION_CHART.PADDING} y={NAVIGATION_CHART.PADDING} transform={`translate(${NAVIGATION_CHART.PADDING}, ${NAVIGATION_CHART.PADDING})`}>
              <Timeline pivot={pivot} componentHeight={calculatedComponentHeight} />
              {this.renderVisits(pivot, this.getVisitTree())}
            </g>
          </svg>
        </Refresh>
      </Socket>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  sailingSchedule: state.sailingSchedule.schedule,
});

export const NavigationChart = connect<any, Object, _, _, _, _>(mapStateToProps, { getSailingScheduleAction })(NavigationChartComponent);
