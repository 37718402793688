// @flow
import { combineReducers } from 'redux';
import { ErrorActions } from '../../actions';
import loadingReducer from '../loading.reducers';
import { visitInternalReducer } from './internal.reducers';
import { visitExternalReducer } from './external.reducers';

const reducer = combineReducers({
  loadingVisitExternalList: loadingReducer(ErrorActions.visitExternalList, ErrorActions.receivedVisitExternalList),
  loadingVisitInternalList: loadingReducer(ErrorActions.visitInternalList, ErrorActions.receivedVisitInternalList),
  visitExternalList: visitExternalReducer,
  visitInternalList: visitInternalReducer,
});

export default reducer;
