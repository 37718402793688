/* eslint-disable no-unused-vars,react/no-unused-prop-types */
// @flow
import * as React from 'react';
import moment, { Moment } from 'moment';
import { injectIntl, intlShape } from 'react-intl';
import { diffDates } from '../../../utils/dates.utils';
import { calcXPos } from '../../../utils/navigation_chart.utils';
import { NAVIGATION_CHART } from '../../../constants/navigation_chart.constants';
import { PLACEHOLDER_MESSAGES } from '../../../config/messages/forms';

type TProps = {
  componentHeight: number,
  intl: intlShape,
  pivot: Moment,
};

const timelineComponent = (props: TProps) => {
  const start = props.pivot.clone().subtract(24, 'hours');
  const times: Moment[] = [];

  const now = moment();
  const nowOffset = diffDates(now, props.pivot);

  const x = calcXPos(nowOffset, NAVIGATION_CHART.HOUR_WIDTH, NAVIGATION_CHART.HOUR_WIDTH * 48);

  const lineHeight = props.componentHeight - NAVIGATION_CHART.PADDING / 2;
  const verticalLineHeight = props.componentHeight / 15;

  for (let i = 0; i <= NAVIGATION_CHART.HOURS_RANGE / NAVIGATION_CHART.HOURS_STEP; i += 1) {
    times.push(start.clone().add(NAVIGATION_CHART.HOURS_STEP * i, 'hours'));
  }

  return (
    <g>
      {times.map((time, index) => (
        <rect key={time} className="stripe" x={index * NAVIGATION_CHART.HOURS_STEP * NAVIGATION_CHART.HOUR_WIDTH} y={lineHeight - verticalLineHeight} height={verticalLineHeight} width={1} />
      ))}

      <rect id="timeline" x={-NAVIGATION_CHART.PADDING / 2} y={lineHeight} width={NAVIGATION_CHART.HOUR_WIDTH * NAVIGATION_CHART.HOURS_RANGE + NAVIGATION_CHART.PADDING} height={1} />

      {times.map((time, index) => (
        <text key={time} x={index * NAVIGATION_CHART.HOURS_STEP * NAVIGATION_CHART.HOUR_WIDTH} y={lineHeight + NAVIGATION_CHART.PADDING / 1.5} className="text-bold navchart-font">
          {time.format('HH:mm')}
        </text>
      ))}

      <text className="timeline-now" x={x} y={lineHeight + NAVIGATION_CHART.PADDING / 3}>
        {props.intl.formatMessage(PLACEHOLDER_MESSAGES.now)}
      </text>

      <line x1={x} x2={x} y1="0" y2={lineHeight} stroke="black" className="timeline-now-line" />
    </g>
  );
};

export const Timeline = injectIntl(timelineComponent);
