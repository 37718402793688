// @flow
import * as React from 'react';
import { Table, Tag } from 'antd';
import { FormattedMessage } from 'react-intl';
import type { TBTSUpdate, TTALInfo } from '../../../types';
import { EditableCell, EditableRow } from '../../organisms/containers/EditField';
import { CONTAINER_STATES } from '../../../constants/container.constants';

type TTalList = {
  containerSize: number,
  containerSizeLabel: string,
  direction: string,
  id: number,
  numberOfContainersEmpty: number,
  numberOfContainersFull: number,
  tosNumberOfContainersEmpty: number,
  tosNumberOfContainersFull: number,
  thresholdMismatchBtsTos: number,
};

type TProps = {
  visitId: string,
  info: TTALInfo[],
  updateBTS: (input: TBTSUpdate) => void,
};

export function TALTable(props: TProps) {
  const containerLabels = ['20ft.', '40ft.'];

  const columns = [
    {
      dataIndex: 'bla',
      render: (text, record, index) => containerLabels[index],
    },
    {
      align: 'left',
      title: <FormattedMessage id="atoms.tables.taltable.empty" defaultMessage="Empty" />,
      children: [
        {
          align: 'left',
          title: <FormattedMessage id="atoms.tables.taltable.To_do" defaultMessage="To do" />,
          dataIndex: 'numberOfContainersEmptyTodo',
        },
        {
          align: 'left',
          title: <FormattedMessage id="atoms.tables.taltable.tot" defaultMessage="Tot." />,
          dataIndex: 'numberOfContainersEmpty',
          editable: true,
        },
        {
          align: 'left',
          title: <FormattedMessage id="atoms.tables.taltable.mism" defaultMessage="Mism." />,
          dataIndex: 'mismatchEmpty',
          render: (text, record: TTALInfo) => <div>{record.mismatchEmpty >= record.thresholdMismatchBtsTos ? <Tag color="red">{record.mismatchEmpty}</Tag> : record.mismatchEmpty}</div>,
        },
      ],
    },
    {
      align: 'left',
      title: <FormattedMessage id="atoms.tables.taltable.full" defaultMessage="Full" />,
      children: [
        {
          align: 'left',
          title: <FormattedMessage id="atoms.tables.taltable.To_do" defaultMessage="To do" />,
          dataIndex: 'numberOfContainersFullTodo',
        },
        {
          align: 'left',
          title: <FormattedMessage id="atoms.tables.taltable.tot" defaultMessage="Tot." />,
          dataIndex: 'numberOfContainersFull',
          editable: true,
        },
        {
          align: 'left',
          title: <FormattedMessage id="atoms.tables.taltable.mism" defaultMessage="Mism." />,
          dataIndex: 'mismatchFull',
          render: (text, record: TTALInfo) => <div>{record.mismatchFull >= record.thresholdMismatchBtsTos ? <Tag color="red">{record.mismatchFull}</Tag> : record.mismatchFull}</div>,
        },
      ],
    },
  ];

  const handleSave = (values: TTalList) => {
    const containerSize = values.containerSize === 20 ? 0 : 1;
    let updateValues = {
      visitId: props.visitId,
      direction: values.direction,
      size: values.containerSize,
    };
    if (values.numberOfContainersEmpty !== props.info[containerSize].numberOfContainersEmpty) {
      updateValues = {
        ...updateValues,
        amount: values.numberOfContainersEmpty,
        state: CONTAINER_STATES.EMPTY,
      };
    } else if (values.numberOfContainersFull !== props.info[containerSize].numberOfContainersFull) {
      updateValues = {
        ...updateValues,
        amount: values.numberOfContainersFull,
        state: CONTAINER_STATES.FULL,
      };
    }
    props.updateBTS(updateValues);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const mapColumns = (cols: Object[]) =>
    cols.map((col) => {
      const colUpdated = col;
      if (colUpdated.children) {
        colUpdated.children = mapColumns(colUpdated.children);
      }
      if (!colUpdated.editable) {
        return colUpdated;
      }
      return {
        ...colUpdated,
        onCell: (record) => ({
          record,
          editable: colUpdated.editable,
          dataIndex: colUpdated.dataIndex,
          handleSave,
        }),
      };
    });

  return <Table className="tal-table" components={components} size="small" bordered columns={mapColumns(columns)} dataSource={props.info} rowKey="containerSizeLabel" pagination={false} />;
}
