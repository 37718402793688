// @flow
import * as React from 'react';
import { Select } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { keys } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { SPACING } from '../../../constants/style.constants';
import { IDLE_TIME_REASON_MESSAGES } from '../../../config/messages/idleTimeReasons';
import { setIdleTimeReasonAction } from '../../../store/actions';
import { selectBargeClerkVisitIdleTimeReason, selectBargeClerkVisitIdleTimeReasonLink } from '../../../store/selectors/barge_clerk.selectors';
import { Elearning } from '../elearning';

type TProps = {
  intl: intlShape,
};

function IdleTimeReasonSectionComponent({ intl }: TProps) {
  const dispatch = useDispatch();
  const idleTimeReasonLink = useSelector(selectBargeClerkVisitIdleTimeReasonLink);
  const idleTimeReason = useSelector(selectBargeClerkVisitIdleTimeReason);
  const onChange = React.useCallback((reason) => dispatch(setIdleTimeReasonAction(idleTimeReasonLink, reason), []));

  return (
    <div className="idletime">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Elearning contentKey="LB_DETAIL_IDLE_TIME" />
        <span style={{ marginRight: SPACING.COLLAPSE / 2 }} className="text-menu">
          <FormattedMessage id="detail-section.idle_time_reason" defaultMessage="Idle time" />
        </span>
        <Select
          allowClear
          style={{ width: 233 }}
          placeholder={<FormattedMessage id="detail-section.idle_time_reason.placeholder" defaultMessage="Select a reason" />}
          value={idleTimeReason}
          onChange={onChange}
        >
          {keys(IDLE_TIME_REASON_MESSAGES).map((key) => (
            <Select.Option key={key} value={key}>
              {intl.formatMessage(IDLE_TIME_REASON_MESSAGES[key])}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export const IdleTimeReasonSection = injectIntl(IdleTimeReasonSectionComponent);
