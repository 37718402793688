// @flow
import { Menu } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { SITE_PATHS } from '../../../config/api.constants';
import { ROLES } from '../../../constants/roles.constants';
import type { TGlobalState } from '../../../types';
import { isAuthorized } from '../../../utils/authorization.utils';
import { ElearningToggle } from '../../atoms/elearning/ElearningToggle';
import { ElearningLink } from '../../atoms/elearning/ElearningLink';
import { getSystemSettingsPageAction } from '../../../store/actions/system_settings.actions';

type TProps = {
  getSystemSettingsPageAction: typeof getSystemSettingsPageAction,
  logout: Function,
  menuBlock?: ?string,
  menuItem?: string,
  username: string,
  userRoles: string[],
};

function TopMenuComponent(props: TProps) {
  props.getSystemSettingsPageAction();

  const menuItemsLeft = [
    {
      label: (
        <Link to={SITE_PATHS.VISIT_LIST}>
          <FormattedMessage id="templates.top_menu.data_processor" defaultMessage="Data Processor" />
        </Link>
      ),
      key: 'visits',
      disabled: !isAuthorized([ROLES.ROLE_DATA_PROCESSOR], props.userRoles),
    },
    {
      label: (
        <Link to={SITE_PATHS.BARGE_CLERK_LIST}>
          <FormattedMessage id="templates.top_menu.barge_clerk" defaultMessage="Barge Clerk" />
        </Link>
      ),
      key: SITE_PATHS.BARGE_CLERK_LIST,
      disabled: !isAuthorized([ROLES.ROLE_EMPTIES_YARD], props.userRoles),
    },
    {
      label: (
        <Link to={SITE_PATHS.LPL_LIST}>
          <FormattedMessage id="templates.top_menu.lpl_requests" defaultMessage="LPL Requests" />
        </Link>
      ),
      key: SITE_PATHS.LPL_LIST,
      disabled: !isAuthorized([ROLES.ROLE_EMPTIES_YARD], props.userRoles),
    },
    {
      label: (
        <Link to={SITE_PATHS.MASTER_DATA_HOME}>
          <FormattedMessage id="templates.top_menu.master_data" defaultMessage="Master Data" />
        </Link>
      ),
      key: 'home',
      disabled: !isAuthorized([ROLES.ROLE_MASTER_DATA_MANAGEMENT, ROLES.ROLE_DATA_PROCESSOR], props.userRoles),
    },
  ];

  const menuItemsRight = [
    {
      label: <ElearningToggle />,
      key: 'elearning-toggle',
      className: 'elearning__toggle',
    },
    {
      label: <ElearningLink />,
      key: 'elearning-link',
      className: 'elearning__link',
    },
    {
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="username">{props.username}</span>
          <DownOutlined />
        </div>
      ),
      key: 'username',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.DIAGNOTICS}>
              <FormattedMessage id="general.diagnostics" defaultMessage="Diagnostics" />
            </Link>
          ),
          key: 'diagnostics',
          disabled: false,
        },
        {
          label: (
            <Link to={SITE_PATHS.CONFIGURATION}>
              <FormattedMessage id="general.configuration" defaultMessage="Configuration" />
            </Link>
          ),
          key: 'configuration',
          disabled: !isAuthorized([ROLES.ROLE_MASTER_DATA_MANAGEMENT], props.userRoles),
        },
        {
          label: <FormattedMessage id="general.logout" defaultMessage="Log Out" />,
          key: 'logout',
          onClick: props.logout,
        },
      ],
    },
  ];
  return (
    <div className="top-menu">
      <span className="logo">
        <a href="/#">
          <img src="/logo.png" alt="logo" />
          <FormattedMessage id="general.site.name" defaultMessage="OptiBarge" />
        </a>
        <div className="triangle" />
      </span>
      <Menu className="top-menu-left" theme="dark" mode="horizontal" defaultSelectedKeys={[props.menuItem]} defaultOpenKeys={[props.menuBlock]} items={menuItemsLeft} />
      <Menu className="top-menu-right" theme="dark" mode="horizontal" defaultSelectedKeys={[props.menuItem]} defaultOpenKeys={[props.menuBlock]} items={menuItemsRight} />
    </div>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  userRoles: state.user.bootstrap.profile.roles,
});

export const TopMenu = connect<any, Object, _, _, _, _>(mapStateToProps, { getSystemSettingsPageAction })(TopMenuComponent);

TopMenuComponent.defaultProps = {
  menuBlock: null,
  menuItem: '',
};
