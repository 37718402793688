// @flow
export const NOTIFICATION_TYPES = {
  QUAY_DELETE: 'QUAY_DELETE',
  BARGE_OPERATOR_DELETE: 'BARGE_OPERATOR_DELETE',
  BARGE_DELETE: 'BARGE_DELETE',
  ERROR_CODE_DELETE: 'ERROR_CODE_DELETE',
  SKIPPER_DELETE: 'SKIPPER_DELETE',
  SYSTEM_SETTINGS_FAIL: 'SYSTEM_SETTINGS_FAIL',
  EXTERNAL_ERROR_SENT_SUCCESS: 'EXTERNAL_ERROR_SENT_SUCCESS',
  EXTERNAL_ERROR_SENT_FAIL: 'EXTERNAL_ERROR_SENT_FAIL',
  EXTERNAL_ERROR_ARCHIVE_SUCCESS: 'EXTERNAL_ERROR_ARCHIVE_SUCCESS',
  EXTERNAL_ERROR_UNARCHIVE_SUCCESS: 'EXTERNAL_ERROR_UNARCHIVE_SUCCESS',
  EXTERNAL_ERROR_ARCHIVE_FAIL: 'EXTERNAL_ERROR_ARCHIVE_FAIL',
  EXTERNAL_ERROR_UNARCHIVE_FAIL: 'EXTERNAL_ERROR_UNARCHIVE_FAIL',
  INTERNAL_ERROR_ARCHIVE_SUCCESS: 'INTERNAL_ERROR_ARCHIVE_SUCCESS',
  INTERNAL_ERROR_UNARCHIVE_SUCCESS: 'INTERNAL_ERROR_UNARCHIVE_SUCCESS',
  INTERNAL_ERROR_ARCHIVE_FAIL: 'INTERNAL_ERROR_ARCHIVE_FAIL',
  INTERNAL_ERROR_UNARCHIVE_FAIL: 'INTERNAL_ERROR_UNARCHIVE_FAIL',
  SELECT_ONLY_ONE_QUAY: 'SELECT_ONLY_ONE_QUAY',
};
