// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { isInteger } from 'lodash';
import { Col, Form, InputNumber } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { saveBollardsAction } from '../../../store/actions';
import type { TVisit } from '../../../types';
import { SPACING } from '../../../constants/style.constants';

type TProps = {
  saveBollardsAction: typeof saveBollardsAction,
  visit: TVisit,
};

export function BollardForm(props: TProps) {
  const [form] = useForm();
  const [saving, setIsSaving] = useState(false);

  useEffect(() => {
    form.resetFields();
  }, [props.visit.id]);

  const onKeyDown = (saveFn: Function) => (e: Object) => {
    if (e.key === 'Enter') {
      saveFn();
    }
  };

  const responseHandler = (statusCode: number): void => {
    if (statusCode === 200) {
      setIsSaving(false);
    }
  };

  const saveBollards = () => {
    const from = form.getFieldValue('from');
    const to = form.getFieldValue('to');

    if (from !== props.visit.bollardFrom || to !== props.visit.bollardTo) {
      props.saveBollardsAction(props.visit, from, to, responseHandler);
      setIsSaving(true);
    }
  };

  const validate = () => {
    const fromVal = form.getFieldValue('from');
    const toVal = form.getFieldValue('to');

    return isInteger(fromVal) && isInteger(toVal);
  };

  const saveFrom = () => {
    const val = form.getFieldValue('from');

    if (isInteger(val) && val > 0) {
      if (validate()) {
        saveBollards();
      }
    } else {
      form.setFields([
        {
          name: 'from',
          value: null,
        },
      ]);
    }
  };

  const saveTo = () => {
    const val = form.getFieldValue('to');

    if (isInteger(val) && val > 0) {
      if (validate()) {
        saveBollards();
      }
    } else {
      form.setFields([
        {
          name: 'to',
          value: null,
        },
      ]);
    }
  };

  if (!props.visit) return null;

  return props.visit ? (
    <>
      <Col span={5} style={{ display: 'flex', alignItems: 'center', height: 39 }}>
        <b>
          <FormattedMessage id="organisms.barge_clerks.detail.teams.bollards" defaultMessage="Bollards" />
        </b>
      </Col>
      <Col span={19}>
        <Form form={form} layout="inline" className="bollard-form">
          <Form.Item name="from" initialValue={props.visit.bollardFrom}>
            <InputNumber onKeyDown={onKeyDown(saveFrom)} onBlur={saveFrom} min={1} max={9999} disabled={saving} />
          </Form.Item>
          <div style={{ display: 'inline-flex', height: 39, alignItems: 'center', flexDirection: 'row', marginRight: SPACING.COLLAPSE / 2 }}>
            {saving ? <LoadingOutlined /> : <FormattedMessage id="organisms.barge_clerks.details.teams.t&m" defaultMessage="t/m" />}
          </div>
          <Form.Item>
            <Form.Item name="to" initialValue={props.visit.bollardTo}>
              <InputNumber onKeyDown={onKeyDown(saveTo)} onBlur={saveTo} min={1} max={9999} disabled={saving} />
            </Form.Item>
          </Form.Item>
        </Form>
      </Col>
    </>
  ) : null;
}
