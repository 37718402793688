// @flow
import * as React from 'react';
import { Col, Row, Spin, Table, Tag } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowDownOutlined, ArrowUpOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { TALTable } from '../Tables/TALTable';
import { extractTALInfo } from '../../../utils/tal.utils';
import { SPACING } from '../../../constants/style.constants';
import { saveBollardsAction, updateBTSAction } from '../../../store/actions';
import { BollardForm } from './BollardForm';
import { SpecialContainersTable } from '../Tables/SpecialContainersTable';
import { Elearning } from '../elearning';
import type { TBTSUpdate, TVisit } from '../../../types';

const columns = [
  {
    align: 'center',
    dataIndex: 'color',
    key: 'color',
    title: <FormattedMessage id="tal_section.color" defaultMessage="Color" />,
    render: (text) => <Tag color={text}>&nbsp;</Tag>,
    width: 100,
  },
  {
    align: 'left',
    dataIndex: 'crane',
    key: 'crane',
    title: <FormattedMessage id="tal_section.crane" defaultMessage="Crane" />,
    render: (text) => text || '?',
  },
];

export function TALSectionComponent() {
  const visit = useSelector(selectBargeClerkVisit);
  const dispatch = useDispatch();
  const useCallbackUpdateBTS = React.useCallback((input: TBTSUpdate) => dispatch(updateBTSAction(input)));

  return visit ? (
    <Row className="tal-panel">
      <Col span={11} style={{ marginRight: SPACING.COLLAPSE }}>
        <Row className="detail-row">
          <Col>
            <h3 style={{ display: 'inline-block' }}>
              <Elearning contentKey="LB_DETAIL_TAL_BLOCK_HEADER" />
              <FormattedMessage id="organisms.visits.visit_detail.panel.tal.header" defaultMessage="TAL" />
            </h3>
            &nbsp;
            <span className="text-subtitle">
              ({visit.numberOfContainersToDo}/{visit.btsNumberOfContainers})
            </span>
            &nbsp;
            {visit.talMismatch > 0 ? (
              <Tag color="red">
                <ExclamationCircleOutlined className="text-red exclamation-icon" width={18} height={18} />
                &nbsp;
                <span style={{ fontSize: 14 }}>{visit.talMismatch}</span>
              </Tag>
            ) : null}
          </Col>
        </Row>
        <Row className="detail-row">
          <Col>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                marginBottom: SPACING.COLLAPSE / 2,
              }}
            >
              <div>
                <ArrowDownOutlined className="text-green" />
                &nbsp;
                <b>
                  <FormattedMessage id="organisms.visits.visit_detail.unload" defaultMessage="Unload" />
                </b>
                &nbsp;
                <span className="text-dust">
                  ({visit.tosNumberOfContainersInDone}/{visit.btsNumberOfContainersIn})
                </span>
              </div>
              <div className="label">
                <b>
                  <FormattedMessage id="atoms.barge_clerks.detail.tal_section.average_stack" defaultMessage="Avg. Stack: " />
                </b>
                {visit.averageStackIn && ` ${visit.averageStackIn}`}
              </div>
            </div>
            <TALTable info={extractTALInfo(visit, false)} total={visit.btsNumberOfContainersIn} average={visit.averageStackIn} updateBTS={useCallbackUpdateBTS} visitId={visit.id} />
          </Col>
        </Row>
        <Row className="detail-row">
          <Col>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline',
                marginBottom: SPACING.COLLAPSE / 2,
              }}
            >
              <div>
                <ArrowUpOutlined className="text-red" />
                &nbsp;
                <b>
                  <FormattedMessage id="organisms.visits.visit_detail.load" defaultMessage="Load" />
                </b>
                &nbsp;
                <span className="text-dust">
                  ({visit.tosNumberOfContainersOutDone}/{visit.btsNumberOfContainersOut})
                </span>
              </div>
              <div className="label">
                <b>
                  <FormattedMessage id="atoms.barge_clerks.detail.tal_section.average_stack" defaultMessage="Avg. Stack: " />
                </b>
                {visit.averageStackOut && ` ${visit.averageStackOut}`}
              </div>
            </div>
            <TALTable info={extractTALInfo(visit, true)} total={visit.btsNumberOfContainersOut} average={visit.averageStackOut} updateBTS={useCallbackUpdateBTS} visitId={visit.id} />
          </Col>
        </Row>
      </Col>

      <Col span={11} style={{ display: 'flex', flexDirection: 'column' }}>
        <Row className="detail-row">
          <Col>
            <h3>
              <Elearning contentKey="LB_DETAIL_BOLLARDS" />
              <FormattedMessage id="organisms.visits.barge_clerks.detail.panel.bollards" defaultMessage="Bollards" />
            </h3>
          </Col>
        </Row>
        <Row className="detail-row-bollard" type="flex" align="top">
          <BollardForm saveBollardsAction={(visit: TVisit, from: number, to: number, responseCallback: Function) => dispatch(saveBollardsAction(visit, from, to, responseCallback))} visit={visit} />
          <h3 className="in-content-title">
            <FormattedMessage id="organisms.visits.barge_clerks.detail.panel.teams" defaultMessage="Teams" />
          </h3>
          <Table columns={columns} className="no-empty-image-ant no-empty-text-ant" rowKey="id" dataSource={visit.gangs} size="small" pagination={false} style={{ width: '100%' }} />
        </Row>
        <Row className="detail-row">
          <SpecialContainersTable
            imdgTodo={visit.imdgToDo}
            imdgTotal={visit.imdgTotal}
            oogTodo={visit.oversizeToDo}
            oogTotal={visit.oversizeTotal}
            reefersTodo={visit.operationalReeferToDo}
            reefersTotal={visit.operationalReeferTotal}
          />
        </Row>
      </Col>
    </Row>
  ) : (
    <Spin />
  );
}

export const TALSection = injectIntl(TALSectionComponent);
