// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { autocompleteBargeAction, clearBargeAutocompletions, editSkipperAction, fetchSkipperAction } from '../../../store/actions';
import type { TGlobalState, TSkipper } from '../../../types';
import SkipperForm from '../../organisms/skippers/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import type { TLabel } from '../../../types/label.types';

type TProps = {
  autocompleteBargeAction: typeof autocompleteBargeAction,
  autocompletions: TLabel[],
  clearBargeAutocompletions: typeof clearBargeAutocompletions,
  editSkipperAction: typeof editSkipperAction,
  fetchSkipperAction: typeof fetchSkipperAction,
  intl: intlShape,
  loading: boolean,
  loadingCompletions: boolean,
  match: any,
  skipper: TSkipper,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: {
    defaultMessage: 'Edit {firstName} {lastName}',
    id: 'page.skipper.edit.header.title',
  },
});

class SkipperEdit extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchSkipperAction(id);
  }

  render() {
    return (
      <EditTemplate
        title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title, { firstName: this.props.skipper && this.props.skipper.firstName, lastName: this.props.skipper && this.props.skipper.lastName })}
        loading={this.props.loading}
        menuBlock="skippers"
        menuItem="skippers-overview"
      >
        <SkipperForm
          handleSubmit={this.props.editSkipperAction}
          skipper={this.props.skipper}
          autocompletions={this.props.autocompletions}
          autocompleteAction={this.props.autocompleteBargeAction}
          clearAutocompletionsAction={this.props.clearBargeAutocompletions}
          loadingCompletions={this.props.loadingCompletions}
        />
      </EditTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  autocompletions: state.barges.autocompletions,
  loading: state.skippers.loadingDetail,
  loadingCompletions: state.barges.loadingAutocompletions,
  skipper: state.skippers.detail,
});

export const SkipperEditPage = injectIntl(
  connect<any, Object, _, _, _, _>(mapStateToProps, { autocompleteBargeAction, clearBargeAutocompletions, editSkipperAction, fetchSkipperAction })(SkipperEdit),
);
