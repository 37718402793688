// @flow
import { combineReducers } from 'redux';
import { ErrorCodeActions } from '../../actions/error_code.actions';
import loadingReducer from '../loading.reducers';
import { pageListReducer } from '../pageList.reducers';
import { detailReducer } from './detail.reducers';

const reducer = combineReducers({
  detail: detailReducer,
  list: pageListReducer(ErrorCodeActions.receivedPage),
  loadingDetail: loadingReducer(ErrorCodeActions.detail, ErrorCodeActions.receivedDetail),
  loadingPage: loadingReducer(ErrorCodeActions.page, ErrorCodeActions.receivedPage),
});

export default reducer;
