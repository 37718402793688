// @flow
import type { TAction, TBaseAction, TPageWithFilter, TGeoJSON, TPageRequest, TVisit, TVisitFilter, TVisitPageRequest } from '../../types';
import type { TBargeClerkSaldo, TShiftLabel } from '../../types/barge_clerk.types';

export const BargeClerkActions = {
  detail: 'BARGE_CLERK_ACTIONS.DETAIL',
  details: 'BARGE_CLERK_ACTIONS.DETAILS',
  downloadExcelForNextShifts: 'BARGE_CLERK_ACTIONS.DOWNLOAD_EXCEL',
  page: 'BARGE_CLERK_ACTIONS.PAGE',
  saldoOverview: 'BARGE_CLERK_ACTIONS.SALDO_OVERVIEW',
  totalsOverview: 'BARGE_CLERK_ACTIONS.TOTALS_OVERVIEW',
  shiftQuaysOverview: 'BARGE_CLERK_ACTIONS.SHIFT_QUAYS_OVERVIEW',
  receivedPage: 'BARGE_CLERK_ACTIONS.RECEIVED_PAGE',
  receivedDetail: 'BARGE_CLERK_ACTIONS.RECEIVED_DETAIL',
  receivedSaldoOverview: 'BARGE_CLERK_ACTIONS.RECEIVED_SALDO_OVERVIEW',
  receivedTotalsOverview: 'BARGE_CLERK_ACTIONS.RECEIVED_TOTALS_OVERVIEW',
  receivedShiftQuaysOverview: 'BARGE_CLERK_ACTIONS.RECEIVED_SHIFT_QUAYS_OVERVIEW',
  getPortGeofence: 'BARGE_CLERK_ACTIONS.GET_PORT_GEOFENCE',
  receivedPortGeofence: 'BARGE_CLERK_ACTIONS.RECEIVED_PORT_GEOFENCE',
};

export const BargeClerkFilterActions = {
  getBarges: 'BARGE_CLERK_FILTER_ACTIONS.GET_BARGES',
  getBtsIds: 'BARGE_CLERK_FILTER_ACTIONS.GET_BTS_IDS',
  getStatuses: 'BARGE_CLERK_FILTER_ACTIONS.GET_STATUSES',
  getSpecialContainers: 'BARGE_CLERK_FILTER_ACTIONS.GET_SPECIAL_CONTAINERS',
  receivedVisitFilter: 'VISIT_FILTER_ACTIONS.RECEIVED_VISIT_FILTER',
};

export const downloadExcelForShiftsAction = (shifts: TShiftLabel[], quays: number[]): TBaseAction<Object> => ({
  payload: {
    shifts,
    quays,
  },
  type: BargeClerkActions.downloadExcelForNextShifts,
});

export const getBargeClerkDetailsAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: BargeClerkActions.details,
});

export const getBargeClerkPageAction = (payload: ?TPageRequest<TVisitFilter>): TBaseAction<?TPageRequest<TVisitFilter>> => ({
  payload,
  type: BargeClerkActions.page,
});

export const getStatusFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: BargeClerkFilterActions.getStatuses,
});

export const getBtsIdFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: BargeClerkFilterActions.getBtsIds,
});

export const getBargeFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: BargeClerkFilterActions.getBarges,
});

export const getSpecialContainerFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: BargeClerkFilterActions.getSpecialContainers,
});

export const receivedVisitFilterAction = (payload: Object): TBaseAction<Object> => ({
  payload,
  type: BargeClerkFilterActions.receivedVisitFilter,
});

export const getSaldoOverviewAction = (shift: number, quays: number[]): TBaseAction<Object> => ({
  payload: { shift, quays },
  type: BargeClerkActions.saldoOverview,
});

export const receivedSaldoOverviewAction = (payload: TBargeClerkSaldo): TBaseAction<TBargeClerkSaldo> => ({
  payload,
  type: BargeClerkActions.receivedSaldoOverview,
});

export const getTotalsOverviewAction = (shift: number, quays: number[]): TBaseAction<Object> => ({
  payload: { shift, quays },
  type: BargeClerkActions.totalsOverview,
});

export const receivedTotalsOverviewAction = (payload: TBargeClerkSaldo): TBaseAction<TBargeClerkSaldo> => ({
  payload,
  type: BargeClerkActions.receivedTotalsOverview,
});

export const getShiftQuaysOverviewAction = (shifts: number[], quays: number[]): TBaseAction<Object> => ({
  payload: { shifts, quays },
  type: BargeClerkActions.shiftQuaysOverview,
});

export const receivedShiftQuaysOverviewAction = (payload: TBargeClerkSaldo): TBaseAction<TBargeClerkSaldo> => ({
  payload,
  type: BargeClerkActions.receivedShiftQuaysOverview,
});

export const receivedBargeClerkPageAction = (page: TPageWithFilter<TVisit>, prio: boolean, originalType: string): TBaseAction<TPageWithFilter<TVisit, TVisitFilter>> => ({
  payload: { page, prio, originalType },
  type: BargeClerkActions.receivedPage,
});

export const getPortGeofenceAction = (): TAction => ({
  type: BargeClerkActions.getPortGeofence,
});

export const receivedPortGeofenceAction = (payload: TGeoJSON): TBaseAction<TGeoJSON> => ({
  payload,
  type: BargeClerkActions.receivedPortGeofence,
});
