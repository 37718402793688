// @flow
import type { TLabel } from '../../types/label.types';
import type { TBaseAction } from '../../types';

export const AutocompletionReducer =
  (autocompletionAction: string, clearAction: string) =>
  (state: TLabel[] = [], action: TBaseAction<TLabel[]>) => {
    switch (action.type) {
      case autocompletionAction:
        return [...action.payload];
      case clearAction:
        return [];
      default:
        return state;
    }
  };
