// @flow
import * as React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';
import { TeamOutlined } from '@ant-design/icons';
import type { TShiftQuay } from '../../../types/barge_clerk.types';
import { isAuthorized } from '../../../utils/authorization.utils';
import { ROLES } from '../../../constants/roles.constants';
import type { TGlobalState } from '../../../types';
import { GangModalContainer } from './GangModalContainer';

type TProps = {
  roles: string[],
  shiftQuay: TShiftQuay,
};

type TState = {
  visible: boolean,
};

class GangModalButtonComponent extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  hideModal = () => {
    this.setState({
      visible: false,
    });
  };

  render() {
    return (
      <>
        <Button type="button" onClick={this.showModal} size="small" disabled={!isAuthorized([ROLES.ROLE_OPERATIONS_MANAGER], this.props.roles)} style={{ fontSize: 11 }}>
          <TeamOutlined /> {this.props.shiftQuay.numberOfGangs}
        </Button>
        <GangModalContainer
          shiftId={this.props.shiftQuay.shiftId}
          quayId={this.props.shiftQuay.quayId}
          shiftStartTime={this.props.shiftQuay.shiftStartTime}
          shiftEndTime={this.props.shiftQuay.shiftEndTime}
          visible={this.state.visible}
          hideModal={this.hideModal}
        />
      </>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  roles: state.user.bootstrap.profile.roles,
});

export const GangModalButton = connect<any, Object, _, _, _, _>(mapStateToProps, null)(GangModalButtonComponent);
