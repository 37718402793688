// @flow
import * as React from 'react';
import { WelcomeBox } from '../organisms/Welcome';
import { CrudTemplate } from './CrudTemplate';

export function WelcomeTemplate() {
  return (
    <CrudTemplate location="home" menuBlock="home" menuItem="home">
      <WelcomeBox />
    </CrudTemplate>
  );
}
