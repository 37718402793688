// @flow
import * as React from 'react';
import moment, { Moment } from 'moment';
import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import { VISIT_STATUS_CSS_MAP } from '../../../constants/visitStatus.constants';
import { diffDates } from '../../../utils/dates.utils';
import { calcWidth, calcXPos } from '../../../utils/navigation_chart.utils';
import { NAVIGATION_CHART } from '../../../constants/navigation_chart.constants';

type TProps = {
  btsId: string,
  endTime: Moment,
  isOwnVisit: boolean,
  level: number,
  percentageCompleted: number,
  pivot: Moment,
  psaVisit: boolean,
  quay: string,
  startTime: Moment,
  status: string,
  tal: number,
  todo: number,
  travelDuration: moment.Duration,
};

export function Visit(props: TProps) {
  const offset = diffDates(props.startTime, props.pivot);
  const duration = diffDates(props.endTime, props.startTime);

  const width = calcWidth(duration, NAVIGATION_CHART.HOUR_WIDTH);
  const progressWidth = (width * props.percentageCompleted) / 100;
  // Make it a bit longer so we can blend it
  const travelWidth = props.travelDuration ? calcWidth(props.travelDuration, NAVIGATION_CHART.HOUR_WIDTH) + 10 : 0;

  const xPosition = calcXPos(offset, NAVIGATION_CHART.HOUR_WIDTH, NAVIGATION_CHART.HOUR_WIDTH * NAVIGATION_CHART.HOURS_RANGE);
  // Shift it a bit to the left so we can blend it
  const travelXPosition = xPosition + width - 10;

  const yPosition = (props.level - 1) * 70;

  const height = 20;

  return (
    <>
      <g transform={`translate(${xPosition}, ${yPosition - 10})`}>
        <text className={`timeline-quay ${props.isOwnVisit ? 'fill-blue' : ''} text-bold`} x="0" y="-15">
          {props.quay}
        </text>
        <text className={`timeline-tal ${props.isOwnVisit ? 'fill-blue' : ''}`} x="0" y="0">
          TAL: {props.tal}
        </text>
      </g>
      <rect rx={2} ry={2} x={travelXPosition} y={yPosition} width={travelWidth} height={height} className={`timeline-visit-travel${props.isOwnVisit ? '-psa' : ''}`} />
      <rect rx={2} ry={2} className="white-background" x={xPosition} y={yPosition} width={width} height={height} />
      <rect rx={2} ry={2} className={`timeline-visit${props.isOwnVisit ? '-psa' : ''} ${VISIT_STATUS_CSS_MAP[props.status]}`} x={xPosition} y={yPosition} width={width} height={height} />
      <rect
        rx={2}
        ry={2}
        className={`timeline-visit-active${props.isOwnVisit ? '-psa' : ''} ${VISIT_STATUS_CSS_MAP[props.status]}`}
        x={xPosition}
        y={yPosition}
        width={progressWidth}
        height={height}
      />
      <Tooltip
        title={
          <div style={{ display: 'flex', flexDirection: 'column', fontSize: 12 }}>
            <span>
              <FormattedMessage id="nav_chart.tooltip.bts_id" defaultMessage="BTS ID: " />
              &nbsp;
              {props.btsId}
            </span>

            <br />

            <span>
              <FormattedMessage id="nav_chart.tooltip.start_time" defaultMessage="Start time: " /> {props.startTime.format('HH:mm')}
            </span>

            <span>
              <FormattedMessage id="nav_chart.tooltip.end_time" defaultMessage="End time: " /> {props.endTime.format('HH:mm')}
            </span>

            <br />

            {props.psaVisit ? (
              <span>
                <FormattedMessage id="nav_chart.tooltip.todo" defaultMessage="To do: " />
                &nbsp;
                {props.todo}
              </span>
            ) : null}

            <span>
              <FormattedMessage id="nav_chart.tooltip.total" defaultMessage="Totaal: " />
              &nbsp;
              {props.tal}
            </span>
          </div>
        }
      >
        <rect className="timeline-visit-hover" x={xPosition} y={yPosition} width={width} height={height} />
      </Tooltip>
    </>
  );
}
