// @flow
import type { TBootstrap, TAction, TBaseAction } from '../../types';

export const UserActions = {
  acquireToken: 'GENERAL_ACTIONS.ACQUIRE_TOKEN',
  appInit: 'GENERAL_ACTIONS.APP_INIT',
  logout: 'USER_ACTIONS.LOGOUT',
  login: 'USER_ACTIONS.LOGIN',
  receivedBootstrap: 'USER_ACTIONS.RECEIVED_BOOTSTRAP',
  receivedToken: 'USER_ACTIONS.RECEIVED_TOKEN',
  receivedProfile: 'USER_ACTIONS.RECEIVED_PROFILE',
  isInitialized: 'APPLICATION.INITIALIZED',
};

export const acquireTokenAction = (): TAction => ({
  type: UserActions.acquireToken,
});

export const appInitAction = (): TAction => ({
  type: UserActions.appInit,
});

export const logoutAction = (): TAction => ({
  type: UserActions.logout,
});

export const receivedBootstrapAction = (payload: TBootstrap): TBaseAction<TBootstrap> => ({
  payload,
  type: UserActions.receivedBootstrap,
});

export const receivedProfileAction = (payload: TBootstrap): TBaseAction<TBootstrap> => ({
  payload,
  type: UserActions.receivedProfile,
});

export const isInitializedAction = (payload: boolean): TBaseAction<boolean> => ({
  payload,
  type: UserActions.isInitialized,
});
