// @flow
import { GangActions } from '../../actions/gang.actions';

const initialState: ?string = null;

const reducer = (state: ?string = initialState, action: Object) => {
  switch (action.type) {
    case GangActions.receivedPreviousNumberOfGangs:
      return action.payload;
    default:
      return state;
  }
};

export const previousNumberOfGangsReducer = reducer;
