export const TAL_LOAD_STATES = {
  EMPTY: 'E',
  FULL: 'F',
};

export const TAL_MOVEMENT = {
  IN: 'IN',
  OUT: 'OUT',
};

export const TAL_SIZE = {
  FT_20: 'FT_20',
  FT_40: 'FT_40',
};

export const TAL_STATUS = {
  TODO: 'TODO',
  DONE: 'DONE',
};
