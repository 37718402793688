// @flow
import { Alert, Button, Layout } from 'antd';
import * as React from 'react';
import { FormattedMessage, defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { loginAction } from '../../store/actions/authentication.actions';

const { Content } = Layout;

type TProps = {
  error: boolean,
  intl: intlShape,
  loginAction: typeof loginAction,
};

const EXTRA_TRANSLATIONS = defineMessages({
  errorTitle: { id: 'login.error.key.error.title', defaultMessage: 'Session expired' },
  errorContent: { id: 'login.error.key.error.content', defaultMessage: 'Your session has expired, please re-login to proceed' },
});

function LoginPageComponent(props: TProps) {
  const adLogin = () => props.loginAction();

  return (
    <Layout className="main-wrapper-login">
      <Content className="content-dp text-center login-page">
        {props.error && (
          <Alert
            style={{ width: 600, margin: 'auto', marginBottom: 60 }}
            message={props.intl.formatMessage(EXTRA_TRANSLATIONS.errorTitle)}
            description={props.intl.formatMessage(EXTRA_TRANSLATIONS.errorContent)}
            type="error"
            closable
          />
        )}

        <img src="/logo-login.png" style={{ width: 600 }} alt="logo login" />
        <h2>
          <FormattedMessage id="login.by_psa" defaultMessage="By PSA" />
        </h2>

        <div className="login-button-wrapper">
          <p>
            <FormattedMessage id="login.intro_slug" defaultMessage="To authenticate with your company credentials, please click the login button." />
          </p>

          <Button type="primary" onClick={adLogin}>
            <FormattedMessage id="login.login_button" defaultMessage="Login" />
          </Button>
        </div>
      </Content>
    </Layout>
  );
}

export const LoginPage = connect<any, Object, _, _, _, _>(null, {
  loginAction,
})(injectIntl(LoginPageComponent));
