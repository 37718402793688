import React from 'react';
import { Switch } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { elearningToggleAction } from '../../../store/actions';

/**
 * Elearning toggle button to switch on the (?) badges.
 *
 * @returns {*}
 * @constructor
 */
export function ElearningToggle() {
  const elearning = useSelector((state) => state.elearning.active);
  const dispatch = useDispatch();

  const toggle = () => dispatch(elearningToggleAction());

  return <Switch checkedChildren="?" unCheckedChildren="?" checked={elearning} onChange={toggle} />;
}
