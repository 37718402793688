// @flow
import { notification } from 'antd';
import { defineMessages, IntlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: IntlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  systemSettingsFailMessage: {
    id: 'system_settings.fail.message',
    defaultMessage: 'Something went wrong',
  },
  systemSettingsFailDescription: {
    id: 'system_settings.fail.description',
    defaultMessage: 'Could not save setting',
  },
});

export function SystemSettingsFailNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.error({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.systemSettingsFailMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.systemSettingsFailDescription),
    });
  });
}
