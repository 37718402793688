// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { deleteOrEmpty } from '../renderers';

export const BargeTableProps = (deleteAction: Function) => [
  {
    dataIndex: 'name',
    key: 'name',
    title: <FormattedMessage id="organisms.barges.name" defaultMessage="Name" />,
    width: 570,
  },
  {
    dataIndex: 'eni',
    key: 'eni',
    title: <FormattedMessage id="organisms.barges.eni" defaultMessage="ENI" />,
  },
  {
    key: 'actions',
    render: deleteOrEmpty(deleteAction),
    title: <FormattedMessage id="organisms.barges.actions" defaultMessage="Actions" />,
    width: 127,
  },
];
