// // @flow

import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { Form, FormInstance, Input, InputNumber, InputRef, Tag, TimePicker } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useIntl } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import moment from 'moment';
import type { TGangLB } from '../../../types/gang.types';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { timeFormats } from '../../../utils/format.utils';

const EditableContext = createContext<FormInstance<any> | null>(null);

type TEditableRowProps = {
  index: number,
};

export function EditableRow({ index, ...props }: TEditableRowProps) {
  const [form] = useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
}

type TEditableCellProps = {
  editable: boolean,
  children: any,
  dataIndex: $Keys<typeof TGangLB>,
  record: TGangLB,
  handleSave: (record: TGangLB) => void,
  type: string,
};

export function EditableCell({ editable, children, dataIndex, record, handleSave, type, ...restProps }: TEditableCellProps) {
  const intl = useIntl();
  const [value, setValue] = useState(children && children[1]);
  const [edited, setEdited] = useState(false);
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);
  const form = useContext(EditableContext);

  useEffect(() => {
    if (inputRef && inputRef.current && editing) {
      inputRef.current.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (children) {
      setValue(children[1]);
    }
  }, [children]);

  useEffect(() => {
    setEdited(false);
  }, [record]);

  const toggleEdit = () => {
    setEditing(!editing);

    // extra check to avoid errors when a user clicks on other cells in the row that are not editable
    if (dataIndex && record) {
      form.setFieldsValue({ [dataIndex]: type === 'time' ? moment(record[dataIndex]) : record[dataIndex] });
    }
  };

  const save = async () => {
    form.validateFields().then((values) => {
      setEdited(true);
      toggleEdit();
      handleSave({ ...record, ...values });
    });
  };

  const renderTag = () => {
    if (!edited) {
      const error =
        dataIndex === 'numberOfContainersEmpty'
          ? Math.abs(record.numberOfContainersEmpty - record.tosNumberOfContainersEmpty) >= record.thresholdMismatchBtsTos
          : Math.abs(record.numberOfContainersFull - record.tosNumberOfContainersFull) >= record.thresholdMismatchBtsTos;

      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div role="button" tabIndex={0} className="talList--editable" onClick={toggleEdit}>
          {error ? (
            <Tag color="red">
              <span>{value}</span>
            </Tag>
          ) : (
            <span>{value}</span>
          )}
        </div>
      );
    }

    return <LoadingOutlined />;
  };

  let childNode = children;

  const renderInput = () => {
    switch (type) {
      case 'time':
        return <TimePicker format={timeFormats.TIMEPICKER} ref={inputRef} onPressEnter={save} onBlur={save} size="small" style={{ width: '150px' }} />;
      case 'alphanumeric':
        return <Input ref={inputRef} onPressEnter={save} onBlur={save} />;
      default:
        return <InputNumber ref={inputRef} onPressEnter={save} onBlur={save} />;
    }
  };

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: intl.formatMessage(VALIDATION_MESSAGES.null),
          },
        ]}
      >
        {renderInput()}
      </Form.Item>
    ) : (
      renderTag()
    );
  } else {
    childNode = (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div className="editable-cell-value-wrap" onClick={toggleEdit}>
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
}
