// @flow
import * as React from 'react';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { ErrorCollapseHeader } from '../../../atoms/ErrorCollapseHeader';
import type { TError } from '../../../../types/index';
import { formatTableSize } from '../../../../utils/format.utils';
import { SendModal } from './SendModal';
import { ExtErrorTable } from '../../errors/ExtErrorTable';
import { archiveExternalErrorsAction, unarchiveExternalErrorsAction } from '../../../../store/actions';
import { Elearning } from '../../../atoms/elearning';

type TProps = {
  b2bLink: string,
  visitId: string,
  disableArchive?: boolean,
  elearningContentKey: string,
  errors: TError[],
  loading: boolean,
  mailLink: string,
  timestamp: string,
  timestampArchived: string,
};

export function ExternalErrorsCollapse(props: TProps) {
  const [selectedErrors, setSelectedErrors] = React.useState([]);
  const [showModal, setShowModal] = React.useState(false);
  const dispatch = useDispatch();

  const sendErrors = (errors: TError[]) => {
    setSelectedErrors(errors);
    setShowModal(true);
  };

  const archiveErrors = React.useCallback(
    (errors: TError[]) => {
      dispatch(archiveExternalErrorsAction(errors));
    },
    [dispatch, archiveExternalErrorsAction],
  );

  const unarchiveErrors = React.useCallback(
    (errors: TError[]) => {
      dispatch(unarchiveExternalErrorsAction(errors));
    },
    [dispatch, unarchiveExternalErrorsAction],
  );

  const hideModal = () => setShowModal(false);

  return (
    <>
      <Collapse className="collapse-error" bordered={false} defaultActiveKey={['1']}>
        <Collapse.Panel
          header={
            <>
              <Elearning contentKey={props.elearningContentKey} />
              <ErrorCollapseHeader
                timeStamp={props.timestamp}
                timeStampArchived={props.timestampArchived}
                errors={props.errors}
                subTitle={props.errors ? formatTableSize(props.errors) : ''}
                title={<FormattedMessage id="organisms.visits.visit_detail.panel.external_errors.header" defaultMessage="External errors" />}
                b2bLink={props.b2bLink}
              />
            </>
          }
          className="panel"
          key="1"
        >
          <div className="collapse-content-wrapper">
            <ExtErrorTable
              errors={props.errors}
              loading={props.loading}
              sendErrors={sendErrors}
              archiveErrors={archiveErrors}
              unarchiveErrors={unarchiveErrors}
              hasContactInfo={!!props.mailLink}
              disableArchive={props.disableArchive}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
      <SendModal show={showModal} selectedErrors={selectedErrors} hideModal={hideModal} visitId={props.visitId} />
    </>
  );
}
