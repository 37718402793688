// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { createBargeAction } from '../../../store/actions';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import BargeForm from '../../organisms/barges/CreateForm';

type TProps = {
  createBargeAction: typeof createBargeAction,
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.barge.create.header.title', defaultMessage: 'Create barge' },
});

function BargeCreate(props: TProps) {
  return (
    <EditTemplate title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} menuBlock="barges" menuItem="barges-create">
      <BargeForm handleSubmit={props.createBargeAction} />
    </EditTemplate>
  );
}

export const BargeCreatePage = injectIntl(connect<any, Object, _, _, _, _>(undefined, { createBargeAction })(BargeCreate));
