// @flow
import * as React from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { selectDetail } from '../../../store/selectors/visit.selectors';
import type { TVisit } from '../../../types';
import { OPSTimeModal } from './OPSTimeModal';
import { UndoOperationsTimeConfirmationModal } from './UndoOperationsTimeConfirmationModal';

type TProps = {
  visible: boolean,
  hideModal: Function,
  showModal: Function,
  selectedVisit?: TVisit,
};

export function OPSModal({ visible, hideModal, selectedVisit, showModal }: TProps) {
  const [showUndoOpsTimeModal, setShowUndoOpsTimeModal] = useState(false);
  const detailVisit = useSelector(selectDetail);
  const visit = selectedVisit || detailVisit;

  return (
    <>
      <OPSTimeModal visible={visible} visit={visit} hideModal={hideModal} showUndoOpsConfirmModal={() => setShowUndoOpsTimeModal(true)} />
      <UndoOperationsTimeConfirmationModal visible={showUndoOpsTimeModal} visit={visit} hideModal={() => setShowUndoOpsTimeModal(false)} showOpsModal={showModal} />
    </>
  );
}
