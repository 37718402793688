// @flow
import { Tooltip } from 'antd';
import Icon, { MessageFilled } from '@ant-design/icons';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { IDLE_TIME_REASON_MESSAGES } from '../../../config/messages/idleTimeReasons';
import { ReactComponent as LplIcon } from '../../../resources/empty-containers.svg';
import { ReactComponent as ErrorIcon } from '../../../resources/error-container-reg.svg';
import { ReactComponent as IdleIcon } from '../../../resources/Idle-time.svg';
import { ReactComponent as LateIcon } from '../../../resources/Next-quay-feas.svg';
import { ReactComponent as SaldoIcon } from '../../../resources/Saldo.svg';
import { selectDetail } from '../../../store/selectors/visit.selectors';
import type { TVisit } from '../../../types';
import { formatDate } from '../../../utils/dates.utils';
import { nl2br } from '../../../utils/format.utils';
import { warningColorTimer } from '../../../utils/general.utils';
import { Refresh } from '../../atoms/Refresh';
import { renderFeasibilityCheck } from '../../atoms/renderers';

type TProps = {
  detail?: boolean,
  intl: Object,
  selectedVisit?: TVisit,
};

function WarningRendererComponent({ detail, intl, selectedVisit }: TProps) {
  const detailVisit = useSelector(selectDetail);
  const visit = selectedVisit || detailVisit;
  const [iconClass, setIconClass] = useState<string>('icon-remarks-red');

  useEffect(() => {
    if (visit) {
      setIconClass(visit.latestRemark ? warningColorTimer(visit.latestRemark.createdAt) : 'icon-remarks-red');
    }
  }, [visit]);

  return visit ? (
    <div className="special-containers">
      {detail ? renderFeasibilityCheck(visit) : null}

      {visit.showSaldo ? (
        <Tooltip placement="right" title={<FormattedMessage id="tooltips.saldo.message" defaultMessage="Saldo" />}>
          <Icon theme="outlined" component={SaldoIcon} />
        </Tooltip>
      ) : null}

      {visit.errors ? (
        <Tooltip placement="right" title={<FormattedMessage id="tooltips.errors.message" defaultMessage="Errors" />}>
          <Icon theme="outlined" component={ErrorIcon} />
        </Tooltip>
      ) : null}

      {visit.lplRequestedToDo ? (
        <Tooltip placement="right" title={<FormattedMessage id="tooltips.lpl.message" defaultMessage="LPL not fulfilled" />}>
          <Icon theme="outlined" component={LplIcon} />
        </Tooltip>
      ) : null}

      {!visit.goingToMakeNextVisit ? (
        <Tooltip placement="right" title={<FormattedMessage id="tooltips.next_visit_late.message" defaultMessage="The next visit is going to be later as expected" />}>
          <Icon theme="outlined" component={LateIcon} />
        </Tooltip>
      ) : null}

      {visit.idleTimeRegistered ? (
        <Tooltip placement="right" title={intl.formatMessage(IDLE_TIME_REASON_MESSAGES[visit.idleTimeReason])}>
          <Icon theme="outlined" component={IdleIcon} />
        </Tooltip>
      ) : null}

      {visit.latestRemark ? (
        <Refresh
          rate={60000}
          refreshAction={() => {
            if (visit.latestRemark) setIconClass(warningColorTimer(visit.latestRemark.createdAt));
          }}
        >
          <Tooltip
            placement="right"
            title={
              <p>
                <strong>
                  <FormattedMessage id="warning_renderer.message.title" defaultMessage="Message" />:
                </strong>
                <br />
                {nl2br(visit.latestRemark.message)}
                <br />
                <br />
                <strong>
                  <FormattedMessage id="warning_renderer.message.created_at" defaultMessage="Created at" />:
                </strong>
                <br />
                {visit.latestRemark && visit.latestRemark.createdAt ? formatDate(visit.latestRemark.createdAt) : null}
                <br />
                <strong>
                  <FormattedMessage id="warning_renderer.message._created_by" defaultMessage="By" />:
                </strong>
                <br />
                {visit.latestRemark ? visit.latestRemark.createdBy : ''}
              </p>
            }
          >
            <MessageFilled className={iconClass} />
          </Tooltip>
        </Refresh>
      ) : null}
    </div>
  ) : null;
}

export const WarningRenderer = injectIntl(WarningRendererComponent);
