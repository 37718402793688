// @flow
import { notification } from 'antd';
import { defineMessages, IntlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: IntlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  quayDeletedMessage: {
    id: 'quay.delete.confirmation',
    defaultMessage: 'Quay deleted',
  },
  quayDeletedDescription: {
    id: 'quay.delete.confirmation.description',
    defaultMessage: 'Quay successfully deleted',
  },
});

export function QuayDelete({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.quayDeletedMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.quayDeletedDescription),
    });
  });
}
