import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import 'core-js/stable';
import 'antd/dist/antd.css';
import './App.less';
import './theme/style.scss';
import store, { browserHistory } from './store';
import { Application } from './components/Application';

function ApplicationProvider() {
  return (
    <Provider store={store}>
      <Application browserHistory={browserHistory} />
    </Provider>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ApplicationProvider />
  </React.StrictMode>,
);
