// @flow
import moment from 'moment';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';

import type { TBaseAction, TGlobalState, TPage, TPageRequest, TSkipper } from '../../types';
import { genericErrorHandler } from '../../utils';
import { getSkipperPageAction, receivedSkipperAction, receivedSkipperPageAction, SkipperActions } from '../actions';
import { showNotificationAction } from '../actions/notificationActions';
import { browserHistory } from '../index';
import { API_PATHS, SITE_PATHS } from '../../config/api.constants';
import { appApi } from '../../config/api.config';
import { createPath } from '../../utils/routing.utils';
import { Skipper } from '../models/Skipper';
import { formatUri } from '../../utils/format.utils';

export function* create(action: TBaseAction<TSkipper>): Generator<*, *, *> {
  // $FlowFixMe
  yield call(appApi.post, API_PATHS.V1.SKIPPER, action.payload);
  browserHistory.push(SITE_PATHS.SKIPPER_LIST);
}
export function* detail(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(appApi.get, `${API_PATHS.V1.SKIPPER}/${action.payload}`);
  const { data } = response;

  data.unavailabilityWindows = data.unavailabilityWindows.map((unavailabilityWindow) => ({
    ...unavailabilityWindow,
    fromTime: moment(unavailabilityWindow.fromTime, 'HH:mmZ').local().format('HH:mm'),
    toTime: moment(unavailabilityWindow.toTime, 'HH:mmZ').local().format('HH:mm'),
  }));

  yield put(receivedSkipperAction(data));
}
export function* edit(action: TBaseAction<TSkipper>): Generator<*, *, *> {
  // $FlowFixMe
  const { keys, ...skipper } = action.payload;
  const cachedSkipper: TSkipper = yield select((state) => state.skippers.detail);
  const skipperUri = `${API_PATHS.V1.SKIPPER}/${cachedSkipper.id}`;

  skipper.contactDetails = skipper.contactDetails.filter((c) => !!c.type);
  // transform to a rest model
  const skipperJson = Skipper.toJson(skipper);

  yield call(appApi.put, skipperUri, skipperJson);

  browserHistory.push(createPath(SITE_PATHS.SKIPPER_DETAIL, { id: cachedSkipper.id }));
}
export function* page(action: TBaseAction<?TPageRequest<null>>): Generator<*, *, *> {
  const uri = action.payload ? formatUri(API_PATHS.V1.SKIPPER, action.payload) : API_PATHS.V1.SKIPPER;
  const response = yield call(appApi.get, uri);

  yield put(receivedSkipperPageAction(response.data));
}

export function* remove(action: TBaseAction<TSkipper>): Generator<*, *, *> {
  const { keys, ...skipper } = action.payload;
  if (skipper.deletable) {
    const response = yield call(appApi.delete, `${API_PATHS.V1.SKIPPER}/${action.payload.id}`, skipper);
    if (response.status === 204) {
      yield put(showNotificationAction(NOTIFICATION_TYPES.SKIPPER_DELETE));
    }

    const pageData: TPage<TSkipper> = yield select((state: TGlobalState) => state.skippers.list);
    yield put(getSkipperPageAction({ filter: {}, paging: { size: pageData.size, number: pageData.number } }));
  }
}

// SAGAS
export function* SkipperSaga(): Generator<*, *, *> {
  yield takeLatest(SkipperActions.page, genericErrorHandler(page));
  yield takeLatest(SkipperActions.detail, genericErrorHandler(detail));
  yield takeLatest(SkipperActions.create, genericErrorHandler(create));
  yield takeLatest(SkipperActions.edit, genericErrorHandler(edit));
  yield takeLatest(SkipperActions.remove, genericErrorHandler(remove));
}
