/**
 * Badge labels visible in route /visits
 * @type {string[]}
 */
export const DP_OVERVIEW = [
  'DP_OVERVIEW_ACTION_LOG_COLUMN',
  'DP_OVERVIEW_CONTEXT_MENU_COLUMN',
  'DP_OVERVIEW_ERROR_COLUMNS_HEADER',
  'DP_OVERVIEW_OPT_RTA_ETA_COLUMN',
  'DP_OVERVIEW_PROCESSED_COLUMN',
  'DP_OVERVIEW_STATUS_COLUMN',
  'DP_OVERVIEW_TAL_COLUMNS_HEADER',
];

/**
 * Badge labels visible in route /visits/detail
 * @type {string[]}
 */
export const DP_DETAIL = [
  'DP_DETAIL_CONTAINERS_BLOCK_HEADER',
  'DP_DETAIL_EXT_ERRORS_BLOCK_HEADER',
  'DP_DETAIL_INT_ERRORS_BLOCK_HEADER',
  'DP_DETAIL_LPL_BLOCK_HEADER',
  'DP_DETAIL_OPEN_B2B',
  'DP_DETAIL_OPT_RTA_ETA',
  'DP_DETAIL_REMARKS_BLOCK_HEADER',
  'DP_DETAIL_STATUS',
  'DP_DETAIL_TAL_BLOCK_HEADER',
  'DP_DETAIL_TOP_RIGHT_TOGGLES',
];

/**
 * Badge visible in route /barge-clerk
 * @type {string[]}
 */
export const LB_OVERVIEW = [
  'LB_OVERVIEW_CONTEXT_MENU_COLUMN',
  'LB_OVERVIEW_CRANE_GANGS',
  'LB_OVERVIEW_DISCHARGE_LOAD_TAL_COLUMN',
  'LB_OVERVIEW_EXPORT',
  'LB_OVERVIEW_GANGS_COLUMN',
  'LB_OVERVIEW_OWN_SHIFT',
  'LB_OVERVIEW_RTA_OPT_ATA_COLUMN',
  'LB_OVERVIEW_SPEC_CONTAINERS_COLUMN',
  'LB_OVERVIEW_STATUS_COLUMN',
  'LB_OVERVIEW_WARNINGS_COLUMN',
];

/**
 * Badge visible in route /barge-clerk/detail
 * @type {string[]}
 */
export const LB_DETAIL = [
  'LB_DETAIL_HEADER_ICONS',
  'LB_DETAIL_ATA_OPT_RTA_ETA',
  'LB_DETAIL_IDLE_TIME',
  'LB_DETAIL_STATUS',
  'LB_DETAIL_DECISION_CBPA',
  'LB_DETAIL_LAST_CHANGE_TIMESTAMP',
  'LB_DETAIL_TAL_BLOCK_HEADER',
  'LB_DETAIL_BOLLARDS',
  'LB_DETAIL_REMARKS_BLOCK_HEADER',
  'LB_DETAIL_INT_ERRORS_BLOCK_HEADER',
  'LB_DETAIL_EXT_ERRORS_BLOCK_HEADER',
  'LB_DETAIL_CONTAINERS_BLOCK_HEADER',
];
