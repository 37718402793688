// @flow
import * as React from 'react';
import type { TError } from '../../../types/index';
import { DPDetailTable } from '../../atoms/Tables/DPDetailTable';
import { ErrorRowSelection, ExtErrorTableColumns } from '../../atoms/Tables/ErrorTableProps';
import { ErrorTableHeader } from './ErrorTableHeader';

type TProps = {
  errors: TError[],
  hasContactInfo: boolean,
  loading: boolean,
  sendErrors: Function,
  archiveErrors: Function,
  unarchiveErrors: Function,
  disableArchive?: boolean,
};

type TState = {
  selectedKeys: string[],
};

export class ExtErrorTable extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      selectedKeys: [],
    };
  }

  changeSelection = (selectedKeys: string[]) => {
    this.setState({
      selectedKeys,
    });
  };

  resetAndSend = (errors: TError[]) => {
    this.setState({
      selectedKeys: [],
    });

    this.props.sendErrors(errors);
  };

  resetAndArchive = (errors: TError[]) => {
    this.setState({
      selectedKeys: [],
    });

    this.props.archiveErrors(errors.map((e) => e.id));
  };

  resetAndUnarchive = (errors: TError[]) => {
    this.setState({
      selectedKeys: [],
    });

    this.props.unarchiveErrors(errors.map((e) => e.id));
  };

  render() {
    return (
      <>
        <ErrorTableHeader
          sendErrors={this.resetAndSend}
          errors={this.props.errors}
          selectedKeys={this.state.selectedKeys}
          hasContactInfo={this.props.hasContactInfo}
          archiveErrors={this.resetAndArchive}
          unarchiveErrors={this.resetAndUnarchive}
          isExternal
          disableArchive={this.props.disableArchive}
        />
        <DPDetailTable
          columns={ExtErrorTableColumns}
          dataSource={this.props.errors}
          rowKey="id"
          loading={this.props.loading}
          rowSelection={ErrorRowSelection(this.props.errors, this.changeSelection, this.state.selectedKeys, true)}
        />
      </>
    );
  }
}
