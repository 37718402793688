// @flow
import { combineReducers } from 'redux';
import loadingReducer from '../loading.reducers';
import { diagnosticsReducer } from './diagnostics.reducers';
import { DiagnosticsActions } from '../../actions/diagnostics.actions';

const reducer = combineReducers({
  page: diagnosticsReducer,
  loadingPage: loadingReducer(DiagnosticsActions.page, DiagnosticsActions.receivedPage),
});

export default reducer;
