// @flow
import type { TShiftQuay } from '../../../types/barge_clerk.types';
import { BargeClerkActions } from '../../actions/barge_clerk.actions';

const initialState: ?(TShiftQuay[]) = [];

const reducer = (state: ?(TShiftQuay[]) = initialState, action: Object) => {
  switch (action.type) {
    case BargeClerkActions.receivedShiftQuaysOverview:
      return [...action.payload];
    default:
      return state;
  }
};

export const listReducer = reducer;
