// @flow
import * as React from 'react';
import { Button, DatePicker, Form, Modal, Row } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { SPACING } from '../../../constants/style.constants';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { actualTimeArrivalAction } from '../../../store/actions';
import { selectDetail } from '../../../store/selectors/visit.selectors';
import type { TVisit } from '../../../types';
import type { TActualTimeArrival } from '../../../types/barge_clerk.types';
import { VisitSummary } from '../../atoms/modals/VisitSummary';

type TProps = {
  hideModal: Function,
  visible: boolean,
  selectedVisit?: TVisit,
};

export function ATAModalComponent({ hideModal, visible, selectedVisit }: TProps) {
  const intl = useIntl();
  const [form] = useForm();
  const detailVisit = useSelector(selectDetail);
  const dispatch = useDispatch();
  const visit = selectedVisit || detailVisit;

  const onSubmit = () => {
    form.validateFields().then((values) => {
      // $FlowFixMe
      const actualTimeArrival: TActualTimeArrival = { actualTimeArrival: values.actualTimeArrival.format() };
      dispatch(actualTimeArrivalAction(visit, actualTimeArrival));
      hideModal();
    });
  };

  return visit ? (
    <Modal title={<FormattedMessage id="molecules.modals.ata_time.title" defaultMessage="Change ATA" />} centered open={visible} footer={null} onCancel={hideModal} maskClosable width={700}>
      <Row type="flex" justify="start" style={{ marginBottom: SPACING.COLLAPSE }}>
        <VisitSummary visit={visit} />
      </Row>
      <Row>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            name="actualTimeArrival"
            initialValue={visit.actualTimeArrival ? moment(visit.actualTimeArrival) : moment(Date.now())}
            label={
              <strong>
                <FormattedMessage id="molecules.modals.ops_time.date_time" defaultMessage="Date / time" />
              </strong>
            }
            rules={[
              {
                message: intl.formatMessage(VALIDATION_MESSAGES.null),
                required: false,
              },
            ]}
          >
            <DatePicker
              size="large"
              format="DD-MM-YYYY HH:mm"
              showTime={{ secondStep: 60 }}
              placeholder={intl.formatMessage({
                id: 'molecules.modals.ops_time.select_date',
                defaultMessage: 'Select date / time',
              })}
            />
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 48 }}>
            <Form.Item style={{ marginBottom: 0, marginRight: SPACING.COLLAPSE }}>
              <Button type="default" htmlType="submit">
                <FormattedMessage id="general.submit_change" defaultMessage="Submit change" />
              </Button>
            </Form.Item>
            <button className="visit-top-link" onClick={hideModal} style={{ padding: 0 }} type="button">
              <FormattedMessage id="general.cancel_change" defaultMessage="Cancel change" />
            </button>
          </div>
        </Form>
      </Row>
    </Modal>
  ) : null;
}

export const ATAModal = injectIntl(ATAModalComponent);
