// @flow
import type { TBaseAction, TGeoJSON } from '../../../types';
import type { TBargeClerkState } from '../../../types/barge_clerk.types';
import { BargeClerkActions } from '../../actions/barge_clerk.actions';

const initialState: ?TBargeClerkState = null;

const reducer = (state: ?TBargeClerkState = initialState, action: TBaseAction<TGeoJSON>) => {
  switch (action.type) {
    case BargeClerkActions.receivedPortGeofence:
      return action.payload;
    default:
      return state;
  }
};

export const portGeofenceReducer = reducer;
