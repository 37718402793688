// @flow
import { ConfigProvider } from 'antd';
import { HashRouter } from 'react-router-dom';
import nl from 'antd/lib/locale-provider/nl_BE';
import moment from 'moment';
import * as React from 'react';
import { IntlProvider } from 'react-intl';
import connect from 'react-redux/es/connect/connect';
import Routes from '../config/app.routes';
import type { TGlobalState } from '../types';
import { ApplicationInitializer } from './atoms/ApplicationInitializer';
import { AzureAuthentication } from './atoms/AzureAuthentication';
import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en';
import '@formatjs/intl-pluralrules/locale-data/nl';

import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/nl';

type TApplicationProps = {
  browserHistory: Object,
  language: Object,
};

function RawApplication(props: TApplicationProps) {
  moment.locale(props.language.locale);
  return (
    <IntlProvider defaultLocale="nl" locale={nl} {...props.language}>
      <ConfigProvider locale={nl}>
        <AzureAuthentication>
          <ApplicationInitializer>
            <HashRouter>
              <Routes />
            </HashRouter>
          </ApplicationInitializer>
        </AzureAuthentication>
      </ConfigProvider>
    </IntlProvider>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  language: state.language,
});

export const Application = connect<any, Object, _, _, _, _>(mapStateToProps)(RawApplication);
