// @flow
import { combineReducers } from 'redux';
import loadingReducer from '../loading.reducers';
import { GangActions } from '../../actions/gang.actions';
import { listReducer } from './list.reducer';

const reducer = combineReducers({
  loading: loadingReducer(GangActions.getGangAssignments, GangActions.receivedGangAssignments),
  list: listReducer,
});

export default reducer;
