// @flow
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import type { TBarge, TBargeEditForm, TBargeForm, TBargePageRequest, TBaseAction, TGlobalState, TPage } from '../../types';
import { genericErrorHandler } from '../../utils';
import { browserHistory } from '../index';
import { BargeActions, getBargePageAction, receivedBargeAction, receivedBargeAutocompletions, receivedBargePageAction } from '../actions';
import { appApi } from '../../config/api.config';
import { SITE_PATHS, API_PATHS, API_STATUS_CODE } from '../../config/api.constants';
import { createPath } from '../../utils/routing.utils';
import { showNotificationAction } from '../actions/notificationActions';
import { formatUri } from '../../utils/format.utils';

export function* autocomplete(action: TBaseAction<string>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.get, `${API_PATHS.V1.BARGE_AUTOCOMPLETE}/${payload}`);
  yield put(receivedBargeAutocompletions(response.data));
}

function handleUnprocessableEntity(err, callback) {
  const { response } = err;
  if (response && response.status === API_STATUS_CODE.UNPROCESSABLE_ENTITY) {
    const { validationErrors } = response.data;
    if (callback) {
      callback(validationErrors);
    }
  }
}

export function* create(action: TBaseAction<TBargeForm>): Generator<*, *, *> {
  const { payload } = action;
  try {
    yield call(appApi.post, API_PATHS.V1.BARGE, payload);
    browserHistory.push(SITE_PATHS.BARGE_LIST);
  } catch (err) {
    handleUnprocessableEntity(err, action.callback);
  }
}

export function* detail(action: TBaseAction<number>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.get, `${API_PATHS.V1.BARGE}/${payload}`);
  yield put(receivedBargeAction(response.data));
}

export function* edit(action: TBaseAction<TBargeEditForm>): Generator<*, *, *> {
  const { payload, callback } = action;
  const bargeUri: string = yield select(() => `${API_PATHS.V1.BARGE}/${payload.id}`);

  try {
    const response = yield call(appApi.put, bargeUri, payload);

    yield put(receivedBargeAction(response.data));
    browserHistory.push(createPath(SITE_PATHS.BARGE_DETAIL, { id: payload.id }));
  } catch (err) {
    handleUnprocessableEntity(err, callback);
  }
}

export function* page(action: TBaseAction<?TBargePageRequest>): Generator<*, *, *> {
  const { payload } = action;
  const uri = payload ? formatUri(API_PATHS.V1.BARGE, payload) : API_PATHS.V1.BARGE;
  const response = yield call(appApi.get, uri);

  yield put(receivedBargePageAction(response.data));
}

export function* remove(action: TBaseAction<TBarge>): Generator<*, *, *> {
  const { payload } = action;
  const response = yield call(appApi.delete, `${API_PATHS.V1.BARGE}/${payload.id}`);
  if (response.status === API_STATUS_CODE.SUCCESS_NO_CONTENT) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.BARGE_DELETE));
  }

  const pageData: TPage<TBarge> = yield select((state: TGlobalState) => state.barges.list);
  yield put(getBargePageAction({ filter: {}, paging: { size: pageData.size, number: pageData.number } }));
}

export function* BargeSaga(): Generator<*, *, *> {
  yield takeLatest(BargeActions.create, genericErrorHandler(create));
  yield takeLatest(BargeActions.detail, genericErrorHandler(detail));
  yield takeLatest(BargeActions.edit, genericErrorHandler(edit));
  yield takeLatest(BargeActions.page, genericErrorHandler(page));
  yield takeLatest(BargeActions.remove, genericErrorHandler(remove));
  yield takeLatest(BargeActions.autocomplete, genericErrorHandler(autocomplete));
}
