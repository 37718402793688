// @flow
import type { TBaseAction, TPage } from '../../types';

const defaultValue: TPage<Object> = {
  content: [],
  empty: true,
  first: true,
  last: true,
  number: 1,
  numberOfElements: 0,
  pageable: {},
  size: 0,
  sort: {},
  totalElements: 0,
  totalPages: 1,
};

export const pageListReducer =
  (receivedActionType: string) =>
  (state: TPage<Object> = defaultValue, action: TBaseAction<{ filters?: any, page: TPage<any> }>) => {
    switch (action.type) {
      case receivedActionType:
        return { ...action.payload };
      default:
        return state;
    }
  };
