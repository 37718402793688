import React from 'react';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisit, selectBargeClerkVisitContainersAfterTalCutoff, selectBargeClerkVisitOverlandedContainers } from '../../../store/selectors/barge_clerk.selectors';
import { selectContainerPage, selectLoadingContainerPage } from '../../../store/selectors/containervisits.selectors';
import { ContainerCollapse } from '../collapses/Containers';

export function BargeClerkDetailContainers() {
  const containerPage = useSelector(selectContainerPage);
  const loadingContainerPage = useSelector(selectLoadingContainerPage);
  const overlandedContainers = useSelector(selectBargeClerkVisitOverlandedContainers);
  const containersAfterTalCutOff = useSelector(selectBargeClerkVisitContainersAfterTalCutoff);
  const visit = useSelector(selectBargeClerkVisit);

  return (
    <ContainerCollapse
      containersOverlanded={overlandedContainers}
      containersAfterCutOff={containersAfterTalCutOff}
      elearningContentKey="LB_DETAIL_CONTAINERS_BLOCK_HEADER"
      page={containerPage}
      loadingPage={loadingContainerPage}
      visit={visit}
    />
  );
}
