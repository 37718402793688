// @flow
import * as React from 'react';
import { Col, Row } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

export function PageLoader() {
  return (
    <Row>
      <Col span={20} offset={2} style={{ marginTop: 80, paddingTop: 50, textAlign: 'center' }}>
        <LoadingOutlined style={{ fontSize: 58 }} />
      </Col>
    </Row>
  );
}
