// @flow
import type { TSystemSetting } from '../../../types/system_setting.types';
import { SystemSettingsActions } from '../../actions/system_settings.actions';

const reducer = (state: ?(TSystemSetting[]) = null, action: Object) => {
  switch (action.type) {
    case SystemSettingsActions.receivedPage:
      return [...action.payload];
    default:
      return state;
  }
};

export const listReducer = reducer;
