/* eslint-disable */
// @flow
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { SPACING } from '../../../constants/style.constants';
import { createGangOMAction, deleteGangOMAction } from '../../../store/actions/gang.actions';
import { uuidv4 } from '../../../utils/number.utils';
import { GangFormRow } from '../Gang/GangFormRow';
import type { TGangLB } from '../../../types/gang.types';
import { PlusOutlined } from '@ant-design/icons';

type TProps = {
  gangs: Object[],
  quayId: number,
  shiftEndTime: string,
  shiftId: number,
  shiftStartTime: string,
  createGangOMAction: typeof createGangOMAction,
  deleteGangOMAction: typeof deleteGangOMAction,
};

function GangModalComponent({ gangs, quayId, shiftEndTime, shiftId, shiftStartTime, createGangOMAction, deleteGangOMAction }: TProps) {
  const [gangsOm, setGangsOm] = useState<Object[]>(gangs.map((gang) => gang.om));

  useEffect(() => {
    setGangsOm(gangs.map((gang) => gang.om));
  }, [gangs]);

  const deleteGang = (gang: Object) => {
    setGangsOm(gangsOm.filter((gangObject) => gang.id !== gangObject.id));

    if (!gang.isNew) {
      deleteGangOMAction(gang);
    }
  };

  const saveGang = (gang: Object, callback: Function) => {
    const serverGang = gang.isNew ? { ...gang, id: null, shiftId, quayId } : { ...gang, shiftId, quayId };

    createGangOMAction(serverGang, (newGang: TGangLB) => {
      const entity = { ...newGang, isNew: false };
      const newGangsOm = gangsOm.map((foundGang) => (foundGang.id === gang.id ? entity : foundGang));

      callback();
      setGangsOm(newGangsOm);
    });
  };

  const sync = (gang: Object, allValues: Object) => {
    const updatedGang = { ...gang, ...allValues };
    const newGangsOm = gangsOm.map((foundGang) => (foundGang.id === gang.id ? updatedGang : foundGang));

    setGangsOm(newGangsOm);
  };

  const addRow = () => {
    const newObject = {
      id: uuidv4(),
      startTime: moment(shiftStartTime),
      endTime: moment(shiftEndTime).subtract(15, 'minutes'),
      workload: '0',
      isNew: true,
    };

    setGangsOm((prevGangsOm) => [...prevGangsOm, newObject]);
  };

  return (
    <Row>
      <Col>
        <Row>
          <Col>
            <b>
              <FormattedMessage id="atoms.modals.gang.gang" defaultMessage="Gangs" />
            </b>
            <Button type="default" size="small" style={{ marginLeft: SPACING.DP }} onClick={addRow}>
              <PlusOutlined />
              <FormattedMessage id="atoms.modals.gang.add_gang" defaultMessage="Add gang" />
            </Button>
          </Col>
        </Row>
        <Row style={{ marginTop: SPACING.DP }}>
          {gangsOm.map((gangOm: Object) => (
            <GangFormRow
              key={gangOm.id}
              gangOm={gangOm}
              onSave={saveGang}
              onDelete={() => deleteGang(gangOm)}
              onSync={sync}
              shiftId={shiftId}
              quayId={quayId}
              shiftStartTime={shiftStartTime}
              shiftEndTime={shiftEndTime}
            />
          ))}
        </Row>
      </Col>
    </Row>
  );
}

export const GangModal = connect<any, Object, _, _, _, _>(null, {
  createGangOMAction,
  deleteGangOMAction,
})(GangModalComponent);
