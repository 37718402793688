// @flow
import { combineReducers } from 'redux';
import { BargeClerkActions } from '../../actions/barge_clerk.actions';
import { listReducer } from './list.reducer';
import loadingReducer from '../loading.reducers';

const reducer = combineReducers({
  list: listReducer,
  loadingList: loadingReducer(BargeClerkActions.shiftQuaysOverview, BargeClerkActions.receivedShiftQuaysOverview),
});

export default reducer;
