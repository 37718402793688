// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { Button, Form } from 'antd';
import { FormattedMessage } from 'react-intl';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import type { TUnavailabilityWindow } from '../../../types';
import UnavailabilityWindow from './UnavailabilityWindow';
import { timeFormats } from '../../../utils/format.utils';

type TProps = {
  data: TUnavailabilityWindow[],
  form: Object,
};

export function UnavailabilityWindowForm(props: TProps) {
  const { data, form } = props;

  useEffect(() => {
    const formattedData = data.map((unavailabilityWindow) => {
      if (unavailabilityWindow.allDay) {
        return {
          ...unavailabilityWindow,
          fromTime: null,
          toTime: null,
        };
      }
      return {
        ...unavailabilityWindow,
        fromTime: moment(unavailabilityWindow.fromTime, timeFormats.TIMEPICKER),
        toTime: moment(unavailabilityWindow.toTime, timeFormats.TIMEPICKER),
      };
    });

    form.setFieldsValue({
      unavailabilityWindows: formattedData,
    });
  }, [data]);

  return (
    <>
      <h3>
        <FormattedMessage id="unavailability_windows.create.unavailability_windows" defaultMessage="Unavailability windows" />
      </h3>

      <Form.List name="unavailabilityWindows">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => {
              return (
                <Form.Item key={field.key} shouldUpdate>
                  {() => <UnavailabilityWindow data={data[field.name]} form={form} formField={field} removeFn={() => remove(field.name)} />}
                </Form.Item>
              );
            })}

            <Form.Item>
              <Button type="dashed" onClick={() => add()}>
                <PlusOutlined /> <FormattedMessage id="unavailability_windows.create.add_window" defaultMessage="Add unavailable time" />
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
