import { Button, Collapse } from 'antd';
import * as React from 'react';

export function ErrorFallBackComponent({ error, resetErrorBoundary }) {
  const resetError = () => {
    resetErrorBoundary();
  };

  return (
    <div className="content">
      <h1>An error occured:</h1>
      <Collapse>
        <pre style={{ color: 'red' }}>{error.message}</pre>
        <br />
        <pre>{error.stack}</pre>
      </Collapse>
      <Button type="primary" onClick={resetError}>
        Go back
      </Button>
    </div>
  );
}
