// @flow
import moment, { Moment } from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export const formatDate = (date: ?string): ?string => (date ? moment(date).format('DD/MM/YYYY - HH:mm') : null);
export const humanizeTimeStamp = (timeStamp: Moment): string => moment.duration(moment(timeStamp).diff(moment())).humanize(true);
export const humanShortTimeStamp = (timeStamp: Moment) => {
  const duration = moment.duration(moment(timeStamp).diff(moment()));

  if (duration.asDays() < -1) {
    return <FormattedMessage id="timestamps.shorter_then_one_day" defaultMessage="{x} day(s)" values={{ x: Math.abs(Math.round(duration.asDays())) }} />;
  }

  if (duration.asHours() < -1) {
    return <FormattedMessage id="timestamps.longer_then_one_hour" defaultMessage="{x} hour(s)" values={{ x: Math.abs(Math.round(duration.asHours())) }} />;
  }

  if (duration.asMinutes() < -1) {
    return <FormattedMessage id="timestamps.longer_then_one_minute" defaultMessage="{x} min." values={{ x: Math.abs(Math.round(duration.asMinutes())) }} />;
  }

  return <FormattedMessage id="timestamps.shorter_then_one_minute" defaultMessage="< 1 min." />;
};
export const formatTime = (date: string) => moment(date).format('HH:mm');
export const formatTimeOrNull = (date: ?string): ?string => (date ? formatTime(date) : null);
export const diffDates = (a: string, b: string): Moment => moment.duration(moment(a).diff(moment(b), 'minutes'), 'minutes');
export const isBefore = (a: string, b: string): boolean => moment(a).isBefore(moment(b));
export const formatTimeDate = (date: ?string) => (date ? moment(date).format('HH:mm - DD/MM/YYYY') : null);
export const formatDateTime = (timestamp: string) => moment(timestamp).format('DD/MM/YYYY - HH:mm');
export const setTimeOnDate = (timestamp: string, date: string): Moment => {
  const timestampMoment: Moment = moment(timestamp);
  const dateMoment: Moment = moment(date);

  dateMoment.hour(timestampMoment.hour());
  dateMoment.minutes(timestampMoment.minutes());

  return dateMoment;
};
