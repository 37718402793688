// @flow
import * as React from 'react';
import { Dropdown } from 'antd';
import { FormattedMessage } from 'react-intl';
import { availableVisitAction, priorityVisitAction } from '../../../store/actions';
import { VISIT_STATUS } from '../../../constants/visit.constants';
import { createPath } from '../../../utils/routing.utils';
import { API_PATHS } from '../../../config/api.constants';
import { isAuthorized } from '../../../utils/authorization.utils';
import { ROLES } from '../../../constants/roles.constants';

type TProps = {
  active: boolean,
  availableVisitAction: typeof availableVisitAction,
  priorityVisitAction: typeof priorityVisitAction,
  setMenuActive: Function,
  setMenuInactive: Function,
  showAssignModal: Function,
  showAtaTimeModal: Function,
  showCancelModal: Function,
  showIdleTimeReasonModal: Function,
  showModal: Function,
  showOpsModal: Function,
  url: Object,
  userRoles: string[],
  visit: Object,
};

export function ContextMenu(props: TProps) {
  const handleMenuClick = (key, domEvent) => {
    domEvent.stopPropagation();

    const { priority, available, id } = props.visit;

    // eslint-disable-next-line default-case
    switch (key) {
      case 'change availability':
        props.availableVisitAction({
          newAvailable: !available,
          previousAvailable: available,
          link: createPath(API_PATHS.V1.VISIT_AVAILABLE, { id, value: !available }),
        });
        break;
      case 'open contact info':
        props.showModal();
        break;
      case 'idle time reason':
        props.showIdleTimeReasonModal();
        break;
      case 'change priority':
        props.priorityVisitAction({
          newPriority: !priority,
          previousPriority: priority,
          link: createPath(API_PATHS.V1.VISIT_PRIORITY, { id, value: !priority }),
        });
        break;
      case 'cancel visit':
        props.showCancelModal();
        break;
      case 'change ops':
        props.showOpsModal();
        break;
      case 'change ata':
        props.showAtaTimeModal();
        break;
      case 'assign gang':
        props.showAssignModal();
    }

    props.setMenuInactive();
  };

  const isGangsAssignable = () => {
    const { ownShift, ownQuay } = props.url;

    const isInShift = `${props.visit.shiftId}` === ownShift;

    return isInShift && ownQuay && ownQuay.indexOf(`${props.visit.quayPlannedId}`) > -1;
  };

  const menuItems = [
    {
      label: props.visit.available ? (
        <FormattedMessage id="molecules.barge_clerks.contextmenu.mark_not_available" defaultMessage="Mark as not available" />
      ) : (
        <FormattedMessage id="molecules.barge_clerks.contextmenu.make_available" defaultMessage="Mark as available" />
      ),
      key: 'change availability',
    },
    {
      label: <FormattedMessage id="molecules.barge_clerks.contextmenu.contact_details" defaultMessage="Open contact info" />,
      key: 'open contact info',
    },
    {
      label: <FormattedMessage id="molecules.barge_clerks.contextmenu.idle_time_reason" defaultMessage="Select idle time reason" />,
      key: 'idle time reason',
    },
    {
      label: props.visit.priority ? (
        <FormattedMessage id="molecules.visits.contextmenu.make_non_priority" defaultMessage="Mark as non-priority" />
      ) : (
        <FormattedMessage id="molecules.visits.contextmenu.make_priority" defaultMessage="Mark as priority" />
      ),
      key: 'change priority',
    },
    {
      label:
        props.visit.status === VISIT_STATUS.DECISION_CBPA ? (
          <FormattedMessage id="molecules.visits.contextmenu.undo_cancel" defaultMessage="Undo cancellation" />
        ) : (
          <FormattedMessage id="molecules.visits.contextmenu.cancel" defaultMessage="Cancel visit" />
        ),
      key: 'cancel visit',
      disabled: props.visit.status === VISIT_STATUS.CANCELLED,
    },
    {
      label: <FormattedMessage id="molecules.visits.contextmenu.change_ops" defaultMessage="Change OPS" />,
      key: 'change ops',
      disabled:
        props.visit.status === VISIT_STATUS.DONE || props.visit.status === VISIT_STATUS.CANCELLED || props.visit.status === VISIT_STATUS.DECISION_CBPA || props.visit.status === VISIT_STATUS.REQUESTED,
    },
    {
      label: <FormattedMessage id="molecules.visits.contextmenu.change_ata" defaultMessage="Change ATA" />,
      key: 'change ata',
    },
    {
      label: <FormattedMessage id="molecules.visits.contextmenu.assign_gang" defaultMessage="Assign gang" />,
      key: 'assign gang',
      disabled: !isAuthorized([ROLES.ROLE_LICHTER_BEDIENDE], props.userRoles) || !isGangsAssignable(),
    },
  ];

  const onMenuItemActive = (visible: boolean) => {
    if (visible) {
      props.setMenuActive();
    } else {
      props.setMenuInactive();
    }
  };

  return (
    <Dropdown
      menu={{
        items: menuItems,
        onClick: ({ key, domEvent }) => handleMenuClick(key, domEvent),
      }}
      trigger={['click']}
      placement="bottomLeft"
      onOpenChange={onMenuItemActive}
      onClick={(ev) => ev.stopPropagation()}
    >
      <div className="context-wrapper">
        <div className={`context-menu ${props.active ? 'active' : ''}`}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </Dropdown>
  );
}
