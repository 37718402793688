// @flow
import { Collapse } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisitB2BLink } from '../../../store/selectors/barge_clerk.selectors';
import { selectInternalErrors, selectLoadingInternalErrors } from '../../../store/selectors/errors.selectors';
import { formatTableSize } from '../../../utils/format.utils';
import { Elearning } from '../elearning';
import { ErrorCollapseHeader } from '../ErrorCollapseHeader';
import { DPDetailTable } from '../Tables/DPDetailTable';
import { ErrorTableColumns } from '../Tables/ErrorTableProps';

export function BargeClerkDetailInternalErrors() {
  const internalErrors = useSelector(selectInternalErrors);
  const loadingInternalErrors = useSelector(selectLoadingInternalErrors);
  const b2bLink: string = useSelector(selectBargeClerkVisitB2BLink);

  return (
    <Collapse className="collapse-error" bordered={false}>
      <Collapse.Panel
        header={
          <>
            <Elearning contentKey="LB_DETAIL_INT_ERRORS_BLOCK_HEADER" />
            <ErrorCollapseHeader
              errors={internalErrors}
              subTitle={internalErrors ? formatTableSize(internalErrors) : ''}
              title={<FormattedMessage id="organisms.visits.visit_detail.panel.internal_errors.header" defaultMessage="Internal errors" />}
              b2bLink={b2bLink}
            />
          </>
        }
        className="panel"
      >
        <div className="collapse-content-wrapper">
          <DPDetailTable columns={ErrorTableColumns} dataSource={internalErrors} rowKey="id" loading={loadingInternalErrors} />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
