// @flow
import * as React from 'react';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';

export function WelcomeBox() {
  return (
    <Row>
      <Col>
        <h1>
          <FormattedMessage id="organisms.welcome.title" defaultMessage="Welcome" />
        </h1>
        <p>
          <FormattedMessage id="organisms.welcome.body" defaultMessage="You're logged in" />
        </p>
      </Col>
    </Row>
  );
}
