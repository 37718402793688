// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import { ErrorActions, receivedUpdateVisitExternalErrorsAction, receivedUpdateVisitInternalErrorsAction, receivedVisitExternalErrorsActions, receivedVisitInternalErrorsAction } from '../actions';
import { appApi } from '../../config/api.config';
import { genericErrorHandler } from '../../utils';
import type { TArchiveErrorPayload, TBaseAction, TExternalErrorPayload } from '../../types';
import { showNotificationAction } from '../actions/notificationActions';
import { NOTIFICATION_TYPES } from '../../constants/notification.constants';
import { createPath } from '../../utils/routing.utils';
import { API_PATHS } from '../../config/api.constants';

export function* visitInternalList(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(appApi.get, createPath(API_PATHS.V1.ERRORS_INTERNAL, { id: action.payload }));

  yield put(receivedVisitInternalErrorsAction(response.data));
}

export function* visitExternalList(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(appApi.get, createPath(API_PATHS.V1.ERRORS_EXTERNAL, { id: action.payload }));

  yield put(receivedVisitExternalErrorsActions(response.data));
}

export function* sendExternalErrors(action: TBaseAction<TExternalErrorPayload>): Generator<*, *, *> {
  const sendUri: string = createPath(API_PATHS.V1.VISIT_SEND_ERRORS, { id: action.payload.visitId });
  const response = yield call(appApi.post, sendUri, action.payload);

  if (response.status === 200) yield put(showNotificationAction(NOTIFICATION_TYPES.EXTERNAL_ERROR_SENT_SUCCESS));
  else yield put(showNotificationAction(NOTIFICATION_TYPES.EXTERNAL_ERROR_SENT_FAIL));
}

export function* archiveExternalErrors(action: TBaseAction<TArchiveErrorPayload>): Generator<*, *, *> {
  const response = yield call(appApi.put, API_PATHS.V1.ERROR_ARCHIVE, action.payload);

  if (response.status === 200) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.EXTERNAL_ERROR_ARCHIVE_SUCCESS));
    yield put(receivedUpdateVisitExternalErrorsAction(response.data));
  } else {
    yield put(showNotificationAction(NOTIFICATION_TYPES.EXTERNAL_ERROR_ARCHIVE_FAIL));
  }
}

export function* unarchiveExternalErrors(action: TBaseAction<TArchiveErrorPayload>): Generator<*, *, *> {
  const response = yield call(appApi.put, API_PATHS.V1.ERROR_UNARCHIVE, action.payload);

  if (response.status === 200) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.EXTERNAL_ERROR_UNARCHIVE_SUCCESS));
    yield put(receivedUpdateVisitExternalErrorsAction(response.data));
  } else {
    yield put(showNotificationAction(NOTIFICATION_TYPES.EXTERNAL_ERROR_UNARCHIVE_FAIL));
  }
}

export function* archiveInternalErrors(action: TBaseAction<TArchiveErrorPayload>): Generator<*, *, *> {
  const response = yield call(appApi.put, API_PATHS.V1.ERROR_ARCHIVE, action.payload);

  if (response.status === 200) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.INTERNAL_ERROR_ARCHIVE_SUCCESS));
    yield put(receivedUpdateVisitInternalErrorsAction(response.data));
  } else {
    yield put(showNotificationAction(NOTIFICATION_TYPES.INTERNAL_ERROR_ARCHIVE_FAIL));
  }
}

export function* unarchiveInternalErrors(action: TBaseAction<TArchiveErrorPayload>): Generator<*, *, *> {
  const response = yield call(appApi.put, API_PATHS.V1.ERROR_UNARCHIVE, action.payload);

  if (response.status === 200) {
    yield put(showNotificationAction(NOTIFICATION_TYPES.INTERNAL_ERROR_UNARCHIVE_SUCCESS));
    yield put(receivedUpdateVisitInternalErrorsAction(response.data));
  } else {
    yield put(showNotificationAction(NOTIFICATION_TYPES.INTERNAL_ERROR_UNARCHIVE_FAIL));
  }
}

export function* ErrorSaga(): Generator<*, *, *> {
  yield takeLatest(ErrorActions.visitInternalList, genericErrorHandler(visitInternalList));
  yield takeLatest(ErrorActions.visitExternalList, genericErrorHandler(visitExternalList));
  yield takeLatest(ErrorActions.sendExternalErrors, genericErrorHandler(sendExternalErrors));
  yield takeLatest(ErrorActions.archiveExternalErrors, genericErrorHandler(archiveExternalErrors));
  yield takeLatest(ErrorActions.unarchiveExternalErrors, genericErrorHandler(unarchiveExternalErrors));
  yield takeLatest(ErrorActions.archiveInternalErrors, genericErrorHandler(archiveInternalErrors));
  yield takeLatest(ErrorActions.unarchiveInternalErrors, genericErrorHandler(unarchiveInternalErrors));
}
