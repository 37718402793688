// @flow
import { useCallback, useLayoutEffect, useState, useRef } from 'react';

type TNodeAlignment = 'left' | 'right';

export const INITIAL_POSITION = [0, 0];

function findNode(node: ?Element, parentTagName?: string) {
  if (node && parentTagName && node.parentElement) {
    if (node.tagName.toLowerCase() === parentTagName.toLowerCase()) {
      return node;
    }
    return findNode(node.parentElement, parentTagName);
  }
  if (node && node.parentElement) {
    return node.parentElement;
  }
  return node;
}

function getPosition(align: TNodeAlignment, anchorNode: ?Element): [number, number] {
  if (window && document && anchorNode && anchorNode.parentElement) {
    const scrollTop = window.pageYOffset || (document.documentElement && document.documentElement.scrollTop);

    if (align === 'left') {
      const { top, left } = anchorNode.getBoundingClientRect();
      return [top + scrollTop, left];
    }

    if (align === 'right') {
      const { top, right } = anchorNode.getBoundingClientRect();
      return [top + scrollTop, right];
    }
  }
  return INITIAL_POSITION;
}

/**
 * Hook to determine the position of the parent node of the React element this hook
 * was used in.
 *
 * @returns [(node?: HTMLElement) => void, [number, number]]
 */
export function useNodePosition(align: TNodeAlignment, parentTagName?: string): [(node?: ?Element) => void, [number, number]] {
  // prettier-ignore
  const [position, setPosition] = useState<[number, number]>([0, 0]);

  // prettier-ignore
  const [node, setNode] = useState<?Element>(null);
  // prettier-ignore
  const [anchorNode, setAnchorNode] = useState<?Element | null>(null);
  const intervalRef = useRef<?IntervalID>(null);

  // Get the node
  const ref = useCallback((currentNode: ?Element) => {
    setNode(currentNode);
    setAnchorNode(findNode(currentNode, parentTagName));
  }, []);

  useLayoutEffect(() => {
    const update = () => {
      window.requestAnimationFrame(() => {
        if (node) {
          setPosition(getPosition(align, anchorNode));
        }
      });
    };

    // Add the event listener
    window.addEventListener('resize', update);
    window.addEventListener('scroll', update);

    intervalRef.current = setInterval(update, 250);

    // Run a first time
    update();

    // Return cleanup when component is destroyed
    return () => {
      clearInterval(intervalRef.current);
      window.removeEventListener('resize', update);
      window.removeEventListener('scroll', update);
    };
  }, [node, anchorNode]);

  return [ref, position];
}
