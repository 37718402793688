// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { SPACING } from '../../../constants/style.constants';
import { formatDateTime } from '../../../utils/dates.utils';

type TProps = {
  visit: Object,
};

export function VisitSummary(props: TProps) {
  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: SPACING.COLLAPSE }}>
        <strong>
          <FormattedMessage id="molecules.modals.ops_time.barge" defaultMessage="Barge" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.ops_time.quay" defaultMessage="Quay" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.ops_time.ata" defaultMessage="ATA" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.ops_time.rta" defaultMessage="RTA" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.ops_time.opt" defaultMessage="OPT" />
        </strong>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{props.visit.bargeName || props.visit.barge.name}</span>
        {/* eslint-disable-next-line */}
      <span>{props.visit.quayPlanned ? props.visit.quayPlanned.name : props.visit.quayPlannedName ? props.visit.quayPlannedName : '-'}</span>
        <span>{props.visit.actualTimeArrival ? formatDateTime(props.visit.actualTimeArrival) : '-'}</span>
        <span>{props.visit.responseTimeArrival ? formatDateTime(props.visit.responseTimeArrival) : '-'}</span>
        <span>{props.visit.operationsTime ? formatDateTime(props.visit.operationsTime) : '-'}</span>
      </div>
    </>
  );
}
