// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getPsaQuaysAction } from '../../../store/actions';
import { downloadExcelForShiftsAction } from '../../../store/actions/barge_clerk.actions';
import { getShiftsAction } from '../../../store/actions/shift.actions';
import type { TGlobalState, TQuay } from '../../../types';
import type { TShiftLabel } from '../../../types/barge_clerk.types';
import { ShiftQuayCombinator } from './ShiftQuayCombinator';

type TProps = {
  downloadExcelForShiftsAction: typeof downloadExcelForShiftsAction,
  getPsaQuaysAction: typeof getPsaQuaysAction,
  getShiftsAction: typeof getShiftsAction,
  loading: boolean,
  quays: TQuay[],
  shifts: TShiftLabel[],
  updateUrl: Function,
  url: Object,
};

const setupState = (url: Object) => {
  return {
    ownShift: parseInt(url.ownShift, 10) || null,
    ownQuay: url.ownQuay || [],
    otherShift: url.otherShift || [],
    otherQuay: url.otherQuay || [],
  };
};

function ShiftQuayCombinatorContainerComponent(props: TProps) {
  const [shiftQuayCombinatorState, setShiftQuayCombinatorState] = useState(setupState(props.url));

  useEffect(() => {
    props.getShiftsAction();
    props.getPsaQuaysAction();
  }, []);

  useEffect(() => {
    setShiftQuayCombinatorState(setupState(props.url));
  }, [props.url]);

  return props.loading ? null : (
    <ShiftQuayCombinator
      handleSubmit={props.updateUrl}
      shifts={props.shifts}
      quays={props.quays}
      defaultValues={shiftQuayCombinatorState}
      downloadExcelForShiftsAction={props.downloadExcelForShiftsAction}
    />
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  shifts: state.shifts.list,
  loading: state.shifts.loading && state.psaQuays.loading,
  quays: state.psaQuays.list,
});

export const ShiftQuayCombinatorContainer = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getPsaQuaysAction,
  getShiftsAction,
  downloadExcelForShiftsAction,
})(withRouter<any>(ShiftQuayCombinatorContainerComponent));
