// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckOutlined } from '@ant-design/icons';
import type { TError } from '../../../types/index';
import { errorMovementRenderer } from '../renderers';

const messageIdPrefix = 'molecules.errors.error_table.table';

export const SelectedErrorTableColumns = [
  {
    align: 'center',
    dataIndex: 'code',
    title: <FormattedMessage id="atoms.tables.selected_errors.code" defaultMessage="Error code" />,
    width: 100,
  },
  {
    align: 'center',
    dataIndex: 'system',
    title: <FormattedMessage id="atoms.tables.selected_errors.type" defaultMessage="System" />,
    width: 60,
  },
  {
    align: 'center',
    dataIndex: 'containerNumber',
    title: <FormattedMessage id="atoms.tables.selected_errors.container_number" defaultMessage="Container number" />,
    width: 120,
  },
  {
    dataIndex: 'description',
    title: <FormattedMessage id="atoms.tables.selected_errors.description" defaultMessage="Description" />,
  },
];

export const ErrorTableColumns = [
  {
    align: 'center',
    dataIndex: 'archived',
    render: (text: *, record: *) => (record.archived ? <CheckOutlined className="text-green" /> : ''),
    title: <FormattedMessage id={`${messageIdPrefix}.archived`} defaultMessage="Archived" />,
    width: 120,
  },
  {
    align: 'center',
    dataIndex: 'system',
    title: <FormattedMessage id={`${messageIdPrefix}.system`} defaultMessage="System" />,
    width: 102,
  },
  {
    align: 'center',
    dataIndex: 'origin',
    title: <FormattedMessage id={`${messageIdPrefix}.origin`} defaultMessage="Source" />,
    width: 102,
  },
  {
    align: 'center',
    dataIndex: 'reference',
    title: <FormattedMessage id={`${messageIdPrefix}.reference`} defaultMessage="Message reference" />,
    width: 170,
  },
  {
    align: 'center',
    dataIndex: 'containerNumber',
    title: <FormattedMessage id={`${messageIdPrefix}.container_number`} defaultMessage="Container" />,
    width: 175,
  },
  {
    align: 'center',
    dataIndex: 'movement',
    render: errorMovementRenderer,
    title: <FormattedMessage id={`${messageIdPrefix}.movement`} defaultMessage="Movement" />,
    width: 125,
  },
  {
    align: 'center',
    dataIndex: 'code',
    title: <FormattedMessage id={`${messageIdPrefix}.error_code`} defaultMessage="Error code" />,
    width: 120,
  },
  {
    dataIndex: 'description',
    title: <FormattedMessage id={`${messageIdPrefix}.description`} defaultMessage="Description" />,
    width: 200,
  },
];

export const ExtErrorTableColumns = [
  {
    align: 'center',
    dataIndex: 'sent',
    render: (text: *, record: *) => (record.sent ? <CheckOutlined className="text-green" /> : ''),
    title: <FormattedMessage id={`${messageIdPrefix}.sent`} defaultMessage="Sent" />,
    width: 120,
  },
  ...ErrorTableColumns,
];

export const ErrorRowSelection = (errors: TError[], onSelectionChange: Function, selectedKeys: string[], isExternal: boolean) => {
  const commonSelections = [
    {
      key: 'archived',
      onSelect: () => {
        const containerVisitMovementArchivedErrors = errors.filter(({ archived }: TError) => archived);
        const containerVisitMovementArchivedErrorKeys = containerVisitMovementArchivedErrors.map((val) => val.id);
        onSelectionChange(containerVisitMovementArchivedErrorKeys);
      },
      text: <FormattedMessage id="organisms.visits.visit_detail.archived" defaultMessage="Gearchiveerd" />,
    },
    {
      key: 'unarchived',
      onSelect: () => {
        const containerVisitMovementUnarchivedErrors = errors.filter((val: TError) => !val.archived);
        const containerVisitMovementUnarchivedErrorKeys = containerVisitMovementUnarchivedErrors.map((val) => val.id);
        onSelectionChange(containerVisitMovementUnarchivedErrorKeys);
      },
      text: <FormattedMessage id="organisms.visits.visit_detail.unarchived" defaultMessage="Niet gearchiveerd" />,
    },
    {
      key: 'b2b',
      onSelect: () => {
        const b2bErrors = errors.filter((val) => val.system === 'B2B');
        const b2bErrorKeys = b2bErrors.map((val) => val.id);
        onSelectionChange(b2bErrorKeys);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.b2b`} defaultMessage="B2B" />,
    },
    {
      key: 'tos',
      onSelect: () => {
        const tosErrors = errors.filter((val) => val.system === 'TOS');
        const tosErrorKeys = tosErrors.map((val) => val.id);
        onSelectionChange(tosErrorKeys);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.tos`} defaultMessage="TOS" />,
    },
  ];

  const internalSelections = [
    {
      key: 'all',
      onSelect: (changeableRowKeys: string[]) => {
        onSelectionChange(changeableRowKeys);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.all`} defaultMessage="All" />,
    },
    ...commonSelections,
    {
      key: 'deselect-all',
      onSelect: () => {
        onSelectionChange([]);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.deselect_all`} defaultMessage="Deselect all" />,
    },
  ];

  const externalSelections = [
    {
      key: 'all',
      onSelect: (changeableRowKeys: string[]) => {
        onSelectionChange(changeableRowKeys);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.all`} defaultMessage="All" />,
    },
    {
      key: 'sent',
      onSelect: () => {
        const sentErrors = errors.filter((value) => value.sent);
        const sentErrorKeys = sentErrors.map((val) => val.id);
        onSelectionChange(sentErrorKeys);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.sent`} defaultMessage="Sent" />,
    },
    {
      key: 'unsent',
      onSelect: () => {
        const unsentErrors = errors.filter((value) => !value.sent);
        const unsentErrorKeys = unsentErrors.map((val) => val.id);
        onSelectionChange(unsentErrorKeys);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.unsent`} defaultMessage="Unsent" />,
    },
    {
      key: 'in',
      onSelect: () => {
        const containerVisitMovementInErrors = errors.filter((val) => val.movement === 'IN');
        const containerVisitMovementInErrorKeys = containerVisitMovementInErrors.map((val) => val.id);
        onSelectionChange(containerVisitMovementInErrorKeys);
      },
      text: <FormattedMessage id="organisms.visits.visit_detail.unload" defaultMessage="Lossen" />,
    },
    {
      key: 'out',
      onSelect: () => {
        const containerVisitMovementOutErrors = errors.filter((val) => val.movement === 'OUT');
        const containerVisitMovementOutErrorKeys = containerVisitMovementOutErrors.map((val) => val.id);
        onSelectionChange(containerVisitMovementOutErrorKeys);
      },
      text: <FormattedMessage id="organisms.visits.visit_detail.load" defaultMessage="Laden" />,
    },
    ...commonSelections,
    {
      key: 'deselect-all',
      onSelect: () => {
        onSelectionChange([]);
      },
      text: <FormattedMessage id={`${messageIdPrefix}.selections.deselect_all`} defaultMessage="Deselect all" />,
    },
  ];

  const selections = isExternal ? externalSelections : internalSelections;
  return {
    columnWidth: 65,
    hideDefaultSelections: true,
    onChange: onSelectionChange,
    selectedRowKeys: selectedKeys,
    selections,
  };
};
