// @flow
import * as React from 'react';
import { TitleSubtitle } from '../titlesubtitle';

type TProps = {
  subTitle: string | React.Node,
  title: string | React.Node,
};

export function CollapseTitle(props: TProps) {
  return <TitleSubtitle subTitle={props.subTitle} title={<h3 className="collapse-header-title">{props.title}</h3>} />;
}
