/* eslint-disable no-alert */
// @flow
import { groupBy } from 'lodash';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Form, Row, Select } from 'antd';
import { defineMessages, FormattedMessage, injectIntl, useIntl } from 'react-intl';
import moment from 'moment';
import { useForm } from 'antd/es/form/Form';
import { downloadExcelForShiftsAction } from '../../../store/actions/barge_clerk.actions';
import type { TQuay } from '../../../types';
import type { TShiftLabel } from '../../../types/barge_clerk.types';
import { ExportRangeModal } from '../../molecules/modals/ExportRangeModal';
import { Elearning } from '../../atoms/elearning';

const { Option, OptGroup } = Select;

type TProps = {
  defaultValues: Object,
  downloadExcelForShiftsAction: typeof downloadExcelForShiftsAction,
  handleSubmit: Function,
  quays: TQuay[],
  shifts: TShiftLabel[],
};

const TRANSLATIONS = defineMessages({
  too_many_quays: {
    id: 'organisms.shift_quay_combinator.too_many_quays',
    defaultMessage: '2 quays max.',
  },
});

function ShiftQuayCombinatorComponent(props: TProps) {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [form] = useForm();
  const [visible, setVisible] = useState(false);

  const exportSpecificDays = () => setVisible(true);

  const onCloseExportSpecificDays = () => setVisible(false);

  const { defaultValues, shifts } = props;

  const shiftsPerDate = groupBy(shifts, 'date');
  const currentTime = moment();

  const defaultShift: ?TShiftLabel = shifts.find((shift) => {
    const start = moment(shift.startTime);
    const end = moment(shift.endTime);

    return currentTime.isBetween(start, end) && shift.label !== '4';
  });

  const defaultShiftId = defaultShift ? defaultShift.id : null;

  const defaultFirstActiveValue = defaultShiftId ? `${defaultShiftId}` : null;

  useEffect(() => {
    form.setFieldValue('ownShift', defaultValues.ownShift || defaultShiftId);
  }, [defaultValues, shifts]);

  useEffect(() => {
    if (!props.defaultValues.ownShift) {
      form.resetFields(['ownQuay', 'otherQuay', 'otherShift']);
    }
  }, [defaultValues]);

  const exportNextShifts = () => {
    const selectedId = form.getFieldValue('ownShift');
    const selectedQuays = form.getFieldValue('ownQuay');

    if (selectedQuays.length === 0) {
      alert('Er is geen kaai geselecteerd');
    }

    const value = shifts.find((shift) => shift.id === selectedId);
    const index = shifts.indexOf(value);
    const nextShifts: TShiftLabel[] = shifts.slice(index, index + 4);

    if (nextShifts.length < 4) {
      alert('Er zijn geen 4 volgende shifts beschikbaar.');
      return;
    }

    // dispatch action
    dispatch(downloadExcelForShiftsAction(nextShifts, selectedQuays));
  };

  return (
    <Row className="shift-quay-combinator overview-top">
      <Col span={18}>
        <Form
          form={form}
          layout="inline"
          onValuesChange={(changedValues: Object, allValues: Object) => {
            if (allValues.ownQuay && allValues.ownQuay.length <= 2) {
              props.handleSubmit(allValues);
            }
          }}
        >
          <Form.Item
            name="ownShift"
            label={
              <>
                <Elearning contentKey="LB_OVERVIEW_OWN_SHIFT" />
                <FormattedMessage id="shift_quay_combinator.labels.own_shift" defaultMessage="Own shift" />
              </>
            }
            className="form-inline-label"
          >
            <Select size="large" style={{ width: 220 }} virtual={false}>
              {Object.keys(shiftsPerDate).map((date) => (
                <OptGroup key={`own-shift-${date}`} label={date}>
                  {shiftsPerDate[date].map((shift) => (
                    <Option key={shift.id} value={shift.id}>
                      Shift {shift.label} - {shift.date}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="ownQuay"
            initialValue={props.defaultValues.ownQuay}
            rules={[
              {
                validator: (rule, value) => {
                  if (value) {
                    if (value.length > 2) {
                      return Promise.reject(new Error(intl.formatMessage(TRANSLATIONS.too_many_quays)));
                    }
                    return Promise.resolve();
                  }
                  return Promise.resolve();
                },
              },
            ]}
            label={<FormattedMessage id="shift_quay_combinator.labels.own_quay" defaultMessage="Own quay" />}
            className="form-inline-label"
          >
            <Select mode="tags" size="large" style={{ width: 300 }} maxTagCount={2}>
              {props.quays.map((quay) => (
                <Option key={quay.id} value={`${quay.id}`}>
                  {quay.name}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="otherShift"
            initialValue={props.defaultValues.otherShift}
            label={<FormattedMessage id="shift_quay_combinator.labels.extra_shifts" defaultMessage="Extra shifts" />}
            className="form-inline-label"
          >
            <Select mode="tags" size="large" style={{ width: 300 }} maxTagCount={1} defaultActiveFirstOption={defaultFirstActiveValue}>
              {Object.keys(shiftsPerDate).map((date) => (
                <OptGroup key={`other-shift-${date}`} label={date}>
                  {shiftsPerDate[date].map((shift) => (
                    <Option key={shift.id} value={`${shift.id}`}>
                      Shift {shift.label} - {shift.date}
                    </Option>
                  ))}
                </OptGroup>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            name="otherQuay"
            initialValue={props.defaultValues.otherQuay}
            label={<FormattedMessage id="shift_quay_combinator.labels.extra_quays" defaultMessage="Extra quays" />}
            className="form-inline-label"
          >
            <Select mode="tags" size="large" style={{ width: 300 }} maxTagCount={2}>
              {props.quays.map((quay) => (
                <Option key={`other-quay-${quay.id}`} value={`${quay.id}`}>
                  {quay.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Col>
      <Col span={6} style={{ textAlign: 'right' }}>
        <Button style={{ marginLeft: 5, marginTop: 28 }} onClick={exportNextShifts}>
          <Elearning contentKey="LB_OVERVIEW_EXPORT" />
          <FormattedMessage id="organisms.visits.visit_list.download_excel_next_4_days" defaultMessage="Export own + 3 coming shifts" />
        </Button>

        <Button style={{ marginLeft: 5, marginTop: 28 }} onClick={exportSpecificDays}>
          <FormattedMessage id="organisms.visits.visit_list.download_excel_custom_select" defaultMessage="Export days" />
        </Button>
        {visible ? <ExportRangeModal shifts={shifts} visible={visible} onClose={onCloseExportSpecificDays} /> : null}
      </Col>
    </Row>
  );
}

export const ShiftQuayCombinator = injectIntl(ShiftQuayCombinatorComponent);
