// @flow
import type { TBaseAction, TPageRequest, TPageWithFilter, TQuay, TQuayForm } from '../../types';

export const QuayActions = {
  create: 'QUAY_ACTIONS.CREATE',
  detail: 'QUAY_ACTIONS.GET',
  edit: 'QUAY_ACTIONS.EDIT',
  page: 'QUAY_ACTIONS.PAGE',
  receivedDetail: 'QUAY_ACTIONS.RECEIVED_DETAIL',
  receivedPage: 'QUAY_ACTIONS.RECEIVED_PAGE',
  remove: 'QUAY_ACTIONS.REMOVE',
  listPsaQuays: 'QUAY_ACTIONS.LIST_PSA_QUAYS',
  receivedPsaQuays: 'QUAY_ACTIONS.RECEIVED_PSA_QUAYS',
  listAllQuays: 'QUAY_ACTIONS.LIST_ALL_QUAYS',
  receivedAllQuays: 'QUAY_ACTIONS.RECEIVED_ALL_QUAYS',
};

export const createQuayAction = (payload: TQuayForm, callback?: Function): TBaseAction<TQuayForm> => ({
  payload,
  callback,
  type: QuayActions.create,
});

export const editQuayAction = (payload: TQuayForm, callback?: Function): TBaseAction<TQuayForm> => ({
  payload,
  callback,
  type: QuayActions.edit,
});

export const getQuayAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: QuayActions.detail,
});

export const getQuayPageAction = (payload: ?TPageRequest<null>): TBaseAction<?TPageRequest<null>> => ({
  payload,
  type: QuayActions.page,
});

export const receivedQuayAction = (payload: TQuay): TBaseAction<TQuay> => ({
  payload,
  type: QuayActions.receivedDetail,
});

export const receivedQuayPageAction = (page: TPageWithFilter<TQuay>): TBaseAction<{ page: TPageWithFilter<TQuay> }> => ({
  payload: {
    page,
  },
  type: QuayActions.receivedPage,
});

export const removeQuayAction = (payload: TQuay): TBaseAction<TQuay> => ({
  payload,
  type: QuayActions.remove,
});

export const getPsaQuaysAction = (): TBaseAction<boolean> => ({
  payload: true,
  type: QuayActions.listPsaQuays,
});

export const receivedPsaQuaysAction = (payload: TQuay[]): TBaseAction<TQuay[]> => ({
  payload,
  type: QuayActions.receivedPsaQuays,
});

export const getAllQuaysAction = (): TBaseAction<boolean> => ({
  payload: true,
  type: QuayActions.listAllQuays,
});

export const receivedAllQuaysAction = (payload: TQuay[]): TBaseAction<TQuay[]> => ({
  payload,
  type: QuayActions.receivedAllQuays,
});
