// @flow
import type { TVisitRemark } from '../../../types/remark.types';
import { VisitRemarksActions } from '../../actions/visit_remark.actions';

const initialState: ?(TVisitRemark[]) = null;

const reducer = (state: ?(TVisitRemark[]) = initialState, action: Object) => {
  switch (action.type) {
    case VisitRemarksActions.receivedList:
      return [...action.payload];

    default:
      return state;
  }
};

export const listReducer = reducer;
