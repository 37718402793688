// @flow
import type { TBaseAction, TPaging } from '../../types';
import type { TSystemSetting } from '../../types/system_setting.types';

export const SystemSettingsActions = {
  page: 'SYSTEM_SETTINGS_ACTIONS.PAGE',
  receivedPage: 'SYSTEM_SETTINGS_ACTIONS.RECEIVED_PAGE',
  update: 'SYSTEM_SETTINGS_ACTIONS.UPDATE',
};

export const getSystemSettingsPageAction = (payload: ?TPaging): TBaseAction<?TPaging> => ({
  payload,
  type: SystemSettingsActions.page,
});

export const receivedSystemSettingsPageAction = (payload: TSystemSetting[]): TBaseAction<?(TSystemSetting[])> => ({
  payload,
  type: SystemSettingsActions.receivedPage,
});

export const updateSystemSettingAction = (payload: TSystemSetting): TBaseAction<TSystemSetting> => ({
  payload,
  type: SystemSettingsActions.update,
});
