// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { deleteSkipperAction, fetchSkipperAction } from '../../../store/actions';
import type { TGlobalState, TSkipper } from '../../../types';
import { DetailTemplate } from '../../templates/MasterData/DetailTemplate';
import { SITE_PATHS } from '../../../config/api.constants';
import { ContactDetailTable } from '../../atoms/Tables/MDDetailTables/ContactDetailTable';
import { UnavailabilityWindowDetailTable } from '../../atoms/Tables/MDDetailTables/UnavailabilityWindowDetailTable';
import { getArray, getNumber, getString } from '../../../utils/general.utils';
import { BargeSkipperDetailTable } from '../../atoms/Tables/MDDetailTables/BargeSkipperDetailTable';

type TProps = {
  deleteSkipperAction: typeof deleteSkipperAction,
  fetchSkipperAction: typeof fetchSkipperAction,
  loading: boolean,
  match: any,
  skipper: TSkipper,
};

class SkipperDetail extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchSkipperAction(id);
  }

  render() {
    return (
      <DetailTemplate
        backRoute={SITE_PATHS.SKIPPER_LIST}
        deleteAction={this.props.deleteSkipperAction}
        editRoute={SITE_PATHS.SKIPPER_UPDATE}
        entity={this.props.skipper}
        headerTitle={`${getString(() => this.props.skipper.firstName)} ${getString(() => this.props.skipper.lastName)}`}
        id={getNumber(() => this.props.skipper.id)}
        loading={this.props.loading}
        menuBlock="skippers"
        menuItem="skippers-overview"
      >
        <BargeSkipperDetailTable bargeSkipperIdLabels={getArray(() => this.props.skipper.barges)} />
        <ContactDetailTable contactDetails={getArray(() => this.props.skipper.contactDetails)} />
        <UnavailabilityWindowDetailTable unavailabilityWindows={getArray(() => this.props.skipper.unavailabilityWindows)} />
      </DetailTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loading: state.skippers.loadingDetail,
  skipper: state.skippers.detail,
});

export const SkipperDetailPage = connect<any, Object, _, _, _, _>(mapStateToProps, { fetchSkipperAction, deleteSkipperAction })(SkipperDetail);
