// @flow
import type { TVisitRemark } from '../../../types/remark.types';
import { LPLRemarkActions } from '../../actions/lpl_remark.actions';

const reducer = (state: ?(TVisitRemark[]) = [], action: Object) => {
  switch (action.type) {
    case LPLRemarkActions.receivedList:
      return [...action.payload];
    default:
      return state;
  }
};

export const listReducer = reducer;
