// @flow
import L from 'leaflet';

export const bargeIcon = () =>
  L.icon({
    iconUrl: '/barge_marker.svg',
    iconSize: [24, 27],
    popupAnchor: [0, 0],
    shadowUrl: null,
    shadowSize: null,
    shadowAnchor: null,
    className: 'mark-icon',
  });
