// @flow

import type { TBaseAction } from '../../../types';
import { UserActions } from '../../actions';

const reducer = (state: boolean = false, action: TBaseAction<boolean>) => {
  switch (action.type) {
    case UserActions.isInitialized:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
