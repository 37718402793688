// @flow
import { isEqual } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { getSaldoOverviewAction } from '../../../store/actions/barge_clerk.actions';
import type { TBargeClerkSaldo } from '../../../types/barge_clerk.types';
import { Loading } from '../../atoms/Loading';
import { Counter } from '../../molecules/counter/Counter';

type TProps = {
  getSaldoOverviewAction: typeof getSaldoOverviewAction,
  saldo: TBargeClerkSaldo,
  url: Object,
};

export class OverviewSaldoContainer extends React.Component<TProps> {
  componentDidMount() {
    // check url if own shift & own quays are selected. If so get them.
    const { ownShift, ownQuay } = this.props.url;

    if (ownShift && ownQuay && ownQuay.length > 0) {
      this.props.getSaldoOverviewAction(ownShift, ownQuay);
    }
  }

  componentDidUpdate(prevProps: TProps) {
    // check url if own shift & own quays are selected or changed. If so get them.
    if (!isEqual(prevProps.url, this.props.url)) {
      const { ownShift, ownQuay } = this.props.url;

      if (ownShift && ownQuay && ownQuay.length > 0) {
        this.props.getSaldoOverviewAction(ownShift, ownQuay);
      }
    }
  }

  render() {
    const { saldo } = this.props;

    if (saldo === null) {
      return <Loading />;
    }

    if (saldo && saldo.total === 0) {
      return (
        <p className="no-saldo-message">
          <FormattedMessage id="organisms.barge_clerks.infobar.no_saldo" defaultMessage="No saldo from previous shifts" />
        </p>
      );
    }

    return <Counter done={saldo.done} toDo={saldo.todo} total={saldo.total} hasPreviousTal={false} previousTalChange="" previousTal={0} />;
  }
}
