// @flow
import antEn from 'antd/lib/locale-provider/en_US';
import antNl from 'antd/lib/locale-provider/nl_BE';
import 'moment/locale/nl';
import en from '../../languages/en.json';
import nl from '../../languages/nl.json';
import { GeneralActions } from '../actions/general.actions';

const LANGUAGES = {
  en,
  nl,
};

const ANT_MESSAGES = {
  en: antEn,
  nl: antNl,
};

const initialState: Object = {
  locale: 'nl',
  messages: LANGUAGES.nl,
  ant_messages: ANT_MESSAGES.nl,
};

const reducerFactory = (state: Object = initialState, action: Object) => {
  switch (action.type) {
    case GeneralActions.setLanguage:
      return {
        ...state,
        locale: action.payload,
        messages: LANGUAGES[action.payload],
        ant_messages: ANT_MESSAGES[action.payload],
      };
    default:
      return state;
  }
};

export default reducerFactory;
