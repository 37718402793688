// @flow
export const CONTAINER_DIRECTIONS = {
  IN: 'in',
  OUT: 'out',
};

export const CONTAINER_STATES = {
  EMPTY: 'empty',
  FULL: 'full',
};
