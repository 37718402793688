// @flow
import moment from 'moment';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import {
  BargeClerkActions,
  BargeClerkFilterActions,
  receivedBargeClerkPageAction,
  receivedPortGeofenceAction,
  receivedSaldoOverviewAction,
  receivedShiftQuaysOverviewAction,
  receivedTotalsOverviewAction,
  receivedVisitFilterAction,
} from '../actions/barge_clerk.actions';
import { genericErrorHandler } from '../../utils';
import type { TBaseAction, TPageRequest, TVisitFilter } from '../../types';
import { formatUri, pageToPageRequest } from '../../utils/format.utils';
import { getVisitContainerPageAction, getVisitExternalErrorsAction, getVisitInternalErrorsAction } from '../actions';
import { getVisitRemarksAction } from '../actions/visit_remark.actions';
import { detail } from './visit.sagas';
import { API_PATHS } from '../../config/api.constants';
import { deleteTasks, takeLatestWithManualPrio } from './take_latest_with_prio.sagas';

function* doPageCall(action: TBaseAction<?TPageRequest<TVisitFilter>>): Generator<*, *, *> {
  const bcUri = API_PATHS.V1.VISIT_LB_OVERVIEW_VIEW;
  let uri = action.payload ? formatUri(bcUri, action.payload) : bcUri;

  if (action.payload && action.payload.sorting && !action.payload.sorting.by) {
    uri += '&sort=etaRtaOps,asc';
  }

  return yield call(appApi.post, uri, action.payload && action.payload.filter) || {};
}

export function* page(action: TBaseAction<?TPageRequest<TVisitFilter>>): Generator<*, *, *> {
  const response = yield call(doPageCall, action);
  yield put(receivedBargeClerkPageAction(response.data, action.payload?.prio, action.type));
}

export function* downloadExcelForShifts(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shifts, quays } = action.payload;

  const shiftIds = shifts.map((shift) => shift.id);

  const response = yield call(appApi.get, `${API_PATHS.V1.VISIT_BARGE_CLERKS_DOWNLOAD_EXCEL_NEXT_SHIFTS}${encodeURI(`?quays[]=${quays.join('&quays[]=')}&shifts[]=${shiftIds.join('&shifts[]=')}`)}`, {
    responseType: 'arraybuffer',
  });

  const blob = new Blob([response.data], { type: '' });
  const downloadImaginativeLink = document.createElement('a');

  downloadImaginativeLink.href = window.URL.createObjectURL(blob);
  downloadImaginativeLink.download = `lichterbediende-overview-${moment()}.xls`;
  downloadImaginativeLink.click();
  downloadImaginativeLink.remove();
}

export function* getBtsIds(): Generator<*, *, *> {
  const filters = yield select((state) => state.bargeClerks.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BTS_ID_BARGE_CLERK, filters);

  yield put(receivedVisitFilterAction({ btsId: response.data }));
}

export function* getBarges(): Generator<*, *, *> {
  const filters = yield select((state) => state.bargeClerks.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BARGES_BARGE_CLERK, filters);

  yield put(receivedVisitFilterAction({ barge: response.data }));
}

export function* getStatuses(): Generator<*, *, *> {
  const filters = yield select((state) => state.bargeClerks.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_STATUS_BARGE_CLERK, filters);

  yield put(receivedVisitFilterAction({ status: response.data }));
}

export function* getSpecialContainers(): Generator<*, *, *> {
  const filters = yield select((state) => state.bargeClerks.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_SPECIAL_CONTAINERS_BARGE_CLERK, filters);

  yield put(receivedVisitFilterAction({ specialContainer: response.data }));
}

export function* details(action: TBaseAction<number>): Generator<*, *, *> {
  const page = yield select((state) => state.containerVisits.visitPage);
  yield detail(action);
  yield all([
    put(getVisitExternalErrorsAction(action.payload)),
    put(getVisitInternalErrorsAction(action.payload)),
    put(getVisitContainerPageAction({ pageable: page ? pageToPageRequest(page) : null, visitId: action.payload })),
    put(getVisitRemarksAction(action.payload)),
  ]);
}

export function* overviewSaldo(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shift, quays } = action.payload;

  const response = yield call(appApi.get, `${API_PATHS.V1.SHIFT}/${shift}/saldo-view${encodeURI(`?quays[]=${quays.join('&quays[]=')}`)}`);
  yield put(receivedSaldoOverviewAction(response.data));
}

export function* overviewTotals(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shift, quays } = action.payload;

  const response = yield call(appApi.get, `${API_PATHS.V1.SHIFT}/${shift}/totals-view${encodeURI(`?quays[]=${quays.join('&quays[]=')}`)}`);
  yield put(receivedTotalsOverviewAction(response.data));
}

export function* overviewTalPerShift(action: TBaseAction<Object>): Generator<*, *, *> {
  const { shifts, quays } = action.payload;
  let shiftsParam = shifts;
  let quaysParam = quays;
  if (!Array.isArray(shifts)) shiftsParam = [shifts];
  if (!Array.isArray(quays)) quaysParam = [quays];

  const response = yield call(appApi.get, `${API_PATHS.V1.SHIFT_PER_TAL_VIEW}${encodeURI(`?quays[]=${quaysParam.join('&quays[]=')}&shifts[]=${shiftsParam.join('&shifts[]=')}`)}`);
  yield put(receivedShiftQuaysOverviewAction(response.data));
}

export function* getPortGeofence(): Generator<*, *, *> {
  const response = yield call(appApi.get, API_PATHS.V1.SYSTEM_SETTINGS_PORT_GEOFENCE);
  yield put(receivedPortGeofenceAction(response.data));
}

export function* BargeClerkSaga(): Generator<*, *, *> {
  yield takeLatestWithManualPrio(BargeClerkActions.page, genericErrorHandler(page));
  yield takeLatest(BargeClerkActions.receivedPage, genericErrorHandler(deleteTasks));
  yield takeLatest(BargeClerkActions.details, genericErrorHandler(details));
  yield takeLatest(BargeClerkFilterActions.getStatuses, genericErrorHandler(getStatuses));
  yield takeLatest(BargeClerkFilterActions.getBtsIds, genericErrorHandler(getBtsIds));
  yield takeLatest(BargeClerkFilterActions.getBarges, genericErrorHandler(getBarges));
  yield takeLatest(BargeClerkFilterActions.getSpecialContainers, genericErrorHandler(getSpecialContainers));
  yield takeLatest(BargeClerkActions.saldoOverview, genericErrorHandler(overviewSaldo));
  yield takeLatest(BargeClerkActions.totalsOverview, genericErrorHandler(overviewTotals));
  yield takeLatest(BargeClerkActions.shiftQuaysOverview, genericErrorHandler(overviewTalPerShift));
  yield takeLatest(BargeClerkActions.downloadExcelForNextShifts, genericErrorHandler(downloadExcelForShifts));
  yield takeLatest(BargeClerkActions.getPortGeofence, genericErrorHandler(getPortGeofence));
}
