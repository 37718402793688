// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { cancelLPLRequestAction } from '../../../store/actions/lpl.actions';
import type { TLPLRequest } from '../../../types/lpl.types';
import { LPL_STATUS } from '../../../config/lpl.constants';

type TProps = {
  cancelLPLRequestAction: typeof cancelLPLRequestAction,
  request: TLPLRequest,
  setMenuInactive: Function,
};

function DPMenuComponent(props: TProps) {
  return (
    <Menu
      onClick={(e) => {
        e.domEvent.stopPropagation();

        switch (e.key) {
          case 'cancel':
            props.cancelLPLRequestAction(props.request);
            break;
          default:
            break;
        }

        props.setMenuInactive();
      }}
    >
      {props.request.status === LPL_STATUS.NEW ? (
        <Menu.Item key="cancel">
          <FormattedMessage id="molecules.lpl.context_menu.cancel" defaultMessage="Cancel" />
        </Menu.Item>
      ) : null}
    </Menu>
  );
}

export const DPMenu = connect<any, Object, _, _, _, _>(null, {
  cancelLPLRequestAction,
})(DPMenuComponent);
