// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { Button, Form, FormInstance } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import type { TContactDetail } from '../../../types';
import ContactDetailRow from './ContactDetail';

type TProps = {
  data: ?TContactDetail,
  form: FormInstance,
};

export function ContactDetailForm(props: TProps) {
  const { data, form } = props;

  useEffect(() => {
    form.setFieldsValue({
      contactDetails: data,
    });
  }, [data]);

  return (
    <>
      <h3>
        <FormattedMessage id="general.forms.contact.title" defaultMessage="Add contact details" />
      </h3>
      <Form.List name="contactDetails">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field) => {
              return (
                <Form.Item key={field.key} shouldUpdate>
                  {() => <ContactDetailRow form={form} formField={field} removeFn={() => remove(field.name)} />}
                </Form.Item>
              );
            })}

            <Form.Item>
              <Button type="dashed" onClick={() => add()}>
                <PlusOutlined /> <FormattedMessage id="general.form.contact_details.add_contact_details" defaultMessage="Add contact details" />
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </>
  );
}
