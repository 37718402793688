import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { SPACING } from '../../../constants/style.constants';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { GenericSection } from '../../atoms/barge_clerks/GenericSection';
import { renderQuayPlanned, renderQuayRequested } from '../../atoms/renderers';

export function QuaysInfo() {
  const visit = useSelector(selectBargeClerkVisit);

  return visit ? (
    <GenericSection
      content={
        <>
          <span style={{ marginRight: SPACING.DP }}>{renderQuayPlanned(visit)}</span>
          <span>{renderQuayRequested(visit)}</span>
        </>
      }
      title={<FormattedMessage id="detail_info.quay" defaultMessage="Quay" />}
    />
  ) : null;
}
