// @flow
import * as React from 'react';
import { Button, Form, Input, Select } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { FormStyles } from '../../../theme/form';
import type { TErrorCodeForm } from '../../../types/error_code.types';
import { SITE_PATHS } from '../../../config/api.constants';

const { Option } = Select;

type TProps = {
  errorCode?: TErrorCodeForm,
  form: any,
  handleSubmit: Function,
  intl: Object,
};

const selectData = (property: string, data?: TErrorCodeForm) => (data ? data[property] : null);

function ErrorCodeForm(props: TProps) {
  const intl = useIntl();
  const [form] = useForm();

  const handleServerErrors = (errors) => {
    const antFormat = [];

    if (errors.indexOf('code') > -1) {
      antFormat.push({ name: 'code', errors: [intl.formatMessage(VALIDATION_MESSAGES.not_unique)], value: form.getFieldValue('code') });
    }

    form.setFields(antFormat);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { ...errorCode } = values;
      if (props.errorCode) errorCode.id = props.errorCode.id;
      props.handleSubmit(errorCode, handleServerErrors);
    });
  };

  const formCols = FormStyles.vertical;

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          {...formCols}
          name="code"
          initialValue={selectData('code', props.errorCode)}
          label={<FormattedMessage id="molecules.laundry_codes.label.code" defaultMessage="Code" />}
          rules={[
            {
              message: props.intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
            {
              max: 255,
              message: props.intl.formatMessage(VALIDATION_MESSAGES.max_characters),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formCols}
          name="description"
          initialValue={selectData('description', props.errorCode)}
          label={<FormattedMessage id="molecules.laundry_codes.label.description" defaultMessage="Description" />}
          rules={[
            {
              message: props.intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
            {
              max: 255,
              message: props.intl.formatMessage(VALIDATION_MESSAGES.max_characters),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          {...formCols}
          name="type"
          label="Type"
          initialValue={props.errorCode && props.errorCode.type}
          rules={[
            {
              message: props.intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
          ]}
        >
          <Select initialValue="INTERNAL">
            <Option value="INTERNAL" key="INTERNAL">
              <FormattedMessage id="error_codes.create.options.internal" defaultMessage="INTERNAL" />
            </Option>
            <Option value="EXTERNAL" key="EXTERNAL">
              <FormattedMessage id="error_codes.create.options.external" defaultMessage="EXTERNAL" />
            </Option>
          </Select>
        </Form.Item>
        <Form.Item {...formCols}>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </Button>
        </Form.Item>
      </Form>
      <Link to={SITE_PATHS.ERROR_CODE_LIST}>
        <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
      </Link>
    </>
  );
}

ErrorCodeForm.defaultProps = {
  errorCode: undefined,
};

export default injectIntl(ErrorCodeForm);
