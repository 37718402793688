// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Button, Modal, Row, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { createLPLRequestAction } from '../../../store/actions/lpl.actions';
import { VisitSummaryExtra } from './VisitSummaryExtra';
import type { TVisit } from '../../../types';
import { SPACING } from '../../../constants/style.constants';
import { lplTypeRenderer } from '../renderers';

type TProps = {
  clearAndHide: Function,
  createLPLRequestAction: typeof createLPLRequestAction,
  hideModal: Function,
  lplGroupsAndRequests: Object[],
  visible: boolean,
  visit: TVisit,
};

function LPLModalComponent(props: TProps) {
  const createRequests = () => {
    const requests = props.lplGroupsAndRequests.map((lplGroupAndRequest) => lplGroupAndRequest.request);
    props.createLPLRequestAction(requests);

    props.clearAndHide();
  };

  const mapRequests = () =>
    props.lplGroupsAndRequests.map((lplGroupAndRequest) => ({
      ...lplGroupAndRequest.group,
      amount: lplGroupAndRequest.request.amount,
    }));

  const columns = [
    {
      dataIndex: 'amount',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.amount" defaultMessage="Amount" />,
      width: 30,
    },
    {
      dataIndex: 'customer',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.client" defaultMessage="Client" />,
      width: 25,
    },
    {
      dataIndex: 'type',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.type" defaultMessage="Type" />,
      render: lplTypeRenderer,
      width: 30,
    },
    {
      dataIndex: 'iso',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.iso" defaultMessage="ISO" />,
      width: 25,
    },
    {
      dataIndex: 'loadState',
      render: (text: *, record: *) =>
        record.loadState === 'F' ? (
          <FormattedMessage id="molecules.empties_yard.empties_yard_table.full" defaultMessage="Full" />
        ) : (
          <FormattedMessage id="molecules.empties_yard.empties_yard_table.empty" defaultMessage="Empty" />
        ),
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.load_state" defaultMessage="Empty/Full" />,
      width: 30,
    },
    {
      dataIndex: 'orderId',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.booking_number" defaultMessage="Booking number" />,
      width: 40,
    },
    {
      dataIndex: 'pod',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.destination" defaultMessage="Destination" />,
      width: 30,
    },
    {
      dataIndex: 'qs',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.qs" defaultMessage="QS" />,
      width: 50,
    },
    {
      dataIndex: 'csc',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.csc" defaultMessage="CSC" />,
      width: 50,
    },
    {
      dataIndex: 'remark',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.remark" defaultMessage="Remark" />,
      width: 40,
    },
  ];

  return (
    <Modal centered open={props.visible} footer={null} onOk={props.hideModal} onCancel={props.hideModal} maskClosable width={1200}>
      <Row style={{ marginBottom: SPACING.COLLAPSE }}>
        <VisitSummaryExtra visit={props.visit} />
      </Row>
      <Table columns={columns} dataSource={mapRequests()} size="small" pagination={false} />
      <Button type="default" onClick={createRequests} style={{ marginTop: SPACING.CONTENT }}>
        <FormattedMessage id="general.submit_change" defaultMessage="Submit change" />
      </Button>
    </Modal>
  );
}

export const LPLModal = connect<any, Object, _, _, _, _>(null, {
  createLPLRequestAction,
})(LPLModalComponent);
