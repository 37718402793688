// @flow
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { REMARK_TYPES } from '../../../constants/contact.constants';
import { ROLES } from '../../../constants/roles.constants';
import { createVisitRemarkAction } from '../../../store/actions/visit_remark.actions';
import { selectBargeClerkVisitId, selectBargeClerkVisitRemarks } from '../../../store/selectors/barge_clerk.selectors';
import { selectLoadingVisitRemarks, selectVisitRemarks } from '../../../store/selectors/visit_remarks.selectors';
import type { TVisitRemark } from '../../../types/remark.types';
import { RemarkCollapse } from '../collapses/Remarks';

export function Remarks() {
  const dispatch = useDispatch();
  const loadingVisitRemarks = useSelector(selectLoadingVisitRemarks);
  const visitRemarks = useSelector(selectVisitRemarks);
  const btsRemarks = useSelector(selectBargeClerkVisitRemarks);
  const visitId = useSelector(selectBargeClerkVisitId);
  const onCreateRemark = React.useCallback((payload: TVisitRemark) => dispatch(createVisitRemarkAction(payload)), [createVisitRemarkAction]);

  return (
    <RemarkCollapse
      allowedRoles={[ROLES.ROLE_LICHTER_BEDIENDE]}
      btsRemark={btsRemarks}
      createRemarkAction={onCreateRemark}
      loading={loadingVisitRemarks}
      remarks={visitRemarks}
      remarkType={REMARK_TYPES.VISIT}
      contentKey="LB_DETAIL_REMARKS_BLOCK_HEADER"
      visitId={visitId}
    />
  );
}
