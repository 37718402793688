// @flow
import type { TBaseAction, TPage, TPageRequest, TSkipper } from '../../types';

export const SkipperActions = {
  create: 'SKIPPER_ACTIONS.CREATE',
  detail: 'SKIPPER_ACTIONS.GET',
  edit: 'SKIPPER_ACTIONS.EDIT',
  page: 'SKIPPER_ACTIONS.PAGE',
  receivedDetail: 'SKIPPER_ACTIONS.RECEIVED_DETAIL',
  receivedPage: 'SKIPPER_ACTIONS.RECEIVED_PAGE',
  remove: 'SKIPPER_ACTIONS.REMOVE',
};

export const getSkipperPageAction = (payload: ?TPageRequest<null>): TBaseAction<?TPageRequest<null>> => ({
  payload,
  type: SkipperActions.page,
});

export const fetchSkipperAction = (id: number): TBaseAction<number> => ({
  payload: id,
  type: SkipperActions.detail,
});

export const receivedSkipperPageAction = (payload: TPage<TSkipper>): TBaseAction<TPage<TSkipper>> => ({
  payload,
  type: SkipperActions.receivedPage,
});
export const receivedSkipperAction = (skipper: TSkipper): TBaseAction<TSkipper> => ({
  payload: skipper,
  type: SkipperActions.receivedDetail,
});

export const createSkipperAction = (skipper: TSkipper): TBaseAction<TSkipper> => ({
  payload: skipper,
  type: SkipperActions.create,
});
export const editSkipperAction = (skipper: TSkipper): TBaseAction<TSkipper> => ({
  payload: skipper,
  type: SkipperActions.edit,
});
export const deleteSkipperAction = (skipper: TSkipper): TBaseAction<TSkipper> => ({
  payload: skipper,
  type: SkipperActions.remove,
});
