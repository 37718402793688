// @flow
import * as React from 'react';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ClockCircleOutlined } from '@ant-design/icons';
import { selectBargeClerkUpdatedAt, selectBargeClerkVisitBargeOperator, selectBargeClerkVisitBtsId, selectBargeClerkVisitStatus } from '../../../store/selectors/barge_clerk.selectors';
import { BargeClerkDetailCancelModal } from '../../atoms/barge_clerks/BargeClerkDetailCancelModal';
import { BargeInfo } from '../../atoms/barge_clerks/BargeInfo';
import { GenericSection } from '../../atoms/barge_clerks/GenericSection';
import { ATAModal } from '../modals/ATAModal';
import { OPSModal } from '../modals/OPSModal';
import { TitleSubtitle } from '../titlesubtitle';
import { humanizeTimeStamp } from '../../../utils/dates.utils';
import { renderStatus } from '../../atoms/renderers';
import { LinksSection } from '../../atoms/barge_clerks/LinksSection';
import { WarningRenderer } from '../WarningRenderer/WarningRenderer';
import { Elearning } from '../../atoms/elearning';
import { BargeClerkDetailTimes } from './BargeClerkDetailTimes';
import { BargeClerkSwitches } from './BargeClerkSwitches';
import { QuaysInfo } from './QuaysInfo';

type TProps = {
  showContactInfoModal: Function,
};

export function DetailInfoSection({ showContactInfoModal }: TProps) {
  const [showAtaModal, setShowAtaModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [showOpsModal, setShowOpsModal] = React.useState(false);
  const updatedAt = useSelector(selectBargeClerkUpdatedAt);
  const bargeOperator = useSelector(selectBargeClerkVisitBargeOperator);
  const btsId = useSelector(selectBargeClerkVisitBtsId);
  const status = useSelector(selectBargeClerkVisitStatus);

  const setOpsModalVisibility = React.useCallback((value: boolean) => () => {
    setShowOpsModal(value);
  });

  const setCancelModalVisibility = React.useCallback((value: boolean) => () => {
    setShowCancelModal(value);
  });

  const setAtaModalVisibility = React.useCallback((value: boolean) => () => {
    setShowAtaModal(value);
  });

  return (
    <div className="detail-section">
      <Row type="flex" align="bottom" className="top-row">
        <Col span={4} className="detail-section-section top left">
          <BargeInfo />
        </Col>
        <Col span={3} className="detail-section-section top links">
          <LinksSection showContactInfoModal={showContactInfoModal} />
        </Col>
        <Col span={5}>
          <div className="visit-inline-warnings">
            <Elearning contentKey="LB_DETAIL_HEADER_ICONS" />
            <WarningRenderer detail />
          </div>
        </Col>
        <Col span={12} className="detail-section-section top right-side right" style={{ paddingBottom: 11 }}>
          <BargeClerkSwitches onShowCancelModal={() => setShowCancelModal(true)} />
        </Col>
      </Row>
      <Row className="detail-section-row">
        <Col className="detail-section-section left bottom" span={3}>
          <GenericSection
            content={
              <TitleSubtitle
                subTitle={bargeOperator ? bargeOperator.abbreviation : ''}
                title={bargeOperator ? bargeOperator.name : <FormattedMessage id="barge_operator_not_specified" defaultMessage="Not specified" />}
              />
            }
            title="Barge Op."
          />
        </Col>
        <Col className="detail-section-section bottom" span={2}>
          <GenericSection content={btsId} title="BTS ID" />
        </Col>
        <Col className="detail-section-section bottom" span={11} style={{ paddingTop: 11 }}>
          <BargeClerkDetailTimes onShowAtaModal={() => setShowAtaModal(true)} onShowOpsModal={() => setShowOpsModal(true)} />
        </Col>
        <Col span={3} className="detail-section-section bottom">
          <QuaysInfo />
        </Col>
        <Col span={3} className="detail-section-section bottom">
          <GenericSection
            content={renderStatus(status)}
            title={
              <>
                <Elearning contentKey="LB_DETAIL_STATUS" />
                <FormattedMessage id="detail_info.status" defaultMessage="Status" />
              </>
            }
          />
        </Col>
        <Col span={2} className="detail-section-section bottom right">
          <GenericSection
            content={
              <div>
                <ClockCircleOutlined style={{ marginRight: 5 }} />
                {humanizeTimeStamp(updatedAt)}
              </div>
            }
            title={
              <>
                <Elearning contentKey="LB_DETAIL_LAST_CHANGE_TIMESTAMP" />
                <FormattedMessage id="detail_info.last_change" defaultMessage="Last change" />
              </>
            }
          />
        </Col>
      </Row>
      <BargeClerkDetailCancelModal visible={showCancelModal} hideModal={setCancelModalVisibility(false)} />
      <OPSModal visible={showOpsModal} showModal={setOpsModalVisibility(true)} hideModal={setOpsModalVisibility(false)} />
      <ATAModal visible={showAtaModal} hideModal={setAtaModalVisibility(false)} />
    </div>
  );
}
