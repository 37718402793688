// @flow
import type { TBaseAction, TPageWithFilter } from '../../types';

export const pageReducer =
  (receivedActionType: string) =>
  (state: TPageWithFilter<any>[] = [], action: TBaseAction<{ page: TPageWithFilter<any, any> }>) => {
    switch (action.type) {
      case receivedActionType:
        return action.payload.page;
      default:
        return state;
    }
  };
