// @flow
import { notification } from 'antd';
import { defineMessages, intlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  externalErrorsArchiveSuccessMessage: {
    id: 'external_errors_archive.confirmation',
    defaultMessage: 'Errors archived',
  },
  externalErrorsArchiveSuccessDescription: {
    id: 'external_errors_archive.description',
    defaultMessage: 'Errors successfully archived',
  },
  externalErrorsArchiveFailMessage: {
    id: 'external_errors_archive.fail',
    defaultMessage: 'Archiving of errors failed',
  },
  externalErrorsArchiveFailDescription: {
    id: 'external_errors_archive.fail.description',
    defaultMessage: 'Errors could not be archived',
  },
  externalErrorsUnarchiveSuccessMessage: {
    id: 'external_errors_unarchive.confirmation',
    defaultMessage: 'Errors unarchived',
  },
  externalErrorsUnarchiveSuccessDescription: {
    id: 'external_errors_unarchive.description',
    defaultMessage: 'Errors successfully unarchived',
  },
  externalErrorsUnarchiveFailMessage: {
    id: 'external_errors_unarchive.fail',
    defaultMessage: 'Unarchiving of errors failed',
  },
  externalErrorsUnarchiveFailDescription: {
    id: 'external_errors_unarchive.fail.description',
    defaultMessage: 'Errors could not be unarchived',
  },
});

export function ErrorsArchiveSuccessNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsArchiveSuccessMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsArchiveSuccessDescription),
    });
  });
}

export function ErrorsArchiveFailNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.error({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsArchiveFailMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsArchiveFailDescription),
    });
  });
}

export function ErrorsUnarchiveSuccessNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsUnarchiveSuccessMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsUnarchiveSuccessDescription),
    });
  });
}

export function ErrorsUnarchiveFailNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.error({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsUnarchiveFailMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsUnarchiveFailDescription),
    });
  });
}
