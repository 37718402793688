// @flow
export const CONTACT_TYPES = {
  SKIPPER: 'SKIPPER',
  BARGE_OPERATOR: 'BARGE_OPERATOR',
};

export const REMARK_TYPES = {
  VISIT: 'VISIT',
  LPL: 'LPL',
};
