// @flow
import { Button, Table, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { TShiftQuay } from '../../../types/barge_clerk.types';
import { formatDateTime, humanizeTimeStamp, humanShortTimeStamp } from '../../../utils/dates.utils';
import { GangModalButton } from '../../atoms/Gang/GangModalButton';
import { Refresh } from '../../atoms/Refresh';
import { TalModal } from '../../atoms/TalModal';

type TProps = {
  shiftQuays: TShiftQuay[],
};

type TState = {
  moreShown: boolean,
};

const columns = [
  {
    align: 'left',
    key: 'shiftId',
    render: (shiftQuay: TShiftQuay) => (
      <span>
        Shift {shiftQuay.shiftName} | {moment(shiftQuay.shiftStartTime).format('YYYY-MM-DD')}
      </span>
    ),
  },
  {
    align: 'center',
    key: 'quayName',
    render: (shiftQuay: TShiftQuay) => <span>{shiftQuay.quayName}</span>,
  },
  {
    align: 'center',
    key: 'tal',
    render: (shiftQuay: TShiftQuay) => (
      <div>
        {shiftQuay.hasPreviousTal ? (
          <Tooltip
            title={
              <div>
                <FormattedMessage id="organisms.barge_clerk.visit_list.previous_tal" defaultMessage="Previous TAL: {tal}" values={{ tal: `${shiftQuay.previousTal}` }} />
                <br />
                <br />
                {formatDateTime(moment(shiftQuay.previousTalChange))}
              </div>
            }
          >
            <strong>{shiftQuay.totalWithSaldo}</strong>
          </Tooltip>
        ) : (
          <strong>{shiftQuay.totalWithSaldo}</strong>
        )}
      </div>
    ),
  },
  {
    align: 'center',
    key: 'timestampTal',
    render: (shiftQuay: TShiftQuay) => (
      <div>
        {shiftQuay.hasPreviousTal ? (
          <Tooltip
            title={
              <div>
                <FormattedMessage id="organisms.barge_clerk.visit_list.previous_tal" defaultMessage="Previous TAL: {tal}" values={{ tal: `${shiftQuay.previousTal}` }} />
                <br />
                <br />
                {formatDateTime(moment(shiftQuay.previousTalChange))}
              </div>
            }
          >
            <span className="human-text-history">{humanShortTimeStamp(moment(shiftQuay.previousTalChange))}</span>
          </Tooltip>
        ) : null}
      </div>
    ),
  },
  {
    align: 'center',
    key: 'info',
    render: (shiftQuay: TShiftQuay) => (
      <>
        <TalModal shiftQuay={shiftQuay} />
        <GangModalButton shiftQuay={shiftQuay} />
      </>
    ),
  },
  {
    align: 'right',
    key: 'timestampGangs',
    render: (shiftQuay: TShiftQuay) => (
      <div>
        {shiftQuay.lastGangUpdate ? (
          <Tooltip
            title={
              <div>
                <FormattedMessage id="organisms.barge_clerk.visit_list.previous_gangs" defaultMessage="Previous number of gangs: {gangs}" values={{ gangs: `${shiftQuay.previousNumberOfGangs}` }} />
                <br />
                <br />
                {humanizeTimeStamp(moment(shiftQuay.lastGangUpdate))}
              </div>
            }
          >
            <span className="human-text-history">{humanShortTimeStamp(moment(shiftQuay.lastGangUpdate))}</span>
          </Tooltip>
        ) : null}
      </div>
    ),
  },
];

export class ShiftTalList extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { moreShown: false };
  }

  hasMore() {
    return this.props.shiftQuays.length > 3;
  }

  getData(): TShiftQuay[] {
    const { shiftQuays } = this.props;
    return this.hasMore() && !this.state.moreShown ? shiftQuays.slice(0, 3) : this.props.shiftQuays;
  }

  renderMoreButtons() {
    if (this.hasMore()) {
      return (
        // eslint-disable-next-line react/no-access-state-in-setstate
        <Button style={{ marginTop: 5 }} onClick={() => this.setState({ moreShown: !this.state.moreShown })}>
          {this.state.moreShown ? (
            <FormattedMessage id="shift_tal_list.labels.show_less" defaultMessage="Show less" />
          ) : (
            <FormattedMessage id="shift_tal_list.labels.show_all" defaultMessage="Show all" />
          )}
        </Button>
      );
    }

    return null;
  }

  render() {
    return (
      <Refresh
        rate={30000}
        refreshAction={() => {
          // eslint-disable-next-line react/no-access-state-in-setstate
          this.setState(this.state); // trigger a rerender
        }}
      >
        <Table className="shift-tal-list-table" columns={columns} rowKey="id" dataSource={this.getData()} showHeader={false} size="small" pagination={false} />
        {this.renderMoreButtons()}
      </Refresh>
    );
  }
}
