// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { CancelModal } from '../modals/CancelModal';

type TProps = {
  hideModal: Function,
  visible: boolean,
};

export function BargeClerkDetailCancelModal(props: TProps) {
  return <CancelModal {...props} visit={useSelector(selectBargeClerkVisit)} />;
}
