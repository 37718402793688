// @flow
import moment from 'moment';

export const getProp = (defaultVal: any) => (val: Function, altVal: any) => {
  try {
    return val() || altVal;
  } catch (e) {
    return altVal || defaultVal;
  }
};

export const getString = getProp(null);
export const getNumber = getProp(undefined);
export const getArray = getProp([]);
export const getObject = getProp({});
export const getPropOrNull = getProp(null);

/**
 * Plugin conversion method for QS to convert values back to the correct types instead of only strings.
 */
export const queryStringDecoder = (str: string, decoder: any, charset: string) => {
  const strWithoutPlus = str.replace(/\+/g, ' ');

  if (charset === 'iso-8859-1') {
    // unescape never throws, no try...catch needed:
    return strWithoutPlus.replace(/%[0-9a-f]{2}/gi, unescape);
  }

  if (/^(\d+|\d*\.\d+)$/.test(str)) {
    return parseFloat(str);
  }

  const keywords = {
    true: true,
    false: false,
    null: null,
    undefined,
  };

  if (str in keywords) {
    return keywords[str];
  }

  // utf-8
  try {
    return decodeURIComponent(strWithoutPlus);
  } catch (e) {
    return strWithoutPlus;
  }
};

export const warningColorTimer = (dateTime: string): string => {
  const duration = moment.duration(moment().diff(moment(dateTime)));
  const hours = duration.asHours();

  if (hours < 1) {
    return 'icon-remarks-red';
  }

  if (hours < 2) {
    return 'icon-remarks-orange';
  }

  return 'icon-remarks-blue';
};

export const getHash = (object) => {
  const objectString = JSON.stringify(object);
  let hash = 0;
  let i;
  let chr;
  if (!objectString || objectString.length === 0) return hash;
  for (i = 0; i < objectString.length; i += 1) {
    chr = objectString.charCodeAt(i);
    // eslint-disable-next-line no-bitwise
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line no-bitwise
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};
