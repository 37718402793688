// @flow
import type { TBaseAction, TVisit } from '../../types';
import type { TSailingSchedule } from '../../types/sailing_schedule.types';

export const SailingScheduleActions = {
  get: 'SAILING_SCHEDULE_ACTIONS.GET',
  received: 'SAILING_SCHEDULE_ACTIONS.RECEIVED',
};

export const getSailingScheduleAction = (payload: TVisit): TBaseAction<TVisit> => ({
  payload,
  type: SailingScheduleActions.get,
});

export const receivedSailingScheduleAction = (payload: TSailingSchedule): TBaseAction<TSailingSchedule> => ({
  payload,
  type: SailingScheduleActions.received,
});
