// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import type { TVisit } from '../../../types';
import { priorityVisitAction } from '../../../store/actions';
import { Elearning } from '../../atoms/elearning';
import { createPath } from '../../../utils/routing.utils';
import { API_PATHS } from '../../../config/api.constants';
import { DebouncedSwitch } from '../../atoms/barge_clerks/DebouncedSwitch';
import { selectVisitPriorityLoading } from '../../../store/selectors/visit.selectors';

type TProps = {
  priorityVisitAction: typeof priorityVisitAction,
  visit: TVisit,
};

export function PriorityToggle(props: TProps) {
  const loadingPriority = useSelector(selectVisitPriorityLoading);

  const toggleAction = () => {
    props.priorityVisitAction({
      previousPriority: props.visit.priority,
      newPriority: !props.visit.priority,
      link: createPath(API_PATHS.V1.VISIT_PRIORITY, {
        id: props.visit.id,
        value: !props.visit.priority,
      }),
    });
  };

  return (
    <div className="approved-check-with-text">
      <Elearning contentKey="DP_DETAIL_TOP_RIGHT_TOGGLES" />

      <DebouncedSwitch
        loading={loadingPriority}
        checked={props.visit.priority}
        onToggle={toggleAction}
        text={
          <span className="label">
            <strong className={props.visit.priority ? 'text-green' : ''}>
              <FormattedMessage id="priorityToggle.processed" defaultMessage="Priority" />
            </strong>
          </span>
        }
      />
    </div>
  );
}
