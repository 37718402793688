// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { NavigationChart } from '../../organisms/navigation_chart/NavigationChart';
import { SPACING } from '../../../constants/style.constants';

export function NavigationChartPanel() {
  const visit = useSelector(selectBargeClerkVisit);
  return visit ? (
    <div className="custom-panel" style={{ paddingBottom: 0 }}>
      <div className="detail-row">
        <div>
          <div style={{ display: 'flex', alignItems: 'baseline' }}>
            <h3 style={{ marginRight: SPACING.COLLAPSE / 2, display: 'inline-block' }}>
              <FormattedMessage id="atoms.barge_clerks.navigation_chart_panel.title" defaultMessage="Navigation Chart" />
            </h3>
            <div className="label">
              48
              <FormattedMessage id="atoms.barge_clerks.navigation_chart_panel.hour" defaultMessage="h" />
              &nbsp;
              <FormattedMessage id="atoms.barge_clerks.navigation_chart_panel.timeframe" defaultMessage="timeframe" />
            </div>
          </div>
        </div>
      </div>
      <div className="detail-row">
        <NavigationChart visit={visit} />
      </div>
    </div>
  ) : null;
}
