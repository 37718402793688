// @flow
import { combineReducers } from 'redux';
import { QuayActions } from '../../actions';
import { listReducer } from './list.reducer';
import loadingReducer from '../loading.reducers';

const reducer = combineReducers({
  list: listReducer,
  loadingList: loadingReducer(QuayActions.listPsaQuays, QuayActions.receivedPsaQuays),
});

export default reducer;
