// @flow
import { combineReducers } from 'redux';
import { SystemSettingsActions } from '../../actions/system_settings.actions';
import loadingReducer from '../loading.reducers';
import { listReducer } from './list.reducers';

const reducer = combineReducers({
  page: listReducer,
  loadingPage: loadingReducer(SystemSettingsActions.page, SystemSettingsActions.receivedPage),
});

export default reducer;
