// @flow
import { Card, Col, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { TContactDetail } from '../../../types/index';

type TContactInfoProps = {
  available: boolean,
  contactInfo: TContactDetail[],
  name: React.Node,
};

const typeMessageFactory = (type: string) => {
  switch (type) {
    case 'FAX':
      return <FormattedMessage id="contactInfo.type.fax" defaultMessage="Fax" />;
    case 'MOBILE':
      return <FormattedMessage id="contactInfo.type.mobile" defaultMessage="Mobile" />;
    case 'PHONE':
      return <FormattedMessage id="contactInfo.type.phone" defaultMessage="Phone" />;
    case 'EMAIL':
      return <FormattedMessage id="contactInfo.type.email" defaultMessage="Email" />;
    default:
      return <FormattedMessage id="contactInfo.type.skype" defaultMessage="Skype" />;
  }
};

export function ContactInfo(props: TContactInfoProps) {
  return (
    <Card className="contactInfo-card">
      <Row>
        <Col className="contactInfo-card-name" span={12}>
          {props.name}
        </Col>
        <Col className="pull-right uppercase" span={12}>
          {props.available ? (
            <span className="contactInfo-card-available">
              <FormattedMessage id="contactInfo.available" defaultMessage="available" />
            </span>
          ) : (
            <span className="contactInfo-card-unavailable">
              <FormattedMessage id="contactInfo.unavailable" defaultMessage="unavailable" />
            </span>
          )}
        </Col>
      </Row>
      <Row>
        <Col>
          {props.contactInfo.map((info) => (
            <div key={info.id}>
              <span>{typeMessageFactory(info.type)}:</span>
              <br />
              {info.value}
              <br />
              <span className={`contact-detail-remarks ${info.remark ? '' : 'not-active-remarks'}`}>{info.remark || 'no remarks'}</span>
            </div>
          ))}
        </Col>
      </Row>
    </Card>
  );
}
