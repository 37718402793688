// @flow
import { combineReducers } from 'redux';
import { listReducer } from './list.reducer';
import loadingReducer from '../loading.reducers';
import { VisitRemarksActions } from '../../actions/visit_remark.actions';

const reducer = combineReducers({
  list: listReducer,
  loadingList: loadingReducer(VisitRemarksActions.list, VisitRemarksActions.receivedList),
});

export default reducer;
