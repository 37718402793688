// @flow
import * as React from 'react';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { nl2br } from '../../../utils/format.utils';

type TProps = {
  message: string,
  timestamp: string,
  username: string,
};

export function Remark(props: TProps) {
  return (
    <div className="remark">
      <div className="message">{nl2br(props.message)}</div>
      <div className="meta">
        <FormattedMessage id="molecules.remark.meta" defaultMessage="{timestamp} by {username}" values={{ timestamp: moment(props.timestamp).format('L LT'), username: props.username }} />
      </div>
    </div>
  );
}
