// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { containerImdgRenderer, renderContainerNumber, stringArrayRenderer } from '../renderers';
import type { TContainerVisit } from '../../../types';

const messageIdPrefix = 'organisms.containers.container_list';

export const ContainerTableColumns = [
  {
    align: 'center',
    dataIndex: 'containerNumber',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.container_number`} defaultMessage="Container" />,
    render: renderContainerNumber,
    width: 200,
  },
  {
    align: 'center',
    dataIndex: 'length',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.length`} defaultMessage="Length (ft)" />,
    render: (text: any, record: TContainerVisit) => record.lengthFtIn,
    width: 100,
  },
  {
    align: 'center',
    dataIndex: 'containerType',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.container_type`} defaultMessage="Type" />,
    width: 86,
  },
  {
    align: 'center',
    dataIndex: 'height',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.height`} defaultMessage="Height (ft)" />,
    render: (text: any, record: TContainerVisit) => record.heightFtIn,
    width: 100,
  },
  {
    align: 'center',
    dataIndex: 'movement',
    render: (text: any, record: TContainerVisit) =>
      record.movement === 'OUT' ? (
        <FormattedMessage id="atoms.tables.container_table_props.out" defaultMessage="Out" />
      ) : (
        <FormattedMessage id="atoms.tables.container_table_props.in" defaultMessage="In" />
      ),
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.movement`} defaultMessage="Movement" />,
    width: 130,
  },
  {
    align: 'center',
    dataIndex: 'loadState',
    render: (text: *, record: *) =>
      record.loadState === 'F' ? (
        <FormattedMessage id="atoms.tables.container_table_props.full" defaultMessage="F" />
      ) : (
        <FormattedMessage id="atoms.tables.container_table_props.empty" defaultMessage="E" />
      ),
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.load_state`} defaultMessage="Empty/Full" />,
    width: 94,
  },
  {
    align: 'center',
    dataIndex: 'line',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.line`} defaultMessage="Line" />,
    width: 77,
  },
  {
    align: 'center',
    dataIndex: 'orderId',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.order_number`} defaultMessage="Order number" />,
    width: 134,
  },
  {
    align: 'center',
    dataIndex: 'brutoWeight',
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.bruto_weight`} defaultMessage="Bruto Weight" />,
    width: 105,
  },
  {
    align: 'center',
    dataIndex: 'operationalReefer',
    render: (text: string, record: TContainerVisit) =>
      record.operationalReefer ? <FormattedMessage id="atoms.tables.container_table.yes" defaultMessage="Y" /> : <FormattedMessage id="atoms.tables.container_tables.no" defaultMessage="N" />,
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.operational_reefer`} defaultMessage="Operational reefer" />,
    width: 134,
  },
  {
    align: 'center',
    dataIndex: 'landedStatus',
    render: (text: string, record: TContainerVisit) =>
      record.overlanded ? <FormattedMessage id="atoms.tables.container_table.yes" defaultMessage="Y" /> : <FormattedMessage id="atoms.tables.container_tables.no" defaultMessage="N" />,
    sorter: true,
    title: <FormattedMessage id={`${messageIdPrefix}.overlanded`} defaultMessage="Overlanded" />,
    width: 130,
  },
  {
    align: 'center',
    dataIndex: 'oversize',
    render: (text: string, record: TContainerVisit) =>
      record.oversize ? <FormattedMessage id="atoms.tables.container_table.yes" defaultMessage="Y" /> : <FormattedMessage id="atoms.tables.container_tables.no" defaultMessage="N" />,
    sorterL: true,
    title: <FormattedMessage id={`${messageIdPrefix}.oversize`} defaultMessage="Oversize" />,
    width: 114,
  },
  {
    align: 'center',
    dataIndex: 'imdg1',
    render: containerImdgRenderer,
    title: <FormattedMessage id={`${messageIdPrefix}.imdg`} defaultMessage="IMDG" />,
    width: 76,
  },
  {
    align: 'center',
    dataIndex: 'un1',
    render: (text: string, record: TContainerVisit) => stringArrayRenderer([record.un1, record.un2, record.un3]),
    title: <FormattedMessage id={`${messageIdPrefix}.un`} defaultMessage="UN" />,
    width: 76,
  },
  {
    align: 'center',
    dataIndex: 'specialInstructions',
    render: (text: string, record: TContainerVisit) => stringArrayRenderer(record.specialInstructions),
    title: <FormattedMessage id={`${messageIdPrefix}.specialInstructions`} defaultMessage="Special instructions" />,
  },
];
