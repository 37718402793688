// @flow
import { Collapse } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { EmptiesYardCollapseHeader } from '../../atoms/EmptiesYardCollapseHeader';
import { RequestForm } from '../../organisms/empties_yard/RequestForm';
import type { TVisitRemark } from '../../../types/remark.types';
import { RemarkSection } from '../../organisms/visit_detail/RemarkSection';
import { SPACING } from '../../../constants/style.constants';
import { REMARK_TYPES } from '../../../constants/contact.constants';
import { ROLES } from '../../../constants/roles.constants';
import type { TVisit } from '../../../types';
import { Elearning } from '../../atoms/elearning';

type TProps = {
  createRemarksAction: Function,
  defaultOpen: boolean,
  emptiesYardRemarks: TVisitRemark[],
  loadingRemarks: boolean,
  timestamp: ?string,
  visit: TVisit,
};

export function EmptiesYardCollapse(props: TProps) {
  const headerFactory = () => (
    <EmptiesYardCollapseHeader
      timeStamp={props.timestamp}
      title={<FormattedMessage id="organisms.visits.visit_detail.panel.empties_yard.header" defaultMessage="Empties yard" />}
      numUnreadRemarks={props.emptiesYardRemarks.length}
      visit={props.visit}
    />
  );

  const activeKey = '1';
  const activeKeys = props.defaultOpen ? [activeKey] : [];

  return (
    <div id="empties-yard-section" className="section-full-width">
      <Elearning contentKey="DP_DETAIL_LPL_BLOCK_HEADER" />
      <Collapse className="collapse-error" bordered={false} defaultActiveKey={activeKeys} destroyInactivePanel>
        <Collapse.Panel header={headerFactory()} className="panel" key={activeKey}>
          <div className="collapse-content-wrapper">
            <RequestForm visit={props.visit} />
            <RemarkSection
              btsRemark={props.visit.remarksBts}
              allowedRoles={[ROLES.ROLE_DATA_PROCESSOR]}
              createRemarkAction={props.createRemarksAction}
              loading={props.loadingRemarks}
              remarks={props.emptiesYardRemarks}
              style={{ marginTop: SPACING.COLLAPSE }}
              type={REMARK_TYPES.LPL}
              visitId={props.visit.id}
            />
          </div>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
}
