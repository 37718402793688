// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../types';
import { appInitAction } from '../../store/actions';
import { PageLoader } from './PageLoader';

type TProps = {
  appInitAction: typeof appInitAction,
  children: React.Node,
  initialized: boolean,
};

type TState = {
  error: boolean,
};

class ApplicationInitializerComponent extends React.Component<TProps, TState> {
  componentDidMount() {
    this.props.appInitAction();
  }

  render() {
    return this.props.initialized ? <div>{this.props.children}</div> : <PageLoader />;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  initialized: state.user.initialized,
});

export const ApplicationInitializer = connect<any, Object, _, _, _, _>(mapStateToProps, { appInitAction })(ApplicationInitializerComponent);
