// @flow
import type { TVisitFilter } from '../../../types';
import { LPLActions, LPLFilterActions } from '../../actions/lpl.actions';

const initialState: ?TVisitFilter = null;

const reducer = (state: ?TVisitFilter = initialState, action: Object) => {
  switch (action.type) {
    case LPLActions.receivedPage:
      return action.payload && action.payload.page ? action.payload.page.filter : state;
    case LPLFilterActions.receivedLPLFilter:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const filtersReducer = reducer;
