// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { SITE_PATHS } from '../../config/api.constants';
import { ROLES } from '../../constants/roles.constants';
import { browserHistory } from '../../store';
import type { TGlobalState } from '../../types';

type TProps = {
  roles: string[],
};

class AppComponent extends React.Component<TProps> {
  componentDidMount() {
    const roles = this.props.roles || [];

    if (roles.includes(ROLES.ROLE_DATA_PROCESSOR)) {
      browserHistory.push(SITE_PATHS.VISIT_LIST);
      return;
    }

    if (roles.includes(ROLES.ROLE_MASTER_DATA_MANAGEMENT)) {
      browserHistory.push(SITE_PATHS.MASTER_DATA_HOME);
      return;
    }

    if (roles.includes(ROLES.ROLE_EMPTIES_YARD)) {
      browserHistory.push(SITE_PATHS.LPL_LIST);
      return;
    }

    if (roles.includes(ROLES.ROLE_LICHTER_BEDIENDE)) {
      browserHistory.push(SITE_PATHS.BARGE_CLERK_LIST);
      return;
    }

    if (roles.includes(ROLES.ROLE_OPERATIONS_MANAGER)) {
      browserHistory.push(SITE_PATHS.BARGE_CLERK_LIST);
      return;
    }

    browserHistory.push(SITE_PATHS.UNAUTHORIZED);
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  roles: state.user.bootstrap.profile.roles,
});

export const Page = connect<any, Object, _, _, _, _>(mapStateToProps, {})(AppComponent);
