// @flow
import { notification } from 'antd';
import { defineMessages, intlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  errorCodeDeletedMessage: {
    id: 'errorCode.delete.confirmation',
    defaultMessage: 'Error code deleted',
  },
  errorCodeDeletedDescription: {
    id: 'errorCode.delete.confirmation.description',
    defaultMessage: 'Error code successfully deleted',
  },
});

export function ErrorCodeDelete({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.errorCodeDeletedMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.errorCodeDeletedDescription),
    });
  });
}
