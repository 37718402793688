// @flow
import { call, put, takeEvery } from 'redux-saga/effects';
import type { TBaseAction } from '../../types';
import { appApi } from '../../config/api.config';
import { GangRemarkActions, receivedGangRemarksAction } from '../actions/gang_remark.actions';
import { genericErrorHandler } from '../../utils';
import type { TGangLB, TGangOM, TGangRemark } from '../../types/gang.types';
import { API_PATHS } from '../../config/api.constants';

export function* get(action: TBaseAction<TGangOM | TGangLB>): Generator<*, *, *> {
  const { id } = action.payload;
  const url = `${API_PATHS.V1.GANG_REMARK_GANG}/${id}`;
  const response = yield call(appApi.get, url);

  yield put(receivedGangRemarksAction(response.data));
}

export function* post(action: TBaseAction<{ callback?: Function, gang: TGangOM, remark: TGangRemark }>): Generator<*, *, *> {
  const {
    gang: { id },
  } = action.payload;
  const url = `${API_PATHS.V1.GANG_REMARK_GANG}/${id}`;
  yield call(appApi.post, url, action.payload.remark);

  if (action.payload.callback) {
    action.payload.callback();
  }
}

export function* GangRemarkSaga(): Generator<*, *, *> {
  yield takeEvery(GangRemarkActions.get, genericErrorHandler(get));
  yield takeEvery(GangRemarkActions.post, genericErrorHandler(post));
}
