// @flow
import * as React from 'react';
import { Col, Row } from 'antd';

type TProps = {
  title: string,
};

export function EditHeader(props: TProps) {
  return (
    <Row className="master-header">
      <Col>
        <h1>{props.title}</h1>
      </Col>
    </Row>
  );
}
