// @flow
import { ErrorCodeActions } from '../../actions/error_code.actions';
import type { TErrorCode } from '../../../types';

const initialState: ?TErrorCode = null;

const reducer = (state: ?TErrorCode = initialState, action: Object) => {
  switch (action.type) {
    case ErrorCodeActions.receivedDetail:
      return action.payload;

    default:
      return state;
  }
};

export const detailReducer = reducer;
