// @flow
import * as React from 'react';
import { Select } from 'antd';
import { FormattedMessage } from 'react-intl';
import type { TVisit } from '../../../types';
import { VisitSummaryExtra } from './VisitSummaryExtra';
import type { TGangLB } from '../../../types/gang.types';
import { addGangVisitAction, removeGangVisitAction } from '../../../store/actions';

type TComponentProps = {
  addGangVisitAction: typeof addGangVisitAction,
  gangs: TGangLB[],
  removeGangVisitAction: typeof removeGangVisitAction,
  visit: TVisit,
};

type TComponentState = {
  ids: number[],
};

export class AssignGang extends React.Component<TComponentProps, TComponentState> {
  constructor(props: TComponentProps) {
    super(props);
    this.state = {
      ids: props.visit.gangs.map((gang) => gang.id),
    };
  }

  onChange = (ids: number[]) => {
    const removedId = this.state.ids.find((id) => ids.find((selectedId) => id === selectedId) === undefined);
    const newId = ids.find((selectedId) => this.state.ids.find((id) => id === selectedId) === undefined);

    if (removedId) {
      this.props.removeGangVisitAction(this.props.visit, removedId);
    } else if (newId) {
      this.props.addGangVisitAction(this.props.visit, newId);
    }

    this.setState({ ids });
  };

  render() {
    return (
      <div>
        <VisitSummaryExtra visit={this.props.visit} />
        <Select
          mode="multiple"
          style={{ width: '100%' }}
          placeholder={<FormattedMessage id="atoms.modals.assign_gangs.assign_a_gang" defaultMessage="Select your gang" />}
          onChange={this.onChange}
          defaultValue={this.state.ids}
        >
          {this.props.gangs.map((gang: TGangLB) => (
            <Select.Option key={gang.id} value={gang.id}>
              {gang.crane || '?'}
            </Select.Option>
          ))}
        </Select>
      </div>
    );
  }
}
