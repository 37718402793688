// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { processVisitAction } from '../../../store/actions';
import type { TVisit } from '../../../types';
import { formatDate } from '../../../utils/dates.utils';
import { DebouncedSwitch } from '../../atoms/barge_clerks/DebouncedSwitch';
import { selectVisitProcessedLoading } from '../../../store/selectors/visit.selectors';

type TProps = {
  processVisitAction: typeof processVisitAction,
  visit: TVisit,
};

export function ProcessToggle(props: TProps) {
  const toggleAction = () => props.processVisitAction(props.visit);
  const loadingProcessed = useSelector(selectVisitProcessedLoading);

  return (
    <div className="approved-check-with-text">
      <DebouncedSwitch
        loading={loadingProcessed}
        checked={props.visit.processed}
        onToggle={toggleAction}
        text={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <span className="label">
              {props.visit.processed ? (
                <>
                  <strong className="text-green">
                    <FormattedMessage id="processButton.processed_at_label" defaultMessage="Processed" />
                  </strong>
                  <br />
                  <div style={{ display: 'inline-block' }} className="text-green">
                    {formatDate(props.visit.processedAt)}
                  </div>
                </>
              ) : (
                <strong>
                  <FormattedMessage id="processButton.mark_as_processed_label" defaultMessage="Mark as processed" />
                </strong>
              )}
            </span>
          </div>
        }
      />
    </div>
  );
}
