// @flow
import moment from 'moment';
/* eslint-disable react/no-unused-prop-types */
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { TVisitRemark } from '../../../types/remark.types';
import { CollapseHeader } from '../../atoms/CollapseHeader';

type TProps = {
  remark: TVisitRemark,
};

export function RemarkHeader(props: TProps) {
  return (
    <CollapseHeader title={<FormattedMessage id="molecules.collapse.header.remark.title" defaultMessage="Remarks" />}>
      {props.remark ? (
        <span className="text-subtitle">
          <FormattedMessage
            id="molecules.collapse.header.remark.subtitle"
            defaultMessage="Last added by {username} - {timestamp}"
            values={{ username: props.remark.createdBy, timestamp: moment(props.remark.createdAt).format('L LT') }}
          />
        </span>
      ) : null}
    </CollapseHeader>
  );
}
