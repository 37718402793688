// @flow
import moment from 'moment';
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { WEEKDAYS_FORMATTED } from '../../../../config/messages/date';
import type { TUnavailabilityWindow } from '../../../../types/index';
import { MDDetailTable } from './MDDetailTable';

type TProps = {
  intl: intlShape,
  unavailabilityWindows: TUnavailabilityWindow[],
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'tables.details.unavailability_window', defaultMessage: 'Unavailability windows' },
});

const UnavailabilityWindowTableProps = (unavailabilityWindows: TUnavailabilityWindow[]) => {
  const renderDayOfTheWeek = (text: string, record: TUnavailabilityWindow) => {
    let rowSpan = 0;
    const unAvailabilityWindow = unavailabilityWindows.find((val) => val.dayOfWeek === text);
    if (unAvailabilityWindow && unAvailabilityWindow.id === record.id) rowSpan = unavailabilityWindows.reduce((total, val) => (val.dayOfWeek === text ? total + 1 : total), 0);

    return {
      children: (
        <span className="text-capitalized">
          <b>{WEEKDAYS_FORMATTED[text.toLowerCase()]}</b>
        </span>
      ),
      props: {
        rowSpan,
      },
    };
  };

  const renderTimespan = (text: string, record: TUnavailabilityWindow) =>
    record.allDay ? (
      <FormattedMessage id="date.timespan.all_day" defaultMessage="all day" />
    ) : (
      <span>{`${moment(record.fromTime, 'HH:mm').format('HH:mm')} - ${moment(record.toTime, 'HH:mm').format('HH:mm')}`}</span>
    );

  const renderRemark = (text: string) => (!text || text === '' ? '' : text);

  return [
    {
      dataIndex: 'dayOfWeek',
      render: renderDayOfTheWeek,
      width: 200,
    },
    {
      dataIndex: 'fromTime',
      render: renderTimespan,
      width: 200,
    },
    {
      dataIndex: 'remark',
      render: renderRemark,
      width: 200,
    },
    {
      dataIndex: 'id',
      render: () => '', // filler column (temporary solution)
      width: 200,
    },
  ];
};

const unavailabilityWindowDetailTable = (props: TProps) => (
  <MDDetailTable columns={UnavailabilityWindowTableProps(props.unavailabilityWindows)} dataSource={props.unavailabilityWindows} title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} />
);

export const UnavailabilityWindowDetailTable = injectIntl(unavailabilityWindowDetailTable);
