// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import { genericErrorHandler } from '../../utils';
import { API_PATHS } from '../../config/api.constants';
import { DiagnosticsActions, receivedDiagnosticsAction } from '../actions/diagnostics.actions';

export function* page(): Generator<*, *, *> {
  const response = yield call(appApi.get, API_PATHS.V1.DIAGNOSTICS);
  yield put(receivedDiagnosticsAction(response.data));
}
export function* DiagnosticsSaga(): Generator<*, *, *> {
  yield takeLatest(DiagnosticsActions.page, genericErrorHandler(page));
}
