// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { StompSocket } from './StompSocket';

type TProps = {
  children: React.Node,
  onMessage: Function,
  topics: string[],
  socketKey: string,
  socketParameters?: any,
};

const delayInSeconds = 2;
const intervalInSeconds = 10;

export function Socket(props: TProps) {
  const [triggerCall, setTriggerCall] = useState(false);
  const [waiting, setWaiting] = useState(false);
  const [msgState, setMsgstate] = useState(undefined);

  const onmsg = (msg) => {
    setMsgstate(msg);
    setTriggerCall(true);
  };

  useEffect(() => {
    if (triggerCall && !waiting) {
      // do a call when it is a long time ago
      props.onMessage(msgState, props.socketParameters);
      setWaiting(true);
      setTriggerCall(false);
      setMsgstate(undefined);
    }
  }, [triggerCall, waiting]);

  useEffect(() => {
    const job = setInterval(() => {
      setMsgstate({ force: true });
      setTriggerCall(true);
    }, 1000 * intervalInSeconds);

    return function cleanup() {
      clearInterval(job);
    };
  }, []);

  useEffect(() => {
    if (waiting) {
      const timer1 = setTimeout(() => setWaiting(false), delayInSeconds * 1000);
      return () => clearTimeout(timer1);
    }
    return undefined;
  }, [waiting]);

  return (
    <>
      <StompSocket onMessage={onmsg} topics={props.topics} socketKey={props.socketKey} />
      {props.children}
    </>
  );
}
