// @flow
import { QuayActions } from '../../actions';
import type { TQuay } from '../../../types';

const initialState: ?TQuay = null;

const reducer = (state: ?TQuay = initialState, action: Object) => {
  switch (action.type) {
    case QuayActions.receivedDetail:
      return action.payload;

    default:
      return state;
  }
};

export const detailReducer = reducer;
