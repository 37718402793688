// @flow
import type { TShiftLabel } from '../../../types/barge_clerk.types';
import { ShiftActions } from '../../actions/shift.actions';

const initialState: ?(TShiftLabel[]) = [];

const reducer = (state: ?(TShiftLabel[]) = initialState, action: Object) => {
  switch (action.type) {
    case ShiftActions.received:
      return [...action.payload];

    default:
      return state;
  }
};

export const listReducer = reducer;
