// @flow
import type { TBaseAction, TLocation } from '../../types';

export const RouterActions = {
  urlChange: 'ROUTER_ACTIONS.URL_CHANGE',
};

export const urlChangeAction = (payload: TLocation): TBaseAction<TLocation> => ({
  payload,
  type: RouterActions.urlChange,
});
