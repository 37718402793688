// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { FormInstance, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { showNewGangRemarksOnDetail } from '../../../store/handlers/gangs.handler';
import type { TGlobalState } from '../../../types';
import type { TGangOM, TGangRemark } from '../../../types/gang.types';
import { getGangRemarksAction, postGangRemarkAction } from '../../../store/actions/gang_remark.actions';
import { RemarkSection } from '../../organisms/visit_detail/RemarkSection';
import { ROLES } from '../../../constants/roles.constants';
import { Loading } from '../Loading';
import { Socket } from '../Socket';

type TProps = {
  gang: TGangOM,
  loading: boolean,
  onClose: Function,
  remarks: TGangRemark[],
  visible: boolean,
  visitId?: string,
};

function GangRemarkModalComponent(props: TProps) {
  const dispatch = useDispatch();

  useEffect(() => {
    getGangRemarksAction(props.gang);
  }, [props.gang]);

  const saveRemark = (errors: ?Object, values: Object, form: FormInstance) => {
    if (!errors) {
      const remark: TGangRemark = { ...values };
      dispatch(postGangRemarkAction(props.gang, remark, () => dispatch(getGangRemarksAction(props.gang))));

      form.setFieldsValue({ message: '' });
    }
  };

  const hideModal = () => {
    props.onClose(props.remarks.length);
  };

  return (
    <Modal
      maskClosable
      width={1200}
      centered
      onCancel={hideModal}
      open={props.visible}
      footer={null}
      title={<FormattedMessage id="atoms.modals.gang_remarks.title" defaultMessage="Gang remarks" />}
      destroyOnClose
    >
      {props.loading ? (
        <Loading />
      ) : (
        <Socket
          topics={[EVENT_TYPES.GANG_REMARKS.replace(':id', props.gang.id)]}
          onMessage={showNewGangRemarksOnDetail({ getGangRemarksAction, gang: props.gang })}
          socketKey={`GangRemarkModel.${props.gang.id}`}
        >
          <RemarkSection loading={false} remarks={props.remarks} allowedRoles={[ROLES.ROLE_OPERATIONS_MANAGER, ROLES.ROLE_LICHTER_BEDIENDE]} validateFn={saveRemark} visitId={props.visitId} />
        </Socket>
      )}
    </Modal>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  remarks: state.gangRemarks.remarks,
  loading: state.gangRemarks.loading,
});

export const GangRemarkModal = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getGangRemarksAction,
  postGangRemarkAction,
})(GangRemarkModalComponent);
