// @flow
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ReadOutlined } from '@ant-design/icons';
import type { TGlobalState } from '../../../types';

export function ElearningLink() {
  const name = 'ELEARNING_MANUAL_URL';
  const link = useSelector((state: TGlobalState) => {
    if (state && state.systemSettings) {
      const page = state.systemSettings.page || [];
      const setting = page.filter((p) => p.name === name)[0];
      if (setting) {
        return setting.value;
      }
    }
    return '';
  });

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <ReadOutlined /> <FormattedMessage id="atoms.elearning_manual" defaultMessage="Manual" />
    </a>
  );
}
