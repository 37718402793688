// @flow
import * as React from 'react';
import { Progress } from 'antd';
import { calculatePercentage } from '../../utils/number.utils';

type TProps = {
  fracture: number,
  total: number,
};

export function ProgressTracker(props: TProps) {
  let status: string;
  if (props.fracture !== 0) {
    status = props.fracture < props.total ? 'active' : 'success';
  } else {
    status = 'normal';
  }

  const cssStatusClass = `progress-tracker-${status}`;

  return (
    <div className={`progress-tracker ${cssStatusClass}`}>
      <div className={`${cssStatusClass} progress-tracker-text`}>{`${props.fracture}/${props.total}`}</div>
      <Progress percent={calculatePercentage(props.fracture, props.total)} status={status} showInfo={false} />
    </div>
  );
}
