// @flow
import * as React from 'react';
import { useState } from 'react';
import { Collapse } from 'antd';
import { FormattedMessage } from 'react-intl';
import { ErrorCollapseHeader } from '../../../atoms/ErrorCollapseHeader';
import type { TError } from '../../../../types/index';
import { formatTableSize } from '../../../../utils/format.utils';
import { archiveInternalErrorsAction, unarchiveInternalErrorsAction } from '../../../../store/actions';
import { Elearning } from '../../../atoms/elearning';
import { ErrorTableHeader } from '../../errors/ErrorTableHeader';
import { DPDetailTable } from '../../../atoms/Tables/DPDetailTable';
import { ErrorRowSelection, ErrorTableColumns } from '../../../atoms/Tables/ErrorTableProps';

type TProps = {
  errors: TError[],
  b2bLink: string,
  loading: boolean,
  archiveInternalErrorsAction: typeof archiveInternalErrorsAction,
  unarchiveInternalErrorsAction: typeof unarchiveInternalErrorsAction,
  timestampArchived: string,
};

export function InternalErrorsCollapse(props: TProps) {
  const [selectedErrors, setSelectedErrors] = useState<TError[]>([]);

  const changeSelection = (selectedErrors: string[]) => {
    setSelectedErrors(selectedErrors);
  };

  const resetAndArchiveInternal = (errors: TError[]) => {
    setSelectedErrors([]);

    props.archiveInternalErrorsAction(errors.map((e) => e.id));
  };

  const resetAndUnarchiveInternal = (errors: TError[]) => {
    setSelectedErrors([]);

    props.unarchiveInternalErrorsAction(errors.map((e) => e.id));
  };

  return (
    <Collapse className="collapse-error" bordered={false}>
      <Collapse.Panel
        key="internal"
        header={
          <>
            <Elearning contentKey="DP_DETAIL_INT_ERRORS_BLOCK_HEADER" />
            <ErrorCollapseHeader
              errors={props.errors}
              subTitle={props.errors ? formatTableSize(props.errors) : ''}
              title={<FormattedMessage id="organisms.visits.visit_detail.panel.internal_errors.header" defaultMessage="Internal errors" />}
              b2bLink={props.b2bLink}
              timeStampArchived={props.timestampArchived}
            />
          </>
        }
        className="panel"
      >
        <div className="collapse-content-wrapper">
          <ErrorTableHeader errors={props.errors} selectedKeys={selectedErrors} archiveErrors={resetAndArchiveInternal} unarchiveErrors={resetAndUnarchiveInternal} isExternal={false} />
          <DPDetailTable
            columns={ErrorTableColumns}
            dataSource={props.errors}
            rowKey="id"
            loading={props.loading}
            rowSelection={ErrorRowSelection(props.errors, changeSelection, selectedErrors, false)}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
