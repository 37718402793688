// @flow
import type { TBaseAction } from '../../types';
import type { TDiagnostics } from '../../types/diagnostic.types';

export const DiagnosticsActions = {
  page: 'DIAGNOSTICS_ACTIONS.PAGE',
  receivedPage: 'DIAGNOSTICS_ACTIONS.RECEIVED_PAGE',
};

export const getDiagnosticsAction = (): TBaseAction<?TDiagnostics> => ({
  type: DiagnosticsActions.page,
});

export const receivedDiagnosticsAction = (payload: TDiagnostics): TBaseAction<?TDiagnostics> => ({
  payload,
  type: DiagnosticsActions.receivedPage,
});
