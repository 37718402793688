/* eslint-disable */
// @flow
import * as React from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Popconfirm, Table, Tag, Tooltip } from 'antd';
import moment from 'moment';
import { ROLES } from '../../../constants/roles.constants';
import type { TGangLB } from '../../../types/gang.types';
import { COLORS } from '../../../constants/style.constants';
import { isAuthorized } from '../../../utils/authorization.utils';
import { Refresh } from '../../atoms/Refresh';
import { RoleViewer } from '../../atoms/RoleViewer';
import { formatDateTime, formatTime, humanizeTimeStamp } from '../../../utils/dates.utils';
import { GangRemarkModal } from '../../atoms/modals/GangRemarkModal';
import { Elearning } from '../../atoms/elearning';
import { ArrowDownOutlined, ArrowUpOutlined, DeleteFilled, MessageFilled } from '@ant-design/icons';
import { EditableCell, EditableRow } from '../../organisms/containers/EditField';

type TProps = {
  addNewGang: Function,
  deleteGang: Function,
  gangs: TGangLB[],
  gangsLastModified: ?string,
  previousTotalNumberOfGangs: number,
  roles: string[],
  updateGang: Function,
  userRoles: string[],
};

type TState = {
  moreShown: boolean,
  selectedGang: ?TGangLB,
};

export function GangListComponent(props: TProps) {
  const [moreShown, setMoreShown] = useState(false);
  const [selectedGang, setSelectedGang] = useState<TGangLB | null>(null);

  const hasMore = () => {
    return props.gangs.length > 3;
  };

  const getData = (): TGangLB[] => {
    return hasMore() && !moreShown ? props.gangs.slice(0, 3) : props.gangs;
  };

  const showRemarks = (gang: TGangLB) => {
    setSelectedGang(gang);
  };

  const hideRemarks = () => {
    setSelectedGang(null);
  };

  const renderMoreButtons = () => {
    if (hasMore()) {
      return (
        <Button style={{ marginTop: 5, marginLeft: 5 }} onClick={() => setMoreShown((prevState) => !prevState)}>
          {moreShown ? <FormattedMessage id="shift_tal_list.labels.show_less" defaultMessage="Show less" /> : <FormattedMessage id="shift_tal_list.labels.show_all" defaultMessage="Show all" />}
        </Button>
      );
    }

    return null;
  };

  const renderAddGangButton = () => {
    return (
      <Button style={{ marginTop: 10, marginLeft: 5 }} onClick={props.addNewGang} disabled={!isAuthorized([ROLES.ROLE_LICHTER_BEDIENDE], props.roles)}>
        <FormattedMessage id="molecules.barge_clerks.gang_list.add_gang" defaultMessage="Add new" />
      </Button>
    );
  };

  const components = isAuthorized([ROLES.ROLE_LICHTER_BEDIENDE], props.userRoles)
    ? {
        body: {
          row: EditableRow,
          cell: EditableCell,
        },
      }
    : {};

  const btsRenderer = (gang: TGangLB, property: string) => gang[property];

  const columns = [
    {
      children: [
        {
          align: 'center',
          dataIndex: 'crane',
          key: 'crane',
          onCell: (record: TGangLB) => ({
            editable: true,
            type: 'alphanumeric',
            record,
            dataIndex: 'crane',
            handleSave: props.updateGang,
            style: { margin: 0, width: 50 },
          }),
          render: (_, record: TGangLB) => {
            return <Tag color={record.color}>{record.crane ? record.crane : '?'}</Tag>;
          },
        },
        {
          align: 'center',
          dataIndex: 'startTime',
          key: 'startTime',
          render: (_, record: TGangLB) => formatTime(record.startTime),
          onCell: (record: TGangLB) => ({
            editable: true,
            type: 'time',
            record,
            dataIndex: 'startTime',
            handleSave: props.updateGang,
            style: { margin: 0, width: 50 },
          }),
        },
        {
          align: 'center',
          dataIndex: 'endtime',
          key: 'endTime',
          render: (_, record: TGangLB) => formatTime(record.endTime),
          onCell: (record: TGangLB) => ({
            editable: true,
            type: 'time',
            record,
            dataIndex: 'endTime',
            handleSave: props.updateGang,
            style: { margin: 0, width: 50 },
          }),
        },
        {
          align: 'center',
          dataIndex: 'workload',
          key: 'workload',
          onCell: (record: TGangLB) => ({
            editable: true,
            type: 'number',
            record,
            dataIndex: 'workload',
            handleSave: props.updateGang,
          }),
        },
      ],
    },
    {
      align: 'center',
      title: (
        <span>
          <ArrowDownOutlined style={{ color: COLORS.GREEN, marginRight: 4 }} />
          <FormattedMessage id="molecules.barge_clerks.gang_list.20ft" defaultMessage="20ft" />
        </span>
      ),
      children: [
        {
          align: 'center',
          dataIndex: 'numberOfContainersInTwentyFtToDo',
          key: 'numberOfContainersInTwentyFtToDo',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.todo" defaultMessage="To do" />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersInTwentyFtToDo'),
        },
        {
          align: 'center',
          dataIndex: 'numberOfContainersInTwentyFt',
          key: 'numberOfContainersInTwentyFt',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.total.abbr" defaultMessage="Tot." />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersInTwentyFt'),
        },
      ],
    },
    {
      align: 'center',
      title: (
        <span>
          <ArrowDownOutlined style={{ color: COLORS.GREEN, marginRight: 4 }} />
          <FormattedMessage id="molecules.barge_clerks.gang_list.40ft" defaultMessage="40ft" />
        </span>
      ),
      children: [
        {
          align: 'center',
          dataIndex: 'numberOfContainersInFortyFtToDo',
          key: 'numberOfContainersInFortyFtToDo',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.todo" defaultMessage="To do" />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersInFortyFtToDo'),
        },
        {
          align: 'center',
          dataIndex: 'numberOfContainersInFortyFt',
          key: 'numberOfContainersInFortyFt',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.total.abbr" defaultMessage="Tot." />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersInFortyFt'),
        },
      ],
    },
    {
      align: 'center',
      title: (
        <span>
          <ArrowUpOutlined style={{ color: COLORS.RED }} />
          <FormattedMessage id="molecules.barge_clerks.gang_list.20ft" defaultMessage="20ft" />
        </span>
      ),
      children: [
        {
          align: 'center',
          dataIndex: 'numberOfContainersOutTwentyFtToDo',
          key: 'numberOfContainersOutTwentyFtToDo',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.todo" defaultMessage="To do" />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersOutTwentyFtToDo'),
        },
        {
          align: 'center',
          dataIndex: 'numberOfContainersOutTwentyFt',
          key: 'numberOfContainersOutTwentyFt',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.total.abbr" defaultMessage="Tot." />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersOutTwentyFt'),
        },
      ],
    },
    {
      align: 'center',
      title: (
        <span>
          <ArrowUpOutlined style={{ color: COLORS.RED }} />
          <FormattedMessage id="molecules.barge_clerks.gang_list.40ft" defaultMessage="40ft" />
        </span>
      ),
      children: [
        {
          align: 'center',
          dataIndex: 'numberOfContainersOutFortyFtToDo',
          key: 'numberOfContainersOutFortyFtToDo',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.todo" defaultMessage="To do" />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersOutFortyFtToDo'),
        },
        {
          align: 'center',
          dataIndex: 'numberOfContainersOutFortyFt',
          key: 'numberOfContainersOutFortyFt',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.total.abbr" defaultMessage="Tot." />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersOutFortyFt'),
        },
      ],
    },
    {
      align: 'center',
      title: (
        <span>
          <ArrowDownOutlined style={{ color: COLORS.GREEN }} />
          <ArrowUpOutlined style={{ color: COLORS.RED, marginRight: 4 }} />
          <FormattedMessage id="molecules.barge_clerks.gang_list.total.abbr" defaultMessage="Tot." />
        </span>
      ),
      children: [
        {
          align: 'center',
          dataIndex: 'numberOfContainersToDo',
          key: 'numberOfContainersToDo',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.todo" defaultMessage="To do" />,
          render: (text, record) => btsRenderer(record, 'numberOfContainersToDo'),
        },
        {
          align: 'center',
          dataIndex: 'numberOfContainers',
          key: 'numberOfContainers',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.total.abbr" defaultMessage="Tot." />,
          render: (text, record) => btsRenderer(record, 'numberOfContainers'),
        },
      ],
    },
    {
      align: 'right',
      width: 135,
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => (
        <div className="text-right">
          {record.history ? (
            <Tooltip
              title={
                <div>
                  <p>
                    <FormattedMessage id="tooltips.gang_history.last_change" defaultMessage="Last change: {lastChange}" values={{ lastChange: formatDateTime(moment(record.updatedAt)) }} />
                  </p>
                  <strong>
                    <FormattedMessage id="tooltips.gang_history.previous_values" defaultMessage="Previous values:" />
                  </strong>
                  <br />
                  {`${formatTime(moment(record.history.startTime))} - ${formatTime(moment(record.history.endTime))}`}
                  <br />
                  <strong>
                    <FormattedMessage id="tooltips.gang_history.workload" defaultMessage="Workload" />
                  </strong>{' '}
                  {record.history.workload + ''}
                </div>
              }
            >
              <span className="human-text-history">{humanizeTimeStamp(record.updatedAt)}</span>
            </Tooltip>
          ) : (
            <Tooltip
              title={
                <div>
                  <p>
                    <FormattedMessage id="tooltips.gang_history.last_change" defaultMessage="Last change: {lastChange}" values={{ lastChange: formatDateTime(moment(record.updatedAt)) }} />
                  </p>
                </div>
              }
            >
              <span className="human-text-history">{humanizeTimeStamp(record.updatedAt)}</span>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      align: 'right',
      width: 90,
      title: '',
      render: (text: string, record: TGangLB) => (
        <div style={{ textAlign: 'right' }}>
          <RoleViewer roles={[ROLES.ROLE_LICHTER_BEDIENDE]}>
            <Button type="button" onClick={() => showRemarks(record)} size="small" style={{ fontSize: 11 }}>
              <MessageFilled /> {record.numberOfRemarks}
            </Button>
            <Popconfirm
              onConfirm={(e) => props.deleteGang(record) && e.stopPropagation()}
              onCancel={(e) => e.stopPropagation()}
              title={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_title" defaultMessage="Are you sure ?" />}
              okText={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_confirm" defaultMessage="Delete" />}
              cancelText={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_cancel" defaultMessage="Cancel" />}
            >
              <Button type="button" size="small" style={{ fontSize: 11 }}>
                <DeleteFilled />
              </Button>
            </Popconfirm>
            <GangRemarkModal gang={record} onClose={hideRemarks} visible={!!selectedGang && selectedGang.id === record.id} visitId={props.visitId} />
          </RoleViewer>
        </div>
      ),
    },
  ];

  return (
    <Refresh
      rate={30000}
      refreshAction={() => {
        setMoreShown((prevState) => prevState); // trigger a rerender
      }}
    >
      <h3 className="title-with-history">
        <Elearning contentKey="LB_OVERVIEW_CRANE_GANGS" />
        <FormattedMessage id="organisms.barge_clerks.infobar.gang_list" defaultMessage="Gangs" />
      </h3>
      {props.gangsLastModified ? (
        <Tooltip
          title={
            <div>
              <FormattedMessage
                id="organisms.barge_clerk.visit_list.previous_number_of_gangs"
                defaultMessage="Previous number of gangs: {gangs}"
                values={{ gangs: `${props.previousTotalNumberOfGangs}` }}
              />
              <br />
              <br />
              {formatDateTime(moment(props.gangsLastModified))}
            </div>
          }
        >
          <span className="human-text-history gangs-right-top">
            <FormattedMessage id="organisms.barge_clerks.infobar.gang_time_last_change" defaultMessage="Last change" />: {humanizeTimeStamp(moment(props.gangsLastModified))}
          </span>
        </Tooltip>
      ) : null}

      <Table components={components} className="shift-tal-list-table no-empty-image-ant" columns={columns} rowKey="id" dataSource={getData()} size="small" pagination={false} />
      {renderAddGangButton()}
      {renderMoreButtons()}
    </Refresh>
  );
}
