// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { QuestionCircleTwoTone } from '@ant-design/icons';
import { DPTemplate } from '../templates/DPTemplate';

export function NotFoundPage() {
  return (
    <DPTemplate menuBlock="not found" menuItem="not found">
      <div className="text-center unauthorized-wrapper">
        <QuestionCircleTwoTone className="unauthorized-icon" twoToneColor="#1B89CA" />

        <h1 className="unauthorized-title">
          <FormattedMessage id="notfound" defaultMessage="404: Not found" />
        </h1>
      </div>
    </DPTemplate>
  );
}
