// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { deleteOrEmpty } from '../renderers';

export const BargeOperatorTableProps = (deleteAction: Function) => [
  {
    dataIndex: 'name',
    key: 'name',
    title: <FormattedMessage id="organisms.barge_operators.name" defaultMessage="Name" />,
    width: 570,
  },
  {
    dataIndex: 'abbreviation',
    key: 'abbreviation',
    title: <FormattedMessage id="organisms.barge_operators.abbreviation" defaultMessage="Abbreviation" />,
  },
  {
    dataIndex: 'reference',
    key: 'reference',
    title: <FormattedMessage id="organisms.barge_operators.reference" defaultMessage="Carrier ID" />,
  },
  {
    key: 'actions',
    render: deleteOrEmpty(deleteAction),
    title: <FormattedMessage id="organisms.barge_operators.actions" defaultMessage="actions" />,
    width: 127,
  },
];
