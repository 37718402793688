// @flow
import { takeEvery } from '@redux-saga/core/effects';
import { SocketActions } from '../actions/socket.actions';
import type { ActionSocketWithHandler } from '../actions/socket.actions';

export function* processSocketMessage(action: ActionSocketWithHandler): Generator<*, *, *> {
  const { payload } = action;
  yield payload.handler(payload.message);
}

export function* SocketSaga(): Generator<*, *, *> {
  yield takeEvery(SocketActions.refresh, processSocketMessage);
}
