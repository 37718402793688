// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { getDiagnosticsAction } from '../../../store/actions/diagnostics.actions';

import type { TGlobalState } from '../../../types';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { Loading } from '../../atoms/Loading';
import { DiagnosticsComponent } from '../../molecules/DiagnosticsComponent';
import type { TDiagnostics } from '../../../types/diagnostic.types';

type TProps = {
  getDiagnosticsAction: typeof getDiagnosticsAction,
  intl: intlShape,
  loadingDiagnosticsPage: boolean,

  diagnostics: TDiagnostics,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.diagnostics.header.title', defaultMessage: 'Diagnostics' },
  summary: { id: 'pages.diagnostics.header.summary', defaultMessage: '' },
});

class Diagnostics extends React.Component<TProps> {
  componentDidMount() {
    this.props.getDiagnosticsAction();
  }

  render() {
    return (
      <ListTemplate title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}>
        {this.props.loadingDiagnosticsPage ? (
          <Loading />
        ) : (
          <>
            <h2>{this.props.intl.formatMessage(EXTRA_TRANSLATIONS.summary)}</h2>
            <DiagnosticsComponent diagnostics={this.props.diagnostics} />
          </>
        )}
      </ListTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingDiagnosticsPage: state.diagnostics.loadingPage,
  diagnostics: state.diagnostics.page,
});

export const DiagnosticsPage = connect<any, Object, _, _, _, _>(mapStateToProps, { getDiagnosticsAction })(injectIntl(Diagnostics));
