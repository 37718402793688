// @flow
import * as React from 'react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as materialSnowflake } from '../../resources/material-ac_unit.svg';

export const snowFlakeTag = (
  <Tooltip title={<FormattedMessage id="special_containers.tooltip.message.reefer" defaultMessage="This visit contains operational reefers" />}>
    <Icon theme="outlined" component={materialSnowflake} className="icon-reefer" />
  </Tooltip>
);
