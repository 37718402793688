// @flow
import * as React from 'react';
import { Layout, Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { SITE_PATHS } from '../../../config/api.constants';

const { Sider } = Layout;

type TSiderProps = {
  menuBlock?: ?string,
  menuItem?: string,
};

export function SideMenu(props: TSiderProps) {
  const items = [
    {
      label: <FormattedMessage id="templates.crud_sider.skippers_title" defaultMessage="Skippers" />,
      key: 'skippers',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.SKIPPER_LIST}>
              <FormattedMessage id="templates.crud_sider.skippers_overview" defaultMessage="Overview" />
            </Link>
          ),
          key: 'skippers-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.SKIPPER_CREATE}>
              <FormattedMessage id="templates.crud_sider.skippers_new" defaultMessage="New" />
            </Link>
          ),
          key: 'skippers-create',
        },
      ],
    },
    {
      label: <FormattedMessage id="templates.crud_sider.barge_title" defaultMessage="Barges" />,
      key: 'barges',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.BARGE_LIST}>
              <FormattedMessage id="templates.crud_sider.barges_overview" defaultMessage="Overview" />
            </Link>
          ),
          key: 'barges-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.BARGE_CREATE}>
              <FormattedMessage id="templates.crud_sider.barges_new" defaultMessage="New" />
            </Link>
          ),
          key: 'barges-create',
        },
      ],
    },
    {
      label: <FormattedMessage id="templates.crud_sider.barge_operators_title" defaultMessage="Barge operators" />,
      key: 'barge-operators',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.BARGE_OPERATOR_LIST}>
              <FormattedMessage id="templates.crud_sider.barge_operators_overview" defaultMessage="Overview" />
            </Link>
          ),
          key: 'barge-operators-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.BARGE_OPERATOR_CREATE}>
              <FormattedMessage id="templates.crud_sider.barge_operators_new" defaultMessage="New" />
            </Link>
          ),
          key: 'barge-operators-create',
        },
      ],
    },
    {
      label: (
        <span>
          <FormattedMessage id="templates.crud_sider.error_codes_title" defaultMessage="Error codes" />
        </span>
      ),
      key: 'error-codes',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.ERROR_CODE_LIST}>
              <FormattedMessage id="templates.crud_sider.error_codes_overview" defaultMessage="Overview" />
            </Link>
          ),
          key: 'error-codes-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.ERROR_CODE_CREATE}>
              <FormattedMessage id="templates.crud_sider.error_codes_new" defaultMessage="New" />
            </Link>
          ),
          key: 'error-codes-create',
        },
      ],
    },
    {
      label: <FormattedMessage id="templates.crud_sider.quay_title" defaultMessage="Quays" />,
      key: 'quays',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.QUAY_LIST}>
              <FormattedMessage id="templates.crud_sider.quays_overview" defaultMessage="Overview" />
            </Link>
          ),
          key: 'quays-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.QUAY_CREATE}>
              <FormattedMessage id="templates.crud_sider.quays_new" defaultMessage="New" />
            </Link>
          ),
          key: 'quays-create',
        },
      ],
    },
    {
      label: <FormattedMessage id="templates.crud_sider.elearning_title" defaultMessage="Elearning" />,
      key: 'elearning',
      children: [
        {
          label: (
            <Link to={SITE_PATHS.E_LEARNING_DP_OVERVIEW_LIST}>
              <FormattedMessage id="templates.crud_sider.elearning_dp_overview" defaultMessage="DP Overview" />
            </Link>
          ),
          key: 'elearning-dp-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.E_LEARNING_DP_DETAIL_LIST}>
              <FormattedMessage id="templates.crud_sider.elearning_dp_detail" defaultMessage="DP Detail" />
            </Link>
          ),
          key: 'elearning-dp-detail',
        },
        {
          label: (
            <Link to={SITE_PATHS.E_LEARNING_LB_OVERVIEW_LIST}>
              <FormattedMessage id="templates.crud_sider.elearning_lb_overview" defaultMessage="LB Overview" />
            </Link>
          ),
          key: 'elearning-lb-overview',
        },
        {
          label: (
            <Link to={SITE_PATHS.E_LEARNING_LB_DETAIL_LIST}>
              <FormattedMessage id="templates.crud_sider.elearning_lb_detail" defaultMessage="LB Detail" />
            </Link>
          ),
          key: 'elearning-lb-detail',
        },
      ],
    },
  ];

  return (
    <Sider trigger={null}>
      <Menu mode="inline" defaultSelectedKeys={[props.menuItem]} defaultOpenKeys={[props.menuBlock]} items={items} />
    </Sider>
  );
}

SideMenu.defaultProps = {
  menuBlock: null,
  menuItem: null,
};
