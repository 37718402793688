export const EVENT_TYPES = {
  BARGE_CLERK_ROW: '/queue/barge-clerk-row',
  DATA_PROCESSOR_ROW: '/queue/data-processor-row',
  SPECIFIC_VISIT_STREAM: '/queue/visits/:id',
  SPECIFIC_CONTAINER_STREAM: '/queue/visits/:id/container-visits',
  SPECIFIC_ERROR_STREAM: '/queue/visits/:id/errors',
  GANG_PROGRESS_ROW: '/queue/gang-progress-row',
  SHIFT_TAL_ROW: '/queue/shift-tal-row',
  GANG_REMARKS: '/queue/gangs/:id/remarks',
  LPL_REQUESTS_STREAM: '/queue/lpl-requests',
  GANG_STREAM: '/queue/gangs',
  SPECIFIC_LPL_REQUESTS_STREAM: '/queue/visits/:id/lpl-requests',
};
