// @flow
import React from 'react';
import { injectIntl, useIntl } from 'react-intl';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';
import { ElearningListTable } from '../../organisms/elearning/ElearningListTable';
import { DP_OVERVIEW } from '../../../config/elearning.keys';

const TRANSLATIONS = {
  TITLE: { id: 'templates.crud_sider.elearning_title', defaultMessage: 'Elearning' },
  OVERVIEW: { id: 'templates.crud_sider.elearning_dp_overview', defaultMessage: 'DP Overview' },
  CREATE: { id: 'elearning.overview.create', defaultMessage: 'Edit help item' },
};

function List() {
  const intl = useIntl();

  // Layout
  const title = `${intl.formatMessage(TRANSLATIONS.TITLE)} ${intl.formatMessage(TRANSLATIONS.OVERVIEW)}`;

  return (
    <ListTemplate title={title} buttonText={intl.formatMessage(TRANSLATIONS.CREATE)} menuBlock="elearning" menuItem="elearning-dp-overview">
      <ElearningListTable keys={DP_OVERVIEW} />
    </ListTemplate>
  );
}

export const ElearningDpOverviewListPage = injectIntl(List);
