// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { autocompleteBargeAction, clearBargeAutocompletions, createSkipperAction } from '../../../store/actions';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import SkipperForm from '../../organisms/skippers/CreateForm';
import type { TLabel } from '../../../types/label.types';
import type { TGlobalState } from '../../../types';

type TProps = {
  autocompleteBargeAction: typeof autocompleteBargeAction,
  autocompletions: TLabel[],
  clearBargeAutocompletions: typeof clearBargeAutocompletions,
  createSkipperAction: typeof createSkipperAction,
  intl: intlShape,
  loadingCompletions: boolean,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.skipper.create.header.title', defaultMessage: 'Create skipper' },
});

function SkipperCreate(props: TProps) {
  return (
    <EditTemplate title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} menuBlock="skippers" menuItem="skippers-create">
      <SkipperForm
        handleSubmit={props.createSkipperAction}
        autocompleteAction={props.autocompleteBargeAction}
        autocompletions={props.autocompletions}
        clearAutocompletionsAction={props.clearBargeAutocompletions}
        loadingCompletions={props.loadingCompletions}
      />
    </EditTemplate>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  autocompletions: state.barges.autocompletions,
  loadingCompletions: state.barges.loadingAutocompletions,
});

const actionCreators = {
  autocompleteBargeAction,
  clearBargeAutocompletions,
  createSkipperAction,
};

export const SkipperCreatePage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, actionCreators)(SkipperCreate));
