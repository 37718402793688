// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { createQuayAction } from '../../../store/actions';
import QuayForm from '../../organisms/quays/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';

type TProps = {
  createQuayAction: typeof createQuayAction,
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.quay.create.header.title', defaultMessage: 'Create quay' },
});

export function QuayCreate(props: TProps) {
  return (
    <EditTemplate title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} menuBlock="quays" menuItem="quays-create">
      <QuayForm handleSubmit={props.createQuayAction} />
    </EditTemplate>
  );
}

export const QuayCreatePage = injectIntl(connect<any, Object, _, _, _, _>(undefined, { createQuayAction })(QuayCreate));
