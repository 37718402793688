// @flow
import * as React from 'react';
import { Alert, Button } from 'antd';
import { FormattedMessage } from 'react-intl';
import Icon, { WarningFilled } from '@ant-design/icons';
import type { TError } from '../../../types/index';
import { ReactComponent as materialArrow } from '../../../resources/material-subdirectory.svg';

type THeaderProps = {
  errors: TError[],
  hasContactInfo?: boolean,
  selectedKeys: string[],
  sendErrors?: Function,
  archiveErrors: Function,
  unarchiveErrors: Function,
  isExternal: boolean,
  disableArchive?: boolean,
};

export function ErrorTableHeader(props: THeaderProps) {
  const messageIdPrefix = 'molecules.errors.error_table.header';
  const keysToErrors = () => props.selectedKeys.map((val) => props.errors.find((e) => e.id === val));

  return (
    <div className={`action-bar-table-header ${props.hasContactInfo ? '' : 'text-disabled'}`}>
      <Icon theme="outlined" className="icon-downcurved" component={materialArrow} style={{ fontSize: '18px', position: 'relative', top: 8 }} />
      <span>&nbsp;</span>
      {props.isExternal ? (
        <>
          {props.disableArchive ? (
            <FormattedMessage id={`${messageIdPrefix}.selection_instruction_external_lb`} defaultMessage="Select errors to send" />
          ) : (
            <FormattedMessage id={`${messageIdPrefix}.selection_instruction`} defaultMessage="Select errors to send or archive" />
          )}
          {props.hasContactInfo ? (
            <Button type="default" onClick={() => props.sendErrors(keysToErrors())} className="send-btn btn-default" disabled={props.selectedKeys.length === 0}>
              <FormattedMessage id={`${messageIdPrefix}.send_errors`} defaultMessage="Send selected errors" />
            </Button>
          ) : (
            <Alert
              message={<FormattedMessage id="molecules.errors.ext_error_table.error_table_header" defaultMessage="Not possible to send email: email address of barge operator unknown" />}
              type="error"
              showIcon
              className="alert-error"
              icon={<WarningFilled />}
            />
          )}
        </>
      ) : (
        <FormattedMessage id={`${messageIdPrefix}.selection_instruction_internal`} defaultMessage="Select errors to archive" />
      )}
      {!props.disableArchive && keysToErrors().length > 0 && keysToErrors().every((error) => !error.archived) && (
        <Button type="default" onClick={() => props.archiveErrors(keysToErrors())} className="send-btn btn-default" disabled={props.selectedKeys.length === 0}>
          <FormattedMessage id={`${messageIdPrefix}.archive_errors`} defaultMessage="Archive selected errors" />
        </Button>
      )}
      {!props.disableArchive && keysToErrors().length > 0 && keysToErrors().every((error) => error.archived) && (
        <Button type="default" onClick={() => props.unarchiveErrors(keysToErrors())} className="send-btn btn-default" disabled={props.selectedKeys.length === 0}>
          <FormattedMessage id={`${messageIdPrefix}.unarchive_errors`} defaultMessage="Unarchive selected errors" />
        </Button>
      )}
    </div>
  );
}
