// @flow
import moment from 'moment';
import * as React from 'react';
import { useEffect } from 'react';
import { Button, DatePicker, Modal, Select, Form } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { downloadExcelForShiftsAction } from '../../../store/actions/barge_clerk.actions';
import { getAllQuaysAction } from '../../../store/actions';
import type { TQuay } from '../../../types';
import type { TShiftLabel } from '../../../types/barge_clerk.types';

type TProps = {
  downloadExcelForShiftsAction: typeof downloadExcelForShiftsAction,
  getAllQuaysAction: Function,
  onClose: Function,
  quays: TQuay[],
  shifts: TShiftLabel[],
  visible: boolean,
};

const { RangePicker } = DatePicker;
const { Option } = Select;

function ExportRangeModalComponent({ ...props }: TProps) {
  const [form] = useForm();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllQuaysAction());
  }, []);
  const handleSubmit = () =>
    form.validateFields().then((values) => {
      const quayIds = values.quays.map((quayStr) => parseInt(quayStr, 10));
      const shifts = props.shifts.filter((shift) => moment(shift.date).isBetween(values.dates[0], values.dates[1], 'days', '[]'));
      dispatch(downloadExcelForShiftsAction(shifts, quayIds));
      props.onClose();
    });

  const disabledDate = (current) => {
    const date = current.format('YYYY-MM-DD');
    return props.shifts.filter((shift) => shift.date === date).length === 0;
  };

  return (
    <Modal
      title={<FormattedMessage id="molecules.modals.export_excel.title" defaultMessage="Export to Excel" />}
      centered
      open={props.visible}
      footer={null}
      onCancel={props.onClose}
      maskClosable
      width={700}
    >
      <Form form={form} onFinish={handleSubmit}>
        <p>
          <FormattedMessage id="export_range_modal.description" defaultMessage="Select a date range to export all data within these dates" />
        </p>

        <Form.Item
          name="dates"
          rules={[{ type: 'array', required: true, message: <FormattedMessage id="export_range_modal.description" defaultMessage="Select a date range to export all data within these dates" /> }]}
        >
          <RangePicker format="YYYY-MM-DD" disabledDate={disabledDate} />
        </Form.Item>
        <p>
          <FormattedMessage id="export_range_modal.labels.quays" defaultMessage="Quays" />
        </p>
        <Form.Item name="quays" initialValue={[]}>
          <Select mode="multiple" style={{ width: 220 }} filterOption={(inputValue, option) => option.props.children.indexOf(inputValue) > -1}>
            {props.quays.map((quay) => (
              <Option value={quay.id}>{quay.name}</Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="export_range_modal.download" defaultMessage="Download" />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  quays: state.quays.list,
});

export const ExportRangeModal = connect<any, Object, _, _, _, _>(mapStateToProps, { getAllQuaysAction, downloadExcelForShiftsAction })(injectIntl(ExportRangeModalComponent));
