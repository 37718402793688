// @flow
import type { TBaseAction } from '../../types';
import type { TGangLB, TGangOM } from '../../types/gang.types';

export const GangActions = {
  createGangLB: 'GANG_ACTIONS.CREATE_LB',
  updateGangLB: 'GANG_ACTIONS.UPDATE_LB',
  createGangOM: 'GANG_ACTIONS.CREATE_OM',
  updateGangOM: 'GANG_ACTIONS.UPDATE_OM',
  deleteGangLB: 'GANG_ACTIONS.DELETE_GANG_LB',
  deleteGangOM: 'GANG_ACTIONS.DELETE_GANG_OM',
  getGangs: 'GANG_ACTIONS.GET_GANGS',
  getGangsEdit: 'GANG_ACTIONS.GET_GANGS_EDIT',
  receivedGetGangs: 'GANG_ACTIONS.RECEIVED_GET_GANGS',
  receivedLastModifiedGangs: 'GANG_ACTIONS.RECEIVED_LAST_MODIFIED_GANGS',
  receivedPreviousNumberOfGangs: 'GANG_ACTIONS.RECEIVED_PREVIOUS_NUMBER_OF_GANGS',
  receivedEditGangs: 'GANG_ACTIONS.RECEIVED_EDIT_GANGS',
  getGangAssignments: 'GANG_ACTIONS.GET_GANG_ASSIGNMENTS',
  receivedGangAssignments: 'GANG_ACTIONS.RECEIVED_GANG_ASSIGNMENTS',
  handleCreateGangLBResponse: 'GANG_ACTIONS.HANDLE_CREATE_LB',
  handleUpdateGangLBResponse: 'GANG_ACTIONS.HANDLE_UPDATE_LB',
};

export const getGangsEditAction = (shift: number, quay: number): TBaseAction<Object> => ({
  payload: { shift, quays: [quay], edit: true },
  type: GangActions.getGangsEdit,
});

export const getGangsAction = (shift: number, quays: number[]): TBaseAction<Object> => ({
  payload: { shift, quays },
  type: GangActions.getGangs,
});

export const getGangsAssignmentsAction = (shifts: number[], quays: number[], gangs: number[]): TBaseAction<Object> => ({
  payload: { shifts, quays, gangs },
  type: GangActions.getGangAssignments,
});

export const receivedGangAssignmentsAction = (payload: TGangLB[]): TBaseAction<TGangLB[]> => ({
  payload,
  type: GangActions.receivedGangAssignments,
});

export const receivedGangsAction = (payload: TGangLB[]): TBaseAction<TGangLB[]> => ({
  payload,
  type: GangActions.receivedGetGangs,
});

export const receivedLastModifiedGangsAction = (payload: ?string): TBaseAction<?string> => ({
  payload,
  type: GangActions.receivedLastModifiedGangs,
});

export const receivedPreviousNumberOfGangsAction = (payload: ?string): TBaseAction<?string> => ({
  payload,
  type: GangActions.receivedPreviousNumberOfGangs,
});

export const receivedEditGangsAction = (payload: TGangLB[]): TBaseAction<TGangLB[]> => ({
  payload,
  type: GangActions.receivedEditGangs,
});

export const createGangLBAction = (shift: number, quays: number[]): TBaseAction<Object> => ({
  payload: {
    shift,
    quays,
  },
  type: GangActions.createGangLB,
});

export const handleCreateGangLBActionResponse = (payload: TGangLB): TBaseAction<TGangLB[]> => ({
  payload,
  type: GangActions.handleCreateGangLBResponse,
});

export const handleUpdateGangLBActionResponse = (payload: TGangLB): TBaseAction<TGangLB[]> => ({
  payload,
  type: GangActions.handleUpdateGangLBResponse,
});

export const updateGangLBAction = (gang: TGangLB, callback?: Function): TBaseAction<{ callback?: Function, gang: TGangLB }> => ({
  payload: {
    gang,
    callback,
  },
  type: GangActions.updateGangLB,
});

export const createGangOMAction = (gang: TGangOM, callback?: Function): TBaseAction<{ callback?: Function, gang: TGangOM }> => ({
  payload: {
    gang,
    callback,
  },
  type: GangActions.createGangOM,
});

export const deleteGangLBAction = (gang: TGangLB, callback?: Function): TBaseAction<{ callback?: Function, gang: TGangLB }> => ({
  payload: {
    gang,
    callback,
  },
  type: GangActions.deleteGangLB,
});

export const deleteGangOMAction = (gang: TGangOM, callback?: Function): TBaseAction<{ callback?: Function, gang: TGangOM }> => ({
  payload: {
    callback,
    gang,
  },
  type: GangActions.deleteGangOM,
});
