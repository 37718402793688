// @flow
import { ErrorActions } from '../../actions';
import type { TError } from '../../../types/error.types';
import { concatUnique } from '../../../utils/array.utils';

const initialState: TError[] = [];

export const visitExternalReducer = (state: TError[] = initialState, action: Object) => {
  switch (action.type) {
    case ErrorActions.receivedVisitExternalList:
      return [...action.payload];
    case ErrorActions.receivedUpdateVisitExternalList:
      return concatUnique(state, action.payload, 'id');
    default:
      return state;
  }
};
