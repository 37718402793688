// @flow
import { Col, Row } from 'antd';
import * as React from 'react';
import { CollapseTitle } from '../molecules/visit_detail/CollapseTitle';

type TProps = {
  children?: ?React.Node,
  subTitle?: string | React.Node,
  title: string | React.Node,
};

export function CollapseHeader(props: TProps) {
  return (
    <Row type="flex" justify="start" align="middle">
      <Col xs={6} sm={6} md={4} lg={4} xl={3} xxl={3} className="title-subtitle">
        <CollapseTitle title={props.title} subTitle={props.subTitle || ''} />
      </Col>
      <Col xs={18} sm={18} md={20} lg={20} xl={21} xxl={21}>
        {props.children}
      </Col>
    </Row>
  );
}

CollapseHeader.defaultProps = {
  children: null,
  subTitle: null,
};
