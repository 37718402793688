// @flow
import type { TBaseAction, TContainerVisit } from '../../../types';
import { ContainerVisitActions } from '../../actions';

type TProps = {
  containerVisits: TContainerVisit[],
  lplGroupId: number,
};

export const lplListReducer = (state: TProps[] = [], action: TBaseAction<TProps>) => {
  switch (action.type) {
    case ContainerVisitActions.receivedLPLList:
      // eslint-disable-next-line no-case-declarations
      const listWithoutReceivedLplGroup = state.filter((container) => container.lplGroupId !== action.payload.lplGroupId);
      return [...listWithoutReceivedLplGroup, action.payload];
    default:
      return state;
  }
};
