// @flow
import * as React from 'react';
import { useEffect } from 'react';

type TProps = {
  children: React.Node,
  rate: number,
  refreshAction: Function,
};

export const Refresh = ({ refreshAction, rate, children }: TProps) => {
  useEffect(() => {
    const timerId = setInterval(refreshAction, rate);
    return () => clearInterval(timerId);
  }, [refreshAction, rate]);

  return children;
};
