// @flow
import { fork, take, cancel } from '@redux-saga/core/effects';

const highPrioTask = {};
const lowPrioTask = {};
export const deleteTasks = (action: any) => {
  const { prio, originalType } = action.payload;
  if (prio) {
    delete highPrioTask[originalType];
  }
  delete lowPrioTask[originalType];
};

export const takeLatestWithManualPrio = (patternOrChannel, saga, ...args) =>
  fork(function* () {
    while (true) {
      const action = yield take(patternOrChannel);
      const { type } = action;
      const prio = action.payload?.prio || false;
      if (lowPrioTask[type]) {
        yield cancel(lowPrioTask[type]);
        delete lowPrioTask[type];
      }
      if (prio) {
        if (highPrioTask[type]) {
          highPrioTask[type].cancel();
          delete highPrioTask[type];
        }
        highPrioTask[action.type] = yield fork(saga, ...args.concat(action));
      }

      if (!highPrioTask[type] && !prio) {
        lowPrioTask[type] = yield fork(saga, ...args.concat(action));
      }
    }
  });
