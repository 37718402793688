// @flow
import * as React from 'react';
import { Popover } from 'antd';
import ReactDOM from 'react-dom';
import { INITIAL_POSITION } from './hooks/useNodePosition';
import type { TElearningProps } from './Elearning';
import { usePortal } from './hooks/usePortal';
import { ElearningPopover } from './ElearningPopover';
import type { TElearningPopover } from '../../../types';

export type TElearningBadgeProps = TElearningProps & {
  anchor: HTMLElement,
  content: TElearningPopover,
  position: [number, number],
};

/**
 * This is the actual (?) question mark badge. It takes the position as an argument but renders
 * itself via a React.Portal. It uses a hook usePortal to manage the lifecycle of its HTML element.
 *
 * @see Elearning
 *
 * @param props
 * @returns {React$Portal}
 * @constructor
 */
export const ElearningBadge = (props: TElearningBadgeProps) => {
  const [top, left] = props.position;
  const portalElement = usePortal();
  const style = props.position === INITIAL_POSITION ? {} : { top, left };
  const content = props.content || {};

  return ReactDOM.createPortal(
    <Popover placement="bottom" trigger="hover" title={content.title || '...'} content={<ElearningPopover {...props} />}>
      <div className="elearningbadge" style={style}>
        <div className="elearningbadge__chest">
          <span className="elearningbadge__content">?</span>
        </div>
      </div>
    </Popover>,
    portalElement,
  );
};
