// @flow
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

export const WEEKDAYS_MESSAGES = defineMessages({
  monday: {
    id: 'date.weekdays.monday',
    defaultMessage: 'monday',
  },
  tuesday: {
    id: 'date.weekdays.tuesday',
    defaultMessage: 'tuesday',
  },
  wednesday: {
    id: 'date.weekdays.wednesday',
    defaultMessage: 'wednesday',
  },
  thursday: {
    id: 'date.weekdays.thursday',
    defaultMessage: 'thursday',
  },
  friday: {
    id: 'date.weekdays.friday',
    defaultMessage: 'friday',
  },
  saturday: {
    id: 'date.weekdays.saturday',
    defaultMessage: 'saturday',
  },
  sunday: {
    id: 'date.weekdays.sunday',
    defaultMessage: 'sunday',
  },
});

export const WEEKDAYS_FORMATTED = {
  monday: <FormattedMessage id="date.weekdays.monday" defaultMessage="monday" />,
  tuesday: <FormattedMessage id="date.weekdays.tuesday" defaultMessage="tuesday" />,
  wednesday: <FormattedMessage id="date.weekdays.wednesday" defaultMessage="wednesday" />,
  thursday: <FormattedMessage id="date.weekdays.thursday" defaultMessage="thursday" />,
  friday: <FormattedMessage id="date.weekdays.friday" defaultMessage="friday" />,
  saturday: <FormattedMessage id="date.weekdays.saturday" defaultMessage="saturday" />,
  sunday: <FormattedMessage id="date.weekdays.sunday" defaultMessage="sunday" />,
};
