// @flow
import { Button, Col, Row, Tooltip } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ArrowDownOutlined, ArrowUpOutlined, CheckOutlined, ExclamationCircleOutlined, FilterFilled, FilterOutlined } from '@ant-design/icons';
import { VISIT_STATUS_CSS_ROW_MAP } from '../../../constants/visitStatus.constants';
import { updateBargeClerkVisitList } from '../../../store/handlers/visits.handler';
import { formatDateTime, formatTimeOrNull, humanShortTimeStamp } from '../../../utils/dates.utils';
import { AssignGangModal } from '../../atoms/modals/AssignGangModal';
import { Socket } from '../../atoms/Socket';
import type { TPageWithFilter, TVisit, TVisitBargeClerkOverview, TVisitFilter } from '../../../types';
import { Table } from '../../atoms/Tables/Table';
import { COLORS } from '../../../constants/style.constants';
import { getBargeClerkPageAction, getSpecialContainerFilterAction, getStatusFilterAction } from '../../../store/actions/barge_clerk.actions';
import { FilterWithSearch } from '../../atoms/Tables/filters/FilterWithSearch';
import { FILTER_TYPES } from '../../../constants/filters.constants';
import { FilterWithSearchAndObjects } from '../../atoms/Tables/filters/FilterWithSearchAndObjects';
import {
  bargeNameAbbr,
  gangRenderer,
  prevNextQuayRenderer,
  renderATAOPS,
  renderPriority,
  renderQuay,
  renderStatus,
  specialContainers,
  talMismatchOrEmpty,
  tosSquare,
  tosSquareSide,
} from '../../atoms/renderers';
import { FilterBoolean } from '../../atoms/Tables/filters/FilterBoolean';
import { Filter } from '../../atoms/Tables/filters/Filter';
import { FilterRange } from '../../atoms/Tables/filters/FilterRange';
import { ContextMenu } from '../../molecules/barge_clerks/ContextMenu';
import { ContactInfoModal } from '../../molecules/modals/ContactInfoModal';
import { SPECIAL_CONTAINER_TYPES } from '../../../constants/visit.constants';
import { SITE_PATHS } from '../../../config/api.constants';
import { createPath } from '../../../utils/routing.utils';
import { IdleTimeReasonModal } from '../../atoms/modals/IdleTimeReasonModal';
import { CancelModal } from '../../atoms/modals/CancelModal';
import { OPSModal } from '../../molecules/modals/OPSModal';
import { ATAModal } from '../../molecules/modals/ATAModal';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { WarningRenderer } from '../../molecules/WarningRenderer/WarningRenderer';
import { Elearning } from '../../atoms/elearning';
import { refreshAction } from '../../../store/actions/socket.actions';
import { availableVisitAction, getBargeFilterAction, getBtsIdFilterAction, priorityVisitAction } from '../../../store/actions';
import { formatAscDesc, pageToPageRequest } from '../../../utils/format.utils';
import { getHash } from '../../../utils/general.utils';

type TProps = {
  availableVisitAction: typeof availableVisitAction,
  clearFilter: Function,
  getBargeClerkPageAction: typeof getBargeClerkPageAction,
  getBargeFilterAction: typeof getBargeFilterAction,
  getBtsIdFilterAction: typeof getBtsIdFilterAction,
  getSpecialContainerFilterAction: typeof getSpecialContainerFilterAction,
  getStatusFilterAction: typeof getStatusFilterAction,
  handleSearch: Function,
  page: TPageWithFilter<TVisit, TVisitFilter>,
  priorityVisitAction: typeof priorityVisitAction,
  refreshAction: typeof refreshAction,
  updateData: Function,
  url: Object,
};

export function VisitListTable(props: TProps) {
  const [activeMenuIndex, setActiveMenuIndex] = useState<number>(-1);
  const [assignModalVisible, setAssignModalVisible] = useState<boolean>(false);
  const [ataModalVisible, setAtaModalVisible] = useState<boolean>(false);
  const [cancelModalVisible, setCancelModalVisible] = useState<boolean>(false);
  const [idleReasonModalVisible, setIdleReasonModalVisible] = useState<boolean>(false);
  const [isBargeOpen, setIsBargeOpen] = useState<boolean>(false);
  const [isBtsIdOpen, setIsBtsIdOpen] = useState<boolean>(false);
  const [isSpecialContainerFilterOpen, setIsSpecialContainerFilterOpen] = useState<boolean>(false);
  const [isStatusFilterOpen, setIsStatusFilterOpen] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [opsModalVisible, setOpsModalVisible] = useState<boolean>(false);
  const [visit, setVisit] = useState<?TVisit>(null);
  const [socketParameters, setSocketParameters] = useState(undefined);

  function getSortOrder(key: string) {
    const { sort } = props.page.data;
    if (sort && sort.by && sort.by.indexOf(key) > -1) {
      return formatAscDesc(sort.order);
    }

    return null;
  }

  const getQuays = useCallback(() => {
    const { ownQuay, otherQuay } = props.url;

    let ownQuays = ownQuay || [];
    let otherQuays = otherQuay || [];

    ownQuays = ownQuays.map((q) => parseInt(q, 10));
    otherQuays = otherQuays.map((q) => parseInt(q, 10));

    return [...ownQuays, ...otherQuays];
  }, [props]);

  const getShifts = useCallback(() => {
    const { otherShift } = props.url;
    let { ownShift } = props.url;
    let otherShifts = otherShift || [];

    ownShift = parseInt(ownShift, 10);
    otherShifts = otherShifts.map((s) => parseInt(s, 10));

    return [ownShift, ...otherShifts];
  }, [props]);

  useEffect(() => {
    setSocketParameters({
      quayIds: getQuays(),
      shiftIds: getShifts(),
      getBargeClerkPageAction: props.getBargeClerkPageAction,
      pageRequest: pageToPageRequest(props.page),
    });
  }, [props]);

  const socketCallback = (message, socketParameter) => props.refreshAction(message, updateBargeClerkVisitList(socketParameter));

  if (!props.page.data) return null;

  const columns = [
    {
      align: 'center',
      dataIndex: 'idleTimeRegistered',
      key: 'idleTimeRegistered',
      render: (text: string, visit: TVisit) => <WarningRenderer selectedVisit={visit} />,
      width: 50,
      className: 'no-padding-td',
      title: <Elearning contentKey="LB_OVERVIEW_WARNINGS_COLUMN" parentTagName="th" />,
    },
    {
      align: 'center',
      dataIndex: 'priority',
      // eslint-disable-next-line react/no-unused-prop-types
      filterDropdown: ({ confirm }: { confirm: Function }) => (
        <FilterBoolean confirm={confirm} falseName="Non-prio" trueName="Prio" filterType={FILTER_TYPES.BARGE_CLERK} filterProp="priority" search={props.handleSearch} />
      ),
      filterIcon: () => {
        if (props.page.filter && props.page.filter.priority.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.priority.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.priority.length ? 'filtered' : ''} />;
      },
      key: 'priority',
      render: renderPriority,
      title: <FormattedMessage id="organisms.visits.visit_list.priority" defaultMessage="Prio." />,
      sorter: true,
      sortOrder: getSortOrder('priority'),
      width: 100,
    },
    {
      align: 'left',
      dataIndex: 'btsId',
      key: 'btsId',
      title: <FormattedMessage id="organisms.visits.visit_list.bts_id_title" defaultMessage="BTS ID" />,
      filterDropdown: ({ confirm }) => (
        <FilterWithSearch filterType={FILTER_TYPES.BARGE_CLERK} filterProp="btsId" search={props.handleSearch} confirm={confirm} dataAction={props.getBtsIdFilterAction} visible={isBtsIdOpen} />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsBtsIdOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.btsId.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.btsId && props.page.filter.btsId.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.btsId && props.page.filter.btsId.length ? 'filtered' : ''} />;
      },
    },
    {
      align: 'left',
      className: 'relative',
      dataIndex: 'bargeName',
      key: 'bargeName',
      sorter: true,
      title: <FormattedMessage key="test" id="organisms.visits.visit_list.barge_title" defaultMessage="Barge" />,
      sortOrder: getSortOrder('bargeName'),
      filterDropdown: ({ confirm }) => (
        <FilterWithSearchAndObjects
          filterType={FILTER_TYPES.BARGE_CLERK}
          filterProp="barge"
          search={props.handleSearch}
          confirm={confirm}
          visible={isBargeOpen}
          dataAction={props.getBargeFilterAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsBargeOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.barge.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.barge.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.barge.length ? 'filtered' : ''} />;
      },
      render: (name, visit: TVisitBargeClerkOverview) =>
        bargeNameAbbr({
          name: visit.bargeName,
          tosName: visit.bargeTosName,
        }),
    },
    {
      align: 'left',
      dataIndex: 'status',
      key: 'status',
      render: renderStatus,
      sorter: false,
      title: (
        <>
          <Elearning contentKey="LB_OVERVIEW_STATUS_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.status_bts_title" defaultMessage="Status" />
        </>
      ),
      filterDropdown: ({ confirm }) => (
        <Filter
          filterType={FILTER_TYPES.BARGE_CLERK}
          filterProp="status"
          search={props.handleSearch}
          parseEnum={renderStatus}
          confirm={confirm}
          visible={isStatusFilterOpen}
          dataAction={props.getStatusFilterAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsStatusFilterOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.status.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.status.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.status.length ? 'filtered' : ''} />;
      },
      width: 90,
    },
    {
      align: 'left',
      key: 'quayRequestedName',
      render: (visit: TVisitBargeClerkOverview) => renderQuay({ name: visit.quayRequestedName }),
      sorter: true,
      sortOrder: getSortOrder('quayRequestedName'),
      title: <FormattedMessage id="organisms.visits.visit_list.quay_requested_title" defaultMessage="Quay requested" />,
      width: 115,
    },
    {
      align: 'left',
      key: 'quayPlannedName',
      render: (visit: TVisitBargeClerkOverview) => renderQuay({ name: visit.quayPlannedName }),
      sorter: true,
      sortOrder: getSortOrder('quayPlannedName'),
      title: <FormattedMessage id="organisms.visits.visit_list.quay_planned_title" defaultMessage="Quay planned" />,
      width: 87,
    },
    {
      align: 'center',
      render: gangRenderer,
      title: (
        <>
          <Elearning contentKey="LB_OVERVIEW_GANGS_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.visits.visit_list.gang" defaultMessage="Crane gangs" />
        </>
      ),
      width: 87,
    },
    {
      align: 'center',
      dataIndex: 'shift',
      title: <FormattedMessage id="organisms.barge_clerk.visit_list.shift" defaultMessage="Shift" />,
    },
    {
      align: 'left',
      key: 'estimatedTimeArrival',
      render: (text: string, visit: TVisit) => formatTimeOrNull(visit.estimatedTimeArrival),
      sorter: true,
      sortOrder: getSortOrder('estimatedTimeArrival'),
      title: (
        <div>
          <FormattedMessage id="organisms.barge_clerk.barge_clerk_list.eta_title" defaultMessage="ETA" />
        </div>
      ),
      filterDropdown: () => <FilterRange filterType={FILTER_TYPES.BARGE_CLERK} filterProp="estimatedTimeArrival" search={props.handleSearch} />,
      filterIcon: () => {
        if (props.page.filter && props.page.filter.estimatedTimeArrival.from) {
          return <FilterFilled className={props.page.filter && props.page.filter.estimatedTimeArrival.from ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.estimatedTimeArrival.from ? 'filtered' : ''} />;
      },
    },
    {
      align: 'left',
      // the data is still etaRtaOpsAta but the sort key for the BE is etaRtaOps
      dataIndex: 'etaRtaOpsAta',
      key: 'etaRtaOps',
      title: (
        <div>
          <Elearning contentKey="LB_OVERVIEW_RTA_OPT_ATA_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.barge_clerks.visit_list.rta" defaultMessage="RTA" />
          <br />
          <FormattedMessage id="organisms.barge_clerks.visit_list.ops" defaultMessage="OPT" />
        </div>
      ),
      sorter: true,
      sortOrder: getSortOrder('etaRtaOps'),
      render: renderATAOPS,
      width: 140,
      // eslint-disable-next-line react/no-unused-prop-types
      filterDropdown: () => <FilterRange filterType={FILTER_TYPES.BARGE_CLERK} filterProp="rtaOpsAta" search={props.handleSearch} />,
      filterIcon: () => {
        if (props.page.filter && props.page.filter.rtaOpsAta.from) {
          return <FilterFilled className={props.page.filter && props.page.filter.rtaOpsAta.from ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.rtaOpsAta.from ? 'filtered' : ''} />;
      },
    },
    {
      align: 'center',
      dataIndex: 'available',
      key: 'available',
      title: <FormattedMessage id="organisms.barge_clerks.visit_list.available" defaultMessage="TBS" />,
      filterDropdown: ({ confirm }: { confirm: Function }) => (
        <FilterBoolean
          confirm={confirm}
          filterType={FILTER_TYPES.BARGE_CLERK}
          filterProp="available"
          trueName={<FormattedMessage id="organisms.barge_clerks.available" defaultMessage="Available" />}
          falseName={<FormattedMessage id="organisms.barge_clerks.not_available" defaultMessage="Non-available" />}
          search={props.handleSearch}
        />
      ),
      filterIcon: () => {
        if (props.page.filter && props.page.filter.available.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.available.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.available.length ? 'filtered' : ''} />;
      },
      render: (text, record) => (record.available ? <CheckOutlined /> : null),
    },
    {
      align: 'left',
      title: (
        <span>
          <ArrowDownOutlined style={{ color: COLORS.GREEN }} />
          <ArrowUpOutlined style={{ color: COLORS.RED, marginRight: 4 }} />
          <FormattedMessage id="organisms.barge_clerk.visit_list.total" defaultMessage="Total" />
        </span>
      ),
      children: [
        {
          align: 'left',
          className: 'bts-number-of-containers',
          dataIndex: 'numberOfContainersToDo',
          key: 'numberOfContainersToDo',
          render: (val: number, record: TVisit) => `${record.numberOfContainers - record.numberOfContainersDone}` || '',
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.todo" defaultMessage="To do" />,
        },
        {
          align: 'left',
          className: 'bts-number-of-containers',
          dataIndex: 'numberOfContainers',
          key: 'numberOfContainers',
          render: (val, record) => {
            if (val) {
              if (record.previousTal !== null) {
                return (
                  <Tooltip
                    title={
                      <div>
                        <FormattedMessage id="organisms.barge_clerk.visit_list.previous_tal" defaultMessage="Previous TAL: {tal}" values={{ tal: `${record.previousTal}` }} />
                        <br />
                        <br />
                        {formatDateTime(moment(record.previousTalChange))}
                      </div>
                    }
                  >
                    <span className="no-padding-td"> {val} </span>
                    <span className="human-text-history total-hours-ago">{humanShortTimeStamp(moment(record.previousTalChange))}</span>
                  </Tooltip>
                );
              }
              return `${val}`;
            }
            return '';
          },
          title: <FormattedMessage id="organisms.barge_clerk.visit_list.total.abbr" defaultMessage="Tot." />,
        },
      ],
    },
    {
      align: 'left',
      title: (
        <span>
          <ArrowDownOutlined style={{ color: COLORS.GREEN, marginRight: 4 }} />
          <FormattedMessage id="organisms.barge_clerk.visit_list.tosout" defaultMessage="Unload (TAL)" />
        </span>
      ),
      children: [
        {
          className: 'no-padding-td',
          dataIndex: 'numberOfContainersIn',
          key: 'numberOfContainersInSide',
          render: tosSquareSide,
          width: 20,
        },
        {
          dataIndex: 'numberOfContainersIn',
          key: 'numberOfContainersIn',
          title: (
            <div style={{ padding: 8, overflow: 'auto', width: '120px' }}>
              <div style={{ float: 'left' }}>
                <FormattedMessage id="organisms.barge_clerk.visit_list.empty" defaultMessage="Empty" />
              </div>
              <div style={{ float: 'right' }}>
                <FormattedMessage id="organisms.barge_clerk.visit_list.full" defaultMessage="Full" />
              </div>
            </div>
          ),
          width: 120,
          className: 'no-padding-td',
          render: (text: string, record: TVisit) =>
            tosSquare(
              {
                mismatch: record.talMismatchInTwentyFtEmpty,
                value: record.numberOfContainersInTwentyFtEmpty,
              },
              {
                mismatch: record.talMismatchInTwentyFtFull,
                value: record.numberOfContainersInTwentyFtFull,
              },
              record.numberOfContainersIn,
              {
                mismatch: record.talMismatchInFortyFtEmpty,
                value: record.numberOfContainersInFortyFtEmpty,
              },
              {
                mismatch: record.talMismatchInFortyFtFull,
                value: record.numberOfContainersInFortyFtFull,
              },
            ),
        },
      ],
    },
    {
      align: 'left',
      title: (
        <span>
          <Elearning contentKey="LB_OVERVIEW_DISCHARGE_LOAD_TAL_COLUMN" parentTagName="th" />
          <ArrowUpOutlined style={{ color: COLORS.RED, marginRight: 4 }} />
          <FormattedMessage id="organisms.barge_clerk.visit_list.tosin" defaultMessage="Load (TAL)" />
        </span>
      ),
      children: [
        {
          className: 'no-padding-td',
          dataIndex: 'numberOfContainersOut',
          key: 'numberOfContainersOutSide',
          render: tosSquareSide,
          width: 20,
        },
        {
          dataIndex: 'numberOfContainersOut',
          title: (
            <div style={{ padding: 8, overflow: 'auto', width: '120px' }}>
              <div style={{ float: 'left' }}>
                <FormattedMessage id="organisms.barge_clerk.visit_list.empty" defaultMessage="Empty" />
              </div>
              <div style={{ float: 'right' }}>
                <FormattedMessage id="organisms.barge_clerk.visit_list.full" defaultMessage="Full" />
              </div>
            </div>
          ),
          key: 'numberOfContainersOut',
          width: 120,
          className: 'no-padding-td',
          render: (text: string, record: TVisit) =>
            tosSquare(
              {
                mismatch: record.talMismatchOutTwentyFtEmpty,
                value: record.numberOfContainersOutTwentyFtEmpty,
              },
              {
                mismatch: record.talMismatchOutTwentyFtFull,
                value: record.numberOfContainersOutTwentyFtFull,
              },
              record.numberOfContainersOut,
              {
                mismatch: record.talMismatchOutFortyFtEmpty,
                value: record.numberOfContainersOutFortyFtEmpty,
              },
              {
                mismatch: record.talMismatchOutFortyFtFull,
                value: record.numberOfContainersOutFortyFtFull,
              },
            ),
        },
      ],
    },
    {
      align: 'center',
      dataIndex: 'talMismatch',
      render: talMismatchOrEmpty,
      title: (
        <span>
          <FormattedMessage id="organisms.barge_clerk.visit_list.mis" defaultMessage="Mis-" />
          <br />
          <FormattedMessage id="organisms.barge_clerk.visit_list.match" defaultMessage="match" />
          <br />
          <ExclamationCircleOutlined style={{ color: COLORS.RED }} />
        </span>
      ),
      width: 35,
    },
    {
      align: 'center',
      dataIndex: 'overlandedContainers',
      key: 'overlandedContainers',
      sorter: true,
      sortOrder: getSortOrder('overlandedContainers'),
      title: <FormattedMessage id="organisms.visits.visit_list.overlanded_title" defaultMessage="OVL" />,
      width: 20,
      filterDropdown: ({ confirm }) => (
        <FilterBoolean
          confirm={confirm}
          filterType={FILTER_TYPES.VISITS}
          filterProp="overlanded"
          trueName={<FormattedMessage id="organisms.visits.visit_list.overlanded.filter.true" defaultMessage="Overlanded" />}
          falseName={<FormattedMessage id="organisms.visits.visit_list.error.overlanded.false" defaultMessage="No overlanded" />}
          search={props.handleSearch}
        />
      ),
      filterIcon: () => {
        if (props.page.data && props.page.filter && props.page.filter.overlanded && props.page.filter.overlanded.length) {
          return <FilterFilled className={props.page.data && props.page.filter && props.page.filter.overlanded && props.page.filter.overlanded.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.data && props.page.filter && props.page.filter.overlanded && props.page.filter.overlanded.length ? 'filtered' : ''} />;
      },
    },
    {
      align: 'center',
      dataIndex: 'operationalReefer',
      key: 'operationalReefer',
      title: (
        <span>
          <Elearning contentKey="LB_OVERVIEW_SPEC_CONTAINERS_COLUMN" parentTagName="th" />
          <FormattedMessage id="organisms.barge_clerk.visit_list.spec" defaultMessage="Spec." />
          <br />
          <FormattedMessage id="organisms.barge_clerk.visit_list.cont" defaultMessage="Cont." />
        </span>
      ),
      render: specialContainers,
      className: 'no-padding-td',
      width: 64,
      filterDropdown: ({ confirm }) => (
        <Filter
          filterType={FILTER_TYPES.BARGE_CLERK}
          filterProp="specialContainer"
          search={props.handleSearch}
          parseEnum={(val: string) => {
            switch (val) {
              case SPECIAL_CONTAINER_TYPES.IMDG:
                return <FormattedMessage id="organisms.barge_clerks.visit_list.imdg" defaultMessage="Imdg" />;
              case SPECIAL_CONTAINER_TYPES.OPERATIONAL_REEFER:
                return <FormattedMessage id="organisms.barge_clerks.visit_list.operational_reefer" defaultMessage="Operational reefer" />;
              case SPECIAL_CONTAINER_TYPES.OVERSIZE:
                return <FormattedMessage id="organisms.barge_clerks.visit_list.oversize" defaultMessage="Oversize" />;
              default:
                return '';
            }
          }}
          confirm={confirm}
          visible={isSpecialContainerFilterOpen}
          dataAction={props.getSpecialContainerFilterAction}
        />
      ),
      onFilterDropdownVisibleChange: (visible) => {
        setIsSpecialContainerFilterOpen(visible);
      },
      filterIcon: () => {
        if (props.page.filter && props.page.filter.specialContainer.length) {
          return <FilterFilled className={props.page.filter && props.page.filter.specialContainer.length ? 'filtered' : ''} />;
        }
        return <FilterOutlined className={props.page.filter && props.page.filter.specialContainer.length ? 'filtered' : ''} />;
      },
    },
    {
      align: 'left',
      dataIndex: 'prevVisit',
      title: <FormattedMessage id="organisms.barge_clerks.prev_quay" defaultMessage="Prev quay" />,
      render: (text, record: Object) => prevNextQuayRenderer(record, record.previousVisit),
      width: 75,
    },
    {
      align: 'left',
      dataIndex: 'nextVisit',
      title: <FormattedMessage id="organisms.barge_clerks.next_quay" defaultMessage="Next quay" />,
      render: (text, record: TVisit) => prevNextQuayRenderer(record, record.nextVisit),
      width: 75,
    },
    {
      align: 'left',
      dataIndex: 'averageStackIn',
      key: 'averageStackIn',
      title: (
        <span>
          <FormattedMessage id="organisms.barge_clerks.average_abbr" defaultMessage="Avg." />
          <br />
          <FormattedMessage id="organisms.barge_clerks.stack" defaultMessage="Stack" />
          <br />
          <FormattedMessage id="organisms.barge_clerks.in" defaultMessage="in" />
        </span>
      ),
    },
    {
      align: 'left',
      dataIndex: 'averageStackOut',
      key: 'averageStackOut',
      title: (
        <span>
          <FormattedMessage id="organisms.barge_clerks.average_abbr" defaultMessage="Avg." />
          <br />
          <FormattedMessage id="organisms.barge_clerks.stack" defaultMessage="Stack" />
          <br />
          <FormattedMessage id="organisms.barge_clerks.out" defaultMessage="out" />
        </span>
      ),
    },
    {
      key: 'contextMenu',
      title: <Elearning contentKey="LB_OVERVIEW_CONTEXT_MENU_COLUMN" parentTagName="th" />,
      render: (text: string, record: TVisit, index: number) => {
        if (record.ghostVisit === true) {
          return (
            <Tooltip title={<FormattedMessage id="organisms.barge_clerk.visit_list.ghostVisit" defaultMessage="Ghostvisit" />} placement="rightTop">
              <div className="context-wrapper">
                <div className="context-menu">
                  <div className="dot" />
                  <div className="dot" />
                  <div className="dot" />
                </div>
              </div>
            </Tooltip>
          );
        }
        return (
          <ContextMenu
            userRoles={props.userRoles}
            active={activeMenuIndex === index}
            availableVisitAction={props.availableVisitAction}
            setMenuActive={() => setActiveMenuIndex(index)}
            setMenuInactive={() => setActiveMenuIndex(-1)}
            showModal={() => {
              setModalVisible(true);
              setVisit(record);
            }}
            showIdleTimeReasonModal={() => {
              setIdleReasonModalVisible(true);
              setVisit(record);
            }}
            showOpsModal={() => {
              setOpsModalVisible(true);
              setVisit(record);
            }}
            showAtaTimeModal={() => {
              setAtaModalVisible(true);
              setVisit(record);
            }}
            visit={record}
            priorityVisitAction={props.priorityVisitAction}
            showCancelModal={() => {
              setCancelModalVisible(true);
              setVisit(record);
            }}
            showAssignModal={() => {
              setAssignModalVisible(true);
              setVisit(record);
            }}
            url={props.url}
          />
        );
      },
      className: 'context-menu-cell',
    },
  ];

  return (
    <Socket
      topics={[EVENT_TYPES.BARGE_CLERK_ROW, EVENT_TYPES.GANG_PROGRESS_ROW]}
      onMessage={socketCallback}
      socketKey={`BC_VisitListTable.${socketParameters && socketParameters.pageRequest && `${socketParameters.shiftIds}.${socketParameters.quayIds}.${getHash(socketParameters.pageRequest)}`}`}
      socketParameters={socketParameters}
    >
      <Row>
        <Col span={24} style={{ marginBottom: 10, marginTop: 15 }}>
          <Button onClick={props.clearFilter}>
            <FormattedMessage id="organisms.visits.visit_list.clearFilter" defaultMessage="Clear filters" />
          </Button>
        </Col>
      </Row>
      <Table
        className="visit-overview"
        page={props.page}
        pagination
        columns={columns}
        rowKey="uniqueTableKey"
        size="small"
        onChange={props.updateData}
        onRow={(row) => ({
          onClick: () => window.open(`${window.location.origin}/#${createPath(SITE_PATHS.BARGE_CLERK_DETAIL, { id: row.visitId })}`, 'lb-detail'),
          className: `row-status-default ${VISIT_STATUS_CSS_ROW_MAP[row.status] || ''}`,
        })}
        rowClassName={(record) => `row-status-default ${VISIT_STATUS_CSS_ROW_MAP[record.status] || ''}`}
      />
      <IdleTimeReasonModal visit={visit} hideModal={() => setIdleReasonModalVisible(false)} showDialog={idleReasonModalVisible} />
      <ContactInfoModal visit={visit} hideModal={() => setModalVisible(false)} showDialog={modalVisible} />
      <CancelModal hideModal={() => setCancelModalVisible(false)} visible={cancelModalVisible} visit={visit} />
      <OPSModal visible={opsModalVisible} selectedVisit={visit} showModal={() => setOpsModalVisible(true)} hideModal={() => setOpsModalVisible(false)} />
      <ATAModal visible={ataModalVisible} selectedVisit={visit} hideModal={() => setAtaModalVisible(false)} />
      {visit ? <AssignGangModal visit={visit} hideModal={() => setAssignModalVisible(false)} visible={assignModalVisible} /> : null}
    </Socket>
  );
}
