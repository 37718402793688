// @flow
import { notification } from 'antd';
import { defineMessages, IntlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: IntlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  quaysSelectedMessageTitle: {
    id: 'gang.add.select_only_one_quay_title',
    defaultMessage: 'Multiple quays selected',
  },
  quaysSelectedMessage: {
    id: 'gang.add.select_only_one_quay',
    defaultMessage: 'Select only 1 quay to add a gang.',
  },
});

export function GangMoreThenOneQuayNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.error({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.quaysSelectedMessageTitle),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.quaysSelectedMessage),
    });
  });
}
