// @flow
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { TError } from '../../types';
import { humanizeTimeStamp } from '../../utils/dates.utils';
import { CollapseHeader } from './CollapseHeader';
import { checkOrError } from './renderers';
import { Timestamp } from './Timestamp';

type TProps = {
  b2bLink: string,
  errors: TError[],
  subTitle: string,
  timeStamp?: ?string,
  timeStampArchived?: ?string,
  title: string | React.Node,
};

export function ErrorCollapseHeader(props: TProps) {
  const tosErrors = props.errors.filter((e) => e.system === 'TOS').filter((e) => !e.archived);
  const b2bErrors = props.errors.filter((e) => e.system === 'B2B').filter((e) => !e.archived);

  return (
    <CollapseHeader title={props.title} subTitle={props.subTitle}>
      <a className="visit-top-link" href={props.b2bLink} target="_blank" rel="noreferrer">
        <FormattedMessage id="collapse_error_table_header.apply" defaultMessage="Apply" />
      </a>
      &nbsp;
      <div style={{ display: 'inline-block' }}>
        B2B {checkOrError(b2bErrors.length)} <span style={{ marginLeft: 10 }}>TOS {checkOrError(tosErrors.length)}</span>
      </div>
      {props.timeStamp ? (
        <span className="collapse-error-table-header-timestamp">
          {Timestamp(<FormattedMessage id="collapse.error_table.header.timestamp" defaultMessage="last send {duration}" values={{ duration: humanizeTimeStamp(moment(props.timeStamp)) }} />)}
        </span>
      ) : null}
      {props.timeStampArchived ? (
        <span className="collapse-error-table-header-timestamp">
          {Timestamp(
            <FormattedMessage
              id="collapse.error_table.header.timestamp_archived"
              defaultMessage="last archived {duration}"
              values={{ duration: humanizeTimeStamp(moment(props.timeStampArchived)) }}
            />,
          )}
        </span>
      ) : null}
    </CollapseHeader>
  );
}

ErrorCollapseHeader.defaultProps = {
  timeStamp: null,
};
