// @flow
import { startsWith } from 'lodash';
import type { TBaseAction, TLocation } from '../../types';
import { RouterActions } from '../actions/router.actions';

export function routerReducer(state: ?TLocation = null, action: TBaseAction<TLocation>) {
  switch (action.type) {
    case RouterActions.urlChange: {
      return startsWith(action.payload.pathname, '/id_token=') ? state : action.payload;
    }
    default:
      return state;
  }
}
