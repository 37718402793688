// @flow
import { Button, Form, Input } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { connect, useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { SPACING } from '../../../constants/style.constants';
import type { TGlobalState } from '../../../types';
import { isAuthorized } from '../../../utils/authorization.utils';
import { createVisitRemarkAction } from '../../../store/actions/visit_remark.actions';

type TProps = {
  allowedRoles: string[],
  loading: boolean,
  type?: ?string,
  userRoles: string[],
  validateFn?: ?Function,
  visitId?: string,
};

function RemarkForm(props: TProps) {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [form] = useForm();

  const handleSubmitFailed = (errorInfo: any) => {
    const { values } = errorInfo;
    const errors = errorInfo.errorFields.map((errorField) => errorField.errors);

    if (props.validateFn) {
      props.validateFn(errors, values, form);
    }
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (props.validateFn) {
          props.validateFn(null, values, form);
        }
        const { ...message } = values;
        message.type = props.type;
        message.visitId = props.visitId;
        dispatch(createVisitRemarkAction(message));
        form.setFieldsValue({ message: '' });
      })
      .catch((errorInfo) => handleSubmitFailed(errorInfo));
  };

  return (
    <Form form={form} onFinish={handleSubmit} onFinishFailed={(errorInfo) => handleSubmitFailed(errorInfo)}>
      <Form.Item
        name="message"
        rules={[
          {
            required: true,
            message: intl.formatMessage(VALIDATION_MESSAGES.null),
            whitespace: true,
          },
        ]}
        validateTrigger="onSubmit"
        style={{ marginBottom: SPACING.COLLAPSE / 2 }}
      >
        <Input.TextArea autoSize={{ minRows: 10 }} className="textarea" disabled={!isAuthorized(props.allowedRoles, props.userRoles)} />
      </Form.Item>
      <Form.Item>
        <Button
          disabled={!isAuthorized(props.allowedRoles, props.userRoles)}
          type="default"
          htmlType="submit"
          className="btn-default"
          loading={props.loading}
          onBlur={() => {
            form.setFields([
              {
                name: 'message',
                errors: null,
                value: form.getFieldValue('message'),
              },
            ]);
          }}
        >
          <FormattedMessage id="general.submit" defaultMessage="Submit" />
        </Button>
      </Form.Item>
    </Form>
  );
}

RemarkForm.defaultProps = {
  type: null,
  validateFn: null,
};

export const mapStateToProps = (state: TGlobalState) => ({
  userRoles: state.user.bootstrap.profile.roles,
});

export default connect<any, Object, _, _, _, _>(mapStateToProps, {})(injectIntl(RemarkForm));
