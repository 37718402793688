// @flow
import { Button, Checkbox, Input, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { useEffect, useState } from 'react';
import { PLACEHOLDER_MESSAGES } from '../../../../config/messages/forms';
import type { TFilterObject, TGlobalState } from '../../../../types';
import { Loading } from '../../Loading';

type TProps = {
  confirm: Function,
  dataAction?: Function,
  filterProp: string,
  filterType: string,
  intl: intlShape,
  search: Function,
  selectedValues: string[],
  values: TFilterObject[],
  visible: boolean,
};

function FilterWithSearchAndObjectsComponent(props: TProps) {
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [values, setValues] = useState(props.values);
  const [selectedValues, setSelectedValues] = useState(props.selectedValues);

  useEffect(() => {
    if (props.visible && props.dataAction) {
      props.dataAction();
      setLoading(true);
    }
  }, [props.visible]);

  useEffect(() => {
    if (!props.visible || loading) {
      setValues(props.values);
      setLoading(false);
    }
  }, [props.values]);

  useEffect(() => {
    if (!props.visible) {
      setSelectedValues(props.selectedValues);
    }
  }, [props.selectedValues]);

  const handleSearchTextChange = (e: any) => {
    setSearchText(e.target.value);
  };

  const handleSelectChange = (e: any) => {
    let newValues = [...selectedValues];
    if (e.target.checked) {
      newValues.push(e.target.value);
    } else {
      newValues = newValues.filter((item) => item !== e.target.value);
    }

    setSelectedValues(newValues);
  };

  const filter = (): TFilterObject[] => (searchText && searchText !== '' ? (values && values.filter((item) => item.label.toLowerCase().includes(searchText.toLowerCase()))) || [] : values);

  const search = (values) => {
    setSearchText('');
    props.confirm();
    props.search(values || selectedValues, props.filterProp);
  };

  const reset = () => {
    setSelectedValues([]);
    setSearchText('');
    search([]);
  };

  return (
    <div className="custom-filter-dropdown">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="custom-filter-header">
            <Input placeholder={props.intl.formatMessage(PLACEHOLDER_MESSAGES.generic_search)} value={searchText} onChange={handleSearchTextChange} onPressEnter={handleSearchTextChange} />
          </div>
          <div className="custom-filter-content">
            {filter()
              .filter((item) => !!item)
              .map((item) => (
                <Row className="custom-filter-row" key={item.id}>
                  <Checkbox value={item.id} checked={selectedValues.includes(item.id)} onChange={handleSelectChange}>
                    {item.label}
                  </Checkbox>
                </Row>
              ))}
          </div>
        </>
      )}
      <div className="custom-filter-footer">
        <Row>
          <Button type="primary" onClick={() => search()}>
            <FormattedMessage id="filterDropdown.search" defaultMessage="Apply filter" />
          </Button>
        </Row>
        <Row id="reset" className="text-underline" style={{ cursor: 'pointer' }} onClick={reset}>
          <FormattedMessage id="filterDropdown.reset" defaultMessage="Reset" />
        </Row>
      </div>
    </div>
  );
}

const mapStateToProps = (state: TGlobalState, ownProps: TProps) => ({
  selectedValues: (state[ownProps.filterType].page && state[ownProps.filterType].page.filter && state[ownProps.filterType].page.filter[ownProps.filterProp]) || [],
  values: (state[ownProps.filterType].filters && state[ownProps.filterType].filters[ownProps.filterProp]) || [],
});

export const FilterWithSearchAndObjects = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, undefined)(FilterWithSearchAndObjectsComponent));
