// @flow
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Row } from 'antd';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { Socket } from '../../atoms/Socket';
import { getBargeClerkDetailsAction } from '../../../store/actions/barge_clerk.actions';
import { receivedVisitAction } from '../../../store/actions';
import { DetailInfoSection } from '../../molecules/barge_clerks/DetailInfoSection';
import { SPACING } from '../../../constants/style.constants';
import { BargeClerkDetailMap } from '../../atoms/barge_clerks/BargeClerkDetailMap';
import { TALSection } from '../../atoms/barge_clerks/TALSection';
import { NavigationChartPanel } from '../../atoms/barge_clerks/NavigationChartPanel';
import { Remarks } from '../../molecules/barge_clerks/Remarks';
import { BargeClerkDetailInternalErrors } from '../../atoms/barge_clerks/BargeClerkDetailInternalErrors';
import { BargeClerkDetailExternalErrors } from '../../atoms/barge_clerks/BargeClerkDetailExternalErrors';
import { BargeClerkDetailContainers } from '../../molecules/barge_clerks/BargeClerkDetailContainers';
import { BargeClerkContactInfoModal } from '../../molecules/barge_clerks/ContactInfoModal';

export function BargeClerkDetail() {
  const [showContactInfo, setShowContactInfo] = React.useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(receivedVisitAction(null));
    dispatch(getBargeClerkDetailsAction(id));
  }, [getBargeClerkDetailsAction, dispatch, id]);

  const onMessage = React.useCallback(() => {
    dispatch(getBargeClerkDetailsAction(id));
  }, [getBargeClerkDetailsAction, dispatch, id]);

  return (
    <Socket
      topics={[
        EVENT_TYPES.SPECIFIC_VISIT_STREAM.replace(':id', id),
        EVENT_TYPES.SPECIFIC_CONTAINER_STREAM.replace(':id', id),
        EVENT_TYPES.SPECIFIC_ERROR_STREAM.replace(':id', id),
        EVENT_TYPES.SPECIFIC_LPL_REQUESTS_STREAM.replace(':id', id),
      ]}
      onMessage={onMessage}
      socketKey={`Detail.${id}`}
    >
      <Row className="detail-row">
        <Col />
      </Row>
      <Row className="detail-row">
        <Col>
          <DetailInfoSection showContactInfoModal={() => setShowContactInfo(true)} />
        </Col>
      </Row>
      <Row className="detail-row-panel" gutter={SPACING.DP}>
        <Col span={12}>
          <TALSection />
        </Col>
        <Col span={12} style={{ height: 528 }}>
          <BargeClerkDetailMap />
        </Col>
      </Row>

      <Row className="detail-row-panel" id="navigation_chart_wrapper">
        <Col className="navchart-wrapper">
          <NavigationChartPanel />
        </Col>
      </Row>

      <Row style={{ marginTop: 10 }}>
        <Remarks />
      </Row>

      <Row style={{ marginTop: 10 }}>
        <BargeClerkDetailInternalErrors />
      </Row>

      <Row style={{ marginTop: 10 }}>
        <BargeClerkDetailExternalErrors />
      </Row>

      <Row style={{ marginTop: 10 }}>
        <BargeClerkDetailContainers />
      </Row>

      <BargeClerkContactInfoModal showContactInfo={showContactInfo} hideModal={() => setShowContactInfo(false)} />
    </Socket>
  );
}
