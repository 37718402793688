// @flow
import { Menu, Modal } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { LPL_STATUS } from '../../../config/lpl.constants';
import { cancelLPLRequestAction, completeLPLRequestAction, toggleProgressLPLRequestAction } from '../../../store/actions/lpl.actions';
import type { TLPLRequest } from '../../../types/lpl.types';

type TProps = {
  cancelLPLRequestAction: typeof cancelLPLRequestAction,
  completeLPLRequestAction: typeof completeLPLRequestAction,
  request: TLPLRequest,
  setMenuInactive: Function,
  toggleProgressLPLRequestAction: typeof toggleProgressLPLRequestAction,
};

type TState = {
  cancelModalOpen: boolean,
};

class LPLMenuComponent extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { cancelModalOpen: false };
  }

  onMenuItemClick = (e: any) => {
    e.domEvent.stopPropagation();
    const { request, toggleProgressLPLRequestAction, completeLPLRequestAction, setMenuInactive } = this.props;

    switch (e.key) {
      case 'toggle progress':
        toggleProgressLPLRequestAction(request);
        break;
      case 'cancel':
        this.setState({ cancelModalOpen: true });
        // this.props.cancelLPLRequestAction(request);
        break;
      case 'complete':
        completeLPLRequestAction(request);
        break;
      default:
    }

    setMenuInactive();
  };

  onCancelConfirm = () => {
    const { cancelLPLRequestAction, request } = this.props;

    cancelLPLRequestAction(request);
    this.setState({ cancelModalOpen: false });
  };

  onCancelCancel = () => {
    this.setState({ cancelModalOpen: false });
  };

  render() {
    const { request } = this.props;
    const { cancelModalOpen } = this.state;

    const menuItems = [
      {
        key: 'toggle progress',
        label:
          request.status === LPL_STATUS.NEW || request.status === LPL_STATUS.IN_PROGRESS ? <FormattedMessage id="molecules.lpl.context_menu.toggle_progress" defaultMessage="Toggle progress" /> : null,
      },
      {
        key: 'cancel',
        label: request.status === LPL_STATUS.NEW ? <FormattedMessage id="molecules.lpl.context_menu.cancel" defaultMessage="Cancel" /> : null,
      },
      {
        key: 'complete',
        label: request.status === LPL_STATUS.IN_PROGRESS ? <FormattedMessage id="molecules.lpl.context_menu.complete" defaultMessage="Complete" /> : null,
      },
      {
        key: 'undo complete',
        label: request.status === LPL_STATUS.COMPLETED ? <FormattedMessage id="molecules.lpl.context_menu.undo_complete" defaultMessage="Undo complete" /> : null,
      },
    ].filter((menuItem) => menuItem.label !== null);

    return (
      <div>
        <Menu items={menuItems} onClick={this.onMenuItemClick} />
        <Modal
          title={<FormattedMessage id="lpl_menu.cancel.confirm.title" defaultMessage="Cancel LPL Request" />}
          centered
          open={cancelModalOpen}
          onOk={this.onCancelConfirm}
          onCancel={this.onCancelCancel}
          maskClosable
          destroyOnClose
        >
          <p>
            <FormattedMessage id="lpl_menu.cancel.confirm.message" defaultMessage="Are you sure you want to cancel this LPL request?" />
          </p>
        </Modal>
      </div>
    );
  }
}

export const LPLMenu = connect<any, Object, _, _, _, _>(null, {
  cancelLPLRequestAction,
  completeLPLRequestAction,
  toggleProgressLPLRequestAction,
})(LPLMenuComponent);
