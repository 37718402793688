// @flow
import * as React from 'react';
import { Form } from '@ant-design/compatible';

import '@ant-design/compatible/assets/index.css';
import { Checkbox, Input, InputNumber, TimePicker } from 'antd';
import moment from 'moment';
import Icon from '@ant-design/icons';
import { injectIntl, intlShape } from 'react-intl';
import { VALIDATION_MESSAGES } from '../../../../config/messages/validations';
import { timeFormats } from '../../../../utils/format.utils';

export type TProps = {
  cellRenderer: ?Function,
  children: any,
  editable: boolean,
  form: any,
  format?: ?Function,
  formItemProps?: Object,
  initialValue?: any,
  intl: intlShape,
  property: string,
  record: any,
  saveRecordFn: Function,
  style?: Object,
  type: string,
};

type TState = {
  beingEdited: boolean,
  saving: boolean,
  timePickerPopupOpen: boolean,
};

class EditableCellComponent extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      beingEdited: false,
      saving: false,
      timePickerPopupOpen: false,
    };
  }

  componentDidMount() {
    if (this.state.beingEdited && this.input) {
      this.input.focus();
    }
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps() {
    this.setState({
      saving: false,
    });
  }

  componentDidUpdate() {
    if (this.state.beingEdited && this.input) {
      this.input.focus();
    }
  }

  saveValue() {
    let newValue = this.props.form.getFieldValue(this.props.property);

    if (newValue === '') {
      return;
    }

    const newRecord = { ...this.props.record };

    if (this.props.format) {
      newValue = this.props.format(newValue);
    }

    // eslint-disable-next-line no-underscore-dangle
    if (newValue && newValue._isAMomentObject && !this.props.format) {
      newValue = newValue.format(timeFormats.CONFIG_TIME);
    }

    this.setState({
      beingEdited: false,
    });

    if ((this.props.initialValue && newValue === this.props.initialValue) || (!this.props.initialValue && newValue === newRecord[this.props.property])) return;

    this.setState({
      saving: true,
    });

    newRecord[this.props.property] = newValue;
    this.props.saveRecordFn(newRecord);
  }

  validateAndSaveValue(regExp: RegExp) {
    const newValue = this.props.form.getFieldValue(this.props.property);
    if (regExp.test(newValue)) {
      this.saveValue();
    }
  }

  inputFactory() {
    const onKeyDown = (e) => {
      if (e.key === 'Enter') this.saveValue();
    };

    const alphanumeric = /^[a-zA-Z0-9]*$/;

    switch (this.props.type.toLowerCase()) {
      case 'int':
      case 'long':
      case 'number':
        return this.props.form.getFieldDecorator(this.props.property, {
          initialValue: this.props.initialValue || this.props.record[this.props.property],
        })(
          <InputNumber
            onKeyDown={(e) => onKeyDown(e)}
            onBlur={() => this.saveValue()}
            ref={(node) => {
              this.input = node;
            }}
            {...this.props.formItemProps}
          />,
        );
      case 'time':
        return this.props.form.getFieldDecorator(this.props.property, {
          initialValue: this.props.initialValue || moment(this.props.record[this.props.property], timeFormats.CONFIG_TIME),
        })(
          <TimePicker
            format={this.props.formItemProps && this.props.formItemProps.format ? this.props.formItemProps.format : timeFormats.CONFIG_TIME}
            minuteStep={this.props.formItemProps && this.props.formItemProps.minuteStep ? this.props.formItemProps.minuteStep : 15}
            onOpenChange={(open) => (open ? this.setState({ timePickerPopupOpen: true }) : this.setState({ timePickerPopupOpen: false }))}
            onBlur={() => {
              if (!this.state.timePickerPopupOpen) {
                this.saveValue();
              }
            }}
            ref={(node) => {
              this.input = node;
            }}
            {...this.props.formItemProps}
          />,
        );
      case 'boolean':
        return this.props.form.getFieldDecorator(this.props.property, {
          initialValue: this.props.record[this.props.property],
          valuePropName: 'checked',
        })(
          <Checkbox
            onChange={() => this.saveValue()}
            onBlur={() => this.setState({ beingEdited: false })}
            ref={(node) => {
              this.input = node;
            }}
          />,
        );
      case 'text':
        return this.props.form.getFieldDecorator(this.props.property, {
          initialValue: this.props.record[this.props.property],
        })(
          <Input.TextArea
            autosize={{ minRows: 1 }}
            onBlur={() => this.saveValue()}
            ref={(node) => {
              this.input = node;
            }}
          />,
        );
      case 'alphanumeric':
        return this.props.form.getFieldDecorator(this.props.property, {
          initialValue: this.props.initialValue || this.props.record[this.props.property],
          rules: [
            {
              pattern: alphanumeric,
              message: ' ',
              required: false,
            },
          ],
        })(
          <Input
            onPressEnter={() => this.validateAndSaveValue(alphanumeric)}
            onBlur={() => this.validateAndSaveValue(alphanumeric)}
            ref={(node) => {
              this.input = node;
            }}
            {...this.props.formItemProps}
          />,
        );
      case 'string':
      default:
        return this.props.form.getFieldDecorator(this.props.property, {
          initialValue: this.props.initialValue || this.props.record[this.props.property],
          rules: [
            {
              required: true,
              message: this.props.intl.formatMessage(VALIDATION_MESSAGES.null),
            },
          ],
        })(
          <Input
            onPressEnter={() => this.saveValue()}
            onBlur={() => this.saveValue()}
            ref={(node) => {
              this.input = node;
            }}
            {...this.props.formItemProps}
          />,
        );
    }
  }

  renderCell() {
    if (this.state.saving) {
      return <Icon type="loading" theme="outlined" />;
    }

    return this.props.editable ? (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
      <div
        onClick={(e) => {
          this.setState({ beingEdited: true });
          e.stopPropagation();
          e.preventDefault();
        }}
        className="editable-cell"
      >
        {this.renderValue()}
      </div>
    ) : (
      this.renderValue()
    );
  }

  renderValue() {
    return this.props.cellRenderer && this.props.editable ? this.props.cellRenderer(this.props.record) : this.props.children;
  }

  input: any;

  render() {
    return (
      <td>
        {this.state.beingEdited || ((this.props.type === 'BOOLEAN' || this.props.type === 'boolean') && this.props.editable) ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Form>
              <Form.Item style={this.props.style}>{this.inputFactory()}</Form.Item>
            </Form>
          </div>
        ) : (
          this.renderCell()
        )}
      </td>
    );
  }
}

// https://reactjs.org/docs/react-without-es6.html#declaring-default-props (this is the correct way to add default props to a class)
// $FlowFixMe
EditableCellComponent.defaultProps = {
  formItemProps: {},
  initialValue: null,
  format: null,
  style: { margin: 0 },
};

export const EditableCell = injectIntl(Form.create({})(EditableCellComponent));
