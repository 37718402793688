// @flow
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNodePosition } from './hooks/useNodePosition';
import { ElearningBadge } from './ElearningBadge';
import { elearningGetContentAction } from '../../../store/actions';
import type { TElearningPopover } from '../../../types';

export type TElearningBadgePosition = 'left' | 'right';

export type TElearningProps = {
  align?: ?TElearningBadgePosition,
  contentKey?: string,
  parentTagName?: string,
};

/**
 * Main Elearning component. You should only use this one in the rest of the application.
 * Just mix this element in whathever other React element. It does not mix up your layout
 * as it only renders an empty spam.
 *
 * The actual ElearningBadge, which is the round question mark, is in fact overlayed within a
 * React.Portal. Why is this? In order to dynamically place it you need to position it absolutely
 * with only the viewport as reference. As you do not know whether there are parent elements
 * with absolute positioning you need to 'overlay' the badges within the root of the HTML document.
 *
 * For this sake, you can use React.Portals.
 *
 * How to use this component? It has two properties:
 *
 * - contentKey: string, used as contentKey to fetch the dynamic content
 * - parentTagName: ?string, optional tagname. When none is set the immediate parent is taken to position the badge, if not, recurse until the requested tagname is found.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function Elearning(props: TElearningProps) {
  const align = props.align || 'left';
  const [ref, position] = useNodePosition(align, props.parentTagName);

  const dispatch = useDispatch();
  const active = useSelector((state) => state.elearning.active);
  const content: TElearningPopover = useSelector((state) => state.elearning.content[props.contentKey]);

  useEffect(() => {
    if (active) {
      if (props.contentKey) {
        dispatch(elearningGetContentAction(props.contentKey));
      }
    }
  }, [props.contentKey, active]);

  if (active) {
    return (
      <span className="elearning elearning__placeholder" ref={ref}>
        {/* $FlowFixMe */}
        <ElearningBadge align={align} anchor={ref} content={content} position={position} {...props} />
      </span>
    );
  }
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <></>;
}
