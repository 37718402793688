// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'antd';
import { formatDateTime } from '../../../utils/dates.utils';
import { SPACING } from '../../../constants/style.constants';
import { renderQuayPlanned, renderQuayRequested } from '../renderers';

type TProps = {
  visit: Object,
};

export function VisitSummaryExtra(props: TProps) {
  const bargeName = props.visit.barge ? props.visit.barge.name : props.visit.bargeName;
  const quayRequestedName = props.visit.quayPlanned ? props.visit.quayPlanned.name : props.visit.quayPlannedName;
  const quayPlannedName = props.visit.quayRequested ? props.visit.quayRequested.name : props.visit.quayRequestedName;

  return (
    <div className="visit-summary">
      <div style={{ display: 'flex', flexDirection: 'column', marginRight: SPACING.COLLAPSE }}>
        <strong>
          <FormattedMessage id="molecules.modals.lpl_request.barge" defaultMessage="Barge" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.lpl_request.bts_id" defaultMessage="BTS ID" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.lpl_request.quay_planned_requested" defaultMessage="Quay planned/requested" />
        </strong>
        <strong>
          <FormattedMessage id="molecules.modals.lpl_request.rta_eta" defaultMessage="RTA/ETA" />
        </strong>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{bargeName}</span>
        <span>{props.visit.btsId}</span>
        <span>
          <span style={{ marginRight: SPACING.DP }}>{renderQuayPlanned({ quayPlanned: { name: quayPlannedName } })}</span>
          <span>{renderQuayRequested({ quayRequested: { name: quayRequestedName } })}</span>
        </span>
        <span>
          {props.visit.responseTimeArrival ? (
            <span className="timestamp" style={{ marginRight: SPACING.DP }}>
              <Tag color="green">RTA</Tag>
              {formatDateTime(props.visit.responseTimeArrival)}
            </span>
          ) : null}
          {props.visit.estimatedTimeArrival ? (
            <span className="timestamp">
              <Tag>ETA</Tag>
              {formatDateTime(props.visit.estimatedTimeArrival)}
            </span>
          ) : null}
        </span>
      </div>
    </div>
  );
}
