// @flow
import { Button, Form, Input, InputNumber } from 'antd';
import * as React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useForm } from 'antd/es/form/Form';
import { Link } from 'react-router-dom';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { FormStyles } from '../../../theme/form';
import type { TBargeForm } from '../../../types/barge.types';
import { SITE_PATHS } from '../../../config/api.constants';

type TProps = {
  barge?: TBargeForm,
  handleSubmit: Function,
  intl: Object,
};

const selectData = (property: string, data?: TBargeForm) => (data ? data[property] : null);

function BargeForm(props: TProps) {
  const intl = useIntl();
  const [form] = useForm();

  const handleServerErrors = (errors) => {
    const antFormat = [];

    if (errors.eni !== undefined) {
      antFormat.push({ name: 'eni', errors: [`${intl.formatMessage(VALIDATION_MESSAGES.not_unique)} ${errors.eni.message}`], value: form.getFieldValue('eni') });
    }
    if (errors.mmsi !== undefined) {
      antFormat.push({ name: 'mmsi', errors: [`${intl.formatMessage(VALIDATION_MESSAGES.not_unique)} ${errors.mmsi.message}`], value: form.getFieldValue('mmsi') });
    }

    form.setFields(antFormat);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { ...barge } = values;
      if (props.barge) barge.id = props.barge.id;
      props.handleSubmit(barge, handleServerErrors);
    });
  };

  const formCols = FormStyles.vertical;

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          {...formCols}
          name="eni"
          initialValue={selectData('eni', props.barge)}
          rules={[
            {
              len: 8,
              message: intl.formatMessage(VALIDATION_MESSAGES.eni_character_limit),
            },
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
          ]}
          label={<FormattedMessage id="molecules.barges.label.eni" defaultMessage="ENI" />}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="name"
          initialValue={selectData('name', props.barge)}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
          ]}
          label={<FormattedMessage id="molecules.barges.label.name" defaultMessage="Name" />}
        >
          <Input />
        </Form.Item>

        <Form.Item {...formCols} name="tosName" initialValue={selectData('tosName', props.barge)} label={<FormattedMessage id="molecules.barges.label.tos_name" defaultMessage="TOS Name" />}>
          <Input />
        </Form.Item>

        <Form.Item
          // validateStatus="error"
          // help="test"
          {...formCols}
          name="mmsi"
          initialValue={selectData('mmsi', props.barge)}
          label={<FormattedMessage id="molecules.barges.label.mmsi" defaultMessage="MMSI number" />}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="length"
          initialValue={selectData('length', props.barge)}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.number_of_centimeters),
              min: 1,
              max: 1000000,
              type: 'number',
            },
          ]}
          label={<FormattedMessage id="molecules.barges.label.length" defaultMessage="Length (CM)" />}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>
        <Form.Item
          {...formCols}
          name="width"
          initialValue={selectData('width', props.barge)}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.number_of_centimeters),
              min: 1,
              max: 1000000,
              type: 'number',
            },
          ]}
          label={<FormattedMessage id="molecules.barges.label.width" defaultMessage="Width (CM)" />}
        >
          <InputNumber style={{ width: '100%' }} />
        </Form.Item>

        <Form.Item {...formCols}>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </Button>
        </Form.Item>
      </Form>
      <Link to={SITE_PATHS.BARGE_LIST}>
        <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
      </Link>
    </>
  );
}

export default injectIntl(BargeForm);
