import { combineReducers } from 'redux';
import activeReducer from './active.reducer';
import contentReducer from './content.reducer';

const reducer = combineReducers({
  active: activeReducer,
  content: contentReducer,
});

export default reducer;
