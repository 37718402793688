// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { isAuthenticationCachedAction } from '../../store/actions/authentication.actions';
import type { TGlobalState } from '../../types';
import { AuthController } from './AuthController';
import { PageLoader } from './PageLoader';

type TProps = {
  children: React.Node,
  isAuthenticationCached: ?boolean,
  isAuthenticationCachedAction: typeof isAuthenticationCachedAction,
};

class AzureAuthenticationComponent extends React.Component<TProps> {
  componentDidMount() {
    this.props.isAuthenticationCachedAction();
  }

  render() {
    return this.props.isAuthenticationCached != null ? <AuthController isAuthenticationCached={!!this.props.isAuthenticationCached}>{this.props.children}</AuthController> : <PageLoader />;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  isAuthenticationCached: state.user.authenticationCached,
});

export const AzureAuthentication = connect<any, Object, _, _, _, _>(mapStateToProps, { isAuthenticationCachedAction })(AzureAuthenticationComponent);
