// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, Form, Modal, Row, Select } from 'antd';
import { keys } from 'lodash';
import { useForm } from 'antd/es/form/Form';
import { cancelVisitAction } from '../../../store/actions';
import type { TVisit } from '../../../types';
import { VisitSummary } from './VisitSummary';
import { SPACING } from '../../../constants/style.constants';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { CANCEL_REASON_MESSAGES } from '../../../config/messages/cancelReasons';
import { VISIT_STATUS } from '../../../constants/visit.constants';

type TProps = {
  hideModal: Function,
  intl: intlShape,
  visible: boolean,
  visit: TVisit,
};

function CancelModalComponent({ visit, ...props }: TProps) {
  const [form] = useForm();
  const dispatch = useDispatch();

  if (!visit) return null;

  const onSubmit = () => {
    form.validateFields().then((values) => {
      const { reason } = values;
      dispatch(cancelVisitAction(visit, reason ? `${reason}` : null));
      props.hideModal();
    });
  };

  return (
    <Modal
      title={<FormattedMessage id="atoms.modals.cancel_reason.title" defaultMessage="Cancel visit" />}
      centered
      open={props.visible}
      footer={null}
      onOk={props.hideModal}
      onCancel={props.hideModal}
      maskClosable
      width={700}
    >
      <Row type="flex" justify="start" style={{ marginBottom: SPACING.COLLAPSE }}>
        <VisitSummary visit={visit} />
      </Row>
      <Row>
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item
            name="reason"
            initialValue={visit.cancelReason}
            rules={[
              {
                message: props.intl.formatMessage(VALIDATION_MESSAGES.null),
                required: visit.status !== VISIT_STATUS.DECISION_CBPA,
              },
            ]}
          >
            <Select
              placeholder={<FormattedMessage id="modals.cancel.select.reason.placeholder" defaultMessage="Select a reason" />}
              size="large"
              allowClear={visit.status === VISIT_STATUS.DECISION_CBPA}
            >
              {keys(CANCEL_REASON_MESSAGES).map((key) => (
                <Select.Option key={key} value={key}>
                  {props.intl.formatMessage(CANCEL_REASON_MESSAGES[key])}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Form.Item style={{ marginBottom: 0, marginRight: SPACING.COLLAPSE }}>
              <Button type="default" htmlType="submit">
                <FormattedMessage id="general.submit_change" defaultMessage="Submit change" />
              </Button>
            </Form.Item>
            <button className="visit-top-link" onClick={props.hideModal} style={{ padding: 0 }} type="button">
              <FormattedMessage id="general.cancel_change" defaultMessage="Cancel change" />
            </button>
          </div>
        </Form>
      </Row>
    </Modal>
  );
}

export const CancelModal = injectIntl(CancelModalComponent);
