// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { updateLplGroupDetails } from '../../../store/handlers/visits.handler';
import type { TContainerVisit, TGlobalState, TVisit } from '../../../types';
import type { TLPLGroup } from '../../../types/lpl.types';
import { getLPLContainersAction, getLPLContainersSocketAction } from '../../../store/actions';
import { Socket } from '../Socket';
import { DPDetailTable } from './DPDetailTable';

type TProps = {
  getLPLContainersAction: typeof getLPLContainersAction,
  getLPLContainersSocketAction: typeof getLPLContainersSocketAction,
  lplGroup: TLPLGroup,
  lplList: Object,
  visit: TVisit,
};

class EmbeddedTableComponent extends React.Component<TProps> {
  componentDidMount() {
    this.props.getLPLContainersAction(this.props.lplGroup);
  }

  columns = [
    {
      dataIndex: 'containerNumber',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.container_number" defaultMessage="Container nr." />,
      width: 205,
    },
    {
      dataIndex: 'line',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.line" defaultMessage="Line" />,
      width: 205,
    },
    {
      dataIndex: 'containerType',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.type" defaultMessage="Type" />,
      width: 100,
    },
    {
      dataIndex: 'iso',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.iso" defaultMessage="ISO" />,
      width: 100,
    },
    {
      dataIndex: 'loadState',
      render: (text: *, record: *) =>
        record.loadState === 'F' ? (
          <FormattedMessage id="atoms.tables.embedded_container_table.full" defaultMessage="Full" />
        ) : (
          <FormattedMessage id="atoms.tables.embedded_container_table.empty" defaultMessage="Empty" />
        ),
      title: <FormattedMessage id="atoms.tables.embedded_container_table.load_state" defaultMessage="Empty/Full" />,
      width: 100,
    },
    {
      dataIndex: 'lengthFtIn',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.length" defaultMessage="Length (ft)" />,
      width: 100,
    },
    {
      dataIndex: 'heightFtIn',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.height" defaultMessage="Height (ft)" />,
      width: 100,
    },
    {
      dataIndex: 'orderId',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.order_id" defaultMessage="Booking number" />,
      width: 100,
    },
    {
      dataIndex: 'qs',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.qs" defaultMessage="QS" />,
      width: 100,
    },
    {
      dataIndex: 'csc',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.csc" defaultMessage="CSC" />,
      width: 100,
    },
    {
      dataIndex: 'oogHeight',
      title: <FormattedMessage id="atoms.tables.embedded_container_table.oog" defaultMessage="OOG" />,
      render: (text: string, record: TContainerVisit) => (record.oogBack && record.oogHeight && record.oogFront && record.oogLeft && record.oogRight ? 'Y' : 'N'),
      width: 100,
    },
  ];

  render() {
    const data = this.props.lplList.length > 0 && this.props.lplList.filter((obj) => obj.lplGroupId === this.props.lplGroup.id)[0];
    return (
      <Socket
        topics={[EVENT_TYPES.SPECIFIC_LPL_REQUESTS_STREAM.replace(':id', this.props.visit.id), EVENT_TYPES.SPECIFIC_CONTAINER_STREAM.replace(':id', this.props.visit.id)]}
        onMessage={updateLplGroupDetails({ getLPLContainersSocketAction: this.props.getLPLContainersSocketAction, lplGroup: this.props.lplGroup })}
        socketKey={`EmbeddedTableComponent.${this.props.visit.id}`}
      >
        <DPDetailTable columns={this.columns} dataSource={(data && data.containerVisits) || null} rowKey="id" size="small" />
      </Socket>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  lplList: state.containerVisits.lplList,
});

export const EmbeddedTable = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getLPLContainersAction,
  getLPLContainersSocketAction,
})(EmbeddedTableComponent);
