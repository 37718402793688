// @flow
import { Col, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { TShiftQuay } from '../../types/barge_clerk.types';

type TProps = {
  shiftQuay: TShiftQuay,
};

export function TALGrid(props: TProps) {
  const incoming20EmptyTodo = props.shiftQuay.numberOfContainersInTwentyFtEmptyTodoWithSaldo;
  const incoming20FullTodo = props.shiftQuay.numberOfContainersInTwentyFtFullTodoWithSaldo;

  const incoming40EmptyTodo = props.shiftQuay.numberOfContainersInFortyFtEmptyTodoWithSaldo;
  const incoming40FullTodo = props.shiftQuay.numberOfContainersInFortyFtFullTodoWithSaldo;

  const outgoing20EmptyTodo = props.shiftQuay.numberOfContainersOutTwentyFtEmptyTodoWithSaldo;
  const outgoing20FullTodo = props.shiftQuay.numberOfContainersOutTwentyFtFullTodoWithSaldo;

  const outgoing40EmptyTodo = props.shiftQuay.numberOfContainersOutFortyFtEmptyTodoWithSaldo;
  const outgoing40FullTodo = props.shiftQuay.numberOfContainersOutFortyFtFullTodoWithSaldo;

  const incoming20Empty = props.shiftQuay.numberOfContainersInTwentyFtEmptyWithSaldo;
  const incoming20Full = props.shiftQuay.numberOfContainersInTwentyFtFullWithSaldo;

  const incoming40Empty = props.shiftQuay.numberOfContainersInFortyFtEmptyWithSaldo;
  const incoming40Full = props.shiftQuay.numberOfContainersInFortyFtFullWithSaldo;

  const outgoing20Empty = props.shiftQuay.numberOfContainersOutTwentyFtEmptyWithSaldo;
  const outgoing20Full = props.shiftQuay.numberOfContainersOutTwentyFtFullWithSaldo;

  const outgoing40Empty = props.shiftQuay.numberOfContainersOutFortyFtEmptyWithSaldo;
  const outgoing40Full = props.shiftQuay.numberOfContainersOutFortyFtFullWithSaldo;

  return (
    <Row gutter={16} className="tal-table">
      <Col span={12}>
        <h4>
          <FormattedMessage id="atoms.tal_table.incoming" defaultMessage="Incoming" />
        </h4>
        <Row className="divider">
          <Col span={4} />
          <Col span={10} className="vertical-divider">
            <strong>
              <FormattedMessage id="atoms.tal_table.empty" defaultMessage="Empty" />
            </strong>
            <Row>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.to_do" defaultMessage="To do" />
              </Col>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.total" defaultMessage="Tot." />
              </Col>
            </Row>
          </Col>
          <Col span={10} className="tal-table-alignment">
            <strong>
              <FormattedMessage id="atoms.tal_table.full" defaultMessage="Full" />
            </strong>
            <Row>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.to_do" defaultMessage="To do" />
              </Col>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.total" defaultMessage="Tot." />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="divider">
          <Col span={4}>
            <FormattedMessage id="atoms.tal_table.twenty_ft" defaultMessage="20ft" />
          </Col>
          <Col span={10} className="vertical-divider">
            <Row>
              <Col span={12}>{incoming20EmptyTodo}</Col>
              <Col span={12}>{incoming20Empty}</Col>
            </Row>
          </Col>
          <Col span={10} className="tal-table-alignment">
            <Row>
              <Col span={12}>{incoming20FullTodo}</Col>
              <Col span={12}>{incoming20Full}</Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            <FormattedMessage id="atoms.tal_table.forty_ft" defaultMessage="40ft" />
          </Col>
          <Col span={10} className="vertical-divider">
            <Row>
              <Col span={12}>{incoming40EmptyTodo}</Col>
              <Col span={12}>{incoming40Empty}</Col>
            </Row>
          </Col>
          <Col span={10} className="tal-table-alignment">
            <Row>
              <Col span={12}>{incoming40FullTodo}</Col>
              <Col span={12}>{incoming40Full}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col span={12}>
        <h4>
          <FormattedMessage id="atoms.tal_table.outgoing" defaultMessage="Outgoing" />
        </h4>

        <Row className="divider">
          <Col span={4} />
          <Col span={10} className="vertical-divider">
            <strong>
              <FormattedMessage id="atoms.tal_table.empty" defaultMessage="Empty" />
            </strong>
            <Row>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.to_do" defaultMessage="To do" />
              </Col>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.total" defaultMessage="Tot." />
              </Col>
            </Row>
          </Col>
          <Col span={10} className="tal-table-alignment">
            <strong>
              <FormattedMessage id="atoms.tal_table.full" defaultMessage="Full" />
            </strong>
            <Row>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.to_do" defaultMessage="To do" />
              </Col>
              <Col span={12}>
                <FormattedMessage id="atoms.tal_table.total" defaultMessage="Tot." />
              </Col>
            </Row>
          </Col>
        </Row>

        <Row className="divider">
          <Col span={4}>
            <FormattedMessage id="atoms.tal_table.twenty_ft" defaultMessage="20ft" />
          </Col>
          <Col span={10} className="vertical-divider">
            <Row>
              <Col span={12}>{outgoing20EmptyTodo}</Col>
              <Col span={12}>{outgoing20Empty}</Col>
            </Row>
          </Col>
          <Col span={10} className="tal-table-alignment">
            <Row>
              <Col span={12}>{outgoing20FullTodo}</Col>
              <Col span={12}>{outgoing20Full}</Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col span={4}>
            <FormattedMessage id="atoms.tal_table.forty_ft" defaultMessage="40ft" />
          </Col>
          <Col span={10} className="vertical-divider">
            <Row>
              <Col span={12}>{outgoing40EmptyTodo}</Col>
              <Col span={12}>{outgoing40Empty}</Col>
            </Row>
          </Col>
          <Col span={10} className="tal-table-alignment">
            <Row>
              <Col span={12}>{outgoing40FullTodo}</Col>
              <Col span={12}>{outgoing40Full}</Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
