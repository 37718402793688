// @flow
import { Table } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CONTAINER_STATES } from '../../../constants/container.constants';
import { EditableCell, EditableRow } from './EditField';

type TTalList = {
  containerSize: number,
  containerSizeLabel: string,
  direction: string,
  id: number,
  numberOfContainersEmpty: number,
  numberOfContainersFull: number,
  tosNumberOfContainersEmpty: number,
  tosNumberOfContainersFull: number,
  thresholdMismatchBtsTos: number,
};

type TProps = {
  visitId: string,
  talList: TTalList[],
  updateBTS: Function,
};

export function TalList(props: TProps) {
  const messageIdPrefix = 'organisms.containers.tal_list';

  const columns = [
    {
      dataIndex: 'containerSizeLabel',
    },
    {
      align: 'left',
      title: <FormattedMessage id={`${messageIdPrefix}.empty`} defaultMessage="Empty" />,
      children: [
        {
          align: 'left',
          title: 'BTS',
          dataIndex: 'numberOfContainersEmpty',
          editable: true,
        },
        {
          align: 'left',
          title: 'TOS',
          dataIndex: 'tosNumberOfContainersEmpty',
        },
      ],
    },
    {
      align: 'left',
      title: <FormattedMessage id={`${messageIdPrefix}.full`} defaultMessage="Full" />,
      children: [
        {
          align: 'left',
          title: 'BTS',
          dataIndex: 'numberOfContainersFull',
          editable: true,
        },
        {
          align: 'left',
          title: 'TOS',
          dataIndex: 'tosNumberOfContainersFull',
        },
      ],
    },
  ];

  const handleSave = (values: TTalList) => {
    const containerSize = values.containerSize === 20 ? 0 : 1;
    let updateValues = {
      visitId: props.visitId,
      direction: values.direction,
      size: values.containerSize,
    };
    if (values.numberOfContainersEmpty !== props.talList[containerSize].numberOfContainersEmpty) {
      updateValues = {
        ...updateValues,
        amount: values.numberOfContainersEmpty,
        state: CONTAINER_STATES.EMPTY,
      };
    } else if (values.numberOfContainersFull !== props.talList[containerSize].numberOfContainersFull) {
      updateValues = {
        ...updateValues,
        amount: values.numberOfContainersFull,
        state: CONTAINER_STATES.FULL,
      };
    }

    props.updateBTS(updateValues);
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const mapColumns = (cols: Object[]) =>
    cols.map((col) => {
      const colUpdated = col;
      if (colUpdated.children) {
        colUpdated.children = mapColumns(colUpdated.children);
      }
      if (!colUpdated.editable) {
        return colUpdated;
      }
      return {
        ...colUpdated,
        onCell: (record) => ({
          record,
          editable: colUpdated.editable,
          dataIndex: colUpdated.dataIndex,
          handleSave,
        }),
      };
    });

  return <Table className="tal-table" components={components} size="small" bordered columns={mapColumns(columns)} dataSource={props.talList} rowKey="id" pagination={false} />;
}
