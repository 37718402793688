// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { SITE_PATHS } from '../../../config/api.constants';
import { getQuayAction, removeQuayAction } from '../../../store/actions';
import type { TGlobalState, TQuay } from '../../../types';
import { GeneralDetailTable } from '../../atoms/Tables/MDDetailTables/GeneralDetailTableColumns';
import { DetailTemplate } from '../../templates/MasterData/DetailTemplate';

type TProps = {
  getQuayAction: typeof getQuayAction,
  intl: intlShape,
  loading: boolean,
  match: any,
  quay: TQuay,
  removeQuayAction: typeof removeQuayAction,
};

const EXTRA_TRANSLATIONS = defineMessages({
  name: { id: 'quay.name', defaultMessage: 'Name' },
  length: { id: 'quay.length', defaultMessage: 'length' },
  length_meters: { id: 'quay.length_in_meters', defaultMessage: '{number} M' },
  terminal: { id: 'quay.terminal', defaultMessage: 'Terminal' },
  psa_quay: { id: 'quay.psa_quay', defaultMessage: 'PSA quay' },
  general_not_specified: { id: 'general.not_specified', defaultMessage: 'Not specified' },
  general_yes: { id: 'general.yes', defaultMessage: 'yes' },
  general_no: { id: 'general.no', defaultMessage: 'no' },
  geofence: { id: 'quay.geofence', defaultMessage: 'geofence' },
  barge_planning_by_cbpa: { id: 'quay.barge_planning_by_cbpa', defaultMessage: 'Barge planning by CBPA' },
});

class QuayDetail extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getQuayAction(id);
  }

  extractQuayDetails = (quay: TQuay) => {
    return [
      {
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.name),
        value: quay.name ? quay.name : this.props.intl.formatMessage(EXTRA_TRANSLATIONS.general_not_specified),
      },
      {
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.terminal),
        value: quay.terminal ? quay.terminal : this.props.intl.formatMessage(EXTRA_TRANSLATIONS.general_not_specified),
      },
      {
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.length),
        value:
          // Backend has a default value 1 as length.
          quay.length && quay.length > 1
            ? this.props.intl.formatMessage(EXTRA_TRANSLATIONS.length_meters, { number: quay.length / 100 })
            : this.props.intl.formatMessage(EXTRA_TRANSLATIONS.general_not_specified),
      },
      {
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.psa_quay),
        value: this.props.intl.formatMessage(quay.psaQuay ? EXTRA_TRANSLATIONS.general_yes : EXTRA_TRANSLATIONS.general_no),
      },
      {
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.barge_planning_by_cbpa),
        value: this.props.intl.formatMessage(quay.bargePlanningByCBPA ? EXTRA_TRANSLATIONS.general_yes : EXTRA_TRANSLATIONS.general_no),
      },
      {
        label: this.props.intl.formatMessage(EXTRA_TRANSLATIONS.geofence),
        value: this.props.intl.formatMessage(quay.geofence ? EXTRA_TRANSLATIONS.general_yes : EXTRA_TRANSLATIONS.general_no),
      },
    ];
  };

  render() {
    return (
      <DetailTemplate
        backRoute={SITE_PATHS.QUAY_LIST}
        deleteAction={this.props.removeQuayAction}
        editRoute={SITE_PATHS.QUAY_UPDATE}
        entity={this.props.quay}
        headerTitle={`${this.props.quay && this.props.quay.name}`}
        id={this.props.quay && this.props.quay.id}
        loading={this.props.loading}
        menuBlock="quays"
        menuItem="quays-overview"
      >
        {this.props.quay ? <GeneralDetailTable details={this.extractQuayDetails(this.props.quay)} /> : null}
      </DetailTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loading: state.quays.loadingDetail,
  quay: state.quays.detail,
});

export const QuayDetailPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { getQuayAction, removeQuayAction })(QuayDetail));
