// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../types';
import { UnauthorizedPage } from '../pages/Unauthorized';
import { Loading } from './Loading';

export const Authorizer = (requiredRole: string, roles: string[]): boolean => roles.includes(requiredRole);

type TProps = {
  roles: string[],
};
export function Authorization(allowedRoles: Array<string>) {
  return (WrappedComponent: React.ComponentType<any>) => {
    function WithAuthorization(props: TProps) {
      if (props.roles) {
        const checkUserRoles = () => props.roles.map((role: string) => allowedRoles.includes(role)).includes(true);
        return checkUserRoles() ? <WrappedComponent {...props} /> : <UnauthorizedPage />;
      }
      return <Loading />;
    }

    return connect<any, Object, _, _, _, _>((state: TGlobalState) => ({
      roles: state.user.bootstrap.profile.roles,
    }))(WithAuthorization);
  };
}
