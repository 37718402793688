// @flow
import qs from 'qs';
import * as React from 'react';
import moment, { Moment } from 'moment';
import { padStart } from 'lodash';
import type { TCoordinate, TPageRequest, TPageWithFilter, TPaging, TSort, TSortingRequest } from '../types';

export const capitalize = (str: string) => (str ? str[0].toUpperCase() + str.substring(1) : str);
export const formatTableSize = (arr: Object[]) => (arr && arr.length ? `(${arr.length})` : '');
export const timeFormats = {
  MOMENT: 'HH:mmZ',
  TIMEPICKER: 'HH:mm',
  CONFIG_TIME: 'HH:mm',
  GANG: 'HH:mm',
  MOMENT_FULL: 'HH:mm:ss Z',
  MOMENT_UTC: 'HH:mm UTC',
};
export const pagination = (baseUrl: string) => `${baseUrl}?&size=100&page=0`;

export const formatSortUri = (sort: TSort) => {
  let { order } = sort;
  if (sort.order) {
    order = sort.order === 'ascend' ? 'asc' : 'desc';
  }
  return `sort=${sort.by.join()},${order}`;
};

export const formatAscDesc = (order: string) => {
  return order === 'ASC' ? 'ascend' : 'descend';
};

export const formatPagingUri = (paging: TPaging) => `&size=${paging.size}&page=${paging.number}`;

export const formatSortingUri = (sorting: TSortingRequest) => {
  const order = sorting.order === 'ascend' || sorting.order === 'asc' || sorting.order === 'ASC' ? 'asc' : 'desc';
  return `&sort=${sorting.by.join()},${order}`;
};

export const formatUri = (baseUri: string, pageRequest: TPageRequest<any>) => {
  const sortingUri = pageRequest.sorting && pageRequest.sorting.order ? formatSortingUri(pageRequest.sorting) : '';
  const pagingUri = pageRequest.paging ? formatPagingUri(pageRequest.paging) : '';

  return `${baseUri}?${sortingUri}${pagingUri}`;
};

// eslint-disable-next-line
export let pageToPageRequest = (page: TPageWithFilter<any>): TPageRequest<any> => {
  if (!page || !page.data || !page.data.pageable) {
    return undefined;
  }
  return {
    filter: { ...page.filter },
    paging: {
      number: page.data.pageable.pageNumber,
      size: page.data.pageable.pageSize,
    },
    sorting: { ...page.data.sort },
  };
};

export const formatPasteableURL = (pageRequest: TPageRequest<any>) => `?${qs.stringify(pageRequest)}`;

export const formatHours = (duration: Moment) => {
  const formatHourNumber = (n: number) => padStart(Math.abs(n).toString(), 2, '0');

  const mDur = moment.duration(duration);

  return `${formatHourNumber(mDur.hours())}:${formatHourNumber(mDur.minutes())}`;
};

export const formatDuration = (duration: Moment) => {
  const momentDuration = moment.duration(duration);
  const dayString = momentDuration.days() ? `${Math.abs(momentDuration.days()).toString()}d` : '';
  const hourString = momentDuration.hours() || momentDuration.minutes() ? formatHours(momentDuration) : '';

  return `${dayString}${dayString && hourString ? ' ' : ''}${hourString}`;
};

export const positionToLeafletPosition = (position: TCoordinate): Array<number> => [position.lat, position.lon];

export const nl2br = (text: string): React.Node => (
  <>
    {text.split('\n').map((item) => (
      <React.Fragment key={item}>
        {item}
        <br />
      </React.Fragment>
    ))}
  </>
);
