// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Checkbox, Col, Form, FormInstance, FormListFieldData, Input, Row, Select } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { PLACEHOLDER_MESSAGES } from '../../../config/messages/forms';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';

type TProps = {
  form: FormInstance,
  formField: FormListFieldData,
  removeFn: Function,
};

function contactDetail(fieldName: string, intl: IntlShape, type: string) {
  switch (type) {
    case 'EMAIL':
      return (
        <Form.Item
          name={fieldName}
          rules={[
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.email),
              type: 'email',
            },
          ]}
        >
          <Input placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.email)} autoComplete="off" />
        </Form.Item>
      );
    case 'SKYPE':
      return (
        <Form.Item
          name={fieldName}
          rules={[
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.skype),
              required: true,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.skype)} autoComplete="off" />
        </Form.Item>
      );
    case 'MOBILE':
      return (
        <Form.Item
          name={fieldName}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.mobile)} autoComplete="off" />
        </Form.Item>
      );
    case 'PHONE':
    case 'FAX':
      return (
        <Form.Item
          name={fieldName}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
          ]}
        >
          <Input placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.phone)} autoComplete="off" />
        </Form.Item>
      );
    default:
      return null;
  }
}

const selectOptions = [
  {
    label: <FormattedMessage id="skippers.create.options.email" defaultMessage="Email" />,
    key: 'EMAIL',
    value: 'EMAIL',
  },
  {
    label: <FormattedMessage id="skippers.create.options.skype" defaultMessage="Skype" />,
    key: 'SKYPE',
    value: 'SKYPE',
  },
  {
    label: <FormattedMessage id="skippers.create.options.mobile" defaultMessage="Mobile" />,
    key: 'MOBILE',
    value: 'MOBILE',
  },
  {
    label: <FormattedMessage id="skippers.create.options.phone" defaultMessage="Phone" />,
    key: 'PHONE',
    value: 'PHONE',
  },
  {
    label: <FormattedMessage id="molecules.contact_details.contact_detail.select.fax" defaultMessage="Fax" />,
    key: 'FAX',
    value: 'FAX',
  },
];

function ContactDetailRow(props: TProps) {
  const intl = useIntl();
  // form field represents an object that is exposed by the antd api when we create a form list
  const { form, formField, removeFn } = props;
  const contactDetailType = form.getFieldValue(['contactDetails', formField.name, 'type']) || 'EMAIL';

  const handleChangeContactType = () => {
    form.setFields([
      {
        name: ['contactDetails', formField.key, 'value'],
        value: '',
        touched: false,
        validating: false,
        errors: [],
      },
      {
        name: ['contactDetails', formField.key, 'remark'],
        value: '',
        touched: false,
        validating: false,
        errors: [],
      },
      {
        name: ['contactDetails', formField.key, 'contactForErrors'],
        value: '',
        touched: false,
        validating: false,
        errors: [],
      },
    ]);
  };

  return (
    <Row className="form-dynamic-row" gutter={24}>
      <Form.Item {...formField} name={[formField.name, 'id']}>
        <Input placeholder="id" type="hidden" />
      </Form.Item>
      <Col xs={24} sm={24} md={24} lg={5} xl={4} xxl={3}>
        <Form.Item
          {...formField}
          name={[formField.name, 'type']}
          required
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.type_of_contact_detail),
              required: true,
              whitespace: true,
            },
          ]}
          validateTrigger={['onChange', 'onBlur']}
          initialValue={contactDetailType}
        >
          <Select onChange={handleChangeContactType} options={selectOptions} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={7} xl={6} xxl={5}>
        {contactDetail([formField.name, 'value'], intl, contactDetailType)}
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          {...formField}
          name={[formField.name, 'remark']}
          className="form-inline-input"
          rules={[
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
            {
              whitespace: true,
              message: intl.formatMessage(VALIDATION_MESSAGES.blank),
            },
          ]}
        >
          <Input placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.remark)} autoComplete="off" />
        </Form.Item>
      </Col>
      <Col xs={21} sm={21} md={22} lg={16} xl={5} xxl={4}>
        <Form.Item {...formField} name={[formField.name, 'contactForErrors']} validateTrigger={['onChange', 'onBlur']} valuePropName="checked">
          <Checkbox className="contains-error">
            <FormattedMessage id="skippers.create.contact_for_errors" defaultMessage="Contact for errors" />
          </Checkbox>
        </Form.Item>
      </Col>
      <Col xs={3} sm={3} md={2} lg={2} xl={1} xxl={1}>
        <Form.Item>
          <MinusCircleOutlined onClick={removeFn} />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default injectIntl(ContactDetailRow);
