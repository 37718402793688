// @flow
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Tooltip } from 'antd';
import { formatDateTime } from '../../../utils/dates.utils';

type TProps = {
  done: number,
  hasPreviousTal: boolean,
  previousTal: number,
  previousTalChange: string,
  toDo: number,
  total: number,
};

export function Counter(props: TProps) {
  return (
    <Row type="flex" justify="space-between" className="counter-body">
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h4>
          <FormattedMessage id="molecules.counter.to_do" defaultMessage="To do" />
        </h4>
        <p className="text-exploding-out">{props.toDo}</p>
      </Col>
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h4>
          <FormattedMessage id="molecules.counter.done" defaultMessage="Done" />
        </h4>
        <p className="text-exploding-out">{props.done}</p>
      </Col>
      <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h4>
          <FormattedMessage id="molecules.counter.total" defaultMessage="Total" />
        </h4>
        <p className="text-exploding-out">
          {props.hasPreviousTal ? (
            <Tooltip
              title={
                <div>
                  <FormattedMessage id="organisms.barge_clerk.visit_list.previous_tal" defaultMessage="Previous TAL: {tal}" values={{ tal: `${props.previousTal}` }} />
                  <br />
                  <br />
                  {formatDateTime(moment(props.previousTalChange))}
                </div>
              }
            >
              {props.total}
            </Tooltip>
          ) : (
            props.total
          )}
        </p>
      </Col>
    </Row>
  );
}
