// @flow
import * as React from 'react';
import { defineMessages, FormattedMessage, injectIntl, intlShape } from 'react-intl';
import type { TContactDetail } from '../../../../types/index';
import { capitalize } from '../../../../utils/format.utils';
import { MDDetailTable } from './MDDetailTable';

type TProps = {
  contactDetails: TContactDetail[],
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'tables.details.contact_details', defaultMessage: 'Contact details' },
});

const renderType = (text: string) => (
  <span>
    <b>{capitalize(text.toLowerCase())}</b>
  </span>
);

const renderContactForErrors = (text: string, record: TContactDetail) =>
  record.contactForErrors ? <FormattedMessage id="tables.contact_details.columns.contact_for_errors.true" defaultMessage="Contact for errors" /> : '';

const ContactDetailTableProps = [
  {
    dataIndex: 'type',
    render: renderType,
    width: 200,
  },
  {
    dataIndex: 'value',
    render: (text: string) => <span>{text}</span>, // wrap in span for cursor setting in css
    width: 200,
  },
  {
    dataIndex: 'remark',
    render: (text: string) => <span>{text}</span>,
    width: 200,
  },
  {
    dataIndex: 'contactForErrors',
    render: renderContactForErrors,
    width: 200,
  },
];

const contactDetailTable = (props: TProps) => <MDDetailTable columns={ContactDetailTableProps} dataSource={props.contactDetails} title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} />;

export const ContactDetailTable = injectIntl(contactDetailTable);
