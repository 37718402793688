// @flow
import * as React from 'react';
import { Button, Spin } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { EditOutlined } from '@ant-design/icons';
import { SPACING } from '../../../constants/style.constants';
import { VISIT_STATUS } from '../../../constants/visit.constants';
import { selectBargeClerkVisitTimes } from '../../../store/selectors/barge_clerk.selectors';
import { formatDateTime } from '../../../utils/dates.utils';
import { GenericSection } from '../../atoms/barge_clerks/GenericSection';
import { Elearning } from '../../atoms/elearning';
import { Tag } from '../../atoms/Tags';

type TProps = {
  onShowOpsModal: () => void,
  onShowAtaModal: () => void,
};

export function BargeClerkDetailTimes({ onShowAtaModal, onShowOpsModal }: TProps) {
  const times = useSelector(selectBargeClerkVisitTimes);

  return times ? (
    <GenericSection
      content={
        <>
          {times.actualTimeArrival ? (
            <span className="timestamp">
              <Tag color="blue">ATA</Tag>
              {formatDateTime(times.actualTimeArrival)}
            </span>
          ) : null}
          {times.operationsTime ? (
            <span className="timestamp">
              <Tag color="blue">
                <FormattedMessage id="organisms.barge_clerks.visit_list.ops" defaultMessage="OPS" />
              </Tag>
              {formatDateTime(times.operationsTime)}
            </span>
          ) : null}
          {times.responseTimeArrival ? (
            <span className="timestamp">
              <Tag>RTA</Tag>
              {formatDateTime(times.responseTimeArrival)}
            </span>
          ) : null}
          {times.estimatedTimeArrival ? (
            <span className="timestamp">
              <Tag>ETA</Tag>
              {formatDateTime(times.estimatedTimeArrival)}
            </span>
          ) : null}
        </>
      }
      title={
        <>
          <span style={{ marginRight: SPACING.COLLAPSE / 2 }}>ATA/OPT/RTA/ETA</span>
          <Button
            type="default"
            size="small"
            disabled={times.status === VISIT_STATUS.DONE || times.status === VISIT_STATUS.CANCELLED || times.status === VISIT_STATUS.DECISION_CBPA || times.status === VISIT_STATUS.REQUESTED}
            onClick={onShowOpsModal}
          >
            <EditOutlined style={{ marginRight: SPACING.DP / 2 }} />
            <FormattedMessage id="detail-section.adjust_ops" defaultMessage="Adjust OPS" />
          </Button>
          <Button type="default" size="small" style={{ marginLeft: SPACING.COLLAPSE / 2 }} onClick={onShowAtaModal}>
            <Elearning contentKey="LB_DETAIL_ATA_OPT_RTA_ETA" align="right" />
            <EditOutlined style={{ marginRight: SPACING.DP / 2 }} />
            <FormattedMessage id="detail-section.adjust_ata" defaultMessage="Adjust ATA" />
          </Button>
        </>
      }
      contentStyle={{ justifyContent: 'flex-start' }}
    />
  ) : (
    <Spin />
  );
}
