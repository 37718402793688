// @flow
import * as React from 'react';

type TProps = {
  content: React.Node | string,
  contentClassName?: string,
  contentStyle?: ?Object,
  title: React.Node | string,
};

export function GenericSection(props: TProps) {
  return (
    <div className="generic-section">
      <div style={{ fontWeight: 800 }} className="text-menu">
        {props.title}
      </div>
      {/* $FlowFixMe */}
      <div className={`generic-section-content ${props.contentClassName}`} style={props.contentStyle}>
        {props.content}
      </div>
    </div>
  );
}

GenericSection.defaultProps = {
  contentClassName: '',
  contentStyle: null,
};
