// @flow
import { Collapse } from 'antd';
import { first } from 'lodash';
import * as React from 'react';
import type { TVisitRemark } from '../../../types/remark.types';
import { RemarkSection } from '../../organisms/visit_detail/RemarkSection';
import { RemarkHeader } from '../visit_detail/RemarkHeader';
import { Elearning } from '../../atoms/elearning';

type TProps = {
  allowedRoles: string[],
  btsRemark: string,
  contentKey?: string,
  createRemarkAction: Function,
  loading: boolean,
  remarks: TVisitRemark[],
  remarkType: string,
  visitId: string,
};

export function RemarkCollapse(props: TProps) {
  return (
    <Collapse className="collapse section-full-width" bordered={false} defaultActiveKey={['1']}>
      {props.contentKey && <Elearning contentKey={props.contentKey} />}
      <Collapse.Panel header={<RemarkHeader remark={first(props.remarks)} />} className="panel" key="1">
        <div className="collapse-content-wrapper">
          <RemarkSection
            btsRemark={props.btsRemark}
            loading={props.loading}
            remarks={props.remarks}
            createRemarkAction={props.createRemarkAction}
            type={props.remarkType}
            allowedRoles={props.allowedRoles}
            visitId={props.visitId}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
