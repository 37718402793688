// @flow
import type { TAction, TBaseAction, TBTSUpdate, TPageWithFilter, TVisit, TVisitFilter, TVisitPageRequest } from '../../types';
import type { TActualTimeArrival, TOperationsTime } from '../../types/barge_clerk.types';
import type { TAllContactDetails } from '../../types/contact_details.types';

export const VisitActions = {
  actualTimeArrival: 'VISIT_ACTIONS.ACTUAL_TIME_ARRIVAL',
  bollard: 'VISIT_ACTIONS.BOLLARD',
  detail: 'VISIT_ACTIONS.GET',
  detailPageDetails: 'VISIT_ACTIONS.DETAIL_PAGE_DETAILS',
  setAvailableLoading: 'VISIT_ACTIONS.SET_AVAILABLE_LOADING',
  setIdleTimeReason: 'VISIT_ACTIONS.SET_IDLE_TIME_REASON',
  setPriorityLoading: 'VISIT_ACTIONS.SET_PRIORITY_LOADING',
  setProcessedLoading: 'VISIT_ACTIONS.SET_PROCESSED_LOADING',
  page: 'VISIT_ACTIONS.PAGE',
  receivedDetail: 'VISIT_ACTIONS.RECEIVED_DETAIL',
  receivedPage: 'VISIT_ACTIONS.RECEIVED_PAGE',
  updateBTS: 'VISIT_ACTIONS.UPDATE_BTS',
  process: 'VISIT_ACTIONS.PROCESS',
  priority: 'VISIT_ACTIONS.PRIORITY',
  configurationBargeClerk: 'VISIT_ACTIONS.configuration_barge_clerk',
  available: 'VISIT_ACTIONS.AVAILABLE',
  operationsTime: 'VISIT_ACTIONS.OPERATIONS_TIME',
  undoOperationsTime: 'VISIT_ACTIONS.UNDO_OPERATIONS_TIME',
  cancel: 'VISIT_ACTIONS.CANCEL',
  addGang: 'VISIT_ACTIONS.ADD_GANG',
  removeGang: 'VISIT_ACTIONS.REMOVE_GANG',
  getContactDetails: 'VISIT_ACTIONS.GET_CONTACT_DETAILS',
  receivedAvailable: 'VISIT_ACTIONS.RECEIVED_AVAILABLE',
  receivedContactDetails: 'VISIT_ACTIONS.RECEIVED_CONTACT_DETAILS',
  reduceProcessed: 'VISIT_ACTIONS.REDUCE_PROCESSED',
  reducePriority: 'VISIT_ACTIONS.REDUCE_PRIORITY',
};

export const VisitFilterActions = {
  getBarges: 'VISIT_FILTER_ACTIONS.GET_BARGES',
  getBargeOperators: 'VISIT_FILTER_ACTIONS.GET_BARGE_OPERATORS',
  getBtsIds: 'VISIT_FILTER_ACTIONS.GET_BTS_IDS',
  getStatuses: 'VISIT_FILTER_ACTIONS.GET_STATUSES',
  getShifts: 'VISIT_FILTER_ACTIONS.GET_SHIFTS',
  getQuaysRequested: 'VISIT_FILTER_ACTIONS.GET_QUAY_REQUESTED',
  getQuaysPlanned: 'VISIT_FILTER_ACTIONS.GET_QUAY_PLANNED',
  receivedVisitFilter: 'VISIT_FILTER_ACTIONS.RECEIVED_VISIT_FILTER',
};

export const getVisitContactDetailsAction = (payload: TVisit): TBaseAction<TVisit> => ({
  payload,
  type: VisitActions.getContactDetails,
});

export const receivedVisitContactDetailsAction = (payload: TAllContactDetails): TBaseAction<TAllContactDetails> => ({
  payload,
  type: VisitActions.receivedContactDetails,
});

export const setVisitAvailableLoadingAction = (): TAction => ({
  type: VisitActions.setAvailableLoading,
});

export const setVisitPriorityLoadingAction = (): TAction => ({
  type: VisitActions.setPriorityLoading,
});

export const setVisitProcessedLoadingAction = (): TAction => ({
  type: VisitActions.setProcessedLoading,
});

export const addGangVisitAction = (visit: TVisit, gangId: number): TBaseAction<{ gangId: number, visit: TVisit }> => ({
  payload: {
    gangId,
    visit,
  },
  type: VisitActions.addGang,
});

export const removeGangVisitAction = (visit: TVisit, gangId: number): TBaseAction<{ gangId: number, visit: TVisit }> => ({
  payload: {
    gangId,
    visit,
  },
  type: VisitActions.removeGang,
});

export const cancelVisitAction = (visit: TVisit, reason?: ?string): TBaseAction<{ reason: ?string, visit: TVisit }> => ({
  payload: {
    reason,
    visit,
  },
  type: VisitActions.cancel,
});

export const operationsTimeAction = (visit: TVisit, operationsTime: TOperationsTime): TBaseAction<{ operationsTime: TOperationsTime, visit: TVisit }> => ({
  payload: {
    operationsTime,
    visit,
  },
  type: VisitActions.operationsTime,
});

export const undoOperationsTimeAction = (payload: TVisit): TBaseAction<TVisit> => ({
  payload,
  type: VisitActions.undoOperationsTime,
});

export const actualTimeArrivalAction = (visit: TVisit, actualTimeArrival: TActualTimeArrival): TBaseAction<{ actualTimeArrival: TActualTimeArrival, visit: TVisit }> => ({
  payload: {
    actualTimeArrival,
    visit,
  },
  type: VisitActions.actualTimeArrival,
});

export const setIdleTimeReasonAction = (link: string, reason?: ?string): TBaseAction<{ reason: ?string, link: string }> => ({
  payload: {
    link,
    reason,
  },
  type: VisitActions.setIdleTimeReason,
});

export const availableVisitAction = (payload: {
  newAvailable: boolean,
  previousAvailable: boolean,
  link: string,
}): TBaseAction<{ newAvailable: boolean, previousAvailable: boolean, link: string }> => ({
  payload,
  type: VisitActions.available,
});

export const saveBollardsAction = (visit: TVisit, from: number, to: number, responseCallback: Function): TBaseAction<{ from: number, to: number }> => ({
  callback: responseCallback,
  payload: {
    visit,
    from,
    to,
  },
  type: VisitActions.bollard,
});

export const getVisitAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: VisitActions.detail,
});

export const getVisitPageAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitActions.page,
});

export const receivedVisitPageAction = (page: TPageWithFilter<>, prio: boolean, originalType: string): TBaseAction<{ page: TPageWithFilter<TVisit, TVisitFilter> }> => ({
  payload: {
    page,
    prio,
    originalType,
  },
  type: VisitActions.receivedPage,
});

export const receivedVisitAction = (payload?: TVisit | null): TBaseAction<TVisit | null> => ({
  payload: payload || null,
  type: VisitActions.receivedDetail,
});

// Page specific action for page specific saga preventing race condition
export const getDetailsAction = (payload: string): TBaseAction<number> => ({
  payload,
  type: VisitActions.detailPageDetails,
});

export const updateBTSAction = (payload: TBTSUpdate): TBaseAction<TBTSUpdate> => ({
  payload,
  type: VisitActions.updateBTS,
});

export const processVisitAction = (payload: TVisit): TBaseAction<TVisit> => ({
  payload,
  type: VisitActions.process,
});

export const priorityVisitAction = (payload: { newPriority: boolean, previousPriority: boolean, link: string }): TBaseAction<{ newPriority: boolean, previousPriority: boolean, link: string }> => ({
  payload,
  type: VisitActions.priority,
});

export const getStatusVisitPageAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getStatuses,
});

export const getShiftsFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getShifts,
});

export const getQuayRequestedFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getQuaysRequested,
});

export const getQuayPlannedFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getQuaysPlanned,
});

export const getBtsIdFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getBtsIds,
});

export const getBargeFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getBarges,
});

export const getBargeOperatorFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: VisitFilterActions.getBargeOperators,
});

export const receivedVisitFilterAction = (payload: Object): TBaseAction<Object> => ({
  payload,
  type: VisitFilterActions.receivedVisitFilter,
});

export const reduceProcessedAction = (processed: boolean, processedAt: string): TBaseAction<Object> => ({
  payload: {
    processed,
    processedAt,
  },
  type: VisitActions.reduceProcessed,
});

export const reducePriorityAction = (priority: boolean): TBaseAction<Object> => ({
  payload: {
    priority,
  },
  type: VisitActions.reducePriority,
});
