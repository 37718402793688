// @flow
import { Col, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

export function NoInfoAvailable() {
  return (
    <Row className="no-info-available">
      <Col span={24}>
        <p>
          <FormattedMessage id="no_info_available.message" defaultMessage="Please configure the filters to list an overview" />
        </p>
      </Col>
    </Row>
  );
}
