// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { updateLplGroups } from '../../../store/handlers/visits.handler';
import { RequestActionBar } from '../../atoms/empties_yard/RequestActionBar';
import type { TGlobalState, TVisit } from '../../../types';
import type { TLPLGroup } from '../../../types/lpl.types';
import { EditableCell } from '../../atoms/Tables/editables/EditableCell';
import { LPLModal } from '../../atoms/modals/LPLModal';
import { LPL_STATUS } from '../../../constants/lpl.constants';
import { getLPLGroupAction } from '../../../store/actions/lpl.actions';
import { LPLRequestTable } from '../../atoms/Tables/LPLRequestTable';
import { DPDetailTable } from '../../atoms/Tables/DPDetailTable';
import { DPMenu } from '../../molecules/lpl/DPMenu';
import { lplTypeRenderer } from '../../atoms/renderers';
import { Progressbar } from '../../atoms/Progressbar';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { Socket } from '../../atoms/Socket';
import { EmbeddedTable } from '../../atoms/Tables/EmbeddedContainerTable';

type TProps = {
  getLPLGroupAction: typeof getLPLGroupAction,
  groups: TLPLGroup[],
  visit: TVisit,
};

type TState = {
  lplToRequests: Object,
  selectedKeys: string[],
  showLPLModal: boolean,
};

class RequestFormComponent extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      lplToRequests: {},
      selectedKeys: [],
      showLPLModal: false,
    };
  }

  componentDidMount(): void {
    this.props.getLPLGroupAction(this.props.visit);
  }

  onSelectionChange = (selectedRowKeys: string[]) => {
    this.setState({
      selectedKeys: selectedRowKeys,
    });
  };

  onAmountChange = (record: TLPLGroup) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newAmounts = { ...this.state.lplToRequests };
    newAmounts[record.id] = record.lplToRequest;

    this.setState({
      lplToRequests: newAmounts,
    });
  };

  components = {
    body: {
      cell: EditableCell,
    },
  };

  mapColumns = (columns: Object[]): Object[] =>
    columns.map((column) => {
      const mappedColumn = { ...column };

      mappedColumn.onCell = (record: TLPLGroup) => ({
        editable: !record.containerNr && mappedColumn.dataIndex === 'lplToRequest',
        property: 'lplToRequest',
        formItemProps: {
          min: 1,
          max: record.lplToRequest,
        },
        record,
        saveRecordFn: this.onAmountChange,
        type: 'number',
        cellRenderer: () => `${this.state.lplToRequests[record.id] || record.lplToRequest}/${record.lplToRequest}`,
        initialValue: this.state.lplToRequests[record.id],
      });

      return mappedColumn;
    });

  columns = [
    {
      dataIndex: 'lplToRequest',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.amount" defaultMessage="Amount" />,
      width: 120,
    },
    {
      dataIndex: 'customer',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.client" defaultMessage="Client" />,
      width: 180,
    },
    {
      dataIndex: 'lplRequested',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.progress" defaultMessage="Progress" />,
      width: 140,
      render: (text: ?string, record: TLPLGroup) => Progressbar(record.lplRequested, record.amount),
    },
    {
      dataIndex: 'type',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.type" defaultMessage="Type" />,
      render: lplTypeRenderer,
      width: 159,
    },
    {
      dataIndex: 'iso',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.iso" defaultMessage="ISO" />,
      width: 100,
    },
    {
      dataIndex: 'loadState',
      render: (text: *, record: *) =>
        record.loadState === 'F' ? (
          <FormattedMessage id="molecules.empties_yard.empties_yard_table.full" defaultMessage="Full" />
        ) : (
          <FormattedMessage id="molecules.empties_yard.empties_yard_table.empty" defaultMessage="Empty" />
        ),
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.load_state" defaultMessage="Empty/Full" />,
      width: 100,
    },
    {
      dataIndex: 'orderId',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.booking_number" defaultMessage="Booking number" />,
      width: 150,
    },
    {
      dataIndex: 'pod',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.destination" defaultMessage="Destination" />,
      width: 150,
    },
    {
      dataIndex: 'qs',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.qs" defaultMessage="QS" />,
      width: 90,
    },
    {
      dataIndex: 'csc',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.csc" defaultMessage="CSC" />,
      width: 90,
    },
    {
      dataIndex: 'remark',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.remark" defaultMessage="Remark" />,
      width: 202,
    },
    {
      dataIndex: 'lplDone',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.lpl_progress" defaultMessage="LPL progress" />,
      width: 100,
      render: (text: ?string, record: TLPLGroup) => Progressbar(record.lplDone, record.lplRequested),
    },
  ];

  render() {
    const expandedRowRender = (record: TLPLGroup) => (
      <div>
        <h3>
          <FormattedMessage id="embedded_table.requests" defaultMessage="Requests" />
        </h3>
        <div style={{ marginTop: 16 }}>
          <LPLRequestTable group={record} menuFactory={(embeddedRow, setInactiveFn) => <DPMenu request={embeddedRow} setMenuInactive={setInactiveFn} />} />
        </div>
        {record.containerNr ? (
          <>
            <h3>
              <FormattedMessage id="lpl_detail_table.containers" defaultMessage="Containers" />
            </h3>
            <div style={{ marginTop: 16 }}>
              <EmbeddedTable lplGroup={record} visit={this.props.visit} />
            </div>
          </>
        ) : null}
      </div>
    );

    const rowSelection = {
      onChange: this.onSelectionChange,
      selectedRowKeys: this.state.selectedKeys,
      getCheckboxProps: (record: TLPLGroup) => ({
        disabled: !record.lplToRequest,
        checked: this.state.selectedKeys.find((selectedKey) => record.id === selectedKey) && record.lplToRequest,
      }),
    };

    const createLPLRequests = () =>
      this.state.selectedKeys.map((selectedKey) => {
        // lplGroup with selectedkey as id is always present
        // $FlowFixMe
        const amount = this.state.lplToRequests[selectedKey] || this.props.groups.find((group) => group.id === selectedKey).lplToRequest;

        return {
          group: this.props.groups.find((group) => group.id === selectedKey),
          request: {
            amount,
            lplGroupId: parseInt(selectedKey, 10),
            status: LPL_STATUS.NEW,
          },
        };
      });

    return (
      <Socket
        topics={[EVENT_TYPES.SPECIFIC_LPL_REQUESTS_STREAM.replace(':id', this.props.visit.id), EVENT_TYPES.SPECIFIC_CONTAINER_STREAM.replace(':id', this.props.visit.id)]}
        onMessage={updateLplGroups({ getLPLGroupAction: this.props.getLPLGroupAction, visit: this.props.visit })}
        socketKey={`RequestForm.${this.props.visit.id}`}
      >
        <>
          <RequestActionBar showModal={() => this.setState({ showLPLModal: true })} enabled={this.state.selectedKeys.length !== 0} />
          <DPDetailTable
            className="editable-table table-checkbox-aligment-fix"
            columns={this.mapColumns(this.columns)}
            dataSource={this.props.groups}
            bordered
            pagination={false}
            rowClassName={(record, index) => (index % 2 ? 'even-row' : '')}
            components={this.components}
            rowSelection={rowSelection}
            rowKey="id"
            expandedRowRender={expandedRowRender}
            scroll={{ x: 0, y: 0 }}
          />
          <LPLModal
            hideModal={() => this.setState({ showLPLModal: false })}
            visible={this.state.showLPLModal}
            // $FlowFixMe
            lplGroupsAndRequests={createLPLRequests()}
            visit={this.props.visit}
            clearAndHide={() => {
              const { lplToRequests } = this.state;
              this.state.selectedKeys.forEach((selectedKey) => {
                delete lplToRequests[selectedKey];
              });
              this.setState({ showLPLModal: false, selectedKeys: [], lplToRequests });
            }}
          />
        </>
      </Socket>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  groups: state.lpl.lplGroups,
});

export const RequestForm = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getLPLGroupAction,
})(RequestFormComponent);
