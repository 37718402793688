// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { editErrorCodeAction, getErrorCodeAction } from '../../../store/actions/error_code.actions';
import type { TGlobalState, TErrorCode } from '../../../types';
import ErrorCodeForm from '../../organisms/error_codes/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';

type TProps = {
  editErrorCodeAction: typeof editErrorCodeAction,
  errorCode: TErrorCode,
  getErrorCodeAction: typeof getErrorCodeAction,
  intl: intlShape,
  loading: boolean,
  match: any,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.error_code.edit.header.title', defaultMessage: 'Edit {code}' },
});

class ErrorCodeEdit extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getErrorCodeAction(id);
  }

  render() {
    return (
      <EditTemplate
        title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title, { code: this.props.errorCode && this.props.errorCode.code })}
        loading={this.props.loading}
        menuBlock="error-codes"
        menuItem="error-codes-overview"
      >
        <ErrorCodeForm handleSubmit={this.props.editErrorCodeAction} errorCode={this.props.errorCode} />
      </EditTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  errorCode: state.errorCodes.detail,
  loading: state.errorCodes.loadingDetail,
});

export const ErrorCodeEditPage = injectIntl(connect<any, Object, _, _, _, _>(mapStateToProps, { editErrorCodeAction, getErrorCodeAction })(ErrorCodeEdit));
