// @flow
import * as React from 'react';
import { Button, Form, Input, Modal, Table } from 'antd';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useForm } from 'antd/es/form/Form';
import type { TError } from '../../../../types';
import { SPACING } from '../../../../constants/style.constants';
import { formatTableSize } from '../../../../utils/format.utils';
import { TitleSubtitle } from '../../titlesubtitle';
import { SelectedErrorTableColumns } from '../../../atoms/Tables/ErrorTableProps';
import { sendExternalErrorsAction } from '../../../../store/actions';

type TProps = {
  hideModal: Function,
  selectedErrors: TError[],
  show: boolean,
  visitId: number,
};

export function SendModal(props: TProps) {
  const dispatch = useDispatch();
  const [form] = useForm();

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const payload = {
        errors: props.selectedErrors.map((val) => val.id),
        message: values.message ? values.message : null,
      };

      dispatch(sendExternalErrorsAction({ ...payload, visitId: props.visitId }));
      props.form.setFieldsValue({ message: '' });
    });

    props.hideModal();
  };

  return (
    <Modal
      open={props.show}
      title={
        <span className="text-normal">
          <FormattedMessage id="molecules.collapses.external_errors.send_modal" defaultMessage="Send all selected external errors to barge operator" />
        </span>
      }
      onCancel={props.hideModal}
      footer={false}
      maskClosable
      closable
      width={800}
      centered
    >
      <Form form={form} onFinish={handleSubmit}>
        <b style={{ marginBottom: SPACING.COLLAPSE / 2, display: 'block' }}>
          <FormattedMessage id="molecules.collapses.external_errors.send_modal.textarea" defaultMessage="Add comment to email (optional)" />
        </b>
        <Form.Item name="message">
          <Input.TextArea className="textarea" autosize={{ minRows: 4 }} />
        </Form.Item>
        <TitleSubtitle
          style={{ marginBottom: SPACING.COLLAPSE / 2 }}
          subTitle={formatTableSize(props.selectedErrors)}
          title={
            <b>
              <FormattedMessage id="molecules.collapses.external_errors.send_modal.error_list" defaultMessage="Selected external errors" />
            </b>
          }
        />
        <Table dataSource={props.selectedErrors} columns={SelectedErrorTableColumns} bordered pagination={false} size="small" scroll={{ y: 250 }} rowKey="id" />
        <Form.Item style={{ marginBottom: 0, marginTop: SPACING.COLLAPSE }}>
          <Button type="default" htmlType="submit">
            <FormattedMessage id="molecules.collapses.external_errors.send_modal.send" defaultMessage="Send errors" />
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
