// @flow
import moment from 'moment';
import { call, put, takeLatest } from 'redux-saga/effects';
import type { TBaseAction } from '../../types';
import { appApi } from '../../config/api.config';
import { receivedShiftsAction, receivedShiftForAction, ShiftActions } from '../actions/shift.actions';
import { genericErrorHandler } from '../../utils';
import { API_PATHS } from '../../config/api.constants';

export function* forDate(action: TBaseAction<string>): Generator<*, *, *> {
  const dateTime = moment(action.payload).toISOString();

  const response = yield call(appApi.get, `${API_PATHS.V1.SHIFTS_FILTER}?offsetDateTime=${encodeURI(dateTime)}`);

  yield put(receivedShiftForAction(response.data));
}

export function* getAction(id: number): Generator<*, *, *> {
  const response = yield call(appApi.get, `${API_PATHS.V1.SHIFT}/${id}`);

  return response.data;
}

export function* get(): Generator<*, *, *> {
  const response = yield call(appApi.get, API_PATHS.V1.SHIFT);

  yield put(receivedShiftsAction(response.data));
}

export function* ShiftSaga(): Generator<*, *, *> {
  yield takeLatest(ShiftActions.for, genericErrorHandler(forDate));
  yield takeLatest(ShiftActions.get, genericErrorHandler(get));
}
