// @flow
import { Col, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { REMARK_TYPES } from '../../../constants/contact.constants';
import { SPACING } from '../../../constants/style.constants';
import type { TVisitRemark } from '../../../types/remark.types';
import { Remark } from '../../molecules/visit_detail/Remark';
import RemarkForm from '../../molecules/visit_detail/RemarkForm';

type TProps = {
  allowedRoles: string[],
  btsRemark?: ?string,
  loading: boolean,
  remarks: any[],
  style?: Object,
  type?: string,
  validateFn?: ?Function,
  visitId?: string,
};

const renderRemarks = (remarks: TVisitRemark[], btsRemark, type) => {
  const btsRemarkDiv = btsRemark !== null && btsRemark !== '' && type !== REMARK_TYPES.LPL && (
    <div className="remark bts-remark">
      <h2>
        <FormattedMessage id="collapses.bts_remark.title" defaultMessage="BTS remarks" />
      </h2>
      <div className="message">{btsRemark}</div>
    </div>
  );
  const remarkDiv = remarks && remarks.length !== 0 && remarks.map((remark) => <Remark message={remark.message} timestamp={remark.createdAt} username={remark.createdBy} key={remark.id} />);

  const noRemarksDiv = (
    <div className="collapse-remark-empty">
      <FormattedMessage id="organisms.visit_detail.remarksection" defaultMessage="No remarks available" />
    </div>
  );

  return btsRemarkDiv || remarkDiv ? (
    <>
      {btsRemarkDiv}
      {remarkDiv}
    </>
  ) : (
    noRemarksDiv
  );
};

export function RemarkSection(props: TProps) {
  return (
    <Row gutter={SPACING.COLLAPSE} style={props.style}>
      <Col span={12} className="remark-list">
        {renderRemarks(props.remarks, props.btsRemark, props.type)}
      </Col>
      <Col span={12}>
        <RemarkForm loading={props.loading} type={props.type} allowedRoles={props.allowedRoles} validateFn={props.validateFn} visitId={props.visitId} />
      </Col>
    </Row>
  );
}

RemarkSection.defaultProps = {
  btsRemark: null,
  style: {},
  type: '',
  validateFn: null,
};
