// @flow
import { BargeOperatorActions } from '../../actions/barge_operator.actions';
import type { TBargeOperator } from '../../../types';

const initialState: ?TBargeOperator = null;

const reducer = (state: ?TBargeOperator = initialState, action: Object) => {
  switch (action.type) {
    case BargeOperatorActions.receivedDetail:
      return action.payload;

    default:
      return state;
  }
};

export const detailReducer = reducer;
