// @flow
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import qs from 'qs';
import * as React from 'react';
import { connect } from 'react-redux';
import type { Location } from 'react-router';
import { SITE_PATHS } from '../../../config/api.constants';
import { refreshAction } from '../../../store/actions/socket.actions';
import type { TGlobalState, TVisit, TPageWithFilter } from '../../../types';
import { LplListTable } from '../../organisms/lpl_requests/LplListTable';
import { DPTemplate } from '../../templates/DPTemplate';
import {
  getBargeLPLFilterAction,
  getBargeOperatorLPLFilterAction,
  getLPLBtsIdFilterAction,
  getLPLPageAction,
  getQuayPlannedLPLFilterAction,
  getQuayRequestedLPLFilterAction,
  getStatusLplPageAction,
} from '../../../store/actions/lpl.actions';

type TProps = {
  getBargeLPLFilterAction: typeof getBargeLPLFilterAction,
  getBargeOperatorLPLFilterAction: typeof getBargeOperatorLPLFilterAction,
  getLPLBtsIdFilterAction: typeof getLPLBtsIdFilterAction,
  getLPLPageAction: typeof getLPLPageAction,
  getQuayPlannedLPLFilterAction: typeof getQuayPlannedLPLFilterAction,
  getQuayRequestedLPLFilterAction: typeof getQuayRequestedLPLFilterAction,
  getStatusLplPageAction: typeof getStatusLplPageAction,
  loadingPage: boolean,
  // eslint-disable-next-line react/no-unused-prop-types
  location: Location,
  page: TPageWithFilter<TVisit>,
  refreshAction: typeof refreshAction,
};

class LPLList extends React.Component<TProps> {
  componentDidMount() {
    this.parseUrl(this.props);
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps: TProps) {
    if (!isEqual(qs.parse(nextProps.location.search), qs.parse(this.props.location.search))) {
      this.parseUrl(nextProps);
    }
  }

  parseUrl = (props: TProps) => {
    const { location } = props;
    const parsedUrl = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (!isEmpty(parsedUrl)) {
      const newFilter = parsedUrl.filter || {};
      const newSorter = parsedUrl.sorting || null;
      const newPaging = parsedUrl.paging;

      const pageRequest = {
        filter: newFilter,
        paging: newPaging,
        sorting: newSorter,
      };

      this.props.getLPLPageAction(pageRequest);
    } else {
      this.props.getLPLPageAction();
    }
  };

  render() {
    return (
      <DPTemplate menuBlock={SITE_PATHS.LPL_LIST} menuItem={SITE_PATHS.LPL_LIST}>
        <LplListTable {...this.props} />
      </DPTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingPage: state.lpl.loadingPage,
  page: state.lpl.page,
});

export const LPLListPage = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getLPLPageAction,
  getStatusLplPageAction,
  getLPLBtsIdFilterAction,
  getQuayRequestedLPLFilterAction,
  getQuayPlannedLPLFilterAction,
  getBargeLPLFilterAction,
  getBargeOperatorLPLFilterAction,
  refreshAction,
})(LPLList);
