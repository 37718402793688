// @flow
/* skeleton
 * All settings come here
 */
import $script from 'scriptjs';
import { ENV } from './environment';

export const ENVIRONMENT = ENV.DEPLOY_ENV || 'development';
export const LOG_TO_SENTRY = ENV.NODE_ENV === 'production';
export const SENTRY_URL = ENV.REACT_APP_SENTRY_URL;
export const WEBSOCKET_URL = ENV.REACT_APP_WEBSOCKET_URL || '';

// eslint-disable-next-line global-require
export const APPLICATION_VERSION = require('../../package.json').version;

const EXTERNAL_SCRIPTS = [
  // Sentry error reporting.
  'https://cdn.ravenjs.com/3.24.0/raven.min.js',
];

export const scriptLoader = (onLoad: Function) => {
  $script(EXTERNAL_SCRIPTS, 'bundle', () => {
    onLoad();
  });
};
