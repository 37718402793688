// @flow
import { Col, Row } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ClockCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { SITE_PATHS } from '../../../config/api.constants';
import { REMARK_TYPES } from '../../../constants/contact.constants';
import { createLPLRemarkAction } from '../../../store/actions/lpl_remark.actions';
import { updateLplGroups, updateVisitDetail } from '../../../store/handlers/visits.handler';
import type { TVisitRemark } from '../../../types/remark.types';
import { humanizeTimeStamp } from '../../../utils/dates.utils';
import { Progressbar } from '../../atoms/Progressbar';
import { Refresh } from '../../atoms/Refresh';
import { renderETA, renderQuayPlanned, renderQuayRequested, renderRTA, renderStatus } from '../../atoms/renderers';
import { Socket } from '../../atoms/Socket';
import { LPLDetailTable } from '../../atoms/Tables/MDDetailTables/LPLDetailTable';
import { PrioTag } from '../../atoms/Tags';
import { RemarkCollapse } from '../../molecules/collapses/Remarks';
import { CollapseTitle } from '../../molecules/visit_detail/CollapseTitle';
import { ContactInfoModal } from '../../molecules/modals/ContactInfoModal';
import { ROLES } from '../../../constants/roles.constants';
import type { TLPLGroup } from '../../../types/lpl.types';
import { getLPLGroupAction } from '../../../store/actions/lpl.actions';
import type { TGlobalState, TPageWithFilter, TVisit } from '../../../types';
import { formatPasteableURL, pageToPageRequest } from '../../../utils/format.utils';
import { SPACING } from '../../../constants/style.constants';
import { EVENT_TYPES } from '../../../constants/update.constants';

type TProps = {
  createLPLRemarkAction: typeof createLPLRemarkAction,
  getDetailsAction: Function,
  getLPLGroupAction: typeof getLPLGroupAction,
  groups: TLPLGroup[],
  loadingRemarks: boolean,
  page: ?TPageWithFilter,
  remarks: TVisitRemark[],
  visit: TVisit,
};

type TState = {
  showContactInfo: boolean,
};

class LPLDetailComponent extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = {
      showContactInfo: false,
    };
  }

  componentDidMount() {
    this.props.getLPLGroupAction(this.props.visit);
  }

  setContactInfoVisibility = (visible: boolean) => {
    this.setState({
      showContactInfo: visible,
    });
  };

  render() {
    const { visit, getDetailsAction } = this.props;
    const overviewUrl = this.props.page ? SITE_PATHS.LPL_LIST + formatPasteableURL(pageToPageRequest(this.props.page)) : SITE_PATHS.LPL_LIST;

    return visit ? (
      <Socket
        topics={[EVENT_TYPES.SPECIFIC_VISIT_STREAM.replace(':id', visit.id), EVENT_TYPES.LPL_REQUESTS_STREAM.replace(':id', visit.id)]}
        onMessage={updateVisitDetail({ getDetailsAction, visit })}
        socketKey={`LPLDetail.${visit.id}`}
      >
        <Refresh
          rate={60000}
          refreshAction={() => {
            // eslint-disable-next-line react/no-access-state-in-setstate
            this.setState(this.state); // trigger a rerender
          }}
        >
          <Row className="detail-row">
            <Col span="12">
              <Link className="text-black" to={overviewUrl}>
                <LeftOutlined />
                <span className="text-underline">
                  <FormattedMessage id="visit.detail.back" defaultMessage="Back to overview" />
                </span>
              </Link>
            </Col>
          </Row>
          <Row type="flex" justify="center" align="middle" style={{ marginTop: SPACING.COLLAPSE }}>
            <Col span={2}>
              <span className="visit-detail-barge">
                {visit.barge && visit.barge.name ? visit.barge.name : ''}
                {visit.priority ? <PrioTag style={{ marginLeft: 8 }} /> : null}
              </span>
            </Col>
            <Col span={22}>
              <button type="button" className="visit-top-link" onClick={() => this.setContactInfoVisibility(true)}>
                <FormattedMessage id="visit.detail.contactInfo" defaultMessage="Contact info" />
              </button>{' '}
            </Col>
          </Row>
          <Row type="flex" justify="start" style={{ marginTop: 24 }}>
            <Col span={2} className="visit-detail-title">
              <FormattedMessage id="visit.detail.agent" defaultMessage="Agent" />
            </Col>
            <Col span={2} className="visit-detail-title">
              <FormattedMessage id="visit.detail.btsID" defaultMessage="BTS ID" />
            </Col>
            <Col span={8} className="visit-detail-title">
              <FormattedMessage id="visit.detail.rtaEta" defaultMessage="RTA/ETA" />
            </Col>
            <Col span={4} className="visit-detail-title">
              <FormattedMessage id="visit.detail.quay" defaultMessage="Quay" />
            </Col>
            <Col span={4} className="visit-detail-title">
              <FormattedMessage id="visit.detail.status" defaultMessage="Status" />
            </Col>
          </Row>
          <Row type="flex" justify="start">
            <Col span={2}>{visit.bargeOperator && visit.bargeOperator.name ? visit.bargeOperator.name : null}</Col>
            <Col span={2}>{visit.btsId}</Col>
            <Col span={8}>
              <span>{renderRTA(visit)}</span>
              <span style={{ marginLeft: 8 }}>{renderETA(visit)}</span>
            </Col>
            <Col span={4}>
              <span>{renderQuayPlanned(visit)}</span>
              <span style={{ marginLeft: visit.quayPlanned ? 8 : 0 }}>{renderQuayRequested(visit)}</span>
            </Col>
            <Col span={2}>{renderStatus(visit.status)}</Col>
            <Col span={6} align="right" className="visit-detail-updated">
              <ClockCircleOutlined style={{ marginRight: 5 }} />
              <FormattedMessage id="visit.detail.update" defaultMessage="Most recently updated {duration}" values={{ duration: humanizeTimeStamp(visit.updatedAt) }} />
            </Col>
          </Row>

          <Row style={{ marginTop: SPACING.CONTENT }}>
            <div className="custom-panel">
              <Row type="flex" justify="start" className="detail-row">
                <Col span={2}>
                  <CollapseTitle subTitle="" title={<FormattedMessage id="organisms.lpl.lpl_detail.requests.title" defaultMessage="Requests" />} />
                </Col>
                <Row>
                  <Col span={3}>
                    <h4>
                      <FormattedMessage id="lpl_progress_bar.lpl_done_title" defaultMessage="lpl done" />
                    </h4>
                    {Progressbar(this.props.visit.lplDone, this.props.visit.lplRequested)}
                  </Col>
                  <Col span={3} offset={1}>
                    <h4>
                      <FormattedMessage id="lpl_progress_bar.lpl_requested_total_title" defaultMessage="lpl requested / lpl total" />
                    </h4>
                    {Progressbar(this.props.visit.lplRequested, this.props.visit.lplToRequest + this.props.visit.lplRequested)}
                  </Col>
                </Row>
              </Row>
              <Row style={{ marginTop: SPACING.COLLAPSE }}>
                <Socket
                  topics={[EVENT_TYPES.SPECIFIC_LPL_REQUESTS_STREAM.replace(':id', this.props.visit.id), EVENT_TYPES.SPECIFIC_CONTAINER_STREAM.replace(':id', this.props.visit.id)]}
                  onMessage={updateLplGroups({ getLPLGroupAction: this.props.getLPLGroupAction, visit: this.props.visit })}
                  socketKey={`LPLDetailTable.${visit.id}`}
                >
                  <LPLDetailTable groups={this.props.groups} visit={this.props.visit} />
                </Socket>
              </Row>
            </div>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <RemarkCollapse
              btsRemark={this.props.visit.remarksBts}
              createRemarkAction={this.props.createLPLRemarkAction}
              loading={this.props.loadingRemarks}
              remarks={this.props.remarks}
              remarkType={REMARK_TYPES.LPL}
              allowedRoles={[ROLES.ROLE_EMPTIES_YARD]}
              visitId={this.props.visit.id}
            />
          </Row>
          <ContactInfoModal visit={this.props.visit} showDialog={this.state.showContactInfo} hideModal={() => this.setContactInfoVisibility(false)} />
        </Refresh>
      </Socket>
    ) : null;
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  groups: state.lpl.lplGroups,
});

export const LPLDetail = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getLPLGroupAction,
})(LPLDetailComponent);
