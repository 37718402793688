// @flow
import moment from 'moment';
import type { TUnavailabilityWindow } from '../../types';

export class UnavailabilityWindow {
  static fromJson(unavailabilityWindow: Object) {
    return {
      ...unavailabilityWindow,
      fromTime: unavailabilityWindow.fromTime ? moment(unavailabilityWindow.fromTime) : null,
      toTime: unavailabilityWindow.toTime ? moment(unavailabilityWindow.toTime) : null,
    };
  }

  static toJson(unavailabilityWindow: TUnavailabilityWindow) {
    return {
      ...unavailabilityWindow,
      fromTime: unavailabilityWindow.fromTime,
      toTime: unavailabilityWindow.toTime,
    };
  }
}
