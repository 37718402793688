// @flow
import type { TContact } from '../components/molecules/modals/ContactInfoModal';
import type { TAllContactDetails } from '../types/contact_details.types';

export const extractContactInfo = (contactDetails: TAllContactDetails): TContact[] => {
  const contacts: TContact[] = [];
  if (contactDetails) {
    if (contactDetails.bargeOperator && contactDetails.bargeOperator.contactDetails.length !== 0) {
      contacts.push({
        id: 0,
        available: contactDetails.bargeOperator ? contactDetails.bargeOperator.available || false : false,
        contactInfo: (contactDetails.bargeOperator && contactDetails.bargeOperator.contactDetails) || [],
        type: 'BARGE_OPERATOR',
      });
    }
    if (contactDetails.skippers) {
      contacts.push(
        ...contactDetails.skippers.map((skipper, index) => ({
          id: index + 1,
          available: skipper.available || false,
          contactInfo: skipper.contactDetails || [],
          type: 'SKIPPER',
        })),
      );
    }
  }

  return contacts;
};
