// @flow
import { Button, Form, Input } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { useForm } from 'antd/es/form/Form';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import type { TBargeOperatorForm } from '../../../types/index';
import { ContactDetailForm } from '../../molecules/contact_details/ContactDetailForm';
import { UnavailabilityWindowForm } from '../../molecules/UnavailabilityWindows/UnavailabilityWindowForm';
import { SITE_PATHS } from '../../../config/api.constants';
import { FormStyles } from '../../../theme/form';
import { cleanContactable } from '../../../utils/form.utils';

type TProps = {
  bargeOperator?: TBargeOperatorForm,
  handleSubmit: Function,
};

function BargeOperatorForm(props: TProps) {
  const [form] = useForm();
  const intl = useIntl();

  const { bargeOperator } = props;
  const contactDetails = bargeOperator ? bargeOperator.contactDetails : [];
  const unavailabilityWindows = bargeOperator ? bargeOperator.unavailabilityWindows : [];
  const formCols = FormStyles.vertical;

  const handleServerErrors = (errors) => {
    const antFormat = [];

    if (errors.indexOf('abbreviation') > -1) {
      antFormat.push({ name: 'abbreviation', errors: [intl.formatMessage(VALIDATION_MESSAGES.not_unique)], value: form.getFieldValue('abbreviation') });
    }

    if (errors.indexOf('reference') > -1) {
      antFormat.push({ name: 'reference', errors: [intl.formatMessage(VALIDATION_MESSAGES.not_unique)], value: form.getFieldValue('reference') });
    }

    form.setFields(antFormat);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const cleanedBargeOperator = cleanContactable(values);
      if (props.bargeOperator) cleanedBargeOperator.id = props.bargeOperator.id;
      props.handleSubmit(cleanedBargeOperator, handleServerErrors);
    });
  };

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          {...formCols}
          name="name"
          initialValue={bargeOperator ? bargeOperator.name : null}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
          ]}
          label={<FormattedMessage id="molecules.barge_operator.create_form.name.label" defaultMessage="Name" />}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="abbreviation"
          initialValue={bargeOperator ? bargeOperator.abbreviation : null}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
          ]}
          label={<FormattedMessage id="molecules.barge_operator.create_form.abbreviation.label" defaultMessage="Abbreviation" />}
        >
          <Input />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="reference"
          initialValue={bargeOperator ? bargeOperator.reference : null}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
          ]}
          label={<FormattedMessage id="molecules.barge_operator.create_form.reference.label" defaultMessage="Carrier ID" />}
        >
          <Input />
        </Form.Item>

        <ContactDetailForm form={form} data={contactDetails} />
        <UnavailabilityWindowForm form={form} data={unavailabilityWindows} />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </Button>
        </Form.Item>
      </Form>
      <Link to={SITE_PATHS.BARGE_OPERATOR_LIST}>
        <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
      </Link>
    </>
  );
}

export default injectIntl(BargeOperatorForm);
