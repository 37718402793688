// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Tag } from 'antd';
import { LockOutlined } from '@ant-design/icons';

/**
 * Defines all the column properties of the ElearningListTable
 *
 * @returns {[{dataIndex: string, width: number, title: *, render: (function(*): *)}, {dataIndex: string, width: number, title: *}, {dataIndex: string, title: *, render: (function(*=): *), key: string}]}
 * @constructor
 */
export const ElearningListTableProps = () => [
  {
    dataIndex: 'editable',
    render: (value: any) => !value && <LockOutlined />,
    width: 20,
  },
  {
    dataIndex: 'key',
    title: <FormattedMessage id="elearning.list.key" defaultMessage="Key" />,
    render: (value: any) => <Tag>{value}</Tag>,
    width: 350,
  },
  {
    dataIndex: 'title',
    title: <FormattedMessage id="elearning.list.title" defaultMessage="Title" />,
    width: 250,
  },
  {
    dataIndex: 'body',
    title: <FormattedMessage id="elearning.list.body" defaultMessage="Content" />,
    key: 'body',
    // eslint-disable-next-line react/no-danger
    render: (value: any) => <div dangerouslySetInnerHTML={{ __html: value }} />,
  },
];
