/* eslint-disable */
// @flow
import * as React from 'react';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { getShiftQuaysOverviewAction } from '../../../store/actions/barge_clerk.actions';
import { updateTalOverview } from '../../../store/handlers/visits.handler';
import type { TShiftQuay } from '../../../types/barge_clerk.types';
import { Loading } from '../../atoms/Loading';
import { Socket } from '../../atoms/Socket';
import { ShiftTalList } from '../../molecules/shift_tal_list/ShiftTalList';
import { useEffect } from 'react';

type TProps = {
  getShiftQuaysOverviewAction: typeof getShiftQuaysOverviewAction,
  loading: boolean,
  shiftQuays: TShiftQuay[],
  url: Object,
};

export const ShiftTalContainer = (props: TProps) => {
  useEffect(() => refreshComponent, [props.url]);

  const refreshComponent = () => {
    // check url if own shift & own quays are selected. If so get them.
    const { ownShift, ownQuay } = props.url;

    if (ownShift && ownQuay && ownQuay.length > 0) {
      const { otherShift, otherQuay } = props.url;
      const shifts = [ownShift, ...(otherShift || [])];
      const quays = [...ownQuay, ...(otherQuay || [])];

      props.getShiftQuaysOverviewAction(shifts, quays);
    }
  };

  const generateUpdateTalOverview = () => {
    const { ownShift, ownQuay } = props.url;
    const ownShiftId = ownShift ? Number(ownShift) : 0;

    const hasSelectedACombination = ownShiftId > 0 && ownQuay && ownQuay.length > 0;

    if (hasSelectedACombination) {
      const { otherShift, otherQuay } = props.url;
      const shiftIds = [ownShift, ...(otherShift || [])].map((s) => parseInt(s, 10));
      const quayIds = [...ownQuay, ...(otherQuay || [])].map((q) => parseInt(q, 10));

      return updateTalOverview({
        getShiftQuaysOverviewAction: props.getShiftQuaysOverviewAction,
        quayIds,
        shiftIds,
      });
    }

    return () => {};
  };

  return props.loading ? (
    <Loading />
  ) : (
    <Socket
      topics={[EVENT_TYPES.SHIFT_TAL_ROW]}
      onMessage={generateUpdateTalOverview()}
      socketKey={`ShiftTalContainer.${props.url.ownShift}.${props.url.ownQuay}.${props.url.otherShift}.${props.url.otherQuay}`}
    >
      <ShiftTalList shiftQuays={props.shiftQuays} />
    </Socket>
  );
};
