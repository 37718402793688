// @flow
import { combineReducers } from 'redux';
import { notification } from './notification.reducers';
import bargeClerks from './barge_clerks';
import bargeOperators from './barge_operators';
import barges from './barges';
import containerVisits from './container_visits';
import diagnostics from './diagnostics';
import elearning from './elearning';
import errorCodes from './error_codes';
import errors from './errors';
import gangAssignments from './gang_assignments';
import gangRemarks from './gang_remarks';
import gangs from './gangs';
import language from './language.reducers';
import lpl from './lpl';
import lplRemarks from './lpl_remarks';
import psaQuays from './psa_quays';
import quays from './quays';
import sailingSchedule from './sailing_schedule';
import shiftQuays from './shift_quays';
import shifts from './shifts';
import skippers from './skippers';
import systemSettings from './system_settings';
import user from './user';
import visitRemarks from './visit_remarks';
import visits from './visits';
import { routerReducer } from './router.reducers';

const reducer = combineReducers({
  bargeClerks,
  bargeOperators,
  barges,
  containerVisits,
  diagnostics,
  elearning,
  errorCodes,
  errors,
  gangAssignments,
  gangRemarks,
  gangs,
  language,
  lpl,
  lplRemarks,
  notification,
  psaQuays,
  quays,
  router: routerReducer,
  sailingSchedule,
  shiftQuays,
  shifts,
  skippers,
  systemSettings,
  user,
  visitRemarks,
  visits,
});

export default reducer;
