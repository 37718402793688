// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import type { TBaseAction, TVisit } from '../../types';
import { appApi } from '../../config/api.config';
import { receivedSailingScheduleAction, SailingScheduleActions } from '../actions/sailing_schedule.actions';
import { genericErrorHandler } from '../../utils';
import { createPath } from '../../utils/routing.utils';
import { API_PATHS } from '../../config/api.constants';

export function* get(action: TBaseAction<TVisit>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.SAILING_SCHEDULE, { id: action.payload.id });
  const response = yield call(appApi.get, url);

  yield put(receivedSailingScheduleAction(response.data));
}

export function* SailingScheduleSaga(): Generator<*, *, *> {
  yield takeLatest(SailingScheduleActions.get, genericErrorHandler(get));
}
