// @flow
import React from 'react';
import type { TElearningBadgeProps } from './ElearningBadge';

/**
 * Elearning popover content. This component is used within the AntD Popover element.
 * This component must also fetch the content dynamically.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export function ElearningPopover(props: TElearningBadgeProps) {
  const contentKey = props.contentKey || '';
  return (
    <div className="elearningbadge__popover popover" id={`elearningbadge__${contentKey.replace(' ', '_')}`}>
      <div className="popover__content">
        {/* This is mentioned in the privsec page and should be mitigated in ticket POF1-1497 */}
        {/* eslint-disable-next-line react/no-danger */}
        {props.content ? <div dangerouslySetInnerHTML={{ __html: props.content.body }} /> : <p>Cannot find content for: {props.contentKey}</p>}
      </div>
    </div>
  );
}
