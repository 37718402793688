// @flow
import { combineReducers } from 'redux';
import { LPLRemarkActions } from '../../actions/lpl_remark.actions';
import { listReducer } from './list.reducer';
import loadingReducer from '../loading.reducers';

const reducer = combineReducers({
  list: listReducer,
  loadingList: loadingReducer(LPLRemarkActions.list, LPLRemarkActions.receivedList),
});

export default reducer;
