// @flow
import { Spin } from 'antd';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisitBarge } from '../../../store/selectors/barge_clerk.selectors';
import type { TBarge } from '../../../types';
import { TitleSubtitle } from '../../molecules/titlesubtitle';

export function BargeInfo() {
  const barge: TBarge | null = useSelector(selectBargeClerkVisitBarge);

  return barge ? (
    <div>
      <TitleSubtitle
        subTitle={barge.tosName}
        title={
          <h3 style={{ fontSize: 18, marginBottom: 0 }} className="text-menu">
            {barge.name}
          </h3>
        }
      />
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span className="text-subtitle">ENI: {barge.eni}</span>
        <span className="text-subtitle">
          LxB: {barge.length ? `${barge.length / 100}m` : '-'} x {barge.width ? `${barge.width / 100}m` : '-'}
        </span>
      </div>
    </div>
  ) : (
    <Spin />
  );
}
