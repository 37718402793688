// @flow
import * as React from 'react';
import { useRef } from 'react';
import { Table } from 'antd';

type TProps = {
  className?: string,
  columns: Object[],
  dataSource: ?(Object[]),
  expandedRowRender?: ?Function,
  loading?: boolean,
  onChange?: Function,
  rowClassName?: Function,
  rowKey: string,
  rowSelection?: Object,
  scroll?: ?Object,
  showHeader?: boolean,
  components: any,
};

export function DPDetailTable(props: TProps) {
  const ref = useRef();

  const compare = (a, b) => {
    if (a.archived < b.archived) {
      return -1;
    }
    if (a.archived > b.archived) {
      return 1;
    }
    return 0;
  };

  const dataSource = !!props.dataSource && props.dataSource.sort(compare);

  return (
    <>
      {/* $FlowFixMe */}
      <Table
        className={props.className}
        bordered
        components={props.dataSource?.length && props.components}
        columns={props.columns}
        dataSource={dataSource}
        rowKey={props.rowKey}
        pagination={false}
        rowSelection={props.rowSelection}
        loading={props.loading}
        onChange={props.onChange}
        rowClassName={props.rowClassName}
        showHeader={props.showHeader}
        expandable={
          props.expandedRowRender && {
            expandedRowRender: (record) => {
              // for some reason colSpan is not set for rendered expandable row with editable cells so forcing it
              if (ref.current) {
                const expElement = ref.current.parentElement;
                expElement.setAttribute('colSpan', props.columns.length + 99);
              }
              return <div ref={ref}>{props.expandedRowRender(record)}</div>;
            },
            expandRowByClick: true,
          }
        }
      />
    </>
  );
}

DPDetailTable.defaultProps = {
  expandedRowRender: null,
  loading: false,
  onChange: undefined,
  rowSelection: undefined,
  showHeader: true,
  rowClassName: undefined,
  className: '',
  scroll: { y: 737 },
};
