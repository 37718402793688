// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { createErrorCodeAction } from '../../../store/actions/error_code.actions';
import ErrorCodeForm from '../../organisms/error_codes/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';

type TProps = {
  createErrorCodeAction: typeof createErrorCodeAction,
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.error_code.create.header.title', defaultMessage: 'Create error code' },
});

function ErrorCodeCreate(props: TProps) {
  return (
    <EditTemplate title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} menuBlock="error-codes" menuItem="error-codes-create">
      <ErrorCodeForm handleSubmit={props.createErrorCodeAction} />
    </EditTemplate>
  );
}

export const ErrorCodeCreatePage = injectIntl(connect<any, Object, _, _, _, _>(undefined, { createErrorCodeAction })(ErrorCodeCreate));
