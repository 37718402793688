import { defineMessages } from 'react-intl';

export const OPERATIONS_TIME_REASON_MESSAGES = defineMessages({
  BARGE_ARRIVED_LATE_POA: {
    id: 'barge_arrived_late_poa',
    defaultMessage: 'Barge arrived late in port of Antwerp',
  },
  LOCK_DELAY: {
    id: 'lock_delay',
    defaultMessage: 'Delay caused by lock',
  },
  CONTINUITY_GANG: {
    id: 'continuity_gang',
    defaultMessage: 'Guarantee continuity gang',
  },
  SKIPPER_DOESNT_WORK_NIGHTS: {
    id: 'skipper_doesnt_work_nights',
    defaultMessage: "Skipper doesn't work nights",
  },
  DELAY_PREV_QUAY: {
    id: 'delay_prev_quay',
    defaultMessage: 'Delay previous quay',
  },
  DEVIATION_SAILING_SCHEDULE: {
    id: 'deviation_sailing_schedule',
    defaultMessage: 'Deviation sailing schedule',
  },
  BARGE_BREAKDOWN: {
    id: 'barge_breakdown',
    defaultMessage: 'Barge breakdown',
  },
  NO_BERTH_SPACE: {
    id: 'no_berth_space',
    defaultMessage: 'No berth space',
  },
  NO_BARGE_GANG: {
    id: 'no_barge_gang',
    defaultMessage: 'No barge gang',
  },
  QUAY_CRANE_BREAKDOWN: {
    id: 'quay_crane_breakdown',
    defaultMessage: 'Quay crane breakdown',
  },
  WEATHER_CONDITIONS: {
    id: 'weather_conditions',
    defaultMessage: 'Weather conditions',
  },

  REQUESTED_BY_OTHER_QUAY: {
    id: 'requested_by_other_quay',
    defaultMessage: 'Requested by other quay',
  },
  YARD_OCCUPATION: {
    id: 'yard_occupation',
    defaultMessage: 'Yard occupation',
  },
  BARGE_CTRS_FOR_VESSEL: {
    id: 'barge_ctrs_for_vessel',
    defaultMessage: 'Barge containers for vessel',
  },
  OTHER_VISIT_FOR_BARGE: {
    id: 'other_visit_for_barge',
    defaultMessage: 'Other visit for barge',
  },
  SMALL_VISIT_ALONGSIDE: {
    id: 'small_visit_alongside',
    defaultMessage: 'Small visit alongside',
  },
  HIGHER_PERFORMANCE: {
    id: 'higher_performance',
    defaultMessage: 'Higher performance',
  },
  DUALHOIST_BARGE: {
    id: 'dualhoist_barge',
    defaultMessage: 'Dualhoist barge',
  },
  CBPA_VOLUME_DROP_FIRST_PLANNING: {
    id: 'cbpa_volume_drop_first_planning',
    defaultMessage: 'CBPA volume drop first planning',
  },
  CBPA_COMPENSATE_OTHER_BARGE_FIRST_PLANNING: {
    id: 'cbpa_compensate_other_barge_first_planning',
    defaultMessage: 'CBPA compensate other barge first planning',
  },
});
