// @flow
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import type { TBaseAction, TPageRequest, TVisit, TVisitFilter } from '../../types';
import { genericErrorHandler } from '../../utils';
import { receivedVisitAction } from '../actions';
import { LPLActions, LPLFilterActions, receivedLPLFilterAction, receivedLPLGroupAction, receivedLPLPageAction, receivedLPLRequestsAction } from '../actions/lpl.actions';
import { getLPLRemarksAction } from '../actions/lpl_remark.actions';
import { detail } from './visit.sagas';
import type { TLPLGroup, TLPLRequest } from '../../types/lpl.types';
import { formatUri } from '../../utils/format.utils';
import { API_PATHS } from '../../config/api.constants';
import { createPath } from '../../utils/routing.utils';

function* doPageCall(action: TBaseAction<?TPageRequest<TVisitFilter>>): Generator<*, *, *> {
  const lplUri = API_PATHS.V1.VISIT_LPL_SEARCH_VIEW;
  const uri = action.payload ? formatUri(lplUri, action.payload) : lplUri;

  return yield call(appApi.post, uri, (action.payload && action.payload.filter) || {});
}

export function* page(action: TBaseAction<?TPageRequest<TVisitFilter>>): Generator<*, *, *> {
  yield put(receivedVisitAction(null));

  const response = yield call(doPageCall, action);

  yield put(receivedLPLPageAction(response.data));
}

export function* getStatuses(): Generator<*, *, *> {
  const filters = yield select((state) => state.lpl.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_STATUS_LPL, filters);

  yield put(receivedLPLFilterAction({ status: response.data }));
}

export function* getQuaysRequested(): Generator<*, *, *> {
  const filters = yield select((state) => state.lpl.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_QUAYS_REQUESTED_LPL, filters);

  yield put(receivedLPLFilterAction({ quayRequested: response.data }));
}

export function* getQuaysPlanned(): Generator<*, *, *> {
  const filters = yield select((state) => state.lpl.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_QUAYS_PLANNED_LPL, filters);

  yield put(receivedLPLFilterAction({ quayPlanned: response.data }));
}

export function* getBtsIds(): Generator<*, *, *> {
  const filters = yield select((state) => state.lpl.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BTS_ID_LPL, filters);

  yield put(receivedLPLFilterAction({ btsId: response.data }));
}

export function* getBarges(): Generator<*, *, *> {
  const filters = yield select((state) => state.lpl.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BARGES_DATA_LPL, filters);

  yield put(receivedLPLFilterAction({ barge: response.data }));
}

export function* getBargeOperators(): Generator<*, *, *> {
  const filters = yield select((state) => state.lpl.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BARGE_OPERATORS_LPL, filters);

  yield put(receivedLPLFilterAction({ bargeOperator: response.data }));
}

// Page specific saga because visit detail needs to be fetched before other saga calls are made
export function* details(action: TBaseAction<number>): Generator<*, *, *> {
  yield detail(action);
  yield all([put(getLPLRemarksAction(action.payload))]);
}

export function* group(action: TBaseAction<TVisit>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.LPL_GROUP_VISIT, { id: action.payload.id });
  const response = yield call(appApi.get, url);

  yield put(receivedLPLGroupAction(response.data));
}

export function* requests(action: TBaseAction<TLPLGroup>): Generator<*, *, *> {
  const response = yield call(appApi.get, `${API_PATHS.V1.LPL_REQUEST_LPL_GROUP}/${action.payload.id}`);

  yield put(receivedLPLRequestsAction(response.data));
}

export function* createRequest(action: TBaseAction<TLPLRequest[]>): Generator<*, *, *> {
  const lplRequests = action.payload;
  yield call(appApi.post, API_PATHS.V1.LPL_REQUEST, { requests: lplRequests });
}

export function* progress(action: TBaseAction<TLPLRequest>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.LPL_REQUEST_TOGGLE_PROGRESS, { id: action.payload.id });
  yield call(appApi.post, url);
}

export function* complete(action: TBaseAction<TLPLRequest>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.LPL_REQUEST_COMPLETE, { id: action.payload.id });
  yield call(appApi.post, url);
}

export function* cancel(action: TBaseAction<TLPLRequest>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.LPL_REQUEST_CANCEL, { id: action.payload.id });
  yield call(appApi.post, url);
}

export function* LplSaga(): Generator<*, *, *> {
  yield takeLatest(LPLActions.details, genericErrorHandler(details));
  yield takeEvery(LPLActions.group, genericErrorHandler(group));
  yield takeEvery(LPLActions.requests, genericErrorHandler(requests));
  yield takeLatest(LPLActions.createRequest, genericErrorHandler(createRequest));
  yield takeLatest(LPLActions.toggleProgressRequest, genericErrorHandler(progress));
  yield takeLatest(LPLActions.completeRequest, genericErrorHandler(complete));
  yield takeLatest(LPLActions.cancelRequest, genericErrorHandler(cancel));
  yield takeLatest(LPLActions.page, genericErrorHandler(page));
  yield takeLatest(LPLFilterActions.getStatuses, genericErrorHandler(getStatuses));
  yield takeLatest(LPLFilterActions.getBtsIds, genericErrorHandler(getBtsIds));
  yield takeLatest(LPLFilterActions.getQuaysRequested, genericErrorHandler(getQuaysRequested));
  yield takeLatest(LPLFilterActions.getQuaysPlanned, genericErrorHandler(getQuaysPlanned));
  yield takeLatest(LPLFilterActions.getBarges, genericErrorHandler(getBarges));
  yield takeLatest(LPLFilterActions.getBargeOperators, genericErrorHandler(getBargeOperators));
}
