// @flow
import { notification } from 'antd';
import { defineMessages, intlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  externalErrorsSentSuccessMessage: {
    id: 'external_errors_sent.confirmation',
    defaultMessage: 'Errors sent',
  },
  externalErrorsSentSuccessDescription: {
    id: 'external_errors_sent.description',
    defaultMessage: 'Errors successfully sent',
  },
  externalErrorsSentFailMessage: {
    id: 'external_errors_sent.fail',
    defaultMessage: 'Sending of errors failed',
  },
  externalErrorsSentFailDescription: {
    id: 'external_errors_sent.fail.description',
    defaultMessage: 'Errors could not be sent',
  },
});

export function ExternalErrorsSentSuccessNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsSentSuccessMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsSentSuccessDescription),
    });
  });
}

export function ExternalErrorsSentFailNotification({ intl }: TProps) {
  useEffectOnce(() => {
    notification.error({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsSentFailMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.externalErrorsSentFailDescription),
    });
  });
}
