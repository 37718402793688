// @flow

import type { TBaseAction } from '../../../types';
import { AuthenticationActions } from '../../actions/authentication.actions';

const reducer = (state: boolean = false, action: TBaseAction<boolean>) => {
  switch (action.type) {
    case AuthenticationActions.isAuthenticating:
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
