// @flow
import { combineReducers } from 'redux';
import loadingReducer from '../loading.reducers';
import { pageListReducer } from '../pageList.reducers';
import { detailReducer } from './detail.reducers';
import { BargeActions } from '../../actions';
import { AutocompletionReducer } from '../autocompletions.reducers';

const reducer = combineReducers({
  autocompletions: AutocompletionReducer(BargeActions.receivedAutocompletions, BargeActions.clearAutocompletions),
  detail: detailReducer,
  list: pageListReducer(BargeActions.receivedPage),
  loadingAutocompletions: loadingReducer(BargeActions.autocomplete, BargeActions.receivedAutocompletions),
  loadingDetail: loadingReducer(BargeActions.detail, BargeActions.receivedDetail),
  loadingPage: loadingReducer(BargeActions.page, BargeActions.receivedPage),
});

export default reducer;
