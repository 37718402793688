// @flow
import * as React from 'react';
import { Tag } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

export function Timestamp(content: string | React.Node) {
  return (
    <Tag color="green">
      <CheckOutlined style={{ marginRight: 5 }} />
      {content}
    </Tag>
  );
}
