// @flow
import { combineReducers } from 'redux';
import { VisitActions } from '../../actions';
import loadingReducer from '../loading.reducers';
import { detailReducer } from './detail.reducers';
import { filtersReducer } from './filters.reducers';
import { pageReducer } from '../page.reducers';
import { payloadReducer } from '../payload.reducer';

const reducer = combineReducers({
  contactDetails: payloadReducer(VisitActions.receivedContactDetails),
  detail: detailReducer,
  filters: filtersReducer,
  loadingAvailable: loadingReducer(VisitActions.setAvailableLoading, VisitActions.receivedDetail),
  loadingContactDetails: loadingReducer(VisitActions.getContactDetails, VisitActions.receivedContactDetails),
  loadingDetail: loadingReducer(VisitActions.detailPageDetails, VisitActions.receivedDetail),
  loadingPage: loadingReducer(VisitActions.page, VisitActions.receivedPage),
  loadingPriority: loadingReducer(VisitActions.setPriorityLoading, VisitActions.receivedDetail),
  loadingProcessed: loadingReducer(VisitActions.setProcessedLoading, VisitActions.receivedDetail),
  page: pageReducer(VisitActions.receivedPage),
});

export default reducer;
