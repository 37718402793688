// @flow
import type { TAction, TBaseAction, TElearningPopover } from '../../types';

export const ElearningActions = {
  toggle: 'ELEARNING.TOGGLE',
  content: 'ELEARNING.CONTENT',
  getContent: 'ELEARNING.GETCONTENT',
  saveContent: 'ELEARNING.SAVECONTENT',
};

export const elearningToggleAction = (): TAction => ({
  type: ElearningActions.toggle,
});

export const elearningGetContentAction = (key: string): TBaseAction<string> => ({
  type: ElearningActions.getContent,
  payload: key,
});

export const elearningContentAction = (payload: TElearningPopover): TBaseAction<TElearningPopover> => ({
  type: ElearningActions.content,
  payload,
});

export const elearningSaveContentAction = (payload: TElearningPopover): TBaseAction<TElearningPopover> => ({
  type: ElearningActions.saveContent,
  payload,
});
