// @flow
export const VISIT_STATUS_CSS_ROW_MAP = {
  CANCELLED: 'row-status-cancelled',
  DECISION_CBPA: 'row-status-cancelled',
  IN_EXECUTION: 'row-status-in-execution',
  EXECUTED: 'row-status-executed',
  EXECUTION_PAUSED: 'row-status-paused',
};

export const VISIT_STATUS_CSS_MAP = {
  CANCELLED: 'visit-status-cancelled',
  DECISION_CBPA: 'visit-status-cancelled',
  IN_EXECUTION: 'visit-status-in-execution',
  EXECUTED: 'visit-status-executed',
  EXECUTION_PAUSED: 'visit-status-paused',
};
