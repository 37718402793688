// @flow
import type { TGangLB } from '../../../types/gang.types';
import { GangActions } from '../../actions/gang.actions';

const reducer = (state: ?(TGangLB[]) = [], action: Object) => {
  switch (action.type) {
    case GangActions.receivedGangAssignments:
      return [...action.payload];
    default:
      return state;
  }
};

export const listReducer = reducer;
