// @flow
import * as React from 'react';
import { Button, Popconfirm } from 'antd';
import { FormattedMessage } from 'react-intl';

type TDeleteActionProps = {
  onConfirm: Function,
};

export function DeleteButton(props: TDeleteActionProps) {
  return (
    <Popconfirm
      onConfirm={(e) => props.onConfirm() && e.stopPropagation()}
      onCancel={(e) => e.stopPropagation()}
      title={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_title" defaultMessage="Are you sure ?" />}
      okText={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_confirm" defaultMessage="Delete" />}
      cancelText={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_cancel" defaultMessage="Cancel" />}
    >
      <Button type="danger" onClick={(e) => e.stopPropagation()}>
        <FormattedMessage id="general.delete" defaultMessage="Delete" />
      </Button>
    </Popconfirm>
  );
}
