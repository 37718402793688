// @flow
import axios from 'axios';
import { call, put, takeLatest, delay } from 'redux-saga/effects';
import { REFETCH_TIME } from '../../constants/time.constants';
import { genericErrorHandler, loginErrorHandler } from '../../utils';
import { appApi } from '../../config/api.config';
import { isInitializedAction, receivedBootstrapAction, receivedProfileAction, UserActions } from '../actions';
import { API_PATHS } from '../../config/api.constants';

export function* logout(): Generator<*, *, *> {
  yield call(appApi.get, API_PATHS.V1.LOGOUT);

  axios.defaults.headers.common.Authorization = '';
  localStorage.clear();

  window.location.href = '/';
}

export function* appInit(): Generator<*, *, *> {
  yield put(isInitializedAction(false));
  const bootstrap = yield call(appApi.get, '/bootstrap');

  yield put(receivedBootstrapAction(bootstrap.data));
  yield put(receivedProfileAction(bootstrap.data.profile));
  yield put(isInitializedAction(true));

  while (true) {
    yield call(appApi.get, '/bootstrap');
    yield delay(REFETCH_TIME);
  }
}

// SAGAS
export function* UserSaga(): Generator<*, *, *> {
  yield takeLatest(UserActions.appInit, loginErrorHandler(appInit));
  yield takeLatest(UserActions.logout, genericErrorHandler(logout));
}
