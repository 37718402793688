// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { checkOrEmpty, deleteOrEmpty } from '../renderers';
import type { TQuay } from '../../../types';

export const QuayTableProps = (deleteAction: Function) => [
  {
    dataIndex: 'name',
    title: <FormattedMessage id="organisms.quays.name" defaultMessage="Name" />,
    width: 467,
  },
  {
    dataIndex: 'terminal',
    title: <FormattedMessage id="visit.detail.terminal" defaultMessage="Terminal" />,
    width: 402,
  },
  {
    dataIndex: 'psaQuay',
    render: (text: string, record: TQuay) => checkOrEmpty(record.psaQuay),
    title: <FormattedMessage id="organisms.quays.psa_quay" defaultMessage="PSA Quay" />,
  },
  {
    key: 'actions',
    render: deleteOrEmpty(deleteAction),
    title: <FormattedMessage id="organisms.quays.actions" defaultMessage="Actions" />,
    width: 127,
  },
];
