// @flow
import * as React from 'react';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { NOTIFICATION_TYPES } from '../../../constants/notification.constants';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { showNotificationAction } from '../../../store/actions/notificationActions';
import { updateGangsOverview } from '../../../store/handlers/gangs.handler';
import type { TGlobalState, TPageWithFilter, TVisit, TVisitFilter } from '../../../types';
import { createGangLBAction, deleteGangLBAction, getGangsAction, updateGangLBAction } from '../../../store/actions/gang.actions';
import type { TGangLB } from '../../../types/gang.types';
import { Socket } from '../../atoms/Socket';
import { GangListComponent } from './GangList';
import { clearRemarksAction } from '../../../store/actions/gang_remark.actions';
import { getBargeClerkPageAction } from '../../../store/actions/barge_clerk.actions';
import { pageToPageRequest } from '../../../utils/format.utils';

type TProps = {
  clearRemarksAction: typeof clearRemarksAction,
  createGangLBAction: typeof createGangLBAction,
  deleteGangLBAction: typeof deleteGangLBAction,
  gangs: TGangLB[],
  gangsLastModified: ?string,
  getBargeClerkPageAction: typeof getBargeClerkPageAction,
  getGangsAction: typeof getGangsAction,
  loadingRemarks: boolean,
  page: TPageWithFilter<TVisit, TVisitFilter>,
  previousTotalNumberOfGangs: number,
  remarks: ?Object,
  roles: string[],
  showNotificationAction: typeof showNotificationAction,
  updateGangLBAction: typeof updateGangLBAction,
  url: Object,
  userRoles: string[],
};

function GangListContainerComponent(props: TProps) {
  const getList = () => {
    const { ownShift, ownQuay } = props.url;

    if (ownShift && ownQuay && ownQuay.length > 0) {
      props.getGangsAction(ownShift, ownQuay);
    }
  };

  const updateGang = (gang: TGangLB) => {
    props.updateGangLBAction(gang, () => props.getBargeClerkPageAction(pageToPageRequest(props.page)));
  };

  const deleteGang = (gang: TGangLB) => {
    if (gang) {
      props.clearRemarksAction(gang);
    }
    props.deleteGangLBAction(gang, () => props.getBargeClerkPageAction(pageToPageRequest(props.page)));
  };

  const addNewGang = () => {
    const { ownShift, ownQuay } = props.url;

    if (ownQuay.length > 1) {
      props.showNotificationAction(NOTIFICATION_TYPES.SELECT_ONLY_ONE_QUAY);
    } else {
      props.createGangLBAction(ownShift, ownQuay);
    }
  };

  const refreshGangList = () => {
    let { ownShift, ownQuay } = props.url;

    if (!ownQuay) {
      return null;
    }

    ownShift = parseInt(ownShift, 10);
    ownQuay = ownQuay.map((q) => parseInt(q, 10));

    return updateGangsOverview({
      getGangsAction: props.getGangsAction,
      quayIds: ownQuay,
      shiftIds: [ownShift],
    });
  };

  useEffect(() => {
    getList();
  }, [props.url]);

  return (
    <Socket
      topics={[EVENT_TYPES.GANG_PROGRESS_ROW, EVENT_TYPES.GANG_STREAM]}
      onMessage={refreshGangList()}
      socketKey={`GangListContainer.${props.url.ownShift}.${props.url.ownQuay}.${props.url.otherShift}.${props.url.otherQuay}`}
    >
      <div className="gang-list-overview">
        <GangListComponent
          roles={props.roles}
          gangs={props.gangs}
          loadingRemarks={props.loadingRemarks}
          remarks={props.remarks}
          updateGang={updateGang}
          deleteGang={deleteGang}
          addNewGang={addNewGang}
          gangsLastModified={props.gangsLastModified}
          userRoles={props.userRoles}
          previousTotalNumberOfGangs={props.previousTotalNumberOfGangs}
        />
      </div>
    </Socket>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  gangs: state.gangs.list,
  gangsLastModified: state.gangs.lastModified,
  previousTotalNumberOfGangs: state.gangs.previousNumberOfGangLb,
  loadingRemarks: state.gangRemarks.loading,
  remarks: state.gangRemarks.remarks,
  roles: state.user.bootstrap.profile.roles,
  page: state.bargeClerks.page,
  userRoles: state.user.bootstrap.profile.roles,
});

export const GangListContainer = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getGangsAction,
  clearRemarksAction,
  deleteGangLBAction,
  getBargeClerkPageAction,
  updateGangLBAction,
  createGangLBAction,
  showNotificationAction,
})(GangListContainerComponent);
