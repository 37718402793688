// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../types';
import { isAuthorized } from '../../utils/authorization.utils';

type TProps = {
  children: React.Node,
  roles: string[],
  userRoles: string[],
};

function RoleViewerComponent(props: TProps) {
  return isAuthorized(props.roles, props.userRoles) ? props.children : null;
}

const mapStateToProps = (state: TGlobalState) => ({
  userRoles: state.user.bootstrap.profile.roles,
});

export const RoleViewer = connect<any, Object, _, _, _, _>(mapStateToProps)(RoleViewerComponent);
