// @flow
import createSagaMiddleware from 'redux-saga';
import { createHashHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';

import reducer from './reducers';
import rootSaga from './sagas';

const historyListener = () => createHashHistory();
export const browserHistory = historyListener();

const sagaMiddleware = createSagaMiddleware();

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducer, composeEnhancers(applyMiddleware(sagaMiddleware)));

sagaMiddleware.run(rootSaga);

export default store;
