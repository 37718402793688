// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { updateLplVisitDetailTable } from '../../../store/handlers/visits.handler';
import type { TLPLGroup, TLPLRequest } from '../../../types/lpl.types';
import type { TGlobalState } from '../../../types';
import { getLPLRequestsAction } from '../../../store/actions/lpl.actions';
import { ContextMenu } from '../../molecules/ContextMenu';
import { Socket } from '../Socket';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { LPL_STATUS } from '../../../constants/lpl.constants';
import { DPDetailTable } from './DPDetailTable';

type TProps = {
  getLPLRequestsAction: typeof getLPLRequestsAction,
  group: TLPLGroup,
  menuFactory: Function,
  requests: TLPLRequest[],
};

function LPLRequestTableComponent(props: TProps) {
  const { getLPLRequestsAction, group, menuFactory, requests } = props;
  const [activeMenuIndex, setActiveMenuIndex] = useState(-1);

  useEffect(() => {
    getLPLRequestsAction();
  }, [group]);

  const columns = [
    {
      dataIndex: 'amount',
      title: <FormattedMessage id="atoms.tables.lpl_request_table.amount" defaultMessage="Amount" />,
    },
    {
      dataIndex: 'status',
      width: 800,
      title: <FormattedMessage id="atoms.tables.lpl_request_table.status" defaultMessage="Status" />,
      render: (text: string, record: TLPLRequest) => {
        switch (record.status) {
          case LPL_STATUS.IN_PROGRESS:
            return <FormattedMessage id="lpl_request_table.status.in_progress" defaultMessage="In progress" />;
          case LPL_STATUS.CANCELLED:
            return <FormattedMessage id="lpl_request_table.status.cancelled" defaultMessage="Cancelled" />;
          case LPL_STATUS.COMPLETED:
            return <FormattedMessage id="lpl_request_table.status.completed" defaultMessage="Completed" />;
          default:
            return <FormattedMessage id="lpl_request_table.status.new" defaultMessage="New" />;
        }
      },
    },
    {
      width: 20,
      dataIndex: 'contextMenu',
      render: (text, record, index: number) => {
        return record.status === 'CANCELLED' ? null : (
          <ContextMenu
            active={activeMenuIndex === index}
            menu={menuFactory(record, () => setActiveMenuIndex(-1))}
            setMenuActive={() => setActiveMenuIndex(index)}
            setMenuInactive={() => setActiveMenuIndex(-1)}
          />
        );
      },
      className: 'context-menu-cell',
    },
  ];

  return (
    <Socket
      topics={[EVENT_TYPES.SPECIFIC_LPL_REQUESTS_STREAM.replace(':id', group.visitId)]}
      onMessage={updateLplVisitDetailTable({ getLPLRequestsAction, group })}
      socketKey={`LplRequestTable.${group.visitId}`}
    >
      <DPDetailTable dataSource={requests} rowKey="id" columns={columns} pagination={false} />
    </Socket>
  );
}

const mapStateToProps = (state: TGlobalState, props: TProps) => ({
  requests: state.lpl.lplRequests && state.lpl.lplRequests[props.group.id],
});

// Flow bugged
// $FlowFixMe
export const LPLRequestTable = connect<any, Object, _, _, _, _>(mapStateToProps, { getLPLRequestsAction })(LPLRequestTableComponent);
