// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import {
  archiveExternalErrorsAction,
  archiveInternalErrorsAction,
  getVisitContainerPageAction,
  priorityVisitAction,
  processVisitAction,
  unarchiveExternalErrorsAction,
  unarchiveInternalErrorsAction,
  updateBTSAction,
  getDetailsAction,
} from '../../../store/actions';
import { createVisitRemarkAction } from '../../../store/actions/visit_remark.actions';
import type { TContainerVisit, TError, TGlobalState, TPage, TVisit, TVisitFilter } from '../../../types';
import type { TVisitRemark } from '../../../types/remark.types';
import { VisitDetail } from '../../organisms/visit_detail/VisitDetail';
import { DPTemplate } from '../../templates/DPTemplate';

type TProps = {
  containerPage: TPage<TContainerVisit>,
  createVisitRemarkAction: typeof createVisitRemarkAction,
  externalErrors: TError[],
  getDetailsAction: typeof getDetailsAction,
  getVisitContainerPageAction: typeof getVisitContainerPageAction,
  internalErrors: TError[],
  loadingContainerPage: boolean,
  loadingExternalErrors: boolean,
  loadingInternalErrors: boolean,
  loadingLPLRemarks: boolean,
  loadingVisitRemarks: boolean,
  LPLRemarks: TVisitRemark[],
  match: any,
  page: ?TPage<TVisitFilter>,
  priorityVisitAction: typeof priorityVisitAction,
  processVisitAction: typeof processVisitAction,
  archiveInternalErrorsAction: typeof archiveInternalErrorsAction,
  unarchiveInternalErrorsAction: typeof unarchiveInternalErrorsAction,
  archiveExternalErrorsAction: typeof archiveExternalErrorsAction,
  unarchiveExternalErrorsAction: typeof unarchiveExternalErrorsAction,
  updateBTSAction: typeof updateBTSAction,
  visit: ?TVisit,
  visitRemarks: TVisitRemark[],
};

class _VisitDetail extends React.Component<TProps, {}> {
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.getDetailsAction(id);
  }

  render() {
    if (!this.props.visit) return null;

    return (
      <DPTemplate menuBlock="visits" menuItem="visits">
        <VisitDetail
          containerPage={this.props.containerPage}
          loadingContainerPage={this.props.loadingContainerPage}
          getVisitContainerPageAction={this.props.getVisitContainerPageAction}
          createVisitRemarkAction={this.props.createVisitRemarkAction}
          externalErrors={this.props.externalErrors}
          getDetailsAction={this.props.getDetailsAction}
          internalErrors={this.props.internalErrors}
          loadingExternalErrors={this.props.loadingExternalErrors}
          loadingInternalErrors={this.props.loadingInternalErrors}
          loadingVisitRemarks={this.props.loadingVisitRemarks}
          processVisitAction={this.props.processVisitAction}
          visitRemarks={this.props.visitRemarks}
          archiveInternalErrorsAction={this.props.archiveInternalErrorsAction}
          unarchiveInternalErrorsAction={this.props.unarchiveInternalErrorsAction}
          archiveExternalErrorsAction={this.props.archiveExternalErrorsAction}
          unarchiveExternalErrorsAction={this.props.unarchiveExternalErrorsAction}
          updateBTSAction={this.props.updateBTSAction}
          visit={this.props.visit}
          loadingLPLRemarks={this.props.loadingLPLRemarks}
          LPLRemarks={this.props.LPLRemarks}
          priorityVisitAction={this.props.priorityVisitAction}
          page={this.props.page}
        />
      </DPTemplate>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state: TGlobalState) => ({
  containerPage: state.containerVisits.visitPage,
  LPLRemarks: state.lplRemarks.list,
  externalErrors: state.errors.visitExternalList,
  internalErrors: state.errors.visitInternalList,
  loadingContainerPage: state.containerVisits.loadingVisitPage,
  loadingExternalErrors: state.errors.loadingVisitExternalList,
  loadingInternalErrors: state.errors.loadingVisitInternalList,
  loadingVisitRemarks: state.visitRemarks.loadingList,
  loadingLPLRemarks: state.lplRemarks.loadingList,
  page: state.visits.page,
  visitRemarks: state.visitRemarks.list,
  visit: state.visits.detail,
});

export const VisitDetailPage = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getDetailsAction,
  updateBTSAction,
  createVisitRemarkAction,
  archiveInternalErrorsAction,
  unarchiveInternalErrorsAction,
  archiveExternalErrorsAction,
  unarchiveExternalErrorsAction,
  getVisitContainerPageAction,
  processVisitAction,
  priorityVisitAction,
})(_VisitDetail);
