// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import type { TVisit } from '../../../../types';
import type { TLPLGroup } from '../../../../types/lpl.types';
import { DPDetailTable } from '../DPDetailTable';
import { lplTypeRenderer } from '../../renderers';
import { LPLRequestTable } from '../LPLRequestTable';
import { LPLMenu } from '../../../molecules/lpl/LPLMenu';
import { EmbeddedTable } from '../EmbeddedContainerTable';
import { Progressbar } from '../../Progressbar';

type TProps = {
  groups: TLPLGroup[],
  visit: TVisit,
};

export function LPLDetailTable(props: TProps) {
  const { visit } = props;

  const expandedRowRender = (record: TLPLGroup) => (
    <div>
      <h3>
        <FormattedMessage id="lpl_detail_table.requests" defaultMessage="Requests" />
      </h3>
      <div style={{ marginTop: 16 }}>
        <LPLRequestTable group={record} menuFactory={(embeddedRow, setInactiveFn) => <LPLMenu request={embeddedRow} setMenuInactive={setInactiveFn} />} />
      </div>
      {record.containerNr ? (
        <>
          <h3>
            <FormattedMessage id="lpl_detail_table.containers" defaultMessage="Containers" />
          </h3>
          <div style={{ marginTop: 16 }}>
            <EmbeddedTable lplGroup={record} visit={visit} />
          </div>
        </>
      ) : null}
    </div>
  );

  const columns = [
    {
      dataIndex: 'customer',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.client" defaultMessage="Client" />,
      width: 250,
    },
    {
      dataIndex: 'amount',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.quantity" defaultMessage="Quantity" />,
      width: 100,
      render: (text, record: TLPLGroup) => Progressbar(record.lplDone, record.lplRequested),
    },
    {
      dataIndex: 'progress',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.progress" defaultMessage="Progress" />,
      width: 120,
      render: (text, record: TLPLGroup) => Progressbar(record.lplRequested, record.amount),
    },
    {
      dataIndex: 'type',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.type" defaultMessage="Type" />,
      render: lplTypeRenderer,
      width: 139,
    },
    {
      dataIndex: 'iso',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.iso" defaultMessage="ISO" />,
      width: 90,
    },
    {
      dataIndex: 'loadState',
      render: (text: *, record: *) =>
        record.loadState === 'F' ? (
          <FormattedMessage id="molecules.empties_yard.empties_yard_table.full" defaultMessage="Full" />
        ) : (
          <FormattedMessage id="molecules.empties_yard.empties_yard_table.empty" defaultMessage="Empty" />
        ),
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.load_state" defaultMessage="Empty/Full" />,
      width: 100,
    },
    {
      dataIndex: 'orderId',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.booking_number" defaultMessage="Booking number" />,
      width: 150,
    },
    {
      dataIndex: 'pod',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.destination" defaultMessage="Destination" />,
      width: 200,
    },
    {
      dataIndex: 'qs',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.qs" defaultMessage="QS" />,
      width: 90,
    },
    {
      dataIndex: 'csc',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.csc" defaultMessage="CSC" />,
      width: 90,
    },
    {
      dataIndex: 'remark',
      title: <FormattedMessage id="molecules.empties_yard.empties_yard_table.table.remark" defaultMessage="Remark" />,
      width: 302,
    },
  ];

  return <DPDetailTable columns={columns} dataSource={props.groups} rowKey="id" expandedRowRender={expandedRowRender} scroll={{ x: 0, y: 0 }} />;
}
