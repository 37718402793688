// @flow
import * as React from 'react';
import { Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as materialBolt } from '../../resources/awesome-bolt.svg';

export const boltTag = (
  <Tooltip title={<FormattedMessage id="special_containers.tooltip.message.imdg" defaultMessage="This visit contains containers with dangerous goods" />}>
    <Icon theme="outlined" component={materialBolt} className="icon-imdg" />
  </Tooltip>
);
