// @flow
import qs from 'qs';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SITE_PATHS } from '../../../config/api.constants';
import { browserHistory } from '../../../store';
import { getBargeClerkPageAction } from '../../../store/actions/barge_clerk.actions';
import type { TGlobalState } from '../../../types';
import { parseUrlQuery } from '../../../utils/routing.utils';
import { InfoBar } from '../../organisms/barge_clerks/InfoBar';
import { VisitListTableContainer } from '../../organisms/barge_clerks/VisitListTableContainer';
import { NoInfoAvailable } from '../../organisms/no_info_available/NoInfoAvailable';
import { ShiftQuayCombinatorContainer } from '../../organisms/shift_quay_combinator/ShiftQuayCombinatorContainer';
import { DPTemplate } from '../../templates/DPTemplate';

type TProps = {
  getBargeClerkPageAction: typeof getBargeClerkPageAction,
  location: Location,
};

function Overview(props: TProps) {
  const [showVisits, setShowVisits] = useState(false);

  const parseUrl = (props: TProps) => {
    const { location } = props;
    const parsedUrl = parseUrlQuery(location.search);

    const { ownShift, ownQuay, otherShift, otherQuay } = parsedUrl;
    const showVisits = ownShift && ownQuay && ownQuay.length > 0;

    setShowVisits(showVisits);

    if (showVisits) {
      const filter = { ...(parsedUrl.filter || {}), ownShift: [ownShift], ownQuay, otherShift, otherQuay };
      const sorting = parsedUrl.sorting || null;
      const { paging } = parsedUrl;
      const pageRequest = { filter, paging, sorting, prio: true };

      props.getBargeClerkPageAction(pageRequest);
    }
  };

  const createUrl = (newState: ?Object): Object => {
    const { location } = props;
    const state = qs.parse(location.search, { ignoreQueryPrefix: true });

    return newState ? { ...state, ...newState } : state;
  };

  const updateUrl = (values: Object) => {
    let newValues = { ...values };

    if ((values.ownQuay && values.ownQuay.length > 1) || (values.otherQuay && values.otherQuay.length > 0)) {
      newValues = { ...values, sorting: { by: ['quayPlannedName'], order: 'ascending' } };
    }

    const url = createUrl(newValues);
    browserHistory.push(`/barge-clerk?${qs.stringify(url)}`);
  };

  useEffect(() => {
    parseUrl(props);
  }, []);

  useEffect(() => {
    parseUrl(props);
  }, [props.location.search]);

  return (
    <DPTemplate menuBlock="visits" menuItem={SITE_PATHS.BARGE_CLERK_LIST}>
      <ShiftQuayCombinatorContainer updateUrl={updateUrl} url={createUrl()} />
      {showVisits ? (
        <>
          <InfoBar url={createUrl()} />
          <VisitListTableContainer updateUrl={updateUrl} url={createUrl()} />
        </>
      ) : (
        <NoInfoAvailable />
      )}
    </DPTemplate>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingPage: state.bargeClerks.loadingPage,
});

export const BargeClerkOverviewPage = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getBargeClerkPageAction,
})(Overview);
