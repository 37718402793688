// @flow
import * as React from 'react';
import { Dropdown } from 'antd';

type TProps = {
  active: boolean,
  menu: React.Node,
  setMenuActive: Function,
  setMenuInactive: Function,
};

export function ContextMenu(props: TProps) {
  return (
    <Dropdown
      // this api will be deprecated in the next major release of antd
      // it should be refactored but the new api requires a complete rewrite of the menu factory
      // and that's out of scope at the time of writing
      overlay={props.menu}
      trigger={['click']}
      placement="bottomLeft"
      onClick={(e) => {
        e.stopPropagation();
      }}
      open={props.active}
      onOpenChange={(visible: boolean) => (visible ? props.setMenuActive() : props.setMenuInactive())}
    >
      <div className="context-wrapper">
        <div className={`context-menu ${props.active ? 'active' : ''}`}>
          <div className="dot" />
          <div className="dot" />
          <div className="dot" />
        </div>
      </div>
    </Dropdown>
  );
}
