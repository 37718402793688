// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { deleteOrEmpty } from '../renderers';

export const ErrorCodeTableProps = (deleteAction: Function) => [
  {
    dataIndex: 'code',
    key: 'code',
    title: <FormattedMessage id="molecules.error_code_list.code" defaultMessage="Code" />,
    width: 570,
  },
  {
    dataIndex: 'description',
    key: 'description',
    title: <FormattedMessage id="molecules.error_code_list.description" defaultMessage="Description" />,
  },
  {
    dataIndex: 'type',
    key: 'type',
    title: <FormattedMessage id="molecules.error_code_list.type" defaultMessage="Type" />,
  },
  {
    key: 'actions',
    render: deleteOrEmpty(deleteAction),
    title: <FormattedMessage id="molecules.error_code_list.actions" defaultMessage="Actions" />,
    width: 127,
  },
];
