// @flow
import { ElearningActions } from '../../actions';
import type { TAction } from '../../../types';

const initialState: boolean = false;

const activeReducer = (state: boolean = initialState, action: TAction) => {
  if (action.type === ElearningActions.toggle) {
    return !state;
  }
  return state;
};

export default activeReducer;
