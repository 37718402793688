// @flow
import { Tooltip } from 'antd';
import { isEqual } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { getTotalsOverviewAction } from '../../../store/actions/barge_clerk.actions';
import type { TBargeClerkSaldo } from '../../../types/barge_clerk.types';
import { formatDateTime, humanizeTimeStamp } from '../../../utils/dates.utils';
import { Loading } from '../../atoms/Loading';
import { Refresh } from '../../atoms/Refresh';
import { Counter } from '../../molecules/counter/Counter';

type TProps = {
  getTotalsOverviewAction: typeof getTotalsOverviewAction,
  totals: TBargeClerkSaldo,
  url: Object,
};

export class OverviewTotalsContainer extends React.Component<TProps> {
  componentDidMount() {
    // check url if own shift & own quays are selected. If so get them.
    const { ownShift, ownQuay } = this.props.url;

    if (ownShift && ownQuay && ownQuay.length > 0) {
      this.props.getTotalsOverviewAction(ownShift, ownQuay);
    }
  }

  componentDidUpdate(prevProps: TProps) {
    // check url if own shift & own quays are selected or changed. If so get them.
    if (!isEqual(prevProps.url, this.props.url)) {
      const { ownShift, ownQuay } = this.props.url;

      if (ownShift && ownQuay && ownQuay.length > 0) {
        this.props.getTotalsOverviewAction(ownShift, ownQuay);
      }
    }
  }

  render() {
    const { totals } = this.props;
    return totals ? (
      <>
        <h3>
          <FormattedMessage id="organisms.barge_clerks.infobar.talcounter" defaultMessage="TAL" />
        </h3>

        <Counter done={totals.done} toDo={totals.todo} total={totals.total} hasPreviousTal={totals.hasPreviousTal} previousTal={totals.previousTal} previousTalChange={totals.previousTalChange} />

        {totals.hasPreviousTal ? (
          <div className="human-text-history">
            <Refresh
              rate={30000}
              refreshAction={() => {
                this.setState({}); // trigger a rerender
              }}
            >
              <Tooltip
                title={
                  <div>
                    <FormattedMessage id="organisms.barge_clerk.visit_list.previous_tal" defaultMessage="Previous TAL: {tal}" values={{ tal: `${totals.previousTal}` }} />
                    <br />
                    <br />
                    {formatDateTime(moment(totals.previousTalChange))}
                  </div>
                }
              >
                <FormattedMessage id="organisms.barge_clerks.infobar.gang_time_last_change" defaultMessage="Last change" />: {humanizeTimeStamp(moment(totals.previousTalChange))}
              </Tooltip>
            </Refresh>
          </div>
        ) : null}
      </>
    ) : (
      <Loading />
    );
  }
}
