// @flow
import { createSelector } from 'reselect';
import type { TGlobalState, TVisit } from '../../types';
import { createPath } from '../../utils/routing.utils';
import { API_PATHS } from '../../config/api.constants';

export const selectDetail = (state: TGlobalState) => state.visits.detail;

export const selectVisitPriority = createSelector(selectDetail, (visit: TVisit) => (visit ? visit.priority : null));
export const selectVisitAvailable = createSelector(selectDetail, (visit: TVisit) => (visit ? visit.available : null));

export const selectVisitPriorityLoading = (state: TGlobalState) => state.visits.loadingPriority;
export const selectVisitProcessedLoading = (state: TGlobalState) => state.visits.loadingProcessed;
export const selectVisitAvailableLoading = (state: TGlobalState) => state.visits.loadingAvailable;

export const selectVisitAvailableLink = createSelector(selectDetail, (visit: TVisit) => (visit ? createPath(API_PATHS.V1.VISIT_AVAILABLE, { id: visit.id, value: !visit.available }) : null));
export const selectVisitPriorityLink = createSelector(selectDetail, (visit: TVisit) => (visit ? createPath(API_PATHS.V1.VISIT_PRIORITY, { id: visit.id, value: !visit.priority }) : null));
