// @flow
import { UserActions } from '../../actions';
import type { TBootstrap, TBaseAction } from '../../../types';

const initialState: TBootstrap = { profile: {}, version: 'x.x.x' };

const reducer = (state: TBootstrap = initialState, action: TBaseAction<TBootstrap>) => {
  switch (action.type) {
    case UserActions.receivedBootstrap:
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
