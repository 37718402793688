// @flow
import type { TQuay } from '../../../types';
import { QuayActions } from '../../actions';

const initialState: ?(TQuay[]) = [];

const reducer = (state: ?(TQuay[]) = initialState, action: Object) => {
  switch (action.type) {
    case QuayActions.receivedPsaQuays:
      return [...action.payload];

    default:
      return state;
  }
};

export const listReducer = reducer;
