// @flow
import { combineReducers } from 'redux';
import { portGeofenceReducer } from './portGeofence.reducer';
import { filtersReducer } from '../filters.reducer';
import { pageReducer } from '../page.reducers';
import { BargeClerkActions } from '../../actions/barge_clerk.actions';
import loadingReducer from '../loading.reducers';
import { payloadReducer } from '../payload.reducer';
import { VisitActions } from '../../actions';
import { ShiftActions } from '../../actions/shift.actions';

const reducer = combineReducers({
  detail: payloadReducer(VisitActions.receivedDetail),
  filters: filtersReducer(BargeClerkActions.receivedPage),
  loadingDetail: loadingReducer(BargeClerkActions.details, BargeClerkActions.receivedDetail),
  loadingPage: loadingReducer(BargeClerkActions.page, BargeClerkActions.receivedPage),
  page: pageReducer(BargeClerkActions.receivedPage),
  shifts: payloadReducer(ShiftActions.receivedFor, ShiftActions.clearFor),
  saldo: payloadReducer(BargeClerkActions.receivedSaldoOverview),
  totals: payloadReducer(BargeClerkActions.receivedTotalsOverview),
  portGeofence: portGeofenceReducer,
});

export default reducer;
