// @flow
import { combineReducers } from 'redux';
import { payloadReducer } from '../payload.reducer';
import { SailingScheduleActions } from '../../actions/sailing_schedule.actions';

const reducer = combineReducers({
  schedule: payloadReducer(SailingScheduleActions.received),
});

export default reducer;
