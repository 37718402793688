// @flow
import type { TBaseAction, TPageWithFilter, TVisit, TVisitFilter, TVisitPageRequest } from '../../types';
import type { TLPLGroup, TLPLRequest } from '../../types/lpl.types';
import { TPageRequest } from '../../types';

export const LPLActions = {
  page: 'LPL_ACTIONS.PAGE',
  receivedPage: 'LPL_ACTIONS.RECEIVED_PAGE',
  details: 'LPL_ACTIONS.DETAILS',
  group: 'LPL_ACTIONS.GROUP',
  receivedGroup: 'LPL_ACTIONS.RECEIVED_GROUP',
  requests: 'LPL_ACTIONS.REQUESTS',
  receivedRequests: 'LPL_ACTIONS.RECEIVED_REQUESTS',
  createRequest: 'LPL_ACTIONS.CREATE_REQUEST',
  toggleProgressRequest: 'LPL_ACTIONS.TOGGLE_PROGRESS_REQUEST',
  completeRequest: 'LPL_ACTIONS.COMPLETE_REQUEST',
  cancelRequest: 'LPL_ACTIONS.CANCEL_REQUEST',
};

export const LPLFilterActions = {
  getBarges: 'LPL_FILTER_ACTIONS.GET_BARGES',
  getBargeOperators: 'LPL_FILTER_ACTIONS.GET_BARGE_OPERATORS',
  getBtsIds: 'LPL_FILTER_ACTIONS.GET_BTS_IDS',
  getStatuses: 'LPL_FILTER_ACTIONS.GET_STATUSES',
  getQuaysRequested: 'LPL_FILTER_ACTIONS.GET_QUAY_REQUESTED',
  getQuaysPlanned: 'LPL_FILTER_ACTIONS.GET_QUAY_PLANNED',
  receivedLPLFilter: 'LPL_FILTER_ACTIONS.RECEIVED_VISIT_FILTER',
};

export const getLPLPageAction = (payload: ?TPageRequest<TVisitFilter>): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLActions.page,
});

export const receivedLPLPageAction = (page: TPageWithFilter<TVisit>): TBaseAction<{ page: TPageWithFilter<TVisit> }> => ({
  payload: {
    page,
  },
  type: LPLActions.receivedPage,
});

export const toggleProgressLPLRequestAction = (payload: TLPLRequest): TBaseAction<TLPLRequest> => ({
  payload,
  type: LPLActions.toggleProgressRequest,
});

export const completeLPLRequestAction = (payload: TLPLRequest): TBaseAction<TLPLRequest> => ({
  payload,
  type: LPLActions.completeRequest,
});

export const cancelLPLRequestAction = (payload: TLPLRequest): TBaseAction<TLPLRequest> => ({
  payload,
  type: LPLActions.cancelRequest,
});

export const createLPLRequestAction = (payload: TLPLRequest[]): TBaseAction<TLPLRequest[]> => ({
  payload,
  type: LPLActions.createRequest,
});

export const getLPLGroupAction = (payload: TVisit): TBaseAction<TVisit> => ({
  payload,
  type: LPLActions.group,
});

export const receivedLPLGroupAction = (payload: TLPLGroup[]): TBaseAction<TLPLGroup[]> => ({
  payload,
  type: LPLActions.receivedGroup,
});

export const getLPLRequestsAction = (payload: TLPLGroup): TBaseAction<TLPLGroup> => ({
  payload,
  type: LPLActions.requests,
});

export const receivedLPLRequestsAction = (payload: TLPLRequest[]): TBaseAction<TLPLRequest[]> => ({
  payload,
  type: LPLActions.receivedRequests,
});

export const getDetailsAction = (payload: number): TBaseAction<number> => ({
  payload,
  type: LPLActions.details,
});

export const getStatusLplPageAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLFilterActions.getStatuses,
});

export const receivedLPLFilterAction = (payload: Object): TBaseAction<Object> => ({
  payload,
  type: LPLFilterActions.receivedLPLFilter,
});

export const getLPLBtsIdFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLFilterActions.getBtsIds,
});

export const getQuayRequestedLPLFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLFilterActions.getQuaysRequested,
});

export const getQuayPlannedLPLFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLFilterActions.getQuaysPlanned,
});

export const getBargeLPLFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLFilterActions.getBarges,
});

export const getBargeOperatorLPLFilterAction = (payload: ?TVisitPageRequest): TBaseAction<?TVisitPageRequest> => ({
  payload,
  type: LPLFilterActions.getBargeOperators,
});
