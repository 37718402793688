// @flow
import type { TBaseAction } from '../../types';
import { NotificationActions } from '../actions/notificationActions';

const initialState: string = '';

const reducer = (state: string = initialState, action: TBaseAction<string>) => {
  switch (action.type) {
    case NotificationActions.showNotification:
      return action.payload;
    case NotificationActions.resetNotification:
      return null;
    default:
      return state;
  }
};

export const notification = reducer;
