// @flow
import * as React from 'react';
import { useState } from 'react';
import moment from 'moment';
import { Checkbox, Col, Form, FormInstance, FormListFieldData, Input, Row, Select, TimePicker } from 'antd';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { MinusCircleOutlined } from '@ant-design/icons';
import type { TUnavailabilityWindow } from '../../../types';
import { timeFormats } from '../../../utils/format.utils';
import { PLACEHOLDER_MESSAGES } from '../../../config/messages/forms';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';

type TProps = {
  data: TUnavailabilityWindow,
  form: FormInstance,
  formField: FormListFieldData,
  removeFn: Function,
};

const formatTime = (time: ?Object) => (time ? moment(time, timeFormats.TIMEPICKER) : null);

const selectOptions = [
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.monday" defaultMessage="Monday" />,
    key: 'MONDAY',
    value: 'MONDAY',
  },
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.tuesday" defaultMessage="Tuesday" />,
    key: 'TUESDAY',
    value: 'TUESDAY',
  },
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.wednesday" defaultMessage="Wednesday" />,
    key: 'WEDNESDAY',
    value: 'WEDNESDAY',
  },
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.thursday" defaultMessage="Thursday" />,
    key: 'THURSDAY',
    value: 'THURSDAY',
  },
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.friday" defaultMessage="Friday" />,
    key: 'FRIDAY',
    value: 'FRIDAY',
  },
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.saturday" defaultMessage="Saturday" />,
    key: 'SATURDAY',
    value: 'SATURDAY',
  },
  {
    label: <FormattedMessage id="molecules.unavailability_windows.days_of_week.sunday" defaultMessage="Sunday" />,
    key: 'SUNDAY',
    value: 'SUNDAY',
  },
];

function UnavailabilityWindow(props: TProps) {
  const intl = useIntl();
  const [disabled, setDisabled] = useState(props.data ? !!props.data.allDay : false);
  const { data, form, formField, removeFn } = props;

  const toggleAllDay = () => {
    if (disabled) {
      setDisabled((prevState) => !prevState);
    } else {
      form.setFields([
        {
          name: ['unavailabilityWindows', formField.key, 'fromTime'],
          value: null,
        },
        {
          name: ['unavailabilityWindows', formField.key, 'toTime'],
          value: null,
        },
      ]);
      setDisabled((prevState) => !prevState);
    }
  };

  return (
    <Row className="form-dynamic-row" gutter={24}>
      <Form.Item {...formField} name={[formField.name, 'id']} initialValue={data ? data.id : null}>
        <Input placeholder="id" type="hidden" />
      </Form.Item>
      <Col xs={24} sm={24} md={24} lg={6} xl={4} xxl={3}>
        <Form.Item {...formField} name={[formField.name, 'dayOfWeek']} initialValue={data ? data.dayOfWeek : 'MONDAY'}>
          <Select options={selectOptions} style={{ width: '100%' }} placeholder={<FormattedMessage id="molecules.unavailability_windows.days_of_week.placeholder" defaultMessage="Select a day" />} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          {...formField}
          name={[formField.name, 'fromTime']}
          initialValue={data && !data.allDay ? formatTime(data.fromTime) : null}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: !disabled,
            },
          ]}
        >
          <TimePicker disabled={disabled} format={timeFormats.TIMEPICKER} minuteStep={1} style={{ width: '100%' }} placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.select_time)} />
        </Form.Item>
      </Col>
      <Col xs={24} sm={24} md={12} lg={6} xl={6} xxl={5}>
        <Form.Item
          {...formField}
          name={[formField.name, 'toTime']}
          initialValue={data && !data.allDay ? formatTime(data.toTime) : null}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: !disabled,
            },
            {
              validator: (rule, value) => {
                if (value && value.isBefore(form.getFieldValue(['unavailabilityWindows', formField.key, 'fromTime']))) {
                  return Promise.reject(new Error(intl.formatMessage(VALIDATION_MESSAGES.start_before_end_time)));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <TimePicker disabled={disabled} format={timeFormats.TIMEPICKER} minuteStep={1} style={{ width: '100%' }} placeholder={intl.formatMessage(PLACEHOLDER_MESSAGES.select_time)} />
        </Form.Item>
      </Col>
      <Col xs={21} sm={21} md={22} lg={16} xl={5} xxl={4}>
        <Form.Item {...formField} name={[formField.name, 'allDay']} initialValue={disabled} valuePropName="checked">
          <Checkbox onChange={toggleAllDay} checked={disabled}>
            <FormattedMessage id="molecules.unavailability_windows.all_day" defaultMessage="All day" />
          </Checkbox>
        </Form.Item>
      </Col>
      <Col xs={3} sm={3} md={2} lg={2} xl={1} xxl={1}>
        <Form.Item>
          <MinusCircleOutlined className="delete-button" onClick={removeFn} />
        </Form.Item>
      </Col>
    </Row>
  );
}

export default injectIntl(UnavailabilityWindow);
