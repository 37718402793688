// @flow
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { createPath } from '../../../utils/routing.utils';
import { SITE_PATHS } from '../../../config/api.constants';

type TProps = {
  showContactInfoModal: Function,
};

export function LinksSection({ showContactInfoModal }: TProps) {
  const visit = useSelector(selectBargeClerkVisit);

  return visit ? (
    <div className="links-section">
      {visit.lplRequested ? (
        <Link className="text-black text-underline" to={createPath(SITE_PATHS.LPL_DETAIL, { id: visit.id })}>
          <FormattedMessage id="atoms.barge_clerks.links_section.goto_lpl" defaultMessage="LPL Detail" />
        </Link>
      ) : null}
      <button type="button" className="visit-top-link" onClick={showContactInfoModal}>
        <FormattedMessage id="atoms.barge_clerks.links_section.contact_info" defaultMessage="Contact info" />
      </button>
    </div>
  ) : null;
}
