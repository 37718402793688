// @flow
import * as React from 'react';
import { Layout } from 'antd';
import { SideMenu } from '../organisms/menus/SideMenu';
import { AppTemplate } from './AppTemplate';
import { Loading } from '../atoms/Loading';

export type TCrudTemplateProps = {
  children: any,
  loading?: boolean,
  menuBlock?: string,
  menuItem?: string,
};

const { Content } = Layout;

export function CrudTemplate(props: TCrudTemplateProps) {
  return (
    <AppTemplate menuBlock="home" menuItem="home">
      <Layout>
        <SideMenu menuItem={props.menuItem} menuBlock={props.menuBlock} />
        <Content className="content-crud">{props.loading ? <Loading /> : props.children}</Content>
      </Layout>
    </AppTemplate>
  );
}

CrudTemplate.defaultProps = {
  loading: false,
  menuBlock: '',
  menuItem: '',
};
