// @flow
import { SkipperActions } from '../../actions';
import type { TSkipper } from '../../../types';

const initialState: ?TSkipper = null;

const reducer = (state: ?TSkipper = initialState, action: Object) => {
  switch (action.type) {
    case SkipperActions.receivedDetail:
      return action.payload;

    default:
      return state;
  }
};

export default reducer;
