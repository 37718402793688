// @flow
import { call, put } from 'redux-saga/effects';
import { API_STATUS_CODE, SITE_PATHS } from '../config/api.constants';
import { ENVIRONMENT, LOG_TO_SENTRY, SENTRY_URL } from '../config/settings';
import { browserHistory } from '../store';
import { log } from './log.util';
import { createHandleErrorAction, createHandleNetworkErrorAction, createHandleOtherErrorAction, createHandleServerErrorAction } from '../store/actions';

const logError = (error) => {
  log.app('error')(error);

  /* $FlowFixMe */
  if (LOG_TO_SENTRY && typeof Raven !== 'undefined') {
    // eslint-disable-next-line no-undef
    Raven.captureException(error);
  }
};

export function* handleError(err: any): Generator<any, any, any> {
  yield put(createHandleErrorAction(err));

  logError(err);

  if (err.response) {
    if (err.response.status === API_STATUS_CODE.FORBIDDEN || err.response.status === API_STATUS_CODE.UNAUTHORIZED) {
      browserHistory.push(SITE_PATHS.UNAUTHORIZED);
    }
    yield put(createHandleServerErrorAction(err));
  } else if (err.message === 'Network Error' || err.code === 'ECONNABORTED') {
    yield put(createHandleNetworkErrorAction(err));
  } else {
    yield put(createHandleOtherErrorAction(err));
  }
}

export const genericErrorHandler = (saga: Function, ...args: any) =>
  function* handleApp(action: Object): Generator<any, any, any> {
    try {
      // yield acquireToken(); // Can't dispatch acquireTokenAction because then it becomes async and the saga here may be called before a valid token is retrieved
      yield call(saga, ...args, action);
    } catch (err) {
      yield call(handleError, ...args, err);
    }
  };

export const loginErrorHandler = (saga: Function, ...args: any) =>
  function* handleApp(action: Object): Generator<any, any, any> {
    try {
      yield call(saga, ...args, action);
    } catch (err) {
      yield call(handleError, ...args, err);
      setTimeout(() => {
        window.location = '/';
      }, 15000);
    }
  };

export const initializeErrorHandler = (starter: Function) => {
  /* $FlowFixMe */
  if (LOG_TO_SENTRY && typeof Raven !== 'undefined') {
    /* $FlowFixMe */
    // eslint-disable-next-line no-undef
    Raven.context(starter);
    /* $FlowFixMe */
    // eslint-disable-next-line no-undef
    Raven.config(SENTRY_URL, {
      autoBreadcrumbs: {
        console: false, // console logging
        dom: true, // DOM interactions, i.e. clicks/typing
        location: true, // url changes, including pushState/popState
        sentry: true, // sentry events
        xhr: true, // XMLHttpRequest
      },
      environment: ENVIRONMENT,
      sanitizeKeys: [/password/i],
    }).install();
  } else {
    starter();
  }
};
