// @flow
import { UserAgentApplication } from 'msal';
import { ENV } from './environment';

export const AZURE_ID = ENV.REACT_APP_AZURE_AD_APP_ID || '';
export const AZURE_TENANT = ENV.REACT_APP_AZURE_AD_TENANT_ID || '';

const authority = ENV.NODE_ENV === 'test' ? '' : `https://login.microsoftonline.com/${AZURE_TENANT}`;

export const AZURE_CONFIG = {
  auth: {
    authority,
    clientId: AZURE_ID,
    validateAuthority: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
};

export const azureProvider = new UserAgentApplication(AZURE_CONFIG);
