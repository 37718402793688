// @flow

import { getGangsAction } from '../actions/gang.actions';
import { getGangRemarksAction } from '../actions/gang_remark.actions';
import { processMessagePerShiftQuayCombo } from './visits.handler';

type GangOverviewProps = {
  getGangsAction: typeof getGangsAction,
  quayIds: number[],
  shiftIds: number[],
};

export const updateGangsOverview = (props: GangOverviewProps) => (incomingMessage: Object) => {
  const callback = () => {
    props.getGangsAction(props.shiftIds[0], props.quayIds);
  };

  processMessagePerShiftQuayCombo(incomingMessage, props.quayIds, props.shiftIds, callback);
};

type GangRemarksDetailProps = {
  gang: Object,
  getGangRemarksAction: typeof getGangRemarksAction,
};

export const showNewGangRemarksOnDetail = (props: GangRemarksDetailProps) => () => {
  props.getGangRemarksAction(props.gang);
};
