// @flow
import { Table as AntTable } from 'antd';
import * as React from 'react';
import { useState } from 'react';
import { DEFAULT_PAGE_SIZE } from '../../../constants/pagination.constants';
import type { TPage } from '../../../types';
import { fixPaginationNumbers } from '../../../utils/pagination.utils';

export type TSimpleTableProps = {
  className: string,
  columns: Object[],
  data: TPage<any>,
  onPageChange: (page: number, pageSize: number) => void,
  onRowClick: Function,
  rowKey: string,
};

export function SimpleTable(props: TSimpleTableProps) {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const onPageChangeWrapper = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    return props.onPageChange(fixPaginationNumbers(page), pageSize);
  };

  const pagination = {
    total: props.data.totalElements,
    defaultCurrent: props.data.number + 1,
    current: props.data.number + 1,
    defaultPageSize: pageSize,
    pageSize,
    hideOnSinglePage: false,
    showQuickJumper: true,
    size: 'default',
    showSizeChanger: true,
    onChange: onPageChangeWrapper,
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100%', flex: 'auto' }} className="Table">
      <AntTable
        onRow={(record) => ({ onClick: () => props.onRowClick(record) })}
        columns={props.columns}
        pagination={pagination}
        rowKey={props.rowKey}
        dataSource={props.data.content}
        bordered
        className={props.className}
        showSorterTooltip={false}
      />
    </div>
  );
}
