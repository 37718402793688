// @flow
import { Tag, Tooltip } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CheckOutlined, ExclamationCircleOutlined, QuestionOutlined, SyncOutlined, WarningFilled } from '@ant-design/icons';
import { VISIT_STATUS } from '../../constants/visit.constants';
import type { TContainerVisit, TError, TMiniVisit, TVisit } from '../../types';
import { diffDates, formatDate, formatTimeDate, formatTimeOrNull, isBefore } from '../../utils/dates.utils';
import { DeleteButton } from './DeleteActions';
import { PrioTag, RedTag, Tag as OptiTag } from './Tags';
import { formatDuration } from '../../utils/format.utils';
import { snowFlakeTag } from './snowflake';
import { boltTag } from './bolt';
import { fourArrowsTag } from './fourArrows';
import type { TLPLGroup } from '../../types/lpl.types';
import { TAL_SIZE } from '../../constants/tal.constants';
import type { TGangMini } from '../../types/gang.types';

export const renderPriority = (priority: boolean) => (priority ? <PrioTag className="tag-bright" /> : null);

export const renderRTA = (visit: TVisit) =>
  visit.responseTimeArrival ? (
    <span>
      <Tag color="green" className="tag-rounded ">
        <FormattedMessage id="organisms.visits.RTA" defaultMessage="RTA" />
      </Tag>
      {formatDate(visit.responseTimeArrival)}
    </span>
  ) : null;

export const render = (visit: TVisit) => (
  <span>
    <Tag className="tag-rounded">
      <FormattedMessage id="organisms.visits.ETA" defaultMessage="ETA" />
    </Tag>
    {formatDate(visit.estimatedTimeArrival)}
  </span>
);

export const renderETA = (visit: TVisit) => (
  <span>
    <Tag className="tag-rounded">
      <FormattedMessage id="organisms.visits.ETA" defaultMessage="ETA" />
    </Tag>
    {formatDate(visit.estimatedTimeArrival)}
  </span>
);

export const renderRTAOrETA = (visit: TVisit) => (visit.responseTimeArrival ? renderRTA(visit) : renderETA(visit));

export const renderOPT = (visit: TVisit) => (
  <span>
    <Tag color="blue" className="tag-rounded">
      <FormattedMessage id="organisms.visits.OPT" defaultMessage="OPT" />
    </Tag>
    {formatDate(visit.operationsTime)}
  </span>
);

export const renderOPTOrRTAOrETA = (text: string, visit: TVisit) => {
  if (visit.operationsTime) {
    return renderOPT(visit);
  }
  if (visit.responseTimeArrival) {
    return renderRTA(visit);
  }
  return renderETA(visit);
};

export const renderQuayPlanned = (visit: Object) =>
  visit.quayPlanned ? (
    <span>
      <Tag color="green" className="circle">
        <CheckOutlined />
      </Tag>
      {visit.quayPlanned.name}
    </span>
  ) : null;

export const renderQuayRequested = (visit: Object) =>
  visit.quayRequested ? (
    <span>
      <Tag className="circle">
        <QuestionOutlined />
      </Tag>
      {visit.quayRequested.name}
    </span>
  ) : null;

export const renderQuay = (quay: ?Object) => (quay ? <span>{quay.name}</span> : null);

export const renderStatus = (statusBpt: string) => {
  switch (statusBpt) {
    case VISIT_STATUS.REQUESTED:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_requested" defaultMessage="Requested" />
        </span>
      );
    case VISIT_STATUS.PLANNED:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_planned" defaultMessage="Planned" />
        </span>
      );
    case VISIT_STATUS.CANCELLED:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_cancelled" defaultMessage="Cancelled" />
        </span>
      );
    case VISIT_STATUS.EXECUTING:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_executing" defaultMessage="Executing" />
        </span>
      );
    case VISIT_STATUS.DONE:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_done" defaultMessage="Done" />
        </span>
      );
    case VISIT_STATUS.PLANNING_NEEDED:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_planning_needed" defaultMessage="Planning needed" />
        </span>
      );
    case VISIT_STATUS.DECISION_CBPA:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_decision" defaultMessage="Decision CBPA" />
        </span>
      );
    case VISIT_STATUS.EXECUTION_PAUSED:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_execution_paused" defaultMessage="Execution paused" />
        </span>
      );
    default:
      return (
        <span>
          <FormattedMessage id="atoms.visit_list.status_bts_not_received" defaultMessage="Not Received" />
        </span>
      );
  }
};

export const redNumber = (number: number) => <Tag color="red">{number}</Tag>;

export const checkOrError = (number: number) =>
  number > 0 ? (
    redNumber(number)
  ) : (
    <Tag color="green">
      <CheckOutlined style={{ fontSize: '10px' }} />
    </Tag>
  );

export const talMismatch = (visit: TVisit) => {
  if (visit.talMismatchError) {
    return (
      <Tag color="red" className="circle">
        {visit.talMismatch}
      </Tag>
    );
  }
  if (visit.talMismatch > 0) {
    return <span>{visit.talMismatch}</span>;
  }
  return (
    <Tag color="green" className="circle">
      <CheckOutlined />
    </Tag>
  );
};

export const talMismatchOrEmpty = (text: string, visit: TVisit) => {
  if (visit.talMismatchError) {
    return (
      <Tag color="red" className="circle">
        {visit.talMismatch}
      </Tag>
    );
  }
  if (visit.talMismatch > 0) {
    return visit.talMismatch;
  }

  return null;
};

export const lplToRequest = (text: string, visit: TVisit) => (
  <span>
    {visit.lplToRequest ? (
      visit.lplToRequest
    ) : (
      <Tag color="green" className="circle">
        <CheckOutlined />
      </Tag>
    )}
  </span>
);

export const overlandedContainers = (text: string, visit: TVisit) => (
  <span>
    {visit.overlandedContainers ? (
      visit.overlandedContainers
    ) : (
      <Tag color="green" className="circle">
        <CheckOutlined />
      </Tag>
    )}
  </span>
);

export const deleteOrEmpty = (deleteAction: Function) =>
  // eslint-disable-next-line func-names
  function (item: Object) {
    return item.deletable && <DeleteButton onConfirm={() => deleteAction(item)} />;
  };

export const checkOrEmpty = (b: boolean) =>
  b ? (
    <Tag color="gray" className="circle">
      <CheckOutlined />
    </Tag>
  ) : null;

export const renderProcessed = (processed: boolean) =>
  processed ? (
    <Tag color="green" className="circle">
      <CheckOutlined />
    </Tag>
  ) : null;

export const renderBTSTotal = (amount: number, visit: TVisit) => (
  <div>
    {visit.containersAfterTalCutOff ? <RedTag>{amount}</RedTag> : <div>{amount}</div>}
    {visit.containersAfterTalCutOff ? (
      <div className="text-red text-extra-extra-small" style={{ marginTop: 4 }}>
        <FormattedMessage id="atoms.renderers.render_bts_total" defaultMessage="CUT-OFF" />
      </div>
    ) : null}
  </div>
);

export const renderContainerNumber = (text: string, container: TContainerVisit) =>
  !container.afterTalCutOff ? (
    <span>{container.containerNumber}</span>
  ) : (
    <span style={{ display: 'flex' }}>
      <Tooltip title={<FormattedMessage id="organisms.containers.container_list.after_cut_off" defaultMessage="Registered after cut-off" />}>
        <ExclamationCircleOutlined className="container--after-cut-off__icon" type="exclamation-circle" />
      </Tooltip>
      {container.containerNumber}
    </span>
  );

export const bargeNameAbbr = (barge: Object) =>
  barge ? (
    <div>
      <span>{barge.name}</span>
      <br />
      <span className="text-dust">{barge.tosName}</span>
    </div>
  ) : null;

export const renderFeasibilityCheck = (visit: TVisit) => {
  const notFeasibleReasons = () => (
    <ul className="tooltip-list">
      {visit.feasibleGeofence === false ? (
        <li>
          <FormattedMessage id="not_feasible.geofence.antwerp" defaultMessage="Barge was not in port area on time" />
        </li>
      ) : null}
      {visit.feasiblePrevVisit === false ? (
        <li>
          <FormattedMessage id="not_feasible.prev_visit_longer" defaultMessage="The previous visit is going to take longer then expected" />
        </li>
      ) : null}
    </ul>
  );

  // null means that the check didn't happen yet.
  return visit.feasibleGeofence === false || visit.feasiblePrevVisit === false ? (
    <Tooltip
      title={
        <div>
          <FormattedMessage id="organisms.renderers.tooltips.geofence_too_late" defaultMessage="Arrival time is not feasible" />:{notFeasibleReasons()}
        </div>
      }
    >
      <WarningFilled className={!visit.feasible ? 'time-not-in-geofence' : ''} />
    </Tooltip>
  ) : null;
};

export const renderATAOPS = (text: string, visit: TVisit) => {
  let tag;
  let timeStamp;
  let delta;
  let before;

  if (visit.operationsTime) {
    tag = (
      <OptiTag color="green">
        <FormattedMessage id="organisms.barge_clerks.visit_list.ops" defaultMessage="OPS" />
      </OptiTag>
    );
    timeStamp = visit.operationsTime;
  } else if (visit.responseTimeArrival) {
    tag = <OptiTag>RTA</OptiTag>;
    timeStamp = visit.responseTimeArrival;
  } else if (visit.estimatedTimeArrival) {
    tag = <OptiTag>ETA</OptiTag>;
    timeStamp = visit.estimatedTimeArrival;
  }

  if (visit.actualTimeArrival && visit.ataRtaOpsMismatch) {
    const compareTimestamp = visit.operationsTime || visit.responseTimeArrival;

    if (compareTimestamp) {
      delta = diffDates(visit.actualTimeArrival, compareTimestamp);
      before = isBefore(visit.actualTimeArrival, compareTimestamp);
    }
  }

  if (tag || timeStamp || delta) {
    const hasError = visit.feasibleGeofence === false || visit.feasiblePrevVisit === false;
    const classNameTime = hasError ? 'time-not-in-geofence' : 'visit-list-table-time-okay';

    return (
      <div className="text-center">
        {tag ? <div style={{ marginBottom: 5 }}>{tag}</div> : null}
        {timeStamp ? (
          <div>
            <span className={classNameTime}>
              {formatDate(timeStamp)} {renderFeasibilityCheck(visit)}
            </span>
          </div>
        ) : null}
        {delta ? (
          <div style={{ marginLeft: -10 }} className={`ATAOPS-timestamp ${before ? 'text-green' : 'text-red'}`}>
            {before ? `+${formatDuration(delta)}` : `-${formatDuration(delta)}`}
          </div>
        ) : null}
      </div>
    );
  }

  return null;
};

export const tosSquare = (topLeft: Object, topRight: Object, center: number, bottomLeft: Object, bottomRight: Object) => (
  <div className="tos-square">
    <div className="tos-square-section">
      <div className={`tos-square-section-number ${topLeft.mismatch ? 'mismatch' : ''}`}>{topLeft.value || ''}</div>
      <div className={`tos-square-section-number bottom ${bottomLeft.mismatch ? 'mismatch' : ''}`}>{bottomLeft.value || ''}</div>
    </div>
    <div className="tos-square-section-middle">
      <div className="tos-square-section-number">
        <span className="tos-square-section-number-middle">
          <b>{center}</b>
        </span>
      </div>
    </div>
    <div className="tos-square-section">
      <div className={`tos-square-section-number ${topRight.mismatch ? 'mismatch' : ''} `}>{topRight.value || ''}</div>
      <div className={`tos-square-section-number bottom ${bottomRight.mismatch ? 'mismatch' : ''}`}>{bottomRight.value || ''}</div>
    </div>
  </div>
);

export const tosSquareSide = () => (
  <div className="tos-square-side">
    <span className="tos-square-side-number">20</span>
    <span className="tos-square-side-number">40</span>
  </div>
);

export const specialContainers = (text: string, visit: TVisit) => (
  <div className="special-containers">
    {visit.operationalReeferTotal ? snowFlakeTag : null} {visit.imdgTotal ? boltTag : null} {visit.oversizeTotal ? fourArrowsTag : null}
  </div>
);

export const gangRenderer = (text: string, visit: TVisit) =>
  !visit.ghostVisit && <div className="special-containers">{visit.gangs.length ? visit.gangs.map((gang: TGangMini) => <Tag color={gang.color}>{gang.crane || '?'}</Tag>) : null}</div>;

export const lplTypeRenderer = (text: string, lplGroup: TLPLGroup) => {
  let size;

  switch (lplGroup.sizeType) {
    case TAL_SIZE.FT_20:
      size = '20ft.';
      break;
    default:
      size = '40ft.';
  }

  return `${size} ${lplGroup.type}`;
};

export const prevNextQuayRenderer = (visit: TVisit, miniVisit: TMiniVisit) => {
  if (!miniVisit || !miniVisit.btsId) return null;

  return (
    <div className="quay-cell-wrapper">
      <Tooltip
        title={
          <div>
            <p>BTS id: {miniVisit.btsId}</p>
            {formatTimeDate(miniVisit.etaRtaOps)}
          </div>
        }
      >
        <div className="quay-info">
          <span className="quay-info-section">{formatTimeOrNull(miniVisit.etaRtaOps)}</span>
          <span className={miniVisit.concurrentVisitAmount > 1 ? 'multiple-planned-visits' : ''}>{miniVisit.quayName}</span>
          <span className="quay-info-section">{miniVisit.psaVisit ? `${miniVisit.numberOfContainersToDo}/${miniVisit.btsNumberOfContainers}` : miniVisit.btsNumberOfContainers}</span>
        </div>
        {visit.status === VISIT_STATUS.EXECUTING ? <SyncOutlined /> : null}
      </Tooltip>
    </div>
  );
};

export const stringArrayRenderer = (strings: string[]) => (
  <div style={{ display: 'flex', flexDirection: 'column' }}>
    {strings.map((str) => (
      <span>{str}</span>
    ))}
  </div>
);

export const containerImdgRenderer = (text: string, record: TContainerVisit) => {
  const imdgStrings = [];
  if (record.imdg1) imdgStrings.push(record.imdg1);
  if (record.imdg2) imdgStrings.push(record.imdg2);
  if (record.imdg3) imdgStrings.push(record.imdg3);

  return stringArrayRenderer(imdgStrings);
};

export const errorMovementRenderer = (text: String, record: TError) => {
  if (!record.hasContainerVisit) return null;
  return record.movement === 'OUT' ? (
    <FormattedMessage id="organisms.visits.visit_detail.load" defaultMessage="Laden" />
  ) : (
    <FormattedMessage id="organisms.visits.visit_detail.unload" defaultMessage="Lossen" />
  );
};
