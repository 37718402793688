// @flow
import type { TBaseAction } from '../../types';

export const payloadReducer =
  (actionType: string, clearType?: string) =>
  (state: ?any = null, action: TBaseAction<any>) => {
    switch (action.type) {
      case actionType:
        return action.payload;
      case clearType:
        return null;
      default:
        return state;
    }
  };
