// @flow
import * as React from 'react';
import { defineMessages, injectIntl, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import { createBargeOperatorAction } from '../../../store/actions';
import BargeOperatorForm from '../../organisms/barge_operators/CreateForm';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';

type TProps = {
  createBargeOperatorAction: typeof createBargeOperatorAction,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: {
    defaultMessage: 'Create barge operator',
    id: 'pages.barge_operator.create.header.title',
  },
});

function BargeOperatorCreate(props: TProps) {
  const intl = useIntl();

  return (
    <EditTemplate title={intl.formatMessage(EXTRA_TRANSLATIONS.title)} menuBlock="barge-operators" menuItem="barge-operators-create">
      <BargeOperatorForm handleSubmit={props.createBargeOperatorAction} />
    </EditTemplate>
  );
}

export const BargeOperatorCreatePage = injectIntl(connect<any, Object, _, _, _, _>(undefined, { createBargeOperatorAction })(BargeOperatorCreate));
