// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, InputNumber, Popconfirm, TimePicker } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import Icon, { CheckOutlined, DeleteFilled, LoadingOutlined, MessageFilled } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { getGangRemarksAction } from '../../../store/actions/gang_remark.actions';
import { showNewGangRemarksOnDetail } from '../../../store/handlers/gangs.handler';
import type { TGlobalState } from '../../../types';
import type { TGangOM } from '../../../types/gang.types';
import { SPACING } from '../../../constants/style.constants';
import { ReactComponent as assignment } from '../../../resources/material-assignment.svg';
import { timeFormats } from '../../../utils/format.utils';
import { GangRemarkModal } from '../modals/GangRemarkModal';
import { Socket } from '../Socket';

type TProps = {
  gangOm: TGangOM,
  onSave: Function,
  onDelete: Function,
  onSync: Function,
  shiftId: number,
  quayId: number,
  shiftStartTime: string,
  shiftEndTime: string,
};

function GangFormRowComponent({ gangOm, onSave, onDelete, onSync }: TProps) {
  const intl = useIntl();
  const [form] = useForm();

  const [edited, setEdited] = useState(false);
  const [saving, setSaving] = useState(false);
  const [startTime, setStartTime] = useState(gangOm && gangOm.startTime && moment(gangOm.startTime));
  const [endTime, setEndTime] = useState(gangOm && gangOm.endTime && moment(gangOm.endTime));
  const [workload, setWorkload] = useState((gangOm && gangOm.workload) || 0);
  const [remarksModalVisible, setRemarksModalVisible] = useState(false);
  const [numberOfRemarks, setNumberOfRemarks] = useState(gangOm.numberOfRemarks);

  useEffect(() => {
    if (!gangOm.isNew) {
      getGangRemarksAction(gangOm);
    }

    setStartTime(gangOm && gangOm.startTime && moment(gangOm.startTime));
    setEndTime(gangOm && gangOm.endTime && moment(gangOm.endTime));
    setWorkload((gangOm && gangOm.workload) || 0);
  }, [gangOm]);

  const handleSubmit = () => {
    setSaving(true);

    form
      .validateFields()
      .then((values) => {
        const gangOmPayload: TGangOM = {
          ...gangOm,
          ...values,
        };

        onSave(gangOmPayload, () => {
          setSaving(false);
          setEdited(false);
        });
      })
      .catch(() => {
        setSaving(false);
      });
  };

  const showRemarks = () => {
    setRemarksModalVisible(true);
  };

  const hideRemarks = (remarkCount: number) => {
    setRemarksModalVisible(false);
    setNumberOfRemarks(remarkCount);

    const newGangOm = { ...gangOm, numberOfRemarks: remarkCount };
    onSync(newGangOm, {});
  };

  return (
    <Form
      form={form}
      layout="inline"
      onFinish={handleSubmit}
      className="gang-row"
      onValuesChange={(props, changedValues, allValues) => {
        onSync(gangOm, allValues);
      }}
    >
      <div className="gang-row-form">
        <Form.Item
          name="startTime"
          initialValue={startTime}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
          ]}
          validateTrigger="onSubmit"
        >
          <TimePicker onChange={() => setEdited(true)} format={timeFormats.GANG} />
        </Form.Item>
        <Form.Item
          name="endTime"
          initialValue={endTime}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
          ]}
          validateTrigger="onSubmit"
        >
          <TimePicker onChange={() => setEdited(true)} format={timeFormats.GANG} />
        </Form.Item>
        <Form.Item style={{ marginRight: SPACING.DP / 2 }}>
          <Icon component={assignment} height="4em" width="4em" />
        </Form.Item>
        <Form.Item
          initialValue={workload}
          name="workload"
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
          ]}
          validateTrigger="onSubmit"
        >
          <InputNumber min={0} onChange={() => setEdited(true)} />
        </Form.Item>
      </div>
      <div className="gang-row-actions">
        <Form.Item>
          <Button htmlType="submit" disabled={saving || (!edited && gangOm)}>
            {saving ? <LoadingOutlined /> : <CheckOutlined />}
          </Button>
          {!gangOm.isNew ? (
            <Socket
              topics={[EVENT_TYPES.GANG_REMARKS.replace(':id', gangOm.id)]}
              onMessage={showNewGangRemarksOnDetail({
                getGangRemarksAction,
                gang: gangOm,
              })}
              socketKey={`GangFormRow.${gangOm.id}`}
            >
              {
                // Socket expects children, this is a workaround.
              }
            </Socket>
          ) : null}
          <Button htmlType="button" disabled={gangOm.isNew} onClick={showRemarks}>
            <MessageFilled /> {numberOfRemarks || '0'}
          </Button>
          <Popconfirm
            onConfirm={onDelete}
            onCancel={(e) => e.stopPropagation()}
            title={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_title" defaultMessage="Are you sure ?" />}
            okText={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_confirm" defaultMessage="Delete" />}
            cancelText={<FormattedMessage id="organisms.confirmation_dialog.delete_popup_cancel" defaultMessage="Cancel" />}
          >
            <Button>
              <DeleteFilled />
            </Button>
          </Popconfirm>
        </Form.Item>
      </div>
      <GangRemarkModal gang={gangOm} onClose={hideRemarks} visible={remarksModalVisible} />
    </Form>
  );
}

const mapStateToProps = (state: TGlobalState) => ({
  remarks: state.gangRemarks.remarks,
});

export const GangFormRow = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getGangRemarksAction,
})(GangFormRowComponent);
