// @flow
import { createSelector } from 'reselect';
import type { TGlobalState, TVisit } from '../../types';
import { createPath } from '../../utils/routing.utils';
import { API_PATHS } from '../../config/api.constants';

export const selectBargeClerkVisit = (state: TGlobalState) => state.bargeClerks.detail;

export const selectBargeClerkVisitRemarks = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.remarksBts : null));
export const selectBargeClerkVisitOverlandedContainers = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.overlandedContainers : null));
export const selectBargeClerkVisitContainersAfterTalCutoff = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.containersAfterTalCutOff : null));
export const selectBargeClerkVisitBarge = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.barge : null));
export const selectBargeClerkVisitIdleTimeReason = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.idleTimeReason : null));

export const selectBargeClerkVisitStatus = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.status : null));
export const selectBargeClerkVisitBargeOperator = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.bargeOperator : null));
export const selectBargeClerkVisitBtsId = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.btsId : null));

export const selectBargeClerkVisitErrorsSentAt = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.errorsSentAt : null));

export const selectBargeClerkVisitB2BLink = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.b2bUrl : null));
export const selectBargeClerkVisitId = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.id : null));
export const selectBargeClerkVisitIdleTimeReasonLink = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? createPath(API_PATHS.V1.VISIT_IDLE_TIME_REASON, { id: visit.id }) : null));
export const selectSendErrorsLink = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? createPath(API_PATHS.V1.VISIT_SEND_ERRORS, { id: visit.id }) : null));

export const selectBargeClerkVisitTimes = createSelector(selectBargeClerkVisit, (visit: TVisit) =>
  visit
    ? {
        responseTimeArrival: visit.responseTimeArrival,
        estimatedTimeArrival: visit.estimatedTimeArrival,
        actualTimeArrival: visit.actualTimeArrival,
        operationsTime: visit.operationsTime,
      }
    : // eslint-disable-next-line
      null,);

export const selectBargeClerkUpdatedAt = createSelector(selectBargeClerkVisit, (visit: TVisit) => (visit ? visit.updatedAt : null));
