import { defineMessages } from 'react-intl';

export const SESSION_FILTER_KEY = 'visitFilter';

export const SPECIAL_CONTAINER_TYPES = {
  IMDG: 'IMDG',
  OPERATIONAL_REEFER: 'OPERATIONAL_REEFER',
  OVERSIZE: 'OVERSIZE',
};

export const VISIT_STATUS = {
  REQUESTED: 'REQUESTED',
  PLANNING_NEEDED: 'PLANNING_NEEDED',
  CANCELLED: 'CANCELLED',
  DONE: 'EXECUTED',
  PLANNED: 'PLANNED',
  EXECUTING: 'IN_EXECUTION',
  EXECUTION_PAUSED: 'EXECUTION_PAUSED',
  DECISION_CBPA: 'DECISION_CBPA',
};

export const VISIT_ACTIONS = defineMessages({
  ERRORS_SENT: {
    id: 'ERRORS_SENT',
    defaultMessage: 'Errors sent',
  },
  ERRORS_ARCHIVED: {
    id: 'ERRORS_ARCHIVED',
    defaultMessage: 'Errors (un)archived',
  },
  TAL_CUTOFF_REACHED: {
    id: 'TAL_CUTOFF_REACHED',
    defaultMessage: 'TAL cutoff reached',
  },
  BTS_TAL_UPDATE: {
    id: 'BTS_TAL_UPDATE',
    defaultMessage: 'BTS TAL update',
  },
  BTSBPT_UPDATE: {
    id: 'BTSBPT_UPDATE',
    defaultMessage: 'BTS BPT update',
  },
  NON_BTSBPT_UPDATE: {
    id: 'NON_BTSBPT_UPDATE',
    defaultMessage: 'Non-BTS BPT update',
  },
  LPLREQUEST_CREATED: {
    id: 'LPLREQUEST_CREATED',
    defaultMessage: 'LPL request created',
  },
  DP_PROCESSED: {
    id: 'DP_PROCESSED',
    defaultMessage: 'Processed',
  },
  CANCELLED: {
    id: 'CANCELLED',
    defaultMessage: 'Cancelled',
  },
});

export const DEBOUNCE_TIME_MS = 1000;
