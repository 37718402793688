// @flow
import { put, select, call, take, takeLatest, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { appApi } from '../../config/api.config';
import { elearningToggleAction, ElearningActions, UserActions, elearningContentAction, elearningGetContentAction } from '../actions';
import type { TBaseAction, TElearningPopover } from '../../types';
import { browserHistory } from '../index';

const STORAGE_KEY = 'optibarge.elearning';
export const API_ELEARNING_URL = '/v1/elearnings';

function localStorageChannel() {
  return eventChannel((emitter) => {
    if (window && window.localStorage) {
      const listener = (e) => {
        if (e.key === STORAGE_KEY) {
          emitter(window.localStorage.getItem(STORAGE_KEY));
        }
      };

      window.addEventListener('storage', listener);

      return () => {
        window.removeEventListener('storage', listener);
      };
    }

    return () => {};
  });
}

function* listenLocalStorage(): Generator<*, *, *> {
  const chan = yield call(localStorageChannel);

  try {
    while (true) {
      const elearning = yield take(chan);
      const currentElearning = yield select((state) => state.elearning.active);

      if (elearning !== currentElearning) {
        yield put(elearningToggleAction());
      }
    }
  } finally {
    // Do nothing
  }
}

function* initElearning(): Generator<*, *, *> {
  if (window && window.localStorage) {
    const elearning: string = window.localStorage.getItem(STORAGE_KEY);
    if (elearning === 'true') {
      yield put(elearningToggleAction());
    }
    yield call(listenLocalStorage);
  }
}

function* saveElearning(): Generator<*, *, *> {
  if (window && window.localStorage) {
    const elearning: boolean = yield select((state) => state.elearning.active);
    window.localStorage.setItem(STORAGE_KEY, elearning.toString());
  }
}

function* getElearningContent(action: TBaseAction<string>): Generator<*, *, *> {
  const key = (action.payload || '').trim();
  const url = `${API_ELEARNING_URL}/${key}`;
  try {
    const response = yield call(appApi.get, url);
    yield put(elearningContentAction(response.data));
  } catch (e) {
    yield put(
      elearningContentAction({
        key,
        title: `Key ${key} is not found`,
        body: '<p>Maybe the translation has not been set, contact your administrator</p>',
        error: true,
        editable: false,
      }),
    );
  }
}

function base64EncodeBodyAndReturnPayload(action) {
  const payload = action.payload;
  payload.body = btoa(action.payload.body);
  return payload;
}

function* saveElearningContent(action: TBaseAction<TElearningPopover>): Generator<*, *, *> {
  const { key } = action.payload;
  const url = `${API_ELEARNING_URL}/${key}`;
  yield call(appApi.put, url, base64EncodeBodyAndReturnPayload(action));
  yield put(elearningGetContentAction(key));
  browserHistory.goBack();
}

export function* ElearningSaga(): Generator<*, *, *> {
  yield takeLatest(UserActions.appInit, initElearning);
  yield takeLatest(ElearningActions.toggle, saveElearning);
  yield takeEvery(ElearningActions.getContent, getElearningContent);
  yield takeEvery(ElearningActions.saveContent, saveElearningContent);
}
