// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { MDDetailTable, renderLabel } from './MDDetailTable';
import type { TLabel } from '../../../../types/label.types';

type TProps = {
  bargeSkipperIdLabels: TLabel[],
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'tables.details.barge_skipper_details', defaultMessage: 'Barges' },
});

const BargeSkipperDetailProps = [
  {
    dataIndex: 'label',
    render: renderLabel,
  },
  {
    dataIndex: 'filler1',
    render: () => '',
    width: 200,
  },
  {
    dataIndex: 'filler2',
    render: () => '',
    width: 200,
  },
  {
    dataIndex: 'filler3',
    render: () => '',
    width: 200,
  },
];

const bargeSkipperDetailTable = (props: TProps) => (
  <MDDetailTable columns={BargeSkipperDetailProps} dataSource={props.bargeSkipperIdLabels} title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} />
);

export const BargeSkipperDetailTable = injectIntl(bargeSkipperDetailTable);
