// @flow
import React, { useEffect } from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { SITE_PATHS } from '../../../config/api.constants';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '../../../constants/pagination.constants';
import { browserHistory } from '../../../store';
import { getErrorCodePageAction, removeErrorCodeAction } from '../../../store/actions/error_code.actions';
import { errorCodeListSelector } from '../../../store/selectors/error_code.selectors';
import { createPath } from '../../../utils/routing.utils';
import { ErrorCodeTableProps } from '../../atoms/Tables/ErrorCodeTableProps';
import { SimpleTable } from '../../atoms/Tables/SimpleTable';
import { ListTemplate } from '../../templates/MasterData/ListTemplate';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  button: { id: 'pages.error_codes.list.header.button', defaultMessage: 'Add new error code' },
  title: { id: 'pages.error_code.list.header.title', defaultMessage: 'Error codes' },
});

function ErrorCodeList(props: TProps) {
  const dispatch = useDispatch();
  const data = useSelector(errorCodeListSelector);

  const loadPage = (page: number, pageSize: number) => {
    dispatch(getErrorCodePageAction({ filter: {}, paging: { size: pageSize, number: page } }));
  };

  const goToDetail = (record) => browserHistory.push(createPath(SITE_PATHS.ERROR_CODE_DETAIL, { id: record.id }));
  const deleteRow = (record) => dispatch(removeErrorCodeAction(record));

  useEffect(() => {
    loadPage(DEFAULT_PAGE, DEFAULT_PAGE_SIZE);
  }, [dispatch]);

  return (
    <ListTemplate
      buttonText={props.intl.formatMessage(EXTRA_TRANSLATIONS.button)}
      createRoute={SITE_PATHS.ERROR_CODE_CREATE}
      title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}
      menuBlock="error-codes"
      menuItem="error-codes-overview"
    >
      <SimpleTable onRowClick={goToDetail} onPageChange={loadPage} columns={ErrorCodeTableProps(deleteRow)} data={data} rowKey="id" className="barge-table-list" />
    </ListTemplate>
  );
}

export const LaundryCodeListPage = injectIntl(ErrorCodeList);
