// @flow
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { indexOf, keys } from 'lodash';
import { appApi } from '../../config/api.config';
import type { TBaseAction, TBTSUpdate, TGlobalState, TPageRequest, TVisit, TVisitFilter, TVisitPageRequest } from '../../types';
import { genericErrorHandler } from '../../utils';
import {
  getVisitContainerPageAction,
  receivedVisitAction,
  receivedVisitContactDetailsAction,
  receivedVisitFilterAction,
  receivedVisitPageAction,
  reducePriorityAction,
  reduceProcessedAction,
  setVisitAvailableLoadingAction,
  setVisitPriorityLoadingAction,
  setVisitProcessedLoadingAction,
  VisitActions,
  VisitFilterActions,
} from '../actions';
import { getLPLRemarksAction } from '../actions/lpl_remark.actions';
import { getVisitRemarksAction } from '../actions/visit_remark.actions';
import * as ErrorSagas from './error.sagas';
import { formatUri, pageToPageRequest } from '../../utils/format.utils';
import type { TActualTimeArrival, TOperationsTime } from '../../types/barge_clerk.types';
import { IDLE_TIME_REASON_MESSAGES } from '../../config/messages/idleTimeReasons';
import { API_PATHS } from '../../config/api.constants';
import { createPath } from '../../utils/routing.utils';
import { deleteTasks, takeLatestWithManualPrio } from './take_latest_with_prio.sagas';

export function* detail(action: TBaseAction<number>): Generator<*, *, *> {
  const response = yield call(appApi.get, `${API_PATHS.V1.VISIT}/${action.payload}`);

  yield put(receivedVisitAction(response.data));
}

export function* doPageCall(action: TBaseAction<?TPageRequest<TVisitFilter>>): Generator<*, *, *> {
  const visitUri = API_PATHS.V1.VISIT_DP_SEARCH_VIEW;
  const uri = action.payload ? formatUri(visitUri, action.payload) : visitUri;

  return yield call(appApi.post, uri, (action.payload && action.payload.filter) || {});
}

export function* page(action: TBaseAction<?TVisitPageRequest>): Generator<*, *, *> {
  const response = yield call(doPageCall, action);
  const { data } = response;

  // Custom conversion because backend is very tightly coupled and it would take weeks to refactor.
  data.data.content = data.data.content.map((entity) => {
    const newEntity = entity;
    newEntity.id = newEntity.visitId;
    return newEntity;
  });

  yield put(receivedVisitPageAction(data, action.payload?.prio, action.type));
}

export function* getStatuses(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_STATUS_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ status: response.data }));
}

export function* getShifts(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_SHIFTS_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ shift: response.data }));
}

export function* getQuaysRequested(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_QUAYS_REQUESTED_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ quayRequested: response.data }));
}

export function* getQuaysPlanned(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_QUAYS_PLANNED_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ quayPlanned: response.data }));
}

export function* getBtsIds(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BTS_ID_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ btsId: response.data }));
}

export function* getBarges(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BARGES_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ barge: response.data }));
}

export function* getBargeOperators(): Generator<*, *, *> {
  const filters = yield select((state) => state.visits.page.filter);
  const response = yield call(appApi.post, API_PATHS.V1.VISIT_FILTER_BARGE_OPERATORS_DATA_PROCESSOR, filters);

  yield put(receivedVisitFilterAction({ bargeOperator: response.data }));
}

// Page specific saga because visit detail needs to be fetched before other saga calls are made
export function* details(action: TBaseAction<string>): Generator<*, *, *> {
  const containerPageInfo = yield select((state: TGlobalState) => state.containerVisits.page);
  yield detail(action);
  yield all([
    put(
      getVisitContainerPageAction({
        pageable: containerPageInfo ? pageToPageRequest(containerPageInfo) : null,
        visitId: action.payload,
      }),
    ),
    ErrorSagas.visitInternalList({ payload: action.payload }),
    ErrorSagas.visitExternalList({ payload: action.payload }),
    put(getVisitRemarksAction(action.payload)),
    put(getLPLRemarksAction(action.payload)),
  ]);
}

export function* updateBTS(action: TBaseAction<TBTSUpdate>): Generator<*, *, *> {
  const visitUri = createPath(API_PATHS.V1.VISIT_BTS, {
    id: action.payload.visitId,
    direction: action.payload.direction.toUpperCase(),
    size: action.payload.size,
    state: action.payload.state === 'empty' ? 'E' : 'F',
  });
  yield call(appApi.post, `${visitUri}/${action.payload.amount}`);
}

export function* process(action: TBaseAction<TVisit>): Generator<*, *, *> {
  const previousProcessed = action.payload.processed;
  const processedUrl = createPath(API_PATHS.V1.VISIT_PROCESSED, {
    id: action.payload.id,
    value: !previousProcessed,
  });
  yield put(reduceProcessedAction(!previousProcessed, Date()));
  yield put(setVisitProcessedLoadingAction());
  try {
    const response = yield call(appApi.post, processedUrl, {});
    yield put(reduceProcessedAction(response.data, Date()));
  } catch {
    yield put(reduceProcessedAction(previousProcessed, Date()));
  }
}

export function* priority(action: TBaseAction<{ previousPriority: boolean, newPriority: boolean, link: string }>): Generator<*, *, *> {
  const { previousPriority, newPriority, link } = action.payload;
  if (newPriority !== previousPriority) {
    yield put(setVisitPriorityLoadingAction());
    yield put(reducePriorityAction(newPriority));
    try {
      const response = yield call(appApi.post, link, {});
      yield put(reducePriorityAction(response.data));
    } catch {
      yield put(reducePriorityAction(previousPriority));
    }
  }
}

export function* bollard(action: TBaseAction<{ visit: TVisit, from: number, to: number }>): Generator<*, *, *> {
  const uri = createPath(API_PATHS.V1.VISIT_BOLLARDS, { id: action.payload.visit.id });
  const response = yield call(appApi.put, `${uri}/${action.payload.from}/${action.payload.to}`);
  if (action.callback) {
    action.callback(response.status);
  }
}

export function* available(action: TBaseAction<{ previousAvailable: boolean, newAvailable: boolean, link: string }>): Generator<*, *, *> {
  const { previousAvailable, newAvailable, link } = action.payload;
  if (previousAvailable !== newAvailable) {
    yield put(setVisitAvailableLoadingAction());
    yield call(appApi.post, link, {});
  }
}

export function* operationsTime(action: TBaseAction<{ operationsTime: TOperationsTime, visit: TVisit }>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_OPERATIONS_TIME, { id: action.payload.visit.visitId || action.payload.visit.id });
  yield call(appApi.put, url, action.payload.operationsTime);
}

export function* undoOperationsTime(action: TBaseAction<TVisit>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_OPERATIONS_TIME, { id: action.payload.id });
  yield call(appApi.delete, url);
}

export function* actualTimeArrival(action: TBaseAction<{ actualTimeArrival: TActualTimeArrival, visit: TVisit }>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_ACTUAL_TIME_ARRIVAL, { id: action.payload.visit.id });
  yield call(appApi.put, url, action.payload.actualTimeArrival);
}

export function* idleTimeReason(action: TBaseAction<{ reason: ?string, link: string }>): Generator<*, *, *> {
  if (indexOf(keys(IDLE_TIME_REASON_MESSAGES), action.payload.reason) === -1 && action.payload.reason) {
    // eslint-disable-next-line no-throw-literal
    throw `INVALID REASON: ${action.payload.reason}`;
  }

  const idleTimeReasonUrl = action.payload.link;
  yield call(appApi.put, idleTimeReasonUrl, { reason: action.payload.reason });
}

export function* cancel(action: TBaseAction<{ reason: ?string, visit: TVisit }>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_LB_CANCEL, { id: action.payload.visit.id });
  yield call(appApi.put, url, { reason: action.payload.reason });
}

export function* gang(action: TBaseAction<{ gangId: number, visit: TVisit }>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_GANG, { id: action.payload.visit.id });
  yield call(appApi.put, `${url}/${action.payload.gangId}`);
}

export function* removeGang(action: TBaseAction<{ gangId: number, visit: TVisit }>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_GANG, { id: action.payload.visit.id });
  yield call(appApi.delete, `${url}/${action.payload.gangId}`);
}

export function* getContactDetails(action: TBaseAction<TVisit>): Generator<*, *, *> {
  const url = createPath(API_PATHS.V1.VISIT_CONTACT_DETAILS, { id: action.payload.id });
  const response = yield call(appApi.get, url);
  yield put(receivedVisitContactDetailsAction(response.data));
}

export function* VisitSaga(): Generator<*, *, *> {
  yield takeLatest(VisitActions.detail, genericErrorHandler(detail));
  yield takeLatestWithManualPrio(VisitActions.page, genericErrorHandler(page));
  yield takeLatest(VisitActions.receivedPage, genericErrorHandler(deleteTasks));
  yield takeLatest(VisitActions.detailPageDetails, genericErrorHandler(details));
  yield takeLatest(VisitActions.updateBTS, genericErrorHandler(updateBTS));
  yield takeLatest(VisitActions.process, genericErrorHandler(process));
  yield takeLatest(VisitActions.priority, genericErrorHandler(priority));
  yield takeLatest(VisitActions.available, genericErrorHandler(available));
  yield takeLatest(VisitActions.operationsTime, genericErrorHandler(operationsTime));
  yield takeLatest(VisitActions.undoOperationsTime, genericErrorHandler(undoOperationsTime));
  yield takeLatest(VisitActions.actualTimeArrival, genericErrorHandler(actualTimeArrival));
  yield takeLatest(VisitActions.setIdleTimeReason, genericErrorHandler(idleTimeReason));
  yield takeLatest(VisitActions.cancel, genericErrorHandler(cancel));
  yield takeLatest(VisitActions.bollard, genericErrorHandler(bollard));
  yield takeEvery(VisitActions.addGang, genericErrorHandler(gang));
  yield takeEvery(VisitActions.removeGang, genericErrorHandler(removeGang));
  yield takeLatest(VisitActions.getContactDetails, genericErrorHandler(getContactDetails));
  yield takeLatest(VisitFilterActions.getStatuses, genericErrorHandler(getStatuses));
  yield takeLatest(VisitFilterActions.getShifts, genericErrorHandler(getShifts));
  yield takeLatest(VisitFilterActions.getQuaysRequested, genericErrorHandler(getQuaysRequested));
  yield takeLatest(VisitFilterActions.getQuaysPlanned, genericErrorHandler(getQuaysPlanned));
  yield takeLatest(VisitFilterActions.getBtsIds, genericErrorHandler(getBtsIds));
  yield takeLatest(VisitFilterActions.getBarges, genericErrorHandler(getBarges));
  yield takeLatest(VisitFilterActions.getBargeOperators, genericErrorHandler(getBargeOperators));
}
