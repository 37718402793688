// @flow
import type { TBargeOperator, TBargeOperatorForm } from '../../types';
import { UnavailabilityWindow } from './UnavailabilityWindow';

export class BargeOperator {
  static fromJson(bargeOperator: Object) {
    return { ...bargeOperator, unavailabilityWindows: bargeOperator.unavailabilityWindows.map((window) => UnavailabilityWindow.fromJson(window)) };
  }

  static toJson(bargeOperator: TBargeOperator | TBargeOperatorForm) {
    // $FlowFixMe
    return { ...bargeOperator, unavailabilityWindows: (bargeOperator.unavailabilityWindows || []).map((window) => UnavailabilityWindow.toJson(window)) };
  }
}
