// @flow

import type { TBaseAction } from '../../../types';
import { GangRemarkActions } from '../../actions/gang_remark.actions';

export const remarkReducer = (state: Object[] = [], action: TBaseAction<any>) => {
  switch (action.type) {
    case GangRemarkActions.received: {
      return [...action.payload];
    }
    default:
      return state;
  }
};
