// @flow
import type { TGangLB } from '../../../types/gang.types';
import { GangActions } from '../../actions/gang.actions';

const reducer = (state: ?(TGangLB[]) = [], action: Object) => {
  switch (action.type) {
    case GangActions.receivedGetGangs:
      return [...action.payload.map((gang) => gang.lb)];
    case GangActions.deleteGangLB:
      return state.filter((gangItem) => gangItem.id !== action.payload.gang.id);
    case GangActions.handleCreateGangLBResponse:
      return [...state, action.payload];
    case GangActions.handleUpdateGangLBResponse:
      return [
        ...state.map((gang) => {
          if (gang.id === action.payload.id) {
            return action.payload;
          }
          return gang;
        }),
      ];
    default:
      return state;
  }
};

export const listReducer = reducer;
