// @flow
import * as React from 'react';
import { Button } from 'antd';
import Icon from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as materialArrow } from '../../../resources/material-subdirectory.svg';

type TProps = {
  enabled: boolean,
  showModal: Function,
};

export function RequestActionBar(props: TProps) {
  return (
    <div className="action-bar-table-header lpl-action-bar-table-header">
      <Icon theme="outlined" className="icon-downcurved" component={materialArrow} style={{ fontSize: '18px', position: 'relative', top: 8 }} />{' '}
      <FormattedMessage id="empties_yard.request_action_bar.selection_instruction" defaultMessage="Select to request containers" />
      <Button type="default" onClick={props.showModal} className="send-btn btn-default" disabled={!props.enabled}>
        <FormattedMessage id="empties_yard.request_action_bar.send" defaultMessage="Send request to empties yard" />
      </Button>
    </div>
  );
}
