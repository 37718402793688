// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { DPTemplate } from '../templates/DPTemplate';

export function UnauthorizedPage() {
  return (
    <DPTemplate menuBlock="unauthorized" menuItem="unauthorized">
      <div className="text-center unauthorized-wrapper">
        <ExclamationCircleTwoTone className="unauthorized-icon" twoToneColor="#FBA435" />

        <h1 className="unauthorized-title">
          <FormattedMessage id="unAuthorized" defaultMessage="You are not authorized to view this page." />
        </h1>
      </div>
    </DPTemplate>
  );
}
