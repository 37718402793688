// @flow
import * as React from 'react';
import { useState } from 'react';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { Button, Form, Input, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import type { TSkipper } from '../../../types/index';
import { ContactDetailForm } from '../../molecules/contact_details/ContactDetailForm';
import { FormStyles } from '../../../theme/form';
import { UnavailabilityWindowForm } from '../../molecules/UnavailabilityWindows/UnavailabilityWindowForm';
import { SITE_PATHS } from '../../../config/api.constants';
import type { TLabel } from '../../../types/label.types';
import { cleanContactable } from '../../../utils/form.utils';

type TProps = {
  autocompleteAction: Function,
  autocompletions: ?(TLabel[]),
  clearAutocompletionsAction: Function,
  handleSubmit: (skipper: TSkipper) => void,
  intl: Object,
  loadingCompletions: boolean,
  skipper?: ?TSkipper,
};

const EXTRA_TRANSLATIONS = defineMessages({
  validation_null: {
    id: 'organisms.skippers.validations.field_null',
    defaultMessage: 'Please enter a value',
  },
});

function SkipperForm(props: TProps) {
  const [form] = useForm();
  const [selectedAutocompletions, setSelectedAutocompletions] = useState<TLabel[]>((props.skipper && props.skipper.barges) || []);

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const cleanedSkipper = cleanContactable(values);
      cleanedSkipper.barges = selectedAutocompletions;
      props.handleSubmit(cleanedSkipper);
    });
  };

  const onSearch = (val: string) => {
    if (val && val.length >= 1) {
      props.autocompleteAction(val);
    } else {
      props.clearAutocompletionsAction();
    }
  };

  const selectAutocompletion = (value) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newSelection = [...selectedAutocompletions];
    const newVal = props.autocompletions && props.autocompletions.find((autocompletion) => autocompletion.label === value);

    if (newVal) {
      newSelection.push(newVal);
    }

    setSelectedAutocompletions(newSelection);
  };

  const deselectAutocompletion = (value) => {
    // eslint-disable-next-line react/no-access-state-in-setstate
    const newSelection = [...selectedAutocompletions];

    setSelectedAutocompletions(newSelection.filter((val) => val.label !== value));
  };

  const noContentRenderer = () => {
    if (props.loadingCompletions) {
      return <Spin size="small" />;
    }

    if (props.autocompletions && props.autocompletions.length === 0) {
      return <FormattedMessage id="organisms.skippers.lastname" defaultMessage="No barges found" />;
    }

    return null;
  };

  const { skipper, intl } = props;
  const contactDetails = skipper ? skipper.contactDetails : [];
  const unavailabilityWindows = skipper ? skipper.unavailabilityWindows : [];
  const formCols = FormStyles.vertical;

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          {...formCols}
          name="firstName"
          initialValue={props.skipper ? props.skipper.firstName : ''}
          rules={[
            {
              message: intl.formatMessage(EXTRA_TRANSLATIONS.validation_null),
              required: true,
              whitespace: true,
            },
          ]}
          label={<FormattedMessage id="organisms.skippers.firstName" defaultMessage="First Name" />}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="lastName"
          initialValue={props.skipper ? props.skipper.lastName : ''}
          rules={[
            {
              message: intl.formatMessage(EXTRA_TRANSLATIONS.validation_null),
              required: true,
              whitespace: true,
            },
          ]}
          label={<FormattedMessage id="organisms.skippers.lastName" defaultMessage="Last Name" />}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="barges"
          initialValue={props.skipper && props.skipper.barges.length !== 0 ? props.skipper.barges.map((barge: TLabel) => barge.label) : undefined}
          label={<FormattedMessage id="organisms.skippers.barges.select" defaultMessage="Select barges to attach" />}
        >
          <Select
            placeholder={<FormattedMessage id="organisms.skippers.barges.placeholder" defaultMessage="Select barges to attach" />}
            allowClear
            mode="multiple"
            onSearch={onSearch}
            notFoundContent={noContentRenderer()}
            style={{ width: '100%' }}
            filterOption={false}
            onSelect={selectAutocompletion}
            onDeselect={deselectAutocompletion}
          >
            {props.autocompletions && !props.loadingCompletions
              ? props.autocompletions.map((autocompletion: TLabel) => <Select.Option key={autocompletion.label}>{autocompletion.label}</Select.Option>)
              : null}
          </Select>
        </Form.Item>

        <ContactDetailForm form={form} data={contactDetails} />
        <UnavailabilityWindowForm form={form} data={unavailabilityWindows} />

        <Form.Item>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </Button>
        </Form.Item>
      </Form>
      <Link to={SITE_PATHS.SKIPPER_LIST}>
        <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
      </Link>
    </>
  );
}

export default injectIntl(SkipperForm);
