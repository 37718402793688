// @flow
import { Collapse, Tag } from 'antd';
import * as React from 'react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { getVisitContainerPageAction } from '../../../store/actions';
import type { TContainerVisit, TPageRequest, TPageWithFilter, TVisit } from '../../../types';
import { formatTableSize } from '../../../utils/format.utils';
import { CollapseHeader } from '../../atoms/CollapseHeader';
import { ContainerTableColumns } from '../../atoms/Tables/ContainerTableProps';
import { Table } from '../../atoms/Tables/Table';
import { Elearning } from '../../atoms/elearning';

type TProps = {
  containersAfterCutOff: number,
  containersOverlanded: number,
  elearningContentKey: string,
  loadingPage: boolean,
  page: TPageWithFilter<TContainerVisit>,
  visit: TVisit,
};

export function ContainerCollapse(props: TProps) {
  const dispatch = useDispatch();
  const onGetPage = useCallback(
    (pageRequest?: TPageRequest<null>) => dispatch(getVisitContainerPageAction({ pageable: pageRequest, visitId: props.visit.id })),
    [dispatch, getVisitContainerPageAction, props.visit],
  );

  const sortHandler = (pageRequest: TPageRequest<null>, sorter: any): TPageRequest<null> => {
    const request = { ...pageRequest };
    const additionalProps = ['movement', 'loadState', 'length', 'containerNumber'].filter((val) => val !== sorter.field);

    request.sorting = {
      by: [sorter.field, ...additionalProps],
      order: sorter.order || '',
    };

    return request;
  };

  const isContainerAfterCutOff = (container: TContainerVisit): string => (container.afterTalCutOff ? 'container--after-cut-off' : '');

  return (
    <Collapse className="collapse section-full-width" bordered={false}>
      <Collapse.Panel
        header={
          <>
            <Elearning contentKey={props.elearningContentKey} />
            <CollapseHeader
              subTitle={props.page && props.page.content && props.page.content.length ? `${formatTableSize(props.page.content)} - Overlanded (${props.containersOverlanded})` : ''}
              title={<FormattedMessage id="organisms.visits.visit_detail.panel.container.header" defaultMessage="Containers" />}
            >
              {props.containersAfterCutOff === 0 ? null : (
                <span>
                  <FormattedMessage id="collapse.container_table.header.cutoff" defaultMessage="After cut-off" />
                  <Tag style={{ marginLeft: 5 }} color="red">
                    {props.containersAfterCutOff}
                  </Tag>
                </span>
              )}
            </CollapseHeader>
          </>
        }
        className="panel"
      >
        <div className="collapse-content-wrapper">
          <Table
            columns={ContainerTableColumns}
            getPageAction={onGetPage}
            loading={{ spinning: props.loadingPage, delay: 1000 }}
            page={props.page}
            rowKey="id"
            rowClassName={isContainerAfterCutOff}
            onSort={sortHandler}
            scroll={{ y: 737 }}
          />
        </div>
      </Collapse.Panel>
    </Collapse>
  );
}
