// @flow
import { notification } from 'antd';
import { defineMessages, intlShape } from 'react-intl';
import { useEffectOnce } from '../../../utils/hooks/useEffectOnce.ts';

type TProps = {
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  skipperDeletedMessage: {
    id: 'skipper.delete.confirmation',
    defaultMessage: 'Skipper deleted',
  },
  skipperDeletedDescription: {
    id: 'skipper.delete.confirmation.description',
    defaultMessage: 'Skipper successfully deleted',
  },
});

export function SkipperDelete({ intl }: TProps) {
  useEffectOnce(() => {
    notification.success({
      message: intl.formatMessage(EXTRA_TRANSLATIONS.skipperDeletedMessage),
      description: intl.formatMessage(EXTRA_TRANSLATIONS.skipperDeletedDescription),
    });
  });
}
