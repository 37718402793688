// @flow
import type { TAction, TBaseAction } from '../../types';
import type { TVisitRemark } from '../../types/remark.types';

export const VisitRemarksActions = {
  create: 'VISIT_REMARK.CREATE',
  receivedList: 'VISIT_REMARK.RECEIVED_LIST',
  list: 'VISIT_REMARK.LIST',
};

export const getVisitRemarksAction = (payload: number): TAction => ({
  payload,
  type: VisitRemarksActions.list,
});

export const receivedVisitRemarkAction = (payload: TVisitRemark[]): TBaseAction<TVisitRemark[]> => ({
  payload,
  type: VisitRemarksActions.receivedList,
});

export const createVisitRemarkAction = (payload: TVisitRemark): TBaseAction<TVisitRemark> => ({
  payload,
  type: VisitRemarksActions.create,
});
