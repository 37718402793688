// @flow
import * as React from 'react';
import { Col, Row } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { getSaldoOverviewAction, getShiftQuaysOverviewAction, getTotalsOverviewAction } from '../../../store/actions/barge_clerk.actions';
import { updateTalCounter } from '../../../store/handlers/visits.handler';
import type { TGlobalState } from '../../../types';
import type { TBargeClerkSaldo, TShiftQuay } from '../../../types/barge_clerk.types';
import { Socket } from '../../atoms/Socket';
import { OverviewSaldoContainer } from './OverviewSaldoContainer';
import { OverviewTotalsContainer } from './OverviewTotalsContainer';
import { GangListContainer } from '../../molecules/barge_clerks/GangListContainer';
import { ShiftTalContainer } from './ShiftTalContainer';
import { EVENT_TYPES } from '../../../constants/update.constants';
import { mapElementsToNumber } from '../../../utils/array.utils';

type TProps = {
  getSaldoOverviewAction: typeof getSaldoOverviewAction,
  getShiftQuaysOverviewAction: typeof getShiftQuaysOverviewAction,
  getTotalsOverviewAction: typeof getTotalsOverviewAction,
  loading: boolean,
  saldo: TBargeClerkSaldo,
  shiftQuays: TShiftQuay[],
  totals: TBargeClerkSaldo,
  url: Object,
};

export class InfoBarComponent extends React.Component<TProps> {
  generateUpdateTalCounter = () => {
    let { ownShift, otherShift, ownQuay, otherQuay } = this.props.url;
    const ownShiftId = ownShift ? Number(ownShift) : 0;

    const hasSelectedACombination = ownShiftId > 0 && ownQuay && ownQuay.length > 0;
    if (hasSelectedACombination) {
      ownShift = parseInt(ownShift, 10);
      otherShift = mapElementsToNumber(otherShift);
      ownQuay = mapElementsToNumber(ownQuay);
      otherQuay = mapElementsToNumber(otherQuay);

      return updateTalCounter({
        getSaldoOverviewAction: this.props.getSaldoOverviewAction,
        getTotalsOverviewAction: this.props.getTotalsOverviewAction,
        getShiftQuaysOverviewAction: this.props.getShiftQuaysOverviewAction,
        quayIds: ownQuay,
        shiftIds: [ownShift],
        otherQuayIds: otherQuay,
        otherShiftIds: otherShift,
      });
    }

    return () => {};
  };

  render() {
    return (
      <Row className="info-bar">
        <Socket
          topics={[EVENT_TYPES.SHIFT_TAL_ROW]}
          onMessage={this.generateUpdateTalCounter()}
          socketKey={`InfoBar.${this.props.url.ownShift}.${this.props.url.ownQuay}.${this.props.url.otherShift}.${this.props.url.otherQuay}`}
        >
          <Col span={3} className="infobar-section-column">
            <div className="infobar-section divided">
              <h3>
                <FormattedMessage id="organisms.barge_clerks.infobar.saldocounter" defaultMessage="Balance" />
              </h3>
              <OverviewSaldoContainer url={this.props.url} getSaldoOverviewAction={this.props.getSaldoOverviewAction} saldo={this.props.saldo} />
            </div>
          </Col>
          <Col span={3}>
            <div className="infobar-section divided">
              <OverviewTotalsContainer url={this.props.url} getTotalsOverviewAction={this.props.getTotalsOverviewAction} totals={this.props.totals} />
            </div>
          </Col>
        </Socket>
        <Col span={6}>
          <div className="infobar-section divided">
            <h3>
              <FormattedMessage id="organisms.barge_clerks.infobar.shift_tal_list" defaultMessage="TAL per shift" />
            </h3>
            <ShiftTalContainer url={this.props.url} shiftQuays={this.props.shiftQuays} loading={this.props.loading} getShiftQuaysOverviewAction={this.props.getShiftQuaysOverviewAction} />
          </div>
        </Col>
        <Col span={12}>
          <div className="infobar-section">
            <GangListContainer url={this.props.url} />
          </div>
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  saldo: state.bargeClerks.saldo,
  shiftQuays: state.shiftQuays.list,
  loading: state.shiftQuays.loading,
  totals: state.bargeClerks.totals,
});

export const InfoBar = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getSaldoOverviewAction,
  getShiftQuaysOverviewAction,
  getTotalsOverviewAction,
})(InfoBarComponent);
