// @flow
import type { TBaseAction, TContainerVisit, TPageWithFilter } from '../../types';
import { TContainerVisitPageAction } from '../../types';
import type { TLPLGroup } from '../../types/lpl.types';

export const ContainerVisitActions = {
  page: 'CONTAINER_ACTIONS.PAGE',
  lpl: 'CONTAINER_ACTIONS.LPL_LIST',
  lplSocket: 'CONTAINER_ACTIONS.LPL_LIST_SOCKET_REFRESH',
  receivedLPLList: 'CONTAINER_ACTIONS.RECEIVED_LPL_VISIT',
  visitPage: 'CONTAINERS_ACTIONS.VISIT_PAGE',
  receivedVisitPage: 'CONTAINERS_ACTIONS.RECEIVED_VISIT_PAGE',
};

export const getVisitContainerPageAction = (payload: TContainerVisitPageAction): TBaseAction<TContainerVisitPageAction> => ({
  payload,
  type: ContainerVisitActions.visitPage,
});

export const receivedVisitContainerPageAction = (page: TPageWithFilter<TContainerVisit>): TBaseAction<{ page: TPageWithFilter<TContainerVisit> }> => ({
  payload: {
    page,
  },
  type: ContainerVisitActions.receivedVisitPage,
});

export const getLPLContainersAction = (payload: TLPLGroup): TBaseAction<TLPLGroup> => ({
  payload,
  type: ContainerVisitActions.lpl,
});

export const getLPLContainersSocketAction = (): TBaseAction<null> => ({
  payload: null,
  type: ContainerVisitActions.lplSocket,
});

export const receivedLPLContainersAction = (containerVisits: TContainerVisit[], lplGroupId: number): TBaseAction<{ containerVisits: TContainerVisit[], lplGroupId: number }> => ({
  payload: {
    containerVisits,
    lplGroupId,
  },
  type: ContainerVisitActions.receivedLPLList,
});
