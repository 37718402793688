// @flow
import { combineReducers } from 'redux';
import { GangRemarkActions } from '../../actions/gang_remark.actions';
import loadingReducer from '../loading.reducers';
import { remarkReducer } from './remark.reducer';

const reducer = combineReducers({
  loadingRemarks: loadingReducer(GangRemarkActions.get, GangRemarkActions.received),
  remarks: remarkReducer,
});

export default reducer;
