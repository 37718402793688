// @flow
import { call, put, takeLatest } from 'redux-saga/effects';
import { appApi } from '../../config/api.config';
import type { TBaseAction } from '../../types';
import type { TVisitLplRemark } from '../../types/remark.types';
import { genericErrorHandler } from '../../utils';
import { LPLRemarkActions, receivedLPLRemarksAction } from '../actions/lpl_remark.actions';
import { createPath } from '../../utils/routing.utils';
import { API_PATHS } from '../../config/api.constants';

export function* list(action: TBaseAction<number>): Generator<*, *, *> {
  const uri = createPath(API_PATHS.V1.VISIT_REMARK_LPL, { id: action.payload });
  const response = yield call(appApi.get, uri);
  yield put(receivedLPLRemarksAction(response.data));
}

export function* create(action: TBaseAction<TVisitLplRemark>): Generator<*, *, *> {
  const remarkUri = createPath(API_PATHS.V1.VISIT_REMARK, { id: action.payload.visitId });
  yield call(appApi.post, remarkUri, action.payload);
}

export function* LPLRemarkSaga(): Generator<*, *, *> {
  yield takeLatest(LPLRemarkActions.list, genericErrorHandler(list));
  yield takeLatest(LPLRemarkActions.create, genericErrorHandler(create));
}
