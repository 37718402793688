// @flow
import type { TSkipper } from '../../types';
import { UnavailabilityWindow } from './UnavailabilityWindow';

export class Skipper {
  static fromJson(skipper: Object) {
    return { ...skipper, unavailabilityWindows: skipper.unavailabilityWindows.map((window) => UnavailabilityWindow.fromJson(window)) };
  }

  static toJson(skipper: TSkipper) {
    // $FlowFixMe
    return { ...skipper, unavailabilityWindows: skipper.unavailabilityWindows.map((window) => UnavailabilityWindow.toJson(window)) };
  }
}
