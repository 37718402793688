// @flow
import { ElearningActions } from '../../actions';
import type { TBaseAction, TElearningContentState, TElearningPopover } from '../../../types';

const initialState: TElearningContentState = {};

const contentReducer = (state: TElearningContentState = initialState, action: TBaseAction<TElearningPopover>) => {
  if (action.type === ElearningActions.content) {
    const newState = { ...state };
    newState[action.payload.key] = action.payload;
    return newState;
  }
  return state;
};

export default contentReducer;
