// @flow
import type { TVisitFilter } from '../../types';
import { BargeClerkFilterActions } from '../actions/barge_clerk.actions';

export const filtersReducer =
  (receivedPageType: string) =>
  (state: ?TVisitFilter = null, action: Object) => {
    switch (action.type) {
      case receivedPageType:
        return action.payload && action.payload.filters ? action.payload.filters : state;
      case BargeClerkFilterActions.receivedVisitFilter:
        return { ...state, ...action.payload };
      default:
        return state;
    }
  };
