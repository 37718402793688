// @flow
import { combineReducers } from 'redux';
import loadingAuthentication from './authenticating.reducers';
import bootstrap from './bootstrap.reducers';
import authenticated from './authenticated.reducers';
import initialized from './initialized.reducers';
import authenticationCached from './authenticationCached.reducers';
import token from './token.reducers';

const reducer = combineReducers({
  bootstrap,
  authenticated,
  loadingAuthentication,
  initialized,
  authenticationCached,
  token,
});

export default reducer;
