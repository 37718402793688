// @flow
import * as React from 'react';
import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { addGangVisitAction, removeGangVisitAction } from '../../../store/actions';
import { getGangsAssignmentsAction } from '../../../store/actions/gang.actions';
import type { TGlobalState } from '../../../types';
import type { TGangLB } from '../../../types/gang.types';
import { Loading } from '../Loading';
import { AssignGang } from './AssignGang';

type TComponentProps = {
  addGangVisitAction: typeof addGangVisitAction,
  gangs: TGangLB[],
  getGangsAssignmentsAction: typeof getGangsAssignmentsAction,
  hideModal: Function,
  loading: boolean,
  removeGangVisitAction: typeof removeGangVisitAction,
  visible: boolean,
  visit: Object,
};

type TComponentState = {};

class AssignGangModalComponent extends React.Component<TComponentProps, TComponentState> {
  componentDidMount() {
    this.launchFetchAction(this.props.visit);
  }

  // eslint-disable-next-line react/sort-comp
  launchFetchAction(visit) {
    const { shiftId, saldoShiftId, quayPlannedId, quayRequestedId, gangs } = visit;
    const gangIds = gangs !== null && gangs.length > 0 ? gangs.map((gang) => gang.id) : [];
    const actualShift = saldoShiftId || shiftId;
    const quayId = quayPlannedId || quayRequestedId;
    this.props.getGangsAssignmentsAction([actualShift].filter(Boolean), [quayId].filter(Boolean), gangIds);
  }

  componentDidUpdate(prevProps: TComponentProps) {
    if (this.props.visible && !prevProps.visible) {
      this.launchFetchAction(this.props.visit);
    }
  }

  render() {
    return (
      <Modal
        maskClosable
        onCancel={this.props.hideModal}
        width={700}
        centered
        open={this.props.visible}
        footer={null}
        title={<FormattedMessage id="atoms.modals.assign_gang.title" defaultMessage="Assign gangs" />}
        destroyOnClose
      >
        {this.props.loading ? (
          <Loading />
        ) : (
          <AssignGang visit={this.props.visit} gangs={this.props.gangs} addGangVisitAction={this.props.addGangVisitAction} removeGangVisitAction={this.props.removeGangVisitAction} />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  gangs: state.gangAssignments.list,
  loading: state.gangAssignments.loading,
});

export const AssignGangModal = connect<any, Object, _, _, _, _>(mapStateToProps, {
  getGangsAssignmentsAction,
  addGangVisitAction,
  removeGangVisitAction,
})(AssignGangModalComponent);
