// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { capitalize } from '../../../../utils/format.utils';
import type { TDetail } from '../../../../types/detail.types';
import { MDDetailTable } from './MDDetailTable';

type TProps = {
  details: TDetail[],
  intl: intlShape,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'tables.details.general', defaultMessage: 'General details' },
});

const GeneralDetailTableColumns = [
  {
    dataIndex: 'label',
    render: (text: string) => (
      <span>
        <b>{capitalize(text.toLowerCase())}</b>
      </span>
    ),
    width: 200,
  },
  {
    dataIndex: 'value',
    render: (text: string) => <span>{text}</span>,
    width: 200,
  },
  {
    dataIndex: 'filler1', // filler column (temporary solution)
    render: () => '',
    width: 200,
  },
  {
    dataIndex: 'filler2', // filler column (temporary solution)
    render: () => '',
    width: 200,
  },
];

const generalDetailTable = (props: TProps) => <MDDetailTable columns={GeneralDetailTableColumns} dataSource={props.details} title={props.intl.formatMessage(EXTRA_TRANSLATIONS.title)} />;

export const GeneralDetailTable = injectIntl(generalDetailTable);
