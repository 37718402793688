// @flow
import React from 'react';
import { useSelector } from 'react-redux';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { ContactInfoModal } from '../modals/ContactInfoModal';

type TProps = {
  showContactInfo: boolean,
  hideModal: () => void,
};

export function BargeClerkContactInfoModal({ showContactInfo, hideModal }: TProps) {
  const visit = useSelector(selectBargeClerkVisit);

  return visit ? <ContactInfoModal visit={visit} showDialog={showContactInfo} hideModal={hideModal} /> : null;
}
