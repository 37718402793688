// @flow
import * as React from 'react';
import { FormattedMessage, injectIntl, useIntl } from 'react-intl';
import { Button, Checkbox, Form, Input, InputNumber } from 'antd';
import { Link } from 'react-router-dom';
import { useForm } from 'antd/es/form/Form';
import { VALIDATION_MESSAGES } from '../../../config/messages/validations';
import { FormStyles } from '../../../theme/form';
import type { TQuay } from '../../../types/index';
import { SITE_PATHS } from '../../../config/api.constants';

type TProps = {
  handleSubmit: Function,
  quay?: TQuay,
};

function QuayForm(props: TProps) {
  const intl = useIntl();
  const [form] = useForm();
  const { quay } = props;

  const handleServerErrors = (errors) => {
    const antFormat = [];

    if (errors.indexOf('name') > -1) {
      antFormat.push({ name: 'name', errors: [intl.formatMessage(VALIDATION_MESSAGES.not_unique)], value: form.getFieldValue('name') });
    }

    form.setFields(antFormat);
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      const { ...quay } = values;
      if (props.quay) quay.id = props.quay.id;
      props.handleSubmit(quay, handleServerErrors);
    });
  };

  const formCols = FormStyles.vertical;

  return (
    <>
      <Form form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          {...formCols}
          name="name"
          initialValue={quay ? quay.name : null}
          rules={[
            {
              max: 255,
              message: intl.formatMessage(VALIDATION_MESSAGES.max_characters),
              type: 'string',
            },
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
              whitespace: true,
            },
          ]}
          label={<FormattedMessage id="molecules.quays.create_form.name.label" defaultMessage="Name" />}
        >
          <Input />
        </Form.Item>

        <Form.Item {...formCols} name="terminal" initialValue={quay ? quay.terminal : null} label={<FormattedMessage id="molecules.quays.create_form.terminal.label" defaultMessage="Terminal" />}>
          <Input />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="length"
          initialValue={quay ? quay.length : null}
          rules={[
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.null),
              required: true,
            },
            {
              message: intl.formatMessage(VALIDATION_MESSAGES.number_of_centimeters),
              min: 1,
              max: 1000000,
              type: 'number',
            },
          ]}
          label={<FormattedMessage id="molecules.quays.create_form.length.label" defaultMessage="Length" />}
        >
          <InputNumber className="quay-length-input" />
        </Form.Item>

        <Form.Item
          {...formCols}
          name="geofence"
          initialValue={quay ? quay.geofence : null}
          rules={[
            () => ({
              validator(_, value) {
                if (!value) {
                  return Promise.resolve();
                }
                try {
                  const data = JSON.parse(value);
                  if (data.geometry && data.geometry.type === 'Polygon') {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error(intl.formatMessage(VALIDATION_MESSAGES.invalid_polygon)));
                } catch (e) {
                  return Promise.reject(new Error(intl.formatMessage(VALIDATION_MESSAGES.invalid_geojson)));
                }
              },
            }),
          ]}
          label={<FormattedMessage id="molecules.quays.create_form.geofence.label" defaultMessage="Geofence" />}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item {...formCols} name="psaQuay" initialValue={quay ? quay.psaQuay : null} valuePropName="checked">
          <Checkbox>
            <FormattedMessage id="molecules.quays.create_form.psa_quay.label" defaultMessage="PSA Quay" />
          </Checkbox>
        </Form.Item>

        <Form.Item {...formCols} name="bargePlanningByCBPA" initialValue={quay ? quay.bargePlanningByCBPA : null} valuePropName="checked">
          <Checkbox>
            <FormattedMessage id="molecules.quays.create_form.barge_planning_by_cbpa.label" defaultMessage="Barge planning by CBPA" />
          </Checkbox>
        </Form.Item>

        <Form.Item {...formCols}>
          <Button type="primary" htmlType="submit">
            <FormattedMessage id="general.submit" defaultMessage="Submit" />
          </Button>
        </Form.Item>
      </Form>
      <Link to={SITE_PATHS.QUAY_LIST}>
        <FormattedMessage id="general.cancel" defaultMessage="Cancel" />
      </Link>
    </>
  );
}

export default injectIntl(QuayForm);
