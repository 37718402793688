/* eslint-disable no-use-before-define */
// @flow
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SockJS from 'sockjs-client';
import Stomp from 'stompjs';
import { selectTokenFunction } from '../../store/selectors/socket.selectors';
import { WEBSOCKET_URL } from '../../config/settings';
import { isAuthenticationCachedAction } from '../../store/actions/authentication.actions';
import { log } from '../../utils/log.util';

type TProps = {
  onMessage: Function,
  topics: string[],
  socketKey: string,
};

// default setting
const heartbeat = 10000;
const retryTime = 1000;

export function StompSocket({ onMessage, topics, socketKey }: TProps) {
  const dispatch = useDispatch();
  const token = useSelector(selectTokenFunction);
  const [doRetry, setDoRetry] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(0);
  const [connected, setConnected] = useState(false);
  const [subscribedTopics, setSubscribedTopics] = useState([]);
  const webSocket = useRef(null);
  const getUrl = useCallback(() => `${WEBSOCKET_URL}?sessId=${token}`, [token]);

  // Disconnect when unmount is done
  useEffect(() => () => disconnect(true), []);

  const isConnected = () => !!webSocket.current && connected;

  // Reconnect when token is changed
  useEffect(() => {
    log.ws(socketKey)('Token has changed %s', token);
    reconnect();
  }, [token]);

  useEffect(() => {
    if (isConnected()) {
      log.ws(socketKey)('SocketKey has changed %s', socketKey);
      reconnect();
    }
  }, [socketKey]);

  // Schedule retry when doRetry is changed
  useEffect(() => {
    if (doRetry) {
      log.ws(socketKey)(`Retry (#${retryAttempts})`);
      setDoRetry(false);

      if (retryAttempts % 5 === 0) {
        log.ws(socketKey)(`Retry (#${retryAttempts}) - Check token...`);
        // Make sure a correct token is being used, only need to do this at the start of the issue
        dispatch(isAuthenticationCachedAction());
      }

      log.ws(socketKey)(`Retry (#${retryAttempts}) scheduled in ${retryTime / 1000} sec`);
      setTimeout(reconnect, retryTime);
      setRetryAttempts(retryAttempts + 1);
    }
  }, [doRetry]);

  // Actions after connection state changed
  useEffect(() => {
    if (isConnected()) {
      log.ws(socketKey)('isConnected');
      try {
        topics.forEach(subscribeTopic);
        setSubscribedTopics(topics);
      } catch (error) {
        onError(error);
      }
    }
  }, [webSocket.current, connected]);

  const getStompClient = () => {
    const client = Stomp.over(new SockJS(getUrl(), null, {}));
    client.heartbeat.outgoing = heartbeat;
    client.heartbeat.incoming = heartbeat;
    return client;
  };

  const connect = () => {
    log.ws(socketKey)('connect - Connecting websocket...');
    if (!token) {
      log.ws(socketKey)('connect - Cancel connecting websocket because there is no token available');
      return;
    }

    if (!isConnected()) {
      log.ws(socketKey)('connect - get websocket client...');
      webSocket.current = getStompClient();
    } else {
      log.ws(socketKey)('connect - websocket client already defined');
    }
    webSocket.current.connect(
      {},
      () => {
        log.ws(socketKey)('onConnect - Connection established - Set connected to true');
        setConnected(true);
      },
      onError,
    );
  };

  const disconnect = (force, callback) => {
    log.ws(socketKey)(`disconnect - Current status '${isConnected() ? 'connected' : 'disconnected'}'`);
    try {
      if (isConnected()) {
        unsubscribeTopics();
        webSocket.current.disconnect(getOnDisconnect(callback));
      } else {
        log.ws(socketKey)(`disconnect`, webSocket.current);

        if (force && webSocket.current) {
          unsubscribeTopics();
          webSocket.current.disconnect(getOnDisconnect(callback));
        }
        getOnDisconnect(callback)();
      }
    } catch (e) {
      log.ws(socketKey)(`Error disconnecting - '${e}'`);
    }
  };

  const getOnDisconnect = (callback) => () => {
    log.ws(socketKey)('onDisconnect');
    webSocket.current = null;
    setConnected(false);
    if (callback) callback();
  };

  const reconnect = () => {
    log.ws(socketKey)('reconnect started');
    if (isConnected()) {
      disconnect(false, connect);
    } else {
      connect();
    }
  };

  const onError = (error) => {
    log.ws(socketKey)(`error (#${retryAttempts}) %O`, error);
    setDoRetry(true);
  };

  const processMessage = (msgBody) => {
    log.ws(socketKey)(`processMessage %O `, msgBody);
    try {
      return JSON.parse(msgBody);
    } catch (e) {
      return msgBody;
    }
  };

  const subscribeTopic = (topic) => {
    log.ws(socketKey)(`subscribeTopic ${topic}`);
    if (isConnected()) {
      try {
        webSocket.current.subscribe(topic, (msg) => onMessage(processMessage(msg.body)), {});
        log.ws(socketKey)(`subscribeTopic ${topic} done`);
      } catch (e) {
        // Catch for logging, throw error up
        log.ws(socketKey)(`subscribeTopic ${topic} error subscribing`);
        throw e;
      }
    }
  };

  const unsubscribeTopics = () => {
    log.ws(socketKey)('unsubscribeTopics - unsubscribing %O', topics);
    subscribedTopics.forEach((topic) => webSocket.current.unsubscribe(topic));
    setSubscribedTopics([]);
    log.ws(socketKey)('unsubscribeTopics - all topics unsubscribed');
  };

  return null;
}
