// @flow
import { BargeActions } from '../../actions';
import type { TBarge } from '../../../types';

const initialState: ?TBarge = null;

const reducer = (state: ?TBarge = initialState, action: Object) => {
  switch (action.type) {
    case BargeActions.receivedDetail:
      return action.payload;

    default:
      return state;
  }
};

export const detailReducer = reducer;
