// @flow
export const ROLES = {
  ROLE_PLANNER: 'ROLE_PLANNER',
  ROLE_OPERATIONS_MANAGER: 'ROLE_OPERATIONS_MANAGER',
  ROLE_LICHTER_BEDIENDE: 'ROLE_LICHTER_BEDIENDE',
  ROLE_DESK: 'ROLE_DESK',
  ROLE_DATA_PROCESSOR: 'ROLE_DATA_PROCESSOR',
  ROLE_MASTER_DATA_MANAGEMENT: 'ROLE_MASTER_DATA_MANAGEMENT',
  ROLE_USER: 'ROLE_USER',
  ROLE_EMPTIES_YARD: 'ROLE_EMPTIES_YARD',
};
