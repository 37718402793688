// @flow
import * as React from 'react';
import { Button, Modal } from 'antd';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { InfoCircleFilled } from '@ant-design/icons';
import { formatDate } from '../../utils/dates.utils';
import { TALGrid } from './TALGrid';
import type { TShiftQuay } from '../../types/barge_clerk.types';

type TProps = {
  shiftQuay: TShiftQuay,
};

type TState = {
  visible: boolean,
};

export class TalModal extends React.Component<TProps, TState> {
  constructor(props: TProps) {
    super(props);
    this.state = { visible: false };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleClose = () => {
    this.setState({ visible: false });
  };

  getTable() {
    return (
      <div>
        <p>
          <strong>
            <FormattedMessage id="tal_modal.labels.quay" defaultMessage="Quay" />:{' '}
          </strong>
          {this.props.shiftQuay.quayName}

          <br />

          <strong>
            <FormattedMessage id="tal_modal.labels.shift" defaultMessage="Shift" />:{' '}
          </strong>
          {this.props.shiftQuay.shiftName}

          <br />

          <strong>
            <FormattedMessage id="tal_modal.labels.from" defaultMessage="From" />:{' '}
          </strong>
          {formatDate(moment(this.props.shiftQuay.shiftStartTime))}

          <br />

          <strong>
            <FormattedMessage id="tal_modal.labels.until" defaultMessage="Until" />:{' '}
          </strong>
          {formatDate(moment(this.props.shiftQuay.shiftEndTime))}

          <br />

          <strong>
            <FormattedMessage id="tal_modal.labels.total_tal" defaultMessage="Total TAL" />:{' '}
          </strong>
          {this.props.shiftQuay.totalWithSaldo}

          <br />
          <br />
        </p>
        <TALGrid shiftQuay={this.props.shiftQuay} />
      </div>
    );
  }

  render() {
    return (
      <>
        <Button type="button" onClick={this.showModal} size="small" style={{ fontSize: 11, marginRight: '2px' }}>
          <InfoCircleFilled />
        </Button>
        <Modal
          className="tal-modal"
          cancelButtonProps={{ style: { display: 'none' } }}
          title={<FormattedMessage id="atoms.tal_modal.title" defaultMessage="TAL Details" />}
          open={this.state.visible}
          onCancel={this.handleClose}
          onOk={this.handleClose}
          maskClosable
        >
          {this.getTable()}
        </Modal>
      </>
    );
  }
}
