// @flow
import { combineReducers } from 'redux';
import loadingReducer from '../loading.reducers';
import { GangActions } from '../../actions/gang.actions';
import { editReducer } from './edit.reducer';
import { lastModifiedReducer } from './lastModified.reducer';
import { listReducer } from './list.reducer';
import { previousNumberOfGangsReducer } from './previousNumberOfGangs.reducer';

const reducer = combineReducers({
  loading: loadingReducer(GangActions.getGangs, GangActions.receivedGetGangs),
  loadingEdit: loadingReducer(GangActions.getGangsEdit, GangActions.receivedEditGangs),
  list: listReducer,
  edit: editReducer,
  lastModified: lastModifiedReducer,
  previousNumberOfGangLb: previousNumberOfGangsReducer,
});

export default reducer;
