// @flow
import type { TAction, TBaseAction } from '../../types';
import type { TShiftLabel } from '../../types/barge_clerk.types';

export const ShiftActions = {
  clearFor: 'SHIFT_ACTIONS.CLEAR_FOR',
  for: 'SHIFT_ACTIONS.FOR',
  receivedFor: 'SHIFT_ACTIONS.RECEIVED_FOR',
  get: 'SHIFT_ACTIONS.GET',
  received: 'SHIFT_ACTIONS.RECEIVED',
};

export const getShiftsAction = () => ({
  type: ShiftActions.get,
});

export const receivedShiftsAction = (payload: TShiftLabel[]): TBaseAction<TShiftLabel[]> => ({
  payload,
  type: ShiftActions.received,
});

export const clearShiftForAction = (): TAction => ({
  type: ShiftActions.clearFor,
});

export const shiftForAction = (payload: string): TBaseAction<string> => ({
  payload,
  type: ShiftActions.for,
});

export const receivedShiftForAction = (payload: TShiftLabel[]): TBaseAction<TShiftLabel[]> => ({
  payload,
  type: ShiftActions.receivedFor,
});
