// @flow
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { deleteOrEmpty } from '../renderers';

export const SkipperTableProps = (deleteAction: Function) => [
  {
    dataIndex: 'firstName',
    key: 'firstName',
    title: <FormattedMessage id="organisms.skippers.firstName" defaultMessage="First Name" />,
    width: 570,
  },
  {
    dataIndex: 'lastName',
    key: 'lastName',
    title: <FormattedMessage id="organisms.skippers.lastName" defaultMessage="Last Name" />,
  },
  {
    key: 'actions',
    render: deleteOrEmpty(deleteAction),
    title: <FormattedMessage id="organisms.skippers.actions" defaultMessage="Actions" />,
    width: 127,
  },
];
