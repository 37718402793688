// @flow
import * as React from 'react';
import { defineMessages, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { EditTemplate } from '../../templates/MasterData/EditTemplate';
import { EditableTable } from '../../atoms/Tables/EditableTable';
import { getSystemSettingsPageAction, updateSystemSettingAction } from '../../../store/actions/system_settings.actions';
import type { TSystemSetting } from '../../../types/system_setting.types';
import type { TGlobalState } from '../../../types';

type TProps = {
  getSystemSettingsPageAction: typeof getSystemSettingsPageAction,
  intl: intlShape,
  loadingSettingsPage: boolean,
  settings: TSystemSetting[],
  updateSystemSettingAction: typeof updateSystemSettingAction,
};

const EXTRA_TRANSLATIONS = defineMessages({
  title: { id: 'pages.configuration.header.title', defaultMessage: 'Configure' },
});

const settingColumns = [
  {
    dataIndex: 'description',
    title: 'Description',
    width: '30%',
  },
  {
    dataIndex: 'value',
    title: 'Value',
    editable: true,
  },
];

class Configuration extends React.Component<TProps> {
  componentDidMount() {
    this.props.getSystemSettingsPageAction();
  }

  render() {
    return (
      <EditTemplate title={this.props.intl.formatMessage(EXTRA_TRANSLATIONS.title)}>
        <EditableTable columns={settingColumns} data={this.props.settings} rowKey="id" saveAction={this.props.updateSystemSettingAction} loading={this.props.loadingSettingsPage} />
      </EditTemplate>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  loadingSettingsPage: state.systemSettings.loadingPage,
  settings: state.systemSettings.page,
});

export const ConfigurationPage = connect<any, Object, _, _, _, _>(mapStateToProps, { getSystemSettingsPageAction, updateSystemSettingAction })(injectIntl(Configuration));
