// @flow
import { combineReducers } from 'redux';
import { LPLActions } from '../../actions/lpl.actions';
import { payloadReducer } from '../payload.reducer';
import { lplRequestReducer } from './lplRequests.reducer';
import { pageReducer } from '../page.reducers';
import { filtersReducer } from './filters.reducers';
import loadingReducer from '../loading.reducers';

const reducer = combineReducers({
  lplGroups: payloadReducer(LPLActions.receivedGroup),
  lplRequests: lplRequestReducer,
  filters: filtersReducer,
  loadingPage: loadingReducer(LPLActions.page, LPLActions.receivedPage),
  page: pageReducer(LPLActions.receivedPage),
});

export default reducer;
