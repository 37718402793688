// @flow
import { Modal } from 'antd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../../types';
import type { TGangLB } from '../../../types/gang.types';
import { getGangsEditAction, receivedEditGangsAction } from '../../../store/actions/gang.actions';
import { Loading } from '../Loading';
import { GangModal } from '../modals/GangModal';

type TProps = {
  gangs: TGangLB[],
  getGangsEditAction: typeof getGangsEditAction,
  hideModal: Function,
  loading: boolean,
  quayId: number,
  receivedEditGangsAction: typeof receivedEditGangsAction,
  shiftEndTime: string,
  shiftId: number,
  shiftStartTime: string,
  visible: boolean,
};

type TState = {};

class GangModalContainerComponent extends React.Component<TProps, TState> {
  componentDidUpdate(prevProps: TProps) {
    if (this.props.visible && prevProps.visible !== this.props.visible) {
      this.props.getGangsEditAction(this.props.shiftId, this.props.quayId);
    }

    if (!this.props.visible && prevProps.visible !== this.props.visible) {
      this.props.receivedEditGangsAction([]);
    }
  }

  render() {
    const { gangs, shiftId, quayId, shiftStartTime, shiftEndTime } = this.props;
    return (
      <Modal
        maskClosable
        onCancel={this.props.hideModal}
        width={700}
        centered
        open={this.props.visible}
        footer={null}
        title={<FormattedMessage id="atoms.modals.gang.title" defaultMessage="Assign gangs" />}
        destroyOnClose
      >
        {this.props.loading ? <Loading /> : <GangModal gangs={gangs} shiftId={shiftId} quayId={quayId} shiftStartTime={shiftStartTime} shiftEndTime={shiftEndTime} />}
      </Modal>
    );
  }
}

const mapStateToProps = (state: TGlobalState) => ({
  gangs: state.gangs.edit,
  loading: state.gangs.loadingEdit,
});

export const GangModalContainer = connect<any, Object, _, _, _, _>(mapStateToProps, { getGangsEditAction, receivedEditGangsAction })(GangModalContainerComponent);
