// @flow
import type { TAction, TBaseAction } from '../../types';

export const NotificationActions = {
  showNotification: 'ALERT_ACTIONS.SHOW_ALERT',
  hideNotification: 'ALERT_ACTIONS.HIDE_ALERT',
  resetNotification: 'ALERT_ACTIONS.RESET',
};

export const showNotificationAction = (payload: string): TBaseAction<string> => ({ type: NotificationActions.showNotification, payload });
export const resetNotificationAction = (): TAction => ({
  type: NotificationActions.resetNotification,
});
