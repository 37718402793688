// @flow
import type { TBaseAction } from '../../types';

export const GeneralActions = {
  setLanguage: 'SET_LANGUAGE',
};

export const setLanguageAction = (payload: string): TBaseAction<string> => ({
  payload,
  type: GeneralActions.setLanguage,
});
