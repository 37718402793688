// @flow
import * as React from 'react';
import { Layout } from 'antd';
import { AppTemplate } from './AppTemplate';
import { Loading } from '../atoms/Loading';

const { Content } = Layout;

type TProps = {
  children: React.Node,
  loading?: boolean,
  menuBlock: string,
  menuItem: string,
};

export function DPTemplate(props: TProps) {
  return (
    <AppTemplate className="ant-layout-block" menuBlock={props.menuBlock} menuItem={props.menuItem}>
      <Layout className="fill-parent scrollable ant-layout-block">
        <Content className="content-dp">{props.loading ? <Loading /> : props.children}</Content>
      </Layout>
    </AppTemplate>
  );
}

DPTemplate.defaultProps = {
  loading: false,
};
