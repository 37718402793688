/* eslint-disable */
// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import type { TGlobalState } from '../../types';
import { LoginPage } from '../pages/Login';
import { PageLoader } from './PageLoader';

type TProps = {
  children: React.Node,
  isAuthenticated: boolean,
  isAuthenticationCached: boolean,
  loadingAuthentication: boolean,
};

function Auth(props: TProps) {
  if (props.isAuthenticationCached) {
    return props.children;
  }

  if (props.loadingAuthentication) {
    return <PageLoader />;
  }

  return props.isAuthenticated ? <div>{props.children}</div> : <LoginPage />;
}

const mapStateToProps = (state: TGlobalState) => ({
  isAuthenticated: state.user.authenticated,
  loadingAuthentication: state.user.loadingAuthentication,
});

export const AuthController = connect<any, Object, _, _, _, _>(mapStateToProps, {})(Auth);
