// @flow
import * as React from 'react';
import { useCallback } from 'react';
import Icon from '@ant-design/icons';
import { Select } from 'antd';
import { keys } from 'lodash';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { cancelVisitAction } from '../../../store/actions';
import { selectBargeClerkVisit } from '../../../store/selectors/barge_clerk.selectors';
import { CANCEL_REASON_MESSAGES } from '../../../config/messages/cancelReasons';
import { ReactComponent as materialWarning } from '../../../resources/material-warning.svg';
import { SPACING } from '../../../constants/style.constants';
import { formatDateTime } from '../../../utils/dates.utils';
import { VISIT_STATUS } from '../../../constants/visit.constants';

type TProps = {
  intl: intlShape,
};

const cancelledSectionComponent = ({ intl }: TProps) => {
  const visit = useSelector(selectBargeClerkVisit);
  const dispatch = useDispatch();

  const onChangeCancelReason = useCallback((reason) => dispatch(cancelVisitAction(visit, reason)), [dispatch, cancelVisitAction, visit]);

  return (
    <div className="label-red">
      <span>
        <Icon theme="outlined" component={materialWarning} style={{ marginRight: SPACING.DP }} />
        <strong>
          <FormattedMessage id="atoms.barge_clerks.cancelled_section.visit_cancelled" defaultMessage="Visit cancelled" />
        </strong>
      </span>
      <span style={{ fontSize: 12, marginLeft: SPACING.DP }}>{formatDateTime(visit.cancelledAt)}</span>
      <Select
        defaultValue={visit.cancelReason}
        style={{ marginLeft: SPACING.COLLAPSE * 2 }}
        onChange={onChangeCancelReason}
        allowClear={visit.status === VISIT_STATUS.DECISION_CBPA}
        value={visit.cancelReason}
      >
        {keys(CANCEL_REASON_MESSAGES).map((key) => (
          <Select.Option key={key} value={key}>
            {intl.formatMessage(CANCEL_REASON_MESSAGES[key])}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export const CancelledSection = injectIntl(cancelledSectionComponent);
