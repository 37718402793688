// @flow
import * as React from 'react';

type TProps = {
  style?: ?Object,
  subTitle: string | React.Node,
  title: string | React.Node,
};

export function TitleSubtitle(props: TProps) {
  return (
    <div className="title-subtitle-wrapper" style={{ ...props.style }}>
      <span>{props.title}</span>&nbsp;
      <span className="text-subtitle">{props.subTitle}</span>
    </div>
  );
}

TitleSubtitle.defaultProps = {
  style: null,
};
